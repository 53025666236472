import * as React from 'react'
import {CenteredSpinner} from '@freckle/educator-materials/ts/components/spinner'
import {mmap} from '@freckle/maybe'
import {fromMaybe} from '@freckle/maybe'
import {useSafeEffectExtraDeps, CallbackFn, unCallbackFn} from '@freckle/react-hooks'
import {getConfig} from '@freckle/classroom/ts/common/marketo-config'
import {marketoFormClass, marketoDivClass} from './marketo-form.module.scss'

const {
  MARKETO_SCRIPT_SRC,
  MARKETO_BASE_URI,
  MARKETO_MUNCHKIN_ID,
  MARKETO_QUOTE_REQUEST_FORM_ID_BY_COUNTRY,
} = getConfig()

export const MarketoForm = (props: {
  countryCode: string | null | undefined
  onSuccess: CallbackFn<(a: {mktoFormId: string; followUpUrl: string}) => void>
}): React.ReactElement => {
  const {onSuccess, countryCode} = props

  const [isLoading, setIsLoading] = React.useState(true)
  const marketoFormRef = React.useRef(null)

  const formId = fromMaybe(
    () => MARKETO_QUOTE_REQUEST_FORM_ID_BY_COUNTRY.DEFAULT,
    mmap(countryCode => MARKETO_QUOTE_REQUEST_FORM_ID_BY_COUNTRY[countryCode], countryCode)
  )

  useSafeEffectExtraDeps<{
    onSuccess: (a: {mktoFormId: string; followUpUrl: string}) => void
  }>(
    ({onSuccess}) => {
      const isMarketoLoaded = () => typeof MktoForms2 !== 'undefined'

      if (!isMarketoLoaded()) {
        const script = document.createElement('script')
        script.src = MARKETO_SCRIPT_SRC
        document.body.appendChild(script)
      }

      //Poll for the Marketo script to finish loading
      const loadForm = setInterval(() => {
        if (isMarketoLoaded()) {
          clearInterval(loadForm)

          //API from: https://developers.marketo.com/javascript-api/forms/api-reference/
          MktoForms2.onFormRender(() => setIsLoading(false))
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          MktoForms2.loadForm(MARKETO_BASE_URI, MARKETO_MUNCHKIN_ID, formId, (form: any) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            form.render($(marketoFormRef.current as any))
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            form.onSuccess((_values: any, followUpUrl: string) => {
              onSuccess({mktoFormId: form.getId(), followUpUrl})
              //This will prevent the form from redirecting
              return false
            })
          })
        }
      }, 100)

      return () => clearInterval(loadForm)
    },
    [formId],
    {onSuccess: {value: unCallbackFn(onSuccess), comparator: () => true}}
  )

  return (
    //Hard coding these classnames for use by the Marketto css
    <div className={`${marketoDivClass} form-container freckle-form freckle-col-2`}>
      <form
        className={marketoFormClass}
        ref={marketoFormRef}
        data-test={`marketo-form-${isLoading ? 'loading' : 'loaded'}`}
      >
        {isLoading && <CenteredSpinner />}
      </form>
    </div>
  )
}
