import {
  ParserT,
  field,
  record,
  firstOf,
  merge,
  number,
  literal,
  onSelf,
  oneOf,
  obfuscated,
} from '@freckle/parser'
import {exhaustive} from '@freckle/exhaustive'
import {
  validateLinearInequationEquation,
  LinearEquationCoefficientsT,
  InequalityT,
} from '@freckle/educator-entities/ts/math/common/logic/equation'
import {LinearInequalityGraphAnswerT} from '@freckle/educator-entities/ts/math/common/logic/answer-types'
import {
  CommonMathGraphQuestionAttrs,
  parseCommonMathGraphQuestionAttrs,
} from '@freckle/educator-entities/ts/math/common/models/questions/common-parser'
import {MathQuestionMetadataAttrs} from '@freckle/educator-entities/ts/math/common/models/questions'

type MathQuestionGraphLinearInequalityExtraAttrs = {
  graphLinearInequalityEquationCoefficients: LinearEquationCoefficientsT
  graphLinearInequalityInequality: InequalityT
}

const rawLinearEquationCoefficientsAttrs: ParserT<LinearEquationCoefficientsT> = record({
  coefficientX1: field(number(), 'coefficient-x-1'),
  coefficientX0: field(number(), 'coefficient-x-0'),
})

// This is almost the same as the in graph-question-linear but the field names are different
const parseGraphLinearInequalityEquationCoefficients: ParserT<{
  graphLinearInequalityEquationCoefficients: LinearEquationCoefficientsT
}> = firstOf(
  record({
    graphLinearInequalityEquationCoefficients: field(
      rawLinearEquationCoefficientsAttrs,
      'graph-linear-inequality-equation-coefficients'
    ),
  }),
  record({
    graphLinearInequalityEquationCoefficients: field(
      obfuscated(rawLinearEquationCoefficientsAttrs),
      'obfuscated-graph-linear-inequality-equation-coefficients'
    ),
  })
)

const rawGraphLinearInequalityInequalityAttrs: ParserT<InequalityT> = oneOf('InequalityT', [
  'lt',
  'le',
  'gt',
  'ge',
])

const parseGraphLinearInequalityInequality: ParserT<{
  graphLinearInequalityInequality: InequalityT
}> = firstOf(
  record({
    graphLinearInequalityInequality: field(
      rawGraphLinearInequalityInequalityAttrs,
      'graph-linear-inequality-inequality'
    ),
  }),
  record({
    graphLinearInequalityInequality: field(
      obfuscated(rawGraphLinearInequalityInequalityAttrs),
      'obfuscated-graph-linear-inequality-inequality'
    ),
  })
)

const parseMathQuestionGraphLinearInequalityExtraAttrs: ParserT<MathQuestionGraphLinearInequalityExtraAttrs> =
  merge(parseGraphLinearInequalityInequality, parseGraphLinearInequalityEquationCoefficients)

export type MathQuestionGraphLinearInequalityMetadataAttrs = CommonMathGraphQuestionAttrs &
  MathQuestionGraphLinearInequalityExtraAttrs

const parseMathQuestionGraphLinearInequalityAttrs: ParserT<MathQuestionGraphLinearInequalityMetadataAttrs> =
  merge(parseCommonMathGraphQuestionAttrs, parseMathQuestionGraphLinearInequalityExtraAttrs)

export const parseMathQuestionGraphLinearInequalityMetadata: ParserT<MathQuestionMetadataAttrs> =
  record({
    answerType: field(literal('graph_linear_inequality'), 'answer-type'),
    content: onSelf(parseMathQuestionGraphLinearInequalityAttrs),
  })

// Assumes we're in not using dangerously-set-inner-html
export function formatInequality(inequality: InequalityT): string {
  switch (inequality) {
    case 'lt':
      return '<'
    case 'gt':
      return '>'
    case 'le':
      return '≤'
    case 'ge':
      return '≥'
    default:
      return exhaustive(inequality, 'InequalityT')
  }
}

// Return :: Double (either 0 or 100)
export function getAnswerCorrectness(
  metadata: MathQuestionGraphLinearInequalityMetadataAttrs,
  answer: LinearInequalityGraphAnswerT
): number {
  const {graphLinearInequalityEquationCoefficients, graphLinearInequalityInequality} = metadata
  const {points, inequality} = answer
  const allCorrect = validateLinearInequationEquation(
    points,
    inequality,
    graphLinearInequalityEquationCoefficients,
    graphLinearInequalityInequality
  )
  return allCorrect ? 100 : 0
}
