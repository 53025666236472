import {v4 as uuidV4} from 'uuid'
import {fromMaybe, mthen} from '@freckle/maybe'
import {PATHS} from '@freckle/educator-entities/ts/common/helpers/paths'
import {appendQueryStringToRoute} from '@freckle/educator-entities/ts/common/helpers/routers/query-params'
import {leaveAjaxErrorBreadcrumb} from '@freckle/educator-entities/ts/common/helpers/exception-handlers/bugsnag-helper'

// An error class for invalid echo responses. This is intended to add more
// visibility to errors reported to bug snag.
class EchoError extends Error {
  constructor(message: string, xhr: JQueryXHR) {
    super(`${message}\n${xhr.getAllResponseHeaders()}`)
    this.name = 'EchoError'
  }
}

export class FetchEchoError extends Error {
  constructor(message: string, response: Response) {
    // @ts-ignore https://app.asana.com/0/1203006199529183/1205043123990678/f
    super(`${message}\n${JSON.stringify(Object.fromEntries(response.headers.entries()))}`)
    this.name = 'EchoError'
  }
}

function appendParams(
  urlString: string,
  params: {
    [x: string]: string | boolean | number
  }
): string {
  // The UrlSearchParams type isn't supported on iOS < 10
  const searchParams = []
  for (const [key, value] of Object.entries(params)) {
    searchParams.push(`${key}=${encodeURIComponent(value.toString())}`)
  }
  const url = new URL(urlString)
  const sep = url.search.trim() === '' ? '?' : '&'
  url.search += `${sep}${searchParams.join('&')}`
  return url.toString()
}

const requestEcho = uuidV4()

export async function genericFetchWithEcho(url: string, init?: RequestInit): Promise<Response> {
  const urlWithEcho = appendQueryStringToRoute(removeXEcho(url), {'x-echo': requestEcho})
  const response = await fetch(urlWithEcho, init)
  const responseXEcho = response.headers.get('x-echo')
  if (
    responseXEcho !== null &&
    requestEcho !== responseXEcho &&
    response.status !== 503 &&
    response.status !== 504
  ) {
    throw new FetchEchoError(
      `X-Echo expected "${requestEcho}", but found "${responseXEcho}"`,
      response
    )
  }
  return response
}

// Optional sides parameter to force the backend to randomly cause 503s
export function ajaxSettingsWithEcho(
  sides: string | undefined | null,
  onEchoError: (a: EchoError) => void,
  onError?: (xhr: JQueryXHR) => void
): JQueryAjaxSettings {
  return {
    dataType: 'json',
    xhrFields: {
      // pass cookies along, useful only when talking to the API, the other
      // $.ajax calls often don't need it and it makes CORS more stringent
      withCredentials: true,
    },

    // Add x-echo to API routes and store it in the AJAX settings to
    // check in complete()
    beforeSend: function(_jqXHR: JQueryXHR, settings: JQueryAjaxSettings) {
      if (settings.url !== undefined) {
        if (sides !== null && sides !== undefined) {
          settings.url = appendParams(settings.url, {sides, 'expose-headers': true})
        }

        if (settings.method !== 'OPTIONS' && settings.url.indexOf(PATHS.unversionedAPIUrl) !== -1) {
          //Cast settings to any so that we can add an _echo field to it
          const settingsWithEcho = settings as {_echo: string}
          settingsWithEcho._echo = requestEcho
          //If the outgoing route already has an x-echo query param, we need to replace it with the new one.
          //Example usecase: pagination link header URLs provided by backend include x-echo's that should be
          //replaced with new ones.
          settings.url = appendParams(removeXEcho(settings.url), {'x-echo': requestEcho})
        }
      }

      //Cast settings to any so that we can stash the time that we queued the request
      const settingsWithTimes = settings as {_requestStartTime: Date; _requestOpenedTime: Date}
      settingsWithTimes._requestStartTime = new Date()
      if (settings.xhr !== undefined) {
        const xhr = settings.xhr
        settings.xhr = () => {
          const output = xhr()
          output.onreadystatechange = function() {
            if (output.readyState === XMLHttpRequest.OPENED) {
              // Stash the time that the request was OPENED so that we can log how long the request
              // took on the server
              settingsWithTimes._requestOpenedTime = new Date()
            }
          }
          return output
        }
      }
    },

    // If this._echo exists, check it against the X-Echo header
    complete: function(jqXHR: JQueryXHR) {
      const echo = this._echo
      if (echo !== null && echo !== undefined) {
        const respEcho = jqXHR.getResponseHeader('X-Echo')
        if (respEcho !== null && echo !== respEcho) {
          onEchoError(new EchoError(`X-Echo expected "${echo}", but found "${respEcho}"`, jqXHR))
        }
      }
    },

    error(jqXHR: JQueryXHR, textStatus: string, errorThrown: string) {
      const nowMs = new Date().getTime()
      const totalDurationMs = nowMs - this._requestStartTime
      const serverDurationMs = fromMaybe(() => 0, mthen(this._requestOpenedTime, t => nowMs - t))
      leaveAjaxErrorBreadcrumb({
        reqType: this.type,
        reqUrl: this.url,
        reqStatus: {status: jqXHR.status.toString(), textStatus},
        totalDurationMs,
        serverDurationMs,
        errorThrown,
      })
      mthen(onError, cb => cb(jqXHR))
    },
  }
}

export function removeXEcho(urlString: string): string {
  const url = new URL(urlString)

  if (url.searchParams.has('x-echo')) {
    url.searchParams.delete('x-echo')
  }

  return url.toString()
}
