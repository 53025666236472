import {RlStandardT} from '@freckle/educator-entities/ts/common/models/rl-standard'
import {parseStandardAttrs} from '@freckle/educator-entities/ts/common/models/rl-standard'
import {ParserT, Parser, string, array, record} from '@freckle/parser'

type ConstructedResponseResourceT = {
  url: string
  name: string
}

const parseResourceAttrs: ParserT<ConstructedResponseResourceT> = record({
  url: string(),
  name: string(),
})

export type ConstructedResponseT = {
  standard: RlStandardT
  resources: Array<ConstructedResponseResourceT>
}

const parseAttrs: ParserT<ConstructedResponseT> = record({
  standard: parseStandardAttrs,
  resources: array(parseResourceAttrs),
})

export const parseConstructedResponses = Parser.mkRun<Array<ConstructedResponseT>>(
  array(parseAttrs)
)
