import * as React from 'react'
import {useLocation} from 'react-router-dom'
import {useSafeTranslation} from '@freckle/educator-materials/ts/helpers/translate'
import {useSettings} from '@freckle/classroom/ts/actions/settings'
import find from 'lodash/find'
import {Languages} from '@freckle/educator-entities/ts/common/helpers/languages'
import {mmap, mEffect} from '@freckle/maybe'
import {extractQueryParamsTuples} from '@freckle/educator-entities/ts/common/helpers/routers/query-params'

import {useLanguage} from '@freckle/classroom/ts/hooks/use-language'

type Props = {
  children?: React.ReactElement
}

function UseLanguageComponent({children}: Props): React.ReactElement {
  const location = useLocation()
  const {setLanguage} = useSettings()
  const {i18n} = useSafeTranslation()
  const settingsLang = useLanguage()
  React.useEffect(() => {
    if (i18n.language !== settingsLang) {
      i18n.changeLanguage(settingsLang)
    }
  }, [settingsLang, i18n])

  React.useEffect(() => {
    const mLanguageTuple = find(extractQueryParamsTuples(location.search), ([key]) => {
      return key === 'language'
    })

    const mLanguage = mmap(tuple => Languages.parse(tuple[1]), mLanguageTuple)

    mEffect(mLanguage, lang =>
      setLanguage({
        code: lang,
        fromUrl: true,
      })
    )
  }, [location.search, setLanguage])

  return children ?? <></>
}

export const UseLanguage = UseLanguageComponent
