import map from 'lodash/map'
import sortBy from 'lodash/sortBy'

import {
  ParserT,
  field,
  record,
  firstOf,
  string,
  literal,
  array,
  onSelf,
  obfuscated,
} from '@freckle/parser'
import {InputAnswerT} from '@freckle/educator-entities/ts/math/common/logic/answer-types'

import {MathQuestionMetadataAttrs} from '@freckle/educator-entities/ts/math/common/models/questions'

export type MathQuestionRegularMetadataAttrs = {
  correctAnswers: Array<string>
}

export const parseMathQuestionRegularExtraAttrs: ParserT<MathQuestionRegularMetadataAttrs> =
  firstOf(
    record({correctAnswers: field(array(string()), 'correct-answers')}),
    record({
      correctAnswers: field(obfuscated(array(string())), 'obfuscated-correct-answers'),
    })
  )

export const parseMathQuestionRegularMetadata: ParserT<MathQuestionMetadataAttrs> = record({
  answerType: field(literal('singular_or'), 'answer-type'),
  content: onSelf(parseMathQuestionRegularExtraAttrs),
})

export function getAnswerCorrectness(
  metadata: MathQuestionRegularMetadataAttrs,
  answer: InputAnswerT
): number {
  const {correctAnswers} = metadata
  const formattedCorrectAnswers = formatAnswersForComparison(correctAnswers)
  return formattedCorrectAnswers.includes(formatAnswerForComparison(answer)) ? 100 : 0
}

// This function is used to format the student answer and the correct
// answers in order to know if the answer is right or wrong
function formatAnswersForComparison(answers: Array<string>): Array<string> {
  return sortBy(map(answers, formatAnswerForComparison))
}

export function formatAnswerForComparison(answer: string): string {
  return answer
    .trim() // Trim leading and trailing whitespace
    .replace(/[\u00A0]+/g, ' ') // Convert nonbreaking spaces to regular spaces
    .replace(/\s+/g, ' ') // Condense whitespace to one space
    .replace(/\s+\/\s+/g, '/') // Remove whitespace in fractions
}
