import {lazy} from 'react'

// components

export const ScienceArticlePage = lazy(
  () =>
    import(
      /* webpackChunkName: "ScienceArticlePage" */ './../../../../units/science/components/article-page'
    )
)

export const ScienceArticleReadingAssignmentSessionReportHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "ScienceArticleReadingAssignmentSessionReportHandler" */ './../../../../units/science/components/reading-assignment-session-report'
    )
)

export const ScienceAssignArticlePage = lazy(
  () =>
    import(
      /* webpackChunkName: "ScienceAssignArticlePage" */ './../../../../units/science/components/assign-article-page'
    )
)

export const ScienceAssignmentSessionHistoryHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "ScienceAssignmentSessionHistoryHandler" */ './../../../../units/science/components/assignment-session-history'
    )
)

export const ScienceAssignmentsHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "ScienceAssignmentsHandler" */ './../../../../units/science/common/components/assignments'
    )
)

export const ScienceListingPageContainer = lazy(
  () =>
    import(
      /* webpackChunkName: "ScienceListingPageContainer" */ './../../../../units/science/components/listing-page'
    )
)

export const ScienceSearchOrListingPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ScienceSearchOrListingPage" */ './../../../../units/science/components/search-or-listing'
    )
)

export const ScienceUnitPageHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "ScienceUnitPageHandler" */ './../../../../units/science/components/unit-page'
    )
)

export const ScienceWritingAssignmentReportHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "ScienceWritingAssignmentReportHandler" */ './../../../../units/science/components/writing-assignment-session-report'
    )
)
