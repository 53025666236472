import * as React from 'react'
import map from 'lodash/map'
import {Navigate, createBrowserRouter} from 'react-router-dom'
import {ResetByEmailPage} from '@freckle/classroom/ts/common/components/page/reset-by-email'

import {
  ChangePasswordPageHandler,
  DeletedLoginHandler,
  ErrorRootBoundary,
  LoginPageHandler,
  SignUpPageHandler,
  SsoMismatchedSchoolHandler,
} from './lazy'

import {routerV2Math} from './math'
import {routerV2Ela} from './ela'
import {routerV2Science} from './science'
import {routerV2SocialStudies} from './social-studies'
import {routerV2Roster} from './roster'
import {routerV2Common} from './common'
import {DefaultPage} from './default-page'
import {NotFound} from './../../../pages/not-found'
import {AuthRedirectRoute} from './auth-redirect-route'
import {ScrollToTop} from './scroll-to-top'
import {UseLanguage} from './use-language'

export const routerV2 = createBrowserRouter(
  map(
    [
      {
        path: '/',
        element: <DefaultPage />,
      },
      {
        path: '/sso-mismatched-school',
        element: <SsoMismatchedSchoolHandler />,
      },
      {
        path: '/signup',
        element: <AuthRedirectRoute component={SignUpPageHandler} />,
      },
      {
        path: '/login',
        element: <AuthRedirectRoute component={LoginPageHandler} />,
      },
      {
        path: '/reset-password',
        element: <AuthRedirectRoute component={ChangePasswordPageHandler} />,
      },
      {
        path: '/school',
        element: <Navigate to="/settings" replace />,
      },
      {
        path: '/deleted-login',
        element: <DeletedLoginHandler />,
      },
      {
        path: '/reset-by-email',
        element: <ResetByEmailPage />,
      },
      ...routerV2Common,
      ...routerV2Ela,
      ...routerV2Math,
      ...routerV2Science,
      ...routerV2SocialStudies,
      ...routerV2Roster,
      {
        path: '*',
        element: <NotFound />,
      },
    ],
    ({path, element}) => ({
      path,
      element: (
        <ScrollToTop>
          <>
            <UseLanguage />
            {element}
          </>
        </ScrollToTop>
      ),
      errorElement: <ErrorRootBoundary />,
    })
  )
)
