// Control what happens when a user clicks on the link.
export type Action =
  | {tag: 'href'; href: string}
  | {tag: 'onClick'; onClick: (e: React.SyntheticEvent<EventTarget, Event>) => void}

export const mkHrefAction = (href: string): Action => ({tag: 'href', href})

export const mkOnClickAction = (
  onClick: (e: React.SyntheticEvent<EventTarget, Event>) => void
): Action => ({tag: 'onClick', onClick})
