import {UseQueryOptions, UseQueryResult} from '@tanstack/react-query'
import {useSafeEffect} from '@freckle/react-hooks'
import {useAsync} from '@freckle/educator-entities/ts/common/hooks/use-async'
import {fetchCurrentTeacher} from '@freckle/classroom/ts/users/models/teacher.api'
import {TeacherAttrs} from '@freckle/classroom/ts/users/models/teacher'
import {useSettings} from '../actions/settings'
import {useLanguage} from './use-language'

export function useTeacher(
  options?: Partial<UseQueryOptions<TeacherAttrs>>
): UseQueryResult<TeacherAttrs> {
  const defaultOptions = {staleTime: 60 * 1000}
  const currentTeacher = useAsync(fetchCurrentTeacher, ['fetchCurrentTeacher'], {
    ...defaultOptions,
    ...options,
  })
  const {setLanguage} = useSettings()
  const currentLang = useLanguage()

  useSafeEffect(() => {
    if (currentTeacher.data && currentTeacher.data.language !== currentLang)
      setLanguage({code: currentTeacher.data.language, fromUrl: false})
    // TODO: https://app.asana.com/0/1207361940282681/1207798116165037/f
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTeacher.data?.language])

  return currentTeacher
}
