import findIndex from 'lodash/findIndex'
import {ParserT, field, record, onSelf, literal} from '@freckle/parser'
import {MCAnswerT} from '@freckle/educator-entities/ts/math/common/logic/answer-types'

import {
  MathQuestionMetadataAttrs,
  MathQuestionAttrs,
} from '@freckle/educator-entities/ts/math/common/models/questions'
import {
  MathQuestionRegularMetadataAttrs,
  parseMathQuestionRegularExtraAttrs,
} from './question-regular'

export type MathQuestionMultipleChoiceMetadataAttrs = MathQuestionRegularMetadataAttrs

const parseMathQuestionMultipleChoiceExtraAttrs = parseMathQuestionRegularExtraAttrs

export const parseMathQuestionMultipleChoiceMetadata: ParserT<MathQuestionMetadataAttrs> = record({
  answerType: field(literal('multiple_choice'), 'answer-type'),
  content: onSelf(parseMathQuestionMultipleChoiceExtraAttrs),
})

// Answer given by the student is always converted in an array
export function getAnswerCorrectness(
  question: MathQuestionAttrs,
  metadata: MathQuestionMultipleChoiceMetadataAttrs,
  answerIndex: MCAnswerT
): number {
  const {answers} = question
  const {correctAnswers} = metadata

  const correctAnswerIndex = getCorrectAnswerIndexes(correctAnswers, answers)
  return answerIndex === correctAnswerIndex ? 100 : 0
}

// Get sorted Indexes of correct answers
function getCorrectAnswerIndexes(correctAnswers: Array<string>, answers: Array<string>): number {
  const correctAnswer = correctAnswers[0]
  return findIndex(answers, ans => ans === correctAnswer)
}
