import * as React from 'react'
import omitBy from 'lodash/omitBy'

type JustifyContentT =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'space-evenly'
  | 'initial'
  | 'inherit'
type FlexWrapT = 'wrap' | 'nowrap' | 'wrap-reverse'
export type AlignItemsT =
  | 'stretch'
  | 'center'
  | 'flex-start'
  | 'flex-end'
  | 'baseline'
  | 'initial'
  | 'inherit'

export type FlexDirectionT = 'row' | 'column'

type StyleProps = {
  flexDirection?: FlexDirectionT
  justifyContent?: JustifyContentT
  flexWrap?: FlexWrapT
  alignItems?: AlignItemsT
  flex?: string
  gap?: string
}

type Props = {
  children?: React.ReactNode
  className?: string
  dataTest?: string
  // Accessibility
  role?: string
  ariaLabel?: string
} & StyleProps

export function Flex(props: Props): React.ReactElement<Props> {
  const {
    className,
    justifyContent,
    flexDirection,
    flexWrap,
    alignItems,
    flex,
    dataTest,
    role,
    ariaLabel,
      gap,
  } = props
  const Component = 'div'

  const styles = {
    display: 'flex',
    justifyContent,
    flexDirection,
    flexWrap,
    alignItems,
    flex,
    gap,
  }

  const ariaLabelProp = ariaLabel !== undefined ? {'aria-label': ariaLabel} : {}

  return React.createElement(
    Component,
    {
      className,
      style: omitBy(styles, v => v === undefined),
      'data-test': dataTest,
      role,
      ...ariaLabelProp,
    },
    [props.children]
  )
}
