import {ajaxJsonCall} from '@freckle/ajax'
import CApiHelper from '@freckle/educator-entities/ts/common/helpers/common-api-helper'

export function postRequestChangePassword(password: string, code: string): Promise<void> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.passwords._(),
    method: 'POST',
    data: JSON.stringify({password, code}),
  })
}

type ResetPasswordResponse = {
  shouldLoginNow: boolean
}

export function postRequestPasswordReset(email: string): Promise<ResetPasswordResponse> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.passwords.resets(),
    method: 'POST',
    data: JSON.stringify({email}),
  })
}
