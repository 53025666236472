import * as React from 'react'
import {SyntheticEvent} from 'react'
import {SafeTrans, useSafeTranslation} from '@freckle/educator-materials/ts/helpers/translate'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import {CourseMembershipAttrs} from '@freckle/educator-entities/ts/roster/models/course-membership'
import {CourseAttrs} from '@freckle/educator-entities/ts/roster/models/course'
import {StudentAttrs} from '@freckle/educator-entities/ts/users/models/student'
import StorageHelper from '@freckle/educator-entities/ts/common/helpers/storage-helper'
import {getStudentTeacherLoginUrl} from '@freckle/classroom/ts/common/logic/sign-in-as-student'

import {ExternalLinkWithConfirmation} from './../external-link-with-confirmation'

const HELP_LINK = 'https://help2.renaissance.com/freckle/115002785093'

function navigateTo(mLoginUrl: string | undefined) {
  if (mLoginUrl !== undefined) {
    // @ts-ignore https://app.asana.com/0/0/1204907599764039/f
    window.location = mLoginUrl
  }
}

type Props = {
  courses: Array<CourseAttrs>
  students: Array<StudentAttrs>
  courseMemberships: Array<CourseMembershipAttrs>
  render: (
    onClickViewStudent: (e: SyntheticEvent<EventTarget, Event>) => void
  ) => React.ReactElement
}

export function ViewStudentAppButton(props: Props): React.ReactElement {
  const {t} = useSafeTranslation()
  const {courses, students, courseMemberships, render} = props
  const mLoginUrl = getStudentTeacherLoginUrl(courses, students, courseMemberships)

  function onClickViewStudent(e: SyntheticEvent<EventTarget, Event>) {
    if (mLoginUrl !== undefined) {
      // only stopPropagation when we have a login url
      // otherwise the popver (which only render when we dont have a login url)
      // will not be triggered

      StorageHelper.setLocalStorageProperty('student-app-viewed', true)
      navigateTo(mLoginUrl)

      e.stopPropagation()
    }
  }

  const signInOverlay = (
    <Popover id="cannot-sign-in-popover">
      <Popover.Title as="h3">{t('VIEW_STUDENT_APP_CANT_SIGN_TITLE')}</Popover.Title>
      <Popover.Content>
        <SafeTrans
          i18nKey="VIEW_STUDENT_APP_CANT_SIGNIN_CONTENT"
          components={{
            studentAppLink: <ExternalLinkWithConfirmation href="https://student.freckle.com" />,
            helpLink: <ExternalLinkWithConfirmation href={HELP_LINK} />,
          }}
        />
      </Popover.Content>
    </Popover>
  )

  const renderedButton = render(onClickViewStudent)

  // Note: tooltip doesn't register on custom component, so wrap in div
  // https://github.com/react-bootstrap/react-bootstrap/issues/2208
  const trigger = 'click' // anything else will BREAK the model that opens from inside the popover
  const linkWithOverlay =
    mLoginUrl === undefined ? (
      <OverlayTrigger rootClose trigger={trigger} placement="bottom" overlay={signInOverlay}>
        {/*
            stopPropagation when popover is shown this is
            helpful in case the popover is displayed in a component
            that interacts on click like an expandableContainer
          */}
        <span onClick={e => e.stopPropagation()}>{renderedButton}</span>
      </OverlayTrigger>
    ) : (
      renderedButton
    )

  return linkWithOverlay
}
