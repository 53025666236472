import {create} from 'zustand'
import {checkSessionCookie, postLogin} from '@freckle/classroom/ts/api/session'

export type AuthStatus = {
  loggedIn: boolean
  hasCheckedAuth: boolean
  provider: string | null
  error: boolean | null
}

const defaultAuthStatus: AuthStatus = {
  loggedIn: false,
  hasCheckedAuth: false,
  provider: null,
  error: null,
}

type Actions = {
  clearError: () => void
  setLoginSuccess: () => void
  setLoginError: () => void
  setAuthProvider: (provider: string | null) => void
  setStatusError: () => void
}

const useStore = create<AuthStatus & Actions>()(set => ({
  ...defaultAuthStatus,
  clearError: () => set(state => ({...state, error: null})),
  setLoginSuccess: () =>
    set(state => ({...state, loggedIn: true, hasCheckedAuth: true, error: null})),
  setLoginError: () =>
    set(state => ({...state, loggedIn: false, hasCheckedAuth: true, error: true})),
  setAuthProvider: (provider: string | null) =>
    set(state => ({...state, loggedIn: true, hasCheckedAuth: true, provider})),
  setStatusError: () =>
    set(_ => ({loggedIn: false, hasCheckedAuth: true, provider: null, error: null})),
}))

export const useAuth = (): {
  login: (email: string, password: string) => Promise<undefined>
  checkAuthStatus: () => Promise<undefined>
  authStatus: AuthStatus
} => {
  const {
    clearError,
    setLoginSuccess,
    setLoginError,
    setAuthProvider,
    setStatusError,
    ...authStatus
  } = useStore()

  const login = async (email: string, password: string): Promise<undefined> => {
    clearError()
    try {
      await postLogin(email, password)
      setLoginSuccess()
    } catch (_resp) {
      setLoginError()
    }
  }

  const checkAuthStatus = async (): Promise<undefined> => {
    if (authStatus.loggedIn) {
      return
    } else {
      try {
        const response = await checkSessionCookie()

        setAuthProvider(response.provider)
      } catch (err) {
        setStatusError()
      }
    }
  }

  return {
    authStatus,
    login,
    checkAuthStatus,
  }
}
