import {parseElementFromEvent} from '@freckle/educator-entities/ts/common/helpers/dom'
import {getOnError} from '@freckle/educator-entities/ts/common/helpers/exception-handlers/bugsnag-helper/bugsnag-onerror'
import {bugsnagClient as classroomBugsnagClient} from '@freckle/classroom/ts/common/helpers/exception-handlers/bugsnag-client'

export function initializeGlobalEventListeners() {
  window.onerror = getOnError(classroomBugsnagClient)
  window.addEventListener('error', onGlobalError, true)
}

function onGlobalError(e: ErrorEvent) {
  if (e.target !== null) {
    const parsed = parseElementFromEvent({target: e.target})
    if (parsed.tag === 'link') {
      reloadStyleChunk(parsed.el)
    }
  }
}

const RELOAD_BYPASS_FLAG = 'bypass'

const RELOAD_DELAY_MS = 1000

// If chunk fails due to transient network issue, retry before failing
function reloadStyleChunk(e: HTMLLinkElement) {
  if (e.href.indexOf(RELOAD_BYPASS_FLAG) === -1) {
    const preservedError = e.onerror
    const preservedLoad = e.onload
    setTimeout(() => {
      const link = document.createElement('link')
      link.setAttribute('rel', 'stylesheet')
      link.setAttribute('href', `${e.href}?${RELOAD_BYPASS_FLAG}=1`)

      // Copy preserved error handler to new element in case it also fails
      link.onerror = preservedError
      link.onload = preservedLoad

      if (e.parentNode !== null) {
        e.parentNode.insertBefore(link, e.nextSibling)
      }
    }, RELOAD_DELAY_MS)

    // Prevent original error handler from bubbling up to ErrorBoundary
    e.onerror = null
  }
}
