import moment, {Moment} from 'moment-timezone'
import {mmap} from '@freckle/maybe'

export const getTimeSpan = (
  aroundDate?: Moment
): {
  from: number
  to: number
} => ({
  from: moment(aroundDate).startOf('week').valueOf(),

  to: moment(aroundDate).endOf('week').valueOf(),
})

// Try to get startsAt field from generic Object
export function findAssignmentStartsAt(postData: Inexact<{startsAt: number | null | undefined}>): Moment | undefined | null {
  return mmap(startsAt => {
    if (typeof startsAt === 'number') {
      return moment(startsAt)
    } else {
      return null
    }
  }, postData.startsAt)
}

export type AssignmentContextArg = {
  courseId: number
  assignmentStartsAt: Moment | undefined | null
}

type AssignmentContextData = {
  courseId: number | undefined | null
  week:
    | {
        from: string
        to: string
      }
    | undefined
    | null
}

export function createAssignmentContextData(context: AssignmentContextArg): AssignmentContextData {
  const now = moment()
  const startsAt = context.assignmentStartsAt ?? now
  const {from, to} = getTimeSpan(startsAt)
  return {
    courseId: context.courseId,
    week: {
      from: moment(from).toISOString(),
      to: moment(to).toISOString(),
    },
  }
}
