import * as React from 'react'
import {ParserT, string, mapStatic} from '@freckle/parser'
import {exhaustive} from '@freckle/exhaustive'

/** An HTML fragment represented as a string that is safe to render. */
export type HTMLText = {tag: 'html-text'; value: string}

/** Plain text; no markup in the value should be interpreted. */
export type PlainText = {tag: 'plain-text'; value: string}

/** Tag a string as being HTML that is safe to render.
 * This function is flagged as being dangerous because inappropriately
 * applying it to arbitrary input can construct an HTMLText value that
 * is not actually safe to render. */
export function toHTMLTextDangerous(value: string): HTMLText {
  return {tag: 'html-text', value}
}

export function toPlainText(value: string): PlainText {
  return {tag: 'plain-text', value}
}

function innerHTMLSpan(input: HTMLText): React.ReactElement {
  return <span {...innerHTMLText(input)} />
}

export function displayHTMLOrPlainTextInSpan(input: HTMLText | PlainText): React.ReactElement {
  switch (input.tag) {
    case 'html-text':
      return innerHTMLSpan(input)
    case 'plain-text':
      return <span>{input.value}</span>
    default:
      exhaustive(input)
  }
}

export const innerHTMLText = (htmlText: HTMLText): {dangerouslySetInnerHTML: {__html: string}} => ({
  dangerouslySetInnerHTML: {__html: htmlText.value},
})

/** Use this as the parser for a string field when the string should be interpreted as HTML to render. */
export const htmlTextParser: ParserT<HTMLText> = mapStatic(string(), value =>
  toHTMLTextDangerous(value)
)

export const plainTextParser: ParserT<PlainText> = mapStatic(string(), value => toPlainText(value))

export function spanDangerous(value: string): React.ReactElement {
  return <span {...innerHTMLText(toHTMLTextDangerous(value))} />
}
