import find from 'lodash/find'
import {fromJust} from '@freckle/maybe'

export type ElaAdaptiveSkillAssignmentPathwayT = 'skill' | 'remediation'

export const ElaAdaptiveSkillAssignmentPathway = {
  skill: 'skill' as ElaAdaptiveSkillAssignmentPathwayT,
  remediation: 'remediation' as ElaAdaptiveSkillAssignmentPathwayT,

  all(): Array<ElaAdaptiveSkillAssignmentPathwayT> {
    return [ElaAdaptiveSkillAssignmentPathway.skill, ElaAdaptiveSkillAssignmentPathway.remediation]
  },

  parse(text: string): ElaAdaptiveSkillAssignmentPathwayT | undefined | null {
    return find(ElaAdaptiveSkillAssignmentPathway.all(), value => value === text)
  },

  parseThrows(text: string): ElaAdaptiveSkillAssignmentPathwayT {
    return fromJust(
      ElaAdaptiveSkillAssignmentPathway.parse(text),
      `Invalid ElaAdaptiveSkillAssignmentPathway ${text}`
    )
  },
}
