import {ParserT, Parser, string} from '@freckle/parser'

export type RlSkillUspIdT = string

export const parse: ParserT<RlSkillUspIdT> = Parser.map(
  string(),
  'RlSkillUspIdT.fromString',
  fromString
)

export function fromString(rlSkillUspId: string): RlSkillUspIdT {
  return rlSkillUspId
}

export const toString = (rlSkillUspId: RlSkillUspIdT): string => rlSkillUspId
