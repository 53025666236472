import * as React from 'react'
import {SplitContext, useSplitTreatments} from '@splitsoftware/splitio-react'
import {isStaging, isTestEnv} from '@freckle/educator-entities/ts/common/helpers/common-api-helper'
import {Spinner} from '../spinner-wrapper/spinner'

type Props = {
  split: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  splitTargetingParams: {[key: string]: any}
  children: (treatment: string) => React.ReactNode
}

const DEFAULT_TREATMENT = 'off'

export const SplitExperiment = (props: Props): React.ReactElement => {
  const {split, splitTargetingParams, children} = props

  const {isReady} = React.useContext(SplitContext)

  const splitTreatments = useSplitTreatments({names: [split], attributes: splitTargetingParams})

  if (isStaging() || isTestEnv()) {
    return children(DEFAULT_TREATMENT) as React.ReactElement
  }

  if (!isReady || !splitTreatments.isReady) {
    return <Spinner />
  }
  // TODO: https://app.asana.com/0/149473556304568/1206945111987245/f
  const treatment = splitTreatments.treatments[split]!.treatment
  return children(treatment) as React.ReactElement
}
