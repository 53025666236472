import find from 'lodash/find'
import {fromJust} from '@freckle/maybe'

export type ElaAssignmentType =
  | 'ela_assessment'
  | 'ela_article'
  | 'ela_word_study'
  | 'ela_adaptive_skills'
  | 'ela_targeted_skills'
  | 'ela_decodable'

type ElaAssignmentTypesT = {
  ElaAssessment: ElaAssignmentType
  ElaArticle: ElaAssignmentType
  ElaWordStudy: ElaAssignmentType
  ElaAdaptiveSkills: ElaAssignmentType
  ElaTargetedSkills: ElaAssignmentType
  ElaDecodable: ElaAssignmentType
  all: () => Array<ElaAssignmentType>
  forDisplay: (elaAssignmentType: ElaAssignmentType) => string
  parse: (text: string) => ElaAssignmentType | undefined | null
  parseThrows(text: string): ElaAssignmentType
}

export const ElaAssignmentTypes: ElaAssignmentTypesT = {
  ElaAssessment: 'ela_assessment',
  ElaArticle: 'ela_article',
  ElaWordStudy: 'ela_word_study',
  ElaAdaptiveSkills: 'ela_adaptive_skills',
  ElaTargetedSkills: 'ela_targeted_skills',
  ElaDecodable: 'ela_decodable',
  all: function (): Array<ElaAssignmentType> {
    return [
      ElaAssignmentTypes.ElaAssessment,
      ElaAssignmentTypes.ElaArticle,
      ElaAssignmentTypes.ElaWordStudy,
      ElaAssignmentTypes.ElaAdaptiveSkills,
      ElaAssignmentTypes.ElaTargetedSkills,
      ElaAssignmentTypes.ElaDecodable,
    ]
  },

  forDisplay: function (elaAssignmentType: ElaAssignmentType): string {
    return elaAssignmentType.replace(/_/g, '-')
  },

  parse: function (text: string): ElaAssignmentType | undefined | null {
    const trimmed = text.trim()
    return find(ElaAssignmentTypes.all(), assignmentType => assignmentType === trimmed)
  },

  parseThrows(text: string): ElaAssignmentType {
    return fromJust(this.parse(text), `Unrecognized value for ElaAssignmentType: ${text}`)
  },
}
