import * as React from 'react'

import {
  inlineContainer,
  fullOverlay,
  overlayContainer,
  spinner,
  textStyle,
} from './spinner.module.scss'

type Props = {
  inline?: boolean | null | undefined
  text?: string | null | undefined
}

export function Spinner(props: Props): React.ReactElement {
  const {inline, text} = props

  const [overlayClass, containerClass] =
    inline === true ? [null, inlineContainer] : [fullOverlay, overlayContainer]
  const ariaLabel = text ?? 'Loading'
  const assistiveLabelAttr = {'aria-label': ariaLabel}
  return (
    <div className={overlayClass}>
      <div className={containerClass} data-test="loading-spinner" {...assistiveLabelAttr}>
        <i className={`fa fa-circle-o-notch fa-spin fa-3x fa-fw ${spinner}`} aria-hidden="true" />
        {text !== null && text !== undefined ? <span className={textStyle}>{text}</span> : null}
      </div>
    </div>
  )
}
