import {useCallback, useState} from 'react'

type UseBooleanT = {
  value: boolean
  setValue: (val: boolean) => void
  setTrue: () => void
  setFalse: () => void
  toggle: () => void
}

export function useBoolean(initialState: boolean): UseBooleanT {
  const [value, setValue] = useState(initialState)
  const setTrue = useCallback(() => setValue(true), [])
  const setFalse = useCallback(() => setValue(false), [])
  const toggle = useCallback(() => setValue(x => !x), [])
  return {value, setValue, setTrue, setFalse, toggle}
}
