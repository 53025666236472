import * as React from 'react'

import {alert} from './invalid-field-alert.module.scss'

type Props = {
  children?: React.ReactElement
  dataTest?: string
}

/**
 * This is the standard component used for showing error message below the invalid form field.
 *
 * ## Example
 *
 * A simple example
 *```
 * <InvalidFieldAlert>Student</InvalidFieldAlert>
 *```
 */
export function InvalidFieldAlert({children, dataTest}: Props): React.ReactElement {
  const dataTestObj = dataTest !== undefined ? {'data-test': dataTest} : {}
  return (
    <p className={alert} {...dataTestObj}>
      {children}
    </p>
  )
}
