import * as React from 'react'
import {useTranslation, Translation, Trans} from 'react-i18next'
import {mapValues} from 'lodash'
import {exhaustive} from '@freckle/exhaustive'
import {
  HTMLText,
  toHTMLTextDangerous,
  innerHTMLText,
} from '@freckle/educator-entities/ts/common/models/html-text'

type LangT = string
export type TranslateT = (key: string, options?: Record<string, unknown>) => string

// Returns a `t` function that more closely aligns with our usage
//
// This lets us avoid unsafely sending `HTMLText` to Locize without first unwrapping it.
// See `tHTMLText` for a `t` function that can translate with `HTMLText`
//
// https://app.asana.com/0/1203006199529183/1206496273151712/f
// eslint-disable-next-line react-refresh/only-export-components
export function useSafeTranslation(ns?: string): {
  t: TranslateT
  i18n: {
    language: LangT
    changeLanguage: (lang: string) => void
    getFixedT: (lang: string) => TranslateT
  }
} {
  return useTranslation(ns)
}

// Returns a `Translation` that more closely aligns with our usage
export function SafeTranslation(props: {
  children: (t: TranslateT) => React.ReactElement
  ns?: string
}): React.ReactElement {
  return <Translation {...props} />
}
// Returns a `Trans` that more closely aligns with our usage
export function SafeTrans(props: {
  i18nKey: string
  count?: number
  t?: TranslateT
  components?: readonly React.ReactElement[] | {readonly [tagName: string]: React.ReactElement}
  values?: Record<string, string | number | boolean>
}): React.ReactElement {
  // @ts-ignore You can pass a custom `t` but `Trans` doesn't like that
  return <Trans {...props} />
}

/** Value of an interpolation parameter when using the 'translateHTML' function.
 * Strings will be escaped, HTMLText will not.
 */
export type TranslationValueHTML = HTMLText | string

/**
 * This can be useful when your translation template includes a mixture of both
 * HTML and non-HTML values. Values of type 'string' will be interpolated
 * as plain text (escaped), and values of type 'HTMLText' will be included as
 * HTML (unescaped).
 *
 * Any HTML in the translation template will be rendered as HTML (not escaped).
 */
// https://app.asana.com/0/1203006199529183/1206496273151712/f
// eslint-disable-next-line react-refresh/only-export-components
export function translateHTML(
  t: TranslateT,
  i18nKey: string,
  values?: Record<string, TranslationValueHTML>
): HTMLText {
  return toHTMLTextDangerous(t(i18nKey, mapValues(values ?? {}, translationValueHTMLToString)))
}

/** Same as 'translateHTML' but wraps the result in a 'span' element. */
// https://app.asana.com/0/1203006199529183/1206496273151712/f
// eslint-disable-next-line react-refresh/only-export-components
export function translateHTMLSpan(
  t: TranslateT,
  i18nKey: string,
  values?: Record<string, TranslationValueHTML>
): React.ReactElement {
  return <span {...innerHTMLText(translateHTML(t, i18nKey, values))} />
}

function escape(r: string): string {
  return r.replace(/[<>&]/g, r => `&#${r.charCodeAt(0)};`)
}

function translationValueHTMLToString(x: TranslationValueHTML): string {
  if (typeof x === 'string') {
    return escape(x)
  } else {
    switch (x.tag) {
      case 'html-text':
        return x.value
      default:
        exhaustive(x.tag)
    }
  }
}
