import {
  PHONE_VERTICAL_END,
  PHONE_HORIZONTAL_END,
  TABLET_VERTICAL_END,
  TABLET_HORIZONTAL_END,
  LARGE_DESKTOP_START,
} from '@freckle/educator-materials/ts/helpers/responsive-breakpoints'

export type ResponsiveBreakpointKey =
  | 'phoneVertical'
  | 'phoneHorizontal'
  | 'tabletVertical'
  | 'tabletHorizontal'
  | 'desktop'
  | 'largeDesktop'

export function isScreenVertical(): boolean {
  return window.innerHeight > window.innerWidth
}

export function isScreenPhoneVertical(): boolean {
  return getScreenResponsiveBreakpoint() === 'phoneVertical'
}

export function isScreenPhoneHorizontal(): boolean {
  return getScreenResponsiveBreakpoint() === 'phoneHorizontal'
}

export function isScreenTabletVertical(): boolean {
  return getScreenResponsiveBreakpoint() === 'tabletVertical'
}

export function isScreenTabletHorizontal(): boolean {
  return getScreenResponsiveBreakpoint() === 'tabletHorizontal'
}

export function isScreenDesktop(): boolean {
  return getScreenResponsiveBreakpoint() === 'desktop'
}

export function isScreenLargeDesktop(): boolean {
  return getScreenResponsiveBreakpoint() === 'largeDesktop'
}

function getScreenResponsiveBreakpoint(): ResponsiveBreakpointKey {
  return getResponsiveBreakpoint(window.innerWidth)
}

export function getResponsiveBreakpoint(width: number): ResponsiveBreakpointKey {
  if (width >= LARGE_DESKTOP_START) {
    return 'largeDesktop'
  }
  if (width > TABLET_HORIZONTAL_END) {
    return 'desktop'
  }
  if (width >= TABLET_VERTICAL_END) {
    return 'tabletHorizontal'
  }
  if (width >= PHONE_HORIZONTAL_END) {
    return 'tabletVertical'
  }
  if (width >= PHONE_VERTICAL_END) {
    return 'phoneHorizontal'
  }
  return 'phoneVertical'
}
