import isArray from 'lodash/isArray'
import reduce from 'lodash/reduce'
import isObject from 'lodash/isObject'
import {ajaxJsonCall, ajaxFormCall} from '@freckle/ajax'
import CApiHelper from '@freckle/educator-entities/ts/common/helpers/common-api-helper'
import {Products, ProductPathsT} from '@freckle/educator-entities/ts/common/helpers/products'

//
// Have the API tell the browser to remove the current teacher session
// cookie. The client cannot interact with the cookie.
//
export function deleteSessionCookie(): Promise<{redirectUrl?: string}> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.teachers.sessions(),
    method: 'DELETE',
  })
}

//
// Check if the cookie on the client represents a valid teacher session
//
export function checkSessionCookie(): Promise<{provider: string}> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.teachers.sessions(),
    method: 'GET',
  })
}

//
// Obtain a valid session cookie.
//
// TODO: change API to expect JSON body, not urlencoded params
export function postLogin(email: string, password: string): Promise<void> {
  return ajaxFormCall({
    url: CApiHelper.fancyPaths.v2.teachers.sessions(),
    method: 'POST',
    data: {email, password},
  })
}

export type EditStudentsPayloadT = {
  studentsToAdd: Array<{studentId: number}>
  studentsToRemove: Array<number>
}

export function editStudentsForAssignmentCurried(
  path: ProductPathsT
): (assignmentId: number, editedStudents: EditStudentsPayloadT) => Promise<void> {
  return (assignmentId, editedStudents) =>
    editStudentsForAssignment(path, assignmentId, editedStudents)
}

export function editStudentsForAssignment(
  path: ProductPathsT,
  assignmentId: number,
  editedStudents: EditStudentsPayloadT
): Promise<void> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v3.subjects.subject.products.product.subject_product_assignments.subject_product_assignment.sessions(
      path.subjectPath,
      path.productPath,
      assignmentId
    ),
    method: 'PATCH',
    data: JSON.stringify(editedStudents),
  })
}

export function deleteSubjectProductAssignmentSessions(
  productPaths: ProductPathsT,
  assignmentIds: number[]
): Promise<void> {
  return ajaxJsonCall({
    url: `${CApiHelper.fancyPaths.v3.subjects.subject.products.product.subject_product_sessions._(
      productPaths.subjectPath,
      productPaths.productPath
    )}?assignmentIds=${assignmentIds.join(',')}`,
    method: 'DELETE',
  })
}

function deleteAllMathIncompleteAssignmentSessions({
  adaptiveAssignmentIds,
  assessmentAssignmentIds,
  factPracticeAssignmentIds,
  iblAssignmentIds,
  standardAssignmentIds,
  numberFactsAssignmentIds,
}: DeleteAllMathIncompleteAssignmentSessionsT): Promise<void> {
  return Promise.all([
    deleteSubjectProductAssignmentSessions(
      Products.getPaths(Products.MathAssessments),
      assessmentAssignmentIds
    ),
    deleteSubjectProductAssignmentSessions(
      Products.getPaths(Products.MathAdaptive),
      adaptiveAssignmentIds
    ),
    deleteSubjectProductAssignmentSessions(
      Products.getPaths(Products.MathFactPractice),
      factPracticeAssignmentIds
    ),
    deleteSubjectProductAssignmentSessions(Products.getPaths(Products.MathIbls), iblAssignmentIds),
    deleteSubjectProductAssignmentSessions(
      Products.getPaths(Products.MathTargeted),
      standardAssignmentIds
    ),
    deleteSubjectProductAssignmentSessions(
      Products.getPaths(Products.MathNumberFacts),
      numberFactsAssignmentIds
    ),
  ]).then(() => undefined)
}

type DeleteAllMathIncompleteAssignmentSessionsT = {
  adaptiveAssignmentIds: Array<number>
  assessmentAssignmentIds: Array<number>
  factPracticeAssignmentIds: Array<number>
  iblAssignmentIds: Array<number>
  standardAssignmentIds: Array<number>
  numberFactsAssignmentIds: Array<number>
}

type DeleteAllElaIncompleteAssignmentSessionsT = {
  readingAssignmentIds: Array<number>
  writingAssignmentIds: Array<number>
  assessmentAssignmentIds: Array<number>
  adaptiveSkillsAssignmentIds: Array<number>
  targetedSkillsAssignmentIds: Array<number>
  decodablesAssignmentIds: Array<number>
}

type DeleteAllSocialStudiesIncompleteAssignmentSessionsT = {
  readingAssignmentIds: Array<number>
  writingAssignmentIds: Array<number>
}

type DeleteAllScienceIncompleteAssignmentSessionsT = {
  readingAssignmentIds: Array<number>
  writingAssignmentIds: Array<number>
}

export type DeleteAllIncompleteAssignmentSessionsT = {
  math: DeleteAllMathIncompleteAssignmentSessionsT
  ela: DeleteAllElaIncompleteAssignmentSessionsT
  socialStudies: DeleteAllSocialStudiesIncompleteAssignmentSessionsT
  science: DeleteAllScienceIncompleteAssignmentSessionsT
}

export const emptyDeleteAllIncompleteAssignmentSessions: DeleteAllIncompleteAssignmentSessionsT = {
  math: {
    adaptiveAssignmentIds: [],
    assessmentAssignmentIds: [],
    factPracticeAssignmentIds: [],
    iblAssignmentIds: [],
    standardAssignmentIds: [],
    numberFactsAssignmentIds: [],
  },
  ela: {
    readingAssignmentIds: [],
    writingAssignmentIds: [],
    assessmentAssignmentIds: [],
    adaptiveSkillsAssignmentIds: [],
    targetedSkillsAssignmentIds: [],
    decodablesAssignmentIds: [],
  },
  science: {writingAssignmentIds: [], readingAssignmentIds: []},
  socialStudies: {writingAssignmentIds: [], readingAssignmentIds: []},
}

export function numDeleteAllIncompleteAssignmentSessions(
  deletes: DeleteAllIncompleteAssignmentSessionsT
): number {
  // This is a bit unsafe by recursively summing the length of all array properties
  // The advantage is that it will stay in sync if we add any new assignment types to DeleteAllIncompleteAssignmentSessionsT
  const sumLengthOfArrayProperties = (obj: Object | Object[]) => reduce(obj, getArrCount, 0)
  function getArrCount(acc: number, objValue: Object | Object[]): number {
    return (
      acc +
      (isArray(objValue)
        ? objValue.length
        : isObject(objValue)
        ? sumLengthOfArrayProperties(objValue)
        : 0)
    )
  }

  return sumLengthOfArrayProperties(deletes)
}

export function deleteAllIncompleteAssignmentSessions({
  math,
  ela,
  socialStudies,
  science,
}: DeleteAllIncompleteAssignmentSessionsT): Promise<void> {
  return Promise.all([
    deleteAllScienceIncompleteAssignmentSessions(science),
    deleteAllSocialStudiesIncompleteAssignmentSessions(socialStudies),
    deleteAllElaIncompleteAssignmentSessions(ela),
    deleteAllMathIncompleteAssignmentSessions(math),
  ]).then(() => undefined)
}

function deleteAllElaIncompleteAssignmentSessions({
  readingAssignmentIds,
  writingAssignmentIds,
  assessmentAssignmentIds,
  adaptiveSkillsAssignmentIds,
  targetedSkillsAssignmentIds,
  decodablesAssignmentIds,
}: DeleteAllElaIncompleteAssignmentSessionsT): Promise<void> {
  return Promise.all([
    deleteSubjectProductAssignmentSessions(
      Products.getPaths(Products.ElaArticlesReading),
      readingAssignmentIds
    ),
    deleteSubjectProductAssignmentSessions(
      Products.getPaths(Products.ElaArticlesWriting),
      writingAssignmentIds
    ),
    deleteSubjectProductAssignmentSessions(
      Products.getPaths(Products.ElaAssessments),
      assessmentAssignmentIds
    ),
    deleteSubjectProductAssignmentSessions(
      Products.getPaths(Products.ElaAdaptiveSkillsPractice),
      adaptiveSkillsAssignmentIds
    ),
    deleteSubjectProductAssignmentSessions(
      Products.getPaths(Products.ElaTargetedSkillsPractice),
      targetedSkillsAssignmentIds
    ),
    deleteSubjectProductAssignmentSessions(
      Products.getPaths(Products.ElaDecodables),
      decodablesAssignmentIds
    ),
  ]).then(() => undefined)
}

function deleteAllSocialStudiesIncompleteAssignmentSessions({
  readingAssignmentIds,
  writingAssignmentIds,
}: DeleteAllSocialStudiesIncompleteAssignmentSessionsT): Promise<Array<void>> {
  return Promise.all([
    deleteSubjectProductAssignmentSessions(
      Products.getPaths(Products.SocialStudiesArticlesReading),
      readingAssignmentIds
    ),
    deleteSubjectProductAssignmentSessions(
      Products.getPaths(Products.SocialStudiesArticlesWriting),
      writingAssignmentIds
    ),
  ])
}

function deleteAllScienceIncompleteAssignmentSessions({
  readingAssignmentIds,
  writingAssignmentIds,
}: DeleteAllScienceIncompleteAssignmentSessionsT): Promise<Array<void>> {
  return Promise.all([
    deleteSubjectProductAssignmentSessions(
      Products.getPaths(Products.ScienceArticlesReading),
      readingAssignmentIds
    ),
    deleteSubjectProductAssignmentSessions(
      Products.getPaths(Products.ScienceArticlesWriting),
      writingAssignmentIds
    ),
  ])
}
