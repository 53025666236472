import find from 'lodash/find'
import {ParserT, stringEnum} from '@freckle/parser'

export type IdentityProviderT = 'clever' | 'csv' | 'class-link' | 'renaissance'
export const IdentityProvider = {
  all: () => [
    IdentityProvider.Clever,
    IdentityProvider.CSV,
    IdentityProvider.ClassLink,
    IdentityProvider.Renaissance,
  ],
  Clever: 'clever' as IdentityProviderT,
  CSV: 'csv' as IdentityProviderT,
  ClassLink: 'class-link' as IdentityProviderT,
  Renaissance: 'renaissance' as IdentityProviderT,
}

type IdpEntity = Inexact<{
  identityProvider: IdentityProviderT | undefined | null
  idpManaged: boolean
}>

export const isIdpManaged = (entity: IdpEntity) => entity.idpManaged

export const isManagedByClever = (entity: IdpEntity) =>
  entity.identityProvider === IdentityProvider.Clever

export const isManagedByCSV = (entity: IdpEntity) =>
  entity.identityProvider === IdentityProvider.CSV

export const isManagedByClassLink = (entity: IdpEntity) =>
  entity.identityProvider === IdentityProvider.ClassLink

export const isManagedByRenaissance = (entity: IdpEntity) =>
  entity.identityProvider === IdentityProvider.Renaissance

export type ManagedCountT = {
  classlink: number
  clever: number
  renaissance: number
  upload: number
}

export const combineManagedCounts = (a: ManagedCountT, b: ManagedCountT): ManagedCountT => ({
  classlink: a.classlink + b.classlink,
  clever: a.clever + b.clever,
  renaissance: a.renaissance + b.renaissance,
  upload: a.upload + b.upload,
})

const singletonWhenAboveZero = (idp: IdentityProviderT, n: number): Array<IdentityProviderT> =>
  n > 0 ? [idp] : []

export const identityProvidersWithCount = ({
  classlink,
  clever,
  renaissance,
  upload,
}: Inexact<ManagedCountT>): Array<IdentityProviderT> => [
  ...singletonWhenAboveZero(IdentityProvider.Clever, clever),
  ...singletonWhenAboveZero(IdentityProvider.Renaissance, renaissance),
  ...singletonWhenAboveZero(IdentityProvider.ClassLink, classlink),
  ...singletonWhenAboveZero(IdentityProvider.CSV, upload),
]

export const parse = (s: string): IdentityProviderT | undefined | null =>
  find(IdentityProvider.all(), role => s === role) ?? null

export const parser: ParserT<IdentityProviderT | undefined | null> = stringEnum(
  'IdentityProviderT',
  parse
)
