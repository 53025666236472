import * as React from 'react'
import {fromMaybe} from '@freckle/maybe'
import {useSafeEffectExtraDeps} from '@freckle/react-hooks'

export type NotificationProps = {
  title: React.ReactElement | string
  message: React.ReactElement | string
  addClass?: string
  icon?: React.ReactElement
  timeOut: number
}

// This is exactly the props above + onClose
// ESLint doesn't supports the spread operator on types.
// When Babylon 7 is merged, we can use spread operator on types
type Props = {
  title: React.ReactElement | string
  message: React.ReactElement | string
  addClass?: string
  icon?: React.ReactElement
  timeOut: number
  onClose: () => void
}
export function Notification(props: Props) {
  const {timeOut, title, message, addClass, icon, onClose} = props

  useSafeEffectExtraDeps<{onClose: () => void}>(
    ({onClose}) => {
      let timer: ReturnType<typeof setTimeout> | null | undefined
      if (timeOut !== 0) {
        timer = setTimeout(onClose, timeOut)
      }

      return () => {
        if (timer !== null && timer !== undefined) {
          clearTimeout(timer)
        }
      }
    },
    [timeOut],
    {onClose: {value: onClose, comparator: () => true}}
  )

  const addClassStr = fromMaybe(() => '', addClass)

  const iconNode = icon ?? null

  return (
    <div className={`notification ${addClassStr}`} data-test="notification">
      <i
        className="fa fa-times close-notification"
        onClick={onClose}
        data-test="close-notification"
      />
      <div className="notification-header">
        <h3>
          {iconNode} {title}
        </h3>
      </div>
      <div className="notification-body">{message}</div>
    </div>
  )
}
