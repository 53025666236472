import * as React from 'react'
import isNil from 'lodash/isNil'
import {SyntheticEvent} from 'react'
import {useSafeTranslation} from '@freckle/educator-materials/ts/helpers/translate'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimes} from '@fortawesome/free-solid-svg-icons'
import {Button} from '@freckle/educator-materials/ts/components/button'
import {Flex} from '@freckle/educator-materials/ts/components/flex'

import {InvalidFieldAlert} from '@freckle/classroom/ts/common/components/invalid-field-alert'
import {
  emailInputFormStyle,
  emailLabelStyle,
  emailInputStyle,
  emailInputErrorStyle,
  incorrectInputStyle,
  submitBtnStyle,
} from './email-form.module.scss'

type Props = {
  email: string | null | undefined
  emailValid: boolean
  interruptedSession: boolean
  showValidity: boolean
  onChangeEmail: (e: SyntheticEvent<Element, Event>) => void
  onSubmit: (e: SyntheticEvent<Element, Event>) => void
}

export function EmailFormView(props: Props): React.ReactElement {
  const {email, emailValid, interruptedSession, showValidity, onChangeEmail, onSubmit} = props
  const {t} = useSafeTranslation()

  const inputErrorIcon = (
    <div className={incorrectInputStyle}>
      <FontAwesomeIcon icon={faTimes} size="sm" />
    </div>
  )

  const [emailValidity, emailAlert] =
    showValidity && !emailValid
      ? [
          inputErrorIcon,
          <InvalidFieldAlert key="emailError">
            <>{t('RESET_PASSWORD_INVALID_EMAIL_ADDRESS_ERROR_MESSAGE')}</>
          </InvalidFieldAlert>,
        ]
      : [null, null]

  const interruptedAlert =
    showValidity && interruptedSession ? (
      <InvalidFieldAlert>
        <>{t('RESET_PASSWORD_UNKNOWN_ERROR_MESSAGE')}</>
      </InvalidFieldAlert>
    ) : null

  const button = (
    <Button
      style="primary"
      size="lg"
      addClass={submitBtnStyle}
      dataTest="reset-password-button"
      disabled={isNil(email)}
      type="submit"
    >
      {t('RESET_PASSWORD_SUBMIT_BTN_TEXT')}
    </Button>
  )

  const emailInput = (
    <div className={`form-group ${emailInputStyle}`}>
      <label htmlFor="reset-email" className={emailLabelStyle}>
        {t('RESET_PASSWORD_EMAIL_FIELD_LABEL')}
      </label>
      <Flex flexDirection="column" className={emailInputErrorStyle}>
        {emailValidity}
        <input
          id="reset-email"
          className="form-control"
          name="email"
          onChange={onChangeEmail}
          type="email"
          autoCorrect="off"
          required
          value={email ?? undefined}
          data-test="email-input"
        />
      </Flex>
      {emailAlert}
    </div>
  )

  const resetEmailForm = (
    <form
      action=""
      id="reset-by-email-form"
      method="post"
      onSubmit={onSubmit}
      className={emailInputFormStyle}
      data-test="reset-email-form"
    >
      {emailInput}
      {button}
      {interruptedAlert}
    </form>
  )

  return (
    <>
      <p>{t('RESET_PASSWORD_INSTRUCTIONS')}</p>
      {resetEmailForm}
    </>
  )
}
