import {Parser, record, string} from '@freckle/parser'

export type QuestionAlignment = {
  question: string
  standard: string
  skill: string
}

export const parseQuestionAlignment = Parser.mkRun<QuestionAlignment>(
  record({
    question: string(),
    standard: string(),
    skill: string(),
  })
)
