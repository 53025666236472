import * as React from 'react'
import BSPopover from 'react-bootstrap/Popover'
import PopoverContent from 'react-bootstrap/PopoverContent'
import PopoverTitle from 'react-bootstrap/PopoverTitle'

import {exhaustive} from '@freckle/exhaustive'
import {addMaybeClassNames} from '@freckle/educator-entities/ts/common/helpers/classnames'

import {
  popoverStyle,
  popoverTopStyle,
  popoverRightStyle,
  popoverBottomStyle,
  popoverLeftStyle,
} from './popover.module.scss'

export type PlacementTypeT = 'top' | 'right' | 'bottom' | 'left'

type Props = {
  id: string
  placement?: PlacementTypeT
  title?: string | null
  addClass?: string | null
  dataTest?: string | null
  role?: string
  /* Content of the Popover */
  children: React.ReactNode
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

export const Popover = React.forwardRef<HTMLDivElement, Props>(
  ({placement = 'bottom', ...otherProps}, ref) => {
    const {id, title, addClass, children, dataTest, onMouseEnter, onMouseLeave, ...rest} =
      otherProps

    const titleNode =
      title !== null && title !== undefined ? <PopoverTitle as="h3">{title}</PopoverTitle> : null
    const placementStyle = getPlacementStyle(placement)
    const className = addMaybeClassNames(popoverStyle, [placementStyle, addClass])

    return (
      <BSPopover
        {...rest} // The rest of the props are passed by the OverlayTrigger
        id={id}
        placement={placement}
        className={className}
        data-test={dataTest}
        ref={ref}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {titleNode}
        <PopoverContent>{children}</PopoverContent>
      </BSPopover>
    )
  }
)
Popover.displayName = 'OverlayPopover'

function getPlacementStyle(placement?: PlacementTypeT | null): string {
  switch (placement) {
    case null:
    case undefined:
    case 'top':
      return popoverTopStyle
    case 'right':
      return popoverRightStyle
    case 'bottom':
      return popoverBottomStyle
    case 'left':
      return popoverLeftStyle
    default:
      return exhaustive(placement)
  }
}
