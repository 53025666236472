import {lazy} from 'react'

export const ActivityFeedHandler = lazy(
  () => import(/* webpackChunkName: "ActivityFeedHandler" */ './../../../components/activity-feed')
)

export const AssignmentsHandler = lazy(
  () => import(/* webpackChunkName: "AssignmentsHandler" */ './../../../components/assignments')
)

export const DashboardHandler = lazy(
  () => import(/* webpackChunkName: "DashboardHandler" */ './../../../components/dashboard/handler')
)

export const MobileReminderHandler = lazy(
  () =>
    import(/* webpackChunkName: "MobileReminderHandler" */ './../../../../pages/mobile-reminder')
)

export const ReferralProgramHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "ReferralProgramHandler" */ './../../../../pages/referral-program/components/'
    )
)

export const SettingsHandler = lazy(
  () => import(/* webpackChunkName: "SettingsHandler" */ './../../../../pages/settings/components/')
)

export const StudentGoalsHandler = lazy(
  () => import(/* webpackChunkName: "StudentGoalsHandler" */ './../../../../pages/student-goals')
)

export const TeacherOnboardingHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "TeacherOnboardingHandler" */ './../../../components/teacher-onboarding'
    )
)

export const ParentWelcomeHandler = lazy(
  () =>
    import(/* webpackChunkName: "ParentWelcomeHandler" */ './../../../components/parent-welcome')
)

export const DefaultCourseRedirectHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "DefaultCourseRedirectHandler" */ '../../../components/default-course-redirect'
    )
)
