import * as React from 'react'
import {SafeTranslation} from '@freckle/educator-materials/ts/helpers/translate'
import {NotificationProps} from '@freckle/classroom/ts/common/components/notifications-wrapper/notification'
import {DashboardUpdateNotification} from '@freckle/classroom/ts/common/components/dashboard-update-notification'

class NotificationManager {
  notificationProps: NotificationProps | null | undefined

  constructor() {
    this.notificationProps = null
  }

  create(notificationProps: NotificationProps) {
    this.notificationProps = notificationProps
    emitChange()
  }

  createDashboardUpdateNotification() {
    const message = <DashboardUpdateNotification />

    const title = (
      <SafeTranslation>
        {t => <>{t('NOTIFICATION_MANAGER_DASHBOARD_UPDATE_NOTIFICATION_TITLE')}</>}
      </SafeTranslation>
    )

    const notificationOptions = {
      title,
      message,
      timeOut: 20000,
    }
    this.create(notificationOptions)
  }

  remove() {
    this.notificationProps = null
    emitChange()
  }
}

function emitChange() {
  window.dispatchEvent(new CustomEvent('update-notification'))
}

export default new NotificationManager()
