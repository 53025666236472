import {NonEmptyArray} from '@freckle/non-empty'
import {fromJust, mmap} from '@freckle/maybe'
import {humanReadableCommaJoin} from '@freckle/educator-entities/ts/common/helpers/string-helper'
import {FactPracticeOperationT} from '@freckle/educator-entities/ts/math/fact-practice/helpers/fact-practice-operation'
import {components} from '@freckle/educator-entities/ts/curricula-api/generated-client'
import {FactPracticeOperations} from '@freckle/educator-entities/ts/curricula-api/generated-client/enums/fact-practice-operation'
import {ParserT, string, mapStatic} from '@freckle/parser'

export type MathFactPracticeOperationT = components['schemas']['Operation']

export const mathFactPracticeOperationTypeParser: ParserT<FactPracticeOperationT> = mapStatic(
  string(),
  FactPracticeOperations.unsafeParse
)

export function formatOperations(
  allOperations: Array<MathFactPracticeOperationT>,
  operations: NonEmptyArray<FactPracticeOperationT>
): string {
  const opStrings = operations.map(operation =>
    fromJust(
      mmap(
        fullOp => fullOp.description,
        allOperations.find(fullOp => fullOp.operation === operation)
      ),
      `Can't find ${operation} in all operations`
    )
  )
  return fromJust(
    humanReadableCommaJoin(opStrings),
    `Fact Practice assignment with empty operations array`
  )
}
