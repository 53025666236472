import * as React from 'react'
import {faArrowUpRightFromSquare} from '@fortawesome/free-solid-svg-icons'
import {SafeTrans, useSafeTranslation} from '@freckle/educator-materials/ts/helpers/translate'
import {Link} from '../link'
import {footerMain,footerWrap,footerText } from './footer.module.scss'

export function AuthenticatedFooter(): React.ReactElement {
  const {t} = useSafeTranslation()
  const systemStatusLink = "https://techcheck.freckle.com"

  return (
    <div className={footerMain}>
      <div className={footerWrap}>
        <p className={footerText}>
          <SafeTrans
            i18nKey="FOOTER_FRECKLE_TEXT"
          />
        </p>
        <p className={footerText}>
          &copy; {' '}
          <SafeTrans
            i18nKey="FOOTER_RIGHT_RESERVED_TEXT"
          />
        </p>
        <Link
          href={systemStatusLink}
          target="_blank"
          reactRouter={false}
          leftIcon={faArrowUpRightFromSquare}
        >
          {t('FOOTER_SYSTEM_STATUS_TEXT')}
        </Link>
      </div>
    </div>
  )
}
