import {Moment} from 'moment-timezone'
import sortBy from 'lodash/sortBy'
import {Languages, LangT} from '@freckle/educator-entities/ts/common/helpers/languages'
import {
  ReadAloudSupports,
  ReadAloudSupportT,
} from '@freckle/educator-entities/ts/common/models/read-aloud-support'
import {
  AutomatedAudios,
  AutomatedAudioT,
} from '@freckle/educator-entities/ts/common/models/automated-audio'
import {
  ParserT,
  Parser,
  string,
  record,
  number,
  boolean,
  date,
  stringEnum,
  array,
  nullable,
} from '@freckle/parser'

import {
  IdentityProviderT,
  parser as identityProviderParser,
} from '@freckle/educator-entities/ts/roster/identity-provider'
import {
  StudentNameSortField,
  StudentNameSortFieldT,
} from '@freckle/educator-entities/ts/users/models/teacher'
import {RosterSources, RosterSourceT} from '@freckle/educator-entities/ts/users/logic/roster-source'

type OnboardingRequiredT = {
  selectAvatar: boolean
  // ^ When true a student hasn't completed the select avatar onboarding step
}

export type StudentAttrs = {
  id: number
  sisId: string | undefined | null
  firstName: string
  lastName: string
  createdBy: number | undefined | null
  coinsTotal: number
  coinsToday: number
  coinsSpent: number
  districtId: number | undefined | null
  grade: number
  createdAt: Moment
  updatedAt: Moment
  lang: LangT
  diagnosticCompletedAt: Moment | undefined | null
  startingFreckleTextLevel: string | undefined | null
  wordStudyDiagnosticLevel: number | undefined | null
  cleverStudentId: string | undefined | null
  classLinkStudentId: string | undefined | null
  password: string
  isTeacher: boolean
  numberFactsLevelId: string | undefined | null
  source: RosterSourceT | undefined | null
  renaissanceRPIdentifier: string | undefined | null
  renaissanceManualRPIdentifier: string | undefined | null
  onboardingRequired: OnboardingRequiredT | undefined | null
  readAloudSupport: ReadAloudSupportT
  automatedAudio: AutomatedAudioT
  identityProvider: IdentityProviderT | undefined | null
  idpManaged: boolean
  storeLimit: number | undefined | null
}

export const parseStudentAttrs: ParserT<StudentAttrs> = record({
  id: number(),
  sisId: nullable(string()),
  firstName: string(),
  lastName: string(),
  createdBy: nullable(number()),
  coinsTotal: number(),
  coinsToday: number(),
  coinsSpent: number(),
  districtId: nullable(number()),
  grade: number(),
  createdAt: date(),
  updatedAt: date(),
  lang: Languages.parseT(),
  diagnosticCompletedAt: nullable(date()),
  startingFreckleTextLevel: nullable(string()),
  wordStudyDiagnosticLevel: nullable(number()),
  cleverStudentId: nullable(string()),
  classLinkStudentId: nullable(string()),
  password: string(),
  isTeacher: boolean(),
  numberFactsLevelId: nullable(string()),
  source: nullable(stringEnum('RosterSourceT', RosterSources.parse)),
  renaissanceRPIdentifier: nullable(string()),
  renaissanceManualRPIdentifier: nullable(string()),
  onboardingRequired: nullable(
    record({
      selectAvatar: boolean(),
    })
  ),
  readAloudSupport: stringEnum('ReadAloudSupportT', ReadAloudSupports.parse),
  automatedAudio: stringEnum('AutomatedAudioT', AutomatedAudios.parse),
  identityProvider: nullable(identityProviderParser),
  idpManaged: boolean(),
  storeLimit: nullable(number()),
})

export const parseBaseStudent = Parser.mkRun<StudentAttrs>(parseStudentAttrs)

export const parseBaseStudents = Parser.mkRun<Array<StudentAttrs>>(array(parseStudentAttrs))

export function wasUploaded(student: StudentAttrs): boolean {
  return student.source === RosterSources.upload
}

export function coinsRemaining(student: StudentAttrs): number {
  return calcCoinsRemaining(student.coinsTotal, student.coinsSpent)
}

export function formattedName(student: Inexact<{firstName: string; lastName: string}>): string {
  return formatFullName(student.firstName, student.lastName)
}

export function formatFullName(firstName: string, lastName: string): string {
  return `${firstName} ${lastName}`
}

export function getFormattedFirstName(student: StudentAttrs): string {
  return formatFirstName(student.firstName)
}

function formatFirstName(firstName: string): string {
  return `${firstName.charAt(0).toUpperCase()}${firstName.slice(1).toLowerCase()}`
}

function calcCoinsRemaining(coinsTotal: number, coinsSpent: number): number {
  return coinsTotal - coinsSpent
}

// N.B. This type uses an open record to allow resusing this function for Student and SelfStudent.
// The refined types expose their own functions, but use this function internally.
type LinkAttrsT = Inexact<{
  renaissanceRPIdentifier: string | undefined | null
  renaissanceManualRPIdentifier: string | undefined | null
}>

export function isStarLinkedGeneric({
  renaissanceRPIdentifier,
  renaissanceManualRPIdentifier,
}: LinkAttrsT): boolean {
  return (
    (renaissanceRPIdentifier !== null && renaissanceRPIdentifier !== undefined) ||
    (renaissanceManualRPIdentifier !== null && renaissanceManualRPIdentifier !== undefined)
  )
}

export function sortStudents(
  students: Array<StudentAttrs>,
  sortField: StudentNameSortFieldT
): Array<StudentAttrs> {
  return sortBy(students, student => nameForSorting(student, sortField))
}

export function nameForSorting(
  {firstName, lastName}: {firstName: string; lastName: string},
  sortByField?: StudentNameSortFieldT | null
): string {
  return sortByField === StudentNameSortField.FirstName
    ? `${firstName} ${lastName}`.toLowerCase()
    : `${lastName} ${firstName}`.toLowerCase()
}
