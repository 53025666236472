import find from 'lodash/find'
import {CourseMembershipAttrs} from '@freckle/educator-entities/ts/roster/models/course-membership'
import {PATHS} from '@freckle/educator-entities/ts/common/helpers/paths'
import {CourseAttrs} from '@freckle/educator-entities/ts/roster/models/course'
import {StudentAttrs} from '@freckle/educator-entities/ts/users/models/student'

import {getAnyActiveAndVisibleCourseWithStudent} from '@freckle/classroom/ts/roster/logic/courses'

export function getStudentTeacherLoginUrl(
  courses: Array<CourseAttrs>,
  students: Array<StudentAttrs>,
  courseMemberships: Array<CourseMembershipAttrs> | undefined
): string | undefined {
  const teacherStudent = find(students, student => student.isTeacher)
  if (teacherStudent !== undefined && courseMemberships !== undefined) {
    return getStudentLoginUrl(teacherStudent, courses, courseMemberships)
  }
}

function getStudentLoginUrl(
  student: StudentAttrs,
  courses: Array<CourseAttrs>,
  courseMemberships: Array<CourseMembershipAttrs>
): string {
  const mCourse = getAnyActiveAndVisibleCourseWithStudent(student, courses, courseMemberships)
  const mToken = mCourse ? getToken(mCourse, student) : undefined
  return PATHS.studentClientUrl + (mToken !== undefined ? `/direct-login?token=${mToken}` : '')
}

function getToken(course: CourseAttrs, student: StudentAttrs): string {
  const {firstName, lastName, password: studentPassword} = student
  const {code, passwordsEnabled} = course

  const password = passwordsEnabled ? studentPassword : null

  const data = {
    firstName,
    lastName,
    code,
    password,
  }

  return bytesToBase64(new TextEncoder().encode(JSON.stringify(data)))
}

// Taken from https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem
function bytesToBase64(bytes: Uint8Array): string {
  const binString = String.fromCodePoint(...bytes)
  return btoa(binString)
}
