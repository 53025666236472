//The only requirement for this sort to work is that the object contain a freckleTextLevel property
export interface FreckleTextLevel {
  freckleTextLevel: string
}

export default function sortFreckleTextLevels(a: FreckleTextLevel, b: FreckleTextLevel): number {
  return sortFreckleTextLevelCodes(a.freckleTextLevel, b.freckleTextLevel)
}

export function sortFreckleTextLevelCodes(a: string, b: string): number {
  if (a === b) return 0
  if (a === 'ER') return -1
  if (b === 'ER') return 1
  return a.localeCompare(b, 'en', {numeric: true})
}
