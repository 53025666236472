import {UseQueryOptions, UseQueryResult} from '@tanstack/react-query'
import {useAsync} from '@freckle/educator-entities/ts/common/hooks/use-async'
import {StudentAttrs} from '@freckle/educator-entities/ts/users/models/student'
import {fetchStudents} from '@freckle/classroom/ts/users/models/student'

export function useStudents(
  options?: Partial<UseQueryOptions<StudentAttrs[]>>
): UseQueryResult<StudentAttrs[]> {
  const defaultOptions = {staleTime: 60 * 1000}
  return useAsync(fetchStudents, ['fetchStudents'], {
    ...defaultOptions,
    ...options,
  })
}
