import * as React from 'react'
import map from 'lodash/map'
import {SidebarSection} from '@freckle/classroom/ts/common/components/sidebar/common/section'
import {SidebarIdT} from '@freckle/classroom/ts/common/components/sidebar/common/logic'
import {SidebarLink} from '@freckle/classroom/ts/common/components/sidebar/common/sidebar-link-button/link'
import {Routes} from '@freckle/classroom/ts/common/routers/routes'
import {SectionLinkInfoT} from './../section-main'

type Props = {
  showOnlyMath: boolean
  courseId: number
}

type SectionInfo = {
  route: string
  tabId: SidebarIdT
}

export function SidebarSectionSubjects(props: Props): React.ReactElement {
  const {showOnlyMath, courseId} = props

  const mathSubjectSectionLinkInfo: SectionInfo = {
    route: Routes.math.overview(courseId),
    tabId: 'math-tab',
  }
  const otherSubjectSectionLinkInfo: SectionInfo[] = [
    {
      route: Routes.ela.overview(courseId),
      tabId: 'ela-tab',
    },
    {
      route: Routes.socialStudies.units(),
      tabId: 'social-studies-tab',
    },
    {
      route: Routes.science.units(),
      tabId: 'science-tab',
    },
  ]

  return (
    <SidebarSection>
      <>
        <SidebarLink
          key={mathSubjectSectionLinkInfo.tabId}
          tabId={mathSubjectSectionLinkInfo.tabId}
          href={mathSubjectSectionLinkInfo.route}
        />

        {!showOnlyMath && <SidebarLinks links={otherSubjectSectionLinkInfo} />}
      </>
    </SidebarSection>
  )
}

type SidebarLinksProps = {
  links: Array<SectionLinkInfoT>
}

function SidebarLinks(props: SidebarLinksProps): React.ReactElement {
  return (
    <>
      {map(props.links, ({tabId, route}) => (
        <SidebarLink key={tabId} tabId={tabId} href={route} />
      ))}
    </>
  )
}
