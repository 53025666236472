import * as React from 'react'

import {LoggedRoute} from './../logged-route'
import {routerV2ElaReports} from './reports'
import {routerV2ElaAssign} from './assign'
import {routerV2ElaPrint} from './print'
import {ElaArticleDebuggerHandler, ElaOverviewHandler, StandardsPageHandler} from './lazy'

export const routerV2Ela = [
  {
    path: '/courses/:courseId/ela/standards',
    element: <LoggedRoute component={ElaOverviewHandler} />,
  },
  {
    path: '/ela/standards/:elaDomainCode/:elaStandardCode',
    element: <LoggedRoute component={StandardsPageHandler} />,
  },
  {
    path: '/courses/:courseId/ela/overview',
    element: <LoggedRoute component={ElaOverviewHandler} />,
  },
  {
    path: '/ela-article-debugger',
    element: <LoggedRoute component={ElaArticleDebuggerHandler} />,
  },
  ...routerV2ElaAssign,
  ...routerV2ElaReports,
  ...routerV2ElaPrint,
]
