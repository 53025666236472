import {Moment} from 'moment-timezone'
import {ajaxJsonCall} from '@freckle/ajax'
import {ParserT, Parser, date, number, array, record, nullable} from '@freckle/parser'
import {mapFromArray} from '@freckle/educator-entities/ts/common/helpers/common-helper'
import CApiHelper from '@freckle/educator-entities/ts/common/helpers/common-api-helper'
import {mathFactPracticeOperationTypeParser} from '@freckle/educator-entities/ts/math/fact-practice/models/math-fact-practice-operation'
import {FactPracticeOperationT} from '@freckle/educator-entities/ts/math/fact-practice/helpers/fact-practice-operation'

export type FactPracticeSnapshotAttrs = {
  id: number
  studentId: number
  assignmentSessionId: number
  createdAt: Moment
  operation: FactPracticeOperationT
  level: number
  accuracy: number | undefined | null
  numQuestionsAnswered: number | undefined | null
}

export const parseFactPracticeSnapshot: ParserT<FactPracticeSnapshotAttrs> = record({
  id: number(),
  studentId: number(),
  assignmentSessionId: number(),
  createdAt: date(),
  operation: mathFactPracticeOperationTypeParser,
  level: number(),
  accuracy: nullable(number()),
  numQuestionsAnswered: nullable(number()),
})

export const parseMathFactPracticeSnapshots = Parser.mkRun<Array<FactPracticeSnapshotAttrs>>(
  array(parseFactPracticeSnapshot)
)

export function groupByOperation(
  snapshots: Array<FactPracticeSnapshotAttrs>
): Map<FactPracticeOperationT, Array<FactPracticeSnapshotAttrs>> {
  const pairs: [FactPracticeOperationT, FactPracticeSnapshotAttrs[]][] = snapshots.map(snapshot => [
    snapshot.operation,
    [snapshot],
  ])
  return mapFromArray(pairs, (lhs, rhs) => [...lhs, ...rhs])
}

export async function fetchLatestFactPracticeSnapshotsForStudent(
  studentId: number
): Promise<Array<FactPracticeSnapshotAttrs>> {
  const url = CApiHelper.fancyPaths.v2.math_fact_practice_snapshots.latest()
  const data = {'student.ids': studentId}
  const response = await ajaxJsonCall({url, data, method: 'GET'})
  return parseMathFactPracticeSnapshots(response)
}
