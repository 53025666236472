import {getRenaissanceKeepaliveIntervalId} from '@freckle/educator-entities/ts/common/helpers/renaissance-keepalive'
import {displayNotification} from '@freckle/classroom/ts/common/helpers/helpers'
import {logOutUser} from '@freckle/classroom/ts/common/helpers/analytics'
import {deleteSessionCookie} from '@freckle/classroom/ts/api/session'

export function logout() {
  deleteSessionCookie().then(
    ({redirectUrl}) => {
      resetAfterSignout()

      if (redirectUrl !== undefined) {
        document.location.href = redirectUrl
      } else {
        document.location.assign(`https://${document.location.host}`)
      }
    },
    () => {
      displayNotification('error', 'Could not log you out at this time, please try again later.')
      console.error('Could not delete session cookie')
    }
  )
}

function resetAfterSignout() {
  const intervalId = getRenaissanceKeepaliveIntervalId()
  if (intervalId !== null) {
    clearInterval(intervalId)
  }

  logOutUser()
}
