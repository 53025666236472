// File generated by generate-openapi-enums.js, do not change directly

import {exhaustive} from '@freckle/exhaustive'
import {fromJust} from '@freckle/maybe'
import {components} from '../../generated-client'

export type FactPracticeOperation = components['schemas']['FactPracticeOperation']

const Addition: FactPracticeOperation = 'addition'
const Subtraction: FactPracticeOperation = 'subtraction'
const Multiplication: FactPracticeOperation = 'multiplication'
const Division: FactPracticeOperation = 'division'
const IntegerAdditionSubtraction: FactPracticeOperation = 'integer_addition_subtraction'
const IntegerMultiplicationDivision: FactPracticeOperation = 'integer_multiplication_division'
const Fractions: FactPracticeOperation = 'fractions'

// Get all FactPracticeOperation values
function all(): FactPracticeOperation[] {
  return [Addition, Subtraction, Multiplication, Division, IntegerAdditionSubtraction, IntegerMultiplicationDivision, Fractions]
}

// Parse FactPracticeOperation from a raw string, returns null if no match
function parse(raw: string): FactPracticeOperation | null {
  return all().find(e => e === raw) ?? null
}

// Parse FactPracticeOperation from a raw string, returns default if no match
function parseWithDefault(raw: string, def: FactPracticeOperation): FactPracticeOperation {
  return parse(raw) ?? def
}

// Parse FactPracticeOperation from a raw string, throws if no match
function unsafeParse(raw: string): FactPracticeOperation {
  return fromJust(parse(raw), `Invalid FactPracticeOperation: ${raw}`)
}

type Cases<Result> = {
  onAddition: () => Result
  onSubtraction: () => Result
  onMultiplication: () => Result
  onDivision: () => Result
  onIntegerAdditionSubtraction: () => Result
  onIntegerMultiplicationDivision: () => Result
  onFractions: () => Result
}

// Make a function that will dispatch cases for FactPracticeOperation
function mkMatch<Result>({
  onAddition,
  onSubtraction,
  onMultiplication,
  onDivision,
  onIntegerAdditionSubtraction,
  onIntegerMultiplicationDivision,
  onFractions,
}: Cases<Result>): (v: FactPracticeOperation) => Result {
  return (v: FactPracticeOperation) => {
    switch (v) {
      case 'addition': return onAddition()
      case 'subtraction': return onSubtraction()
      case 'multiplication': return onMultiplication()
      case 'division': return onDivision()
      case 'integer_addition_subtraction': return onIntegerAdditionSubtraction()
      case 'integer_multiplication_division': return onIntegerMultiplicationDivision()
      case 'fractions': return onFractions()
      default:
        return exhaustive(v)
    }
  }
}

function match<Result>(v: FactPracticeOperation, cases: Cases<Result>): Result {
  return mkMatch<Result>(cases)(v)
}

export const FactPracticeOperations = {
  // Constants
  Addition,
  Subtraction,
  Multiplication,
  Division,
  IntegerAdditionSubtraction,
  IntegerMultiplicationDivision,
  Fractions,

  // Enumeration
  all,

  // Parsing
  parse,
  unsafeParse,
  parseWithDefault,

  // Case dispatch
  mkMatch,
  match,
}
