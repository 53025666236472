import * as React from 'react'

import {LoggedRoute} from './../../logged-route'

import {
  MathAdaptiveAssignmentsHandler,
  MathAssessmentAssignmentsHandler,
  MathFactPracticeHandler,
  MathNumberFactsAssignmentsHandler,
  MathTargetedAssignmentsHandler,
  MathTargetedDepthOfKnowledgePracticeAssignmentsHandler,
} from './lazy'

export const routerV2MathAssign = [
  {
    path: '/courses/:courseId/assignments/adaptive',
    element: <LoggedRoute component={MathAdaptiveAssignmentsHandler} />,
  },
  {
    path: '/courses/:courseId/assignments/number-facts',
    element: <LoggedRoute component={MathNumberFactsAssignmentsHandler} />,
  },
  {
    path: '/courses/:courseId/assignments/targeted',
    element: <LoggedRoute component={MathTargetedAssignmentsHandler} />,
  },
  {
    path: '/courses/:courseId/assignments/assessments',
    element: <LoggedRoute component={MathAssessmentAssignmentsHandler} />,
  },
  {
    path: '/courses/:courseId/assignments/fact-practice',
    element: <LoggedRoute component={MathFactPracticeHandler} />,
  },
  {
    path: '/courses/:courseId/assignments/targeted-depth-of-knowledge-practice',
    element: <LoggedRoute component={MathTargetedDepthOfKnowledgePracticeAssignmentsHandler} />,
  },
]
