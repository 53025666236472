import * as React from 'react'
import {PremiumLicensesAttrs} from '@freckle/educator-entities/ts/common/models/premium-licenses'
import {TeacherAttrs} from '@freckle/classroom/ts/users/models/teacher'
import {trackEvent} from '@freckle/classroom/ts/common/helpers/analytics'
import {useModal} from '@freckle/classroom/ts/hooks/use-modal'
import {NavbarComponent} from './navbar'

type Props = {
  teacher: TeacherAttrs
  premiumLicenses: PremiumLicensesAttrs
}

export function NavbarContainer(props: Props): React.ReactElement {
  const {teacher, premiumLicenses} = props
  const modal = useModal()

  function onAccessAllFeatures() {
    trackEvent('quoteRequestOpenedViaNavbarGetAccessToAllFeatures')
    modal.show()
  }

  return (
    <NavbarComponent
      teacher={teacher}
      premiumLicenses={premiumLicenses}
      showQuoteRequestModal={modal.isVisible}
      hideModal={modal.hide}
      onAccessAllFeatures={onAccessAllFeatures}
    />
  )
}
