import * as React from 'react'
import {SyntheticEvent} from 'react'
import {checkEmailValidity} from '@freckle/classroom/ts/common/logic/email-validity-logic'
import {useBoolean} from '@freckle/classroom/ts/hooks/use-boolean'
import {EmailFormView} from '@freckle/classroom/ts/common/components/page/reset-by-email/email-form/email-form'
import {postRequestPasswordReset} from '@freckle/classroom/ts/api/password'

type Props = {
  onEmailSent: () => void
  onGoogleLogin: () => void
}

export function EmailForm(props: Props): React.ReactElement {
  const [email, setEmail] = React.useState<string>('')
  const {value: emailValid, setValue: setEmailValid} = useBoolean(false)
  const {value: interruptedSession, setValue: setInterruptedSession} = useBoolean(false)
  const {value: showValidity, setValue: setShowValidity} = useBoolean(false)

  function onChangeEmail(e: SyntheticEvent<EventTarget, Event>) {
    if (e.target instanceof HTMLInputElement) {
      setEmail(e.target.value)
    }
    setShowValidity(false)
  }

  function onSubmit(e: SyntheticEvent<EventTarget, Event>) {
    e.preventDefault()
    const isEmailValid = checkEmailValidity(email)
    setShowValidity(true)
    setEmailValid(isEmailValid)

    if (isEmailValid)
      postRequestPasswordReset(email).then(
        value => {
          if (value.shouldLoginNow) {
            props.onGoogleLogin()
          } else {
            props.onEmailSent()
          }
        },
        () => {
          setInterruptedSession(true)
        }
      )
  }
  return (
    <EmailFormView
      email={email}
      emailValid={emailValid}
      interruptedSession={interruptedSession}
      showValidity={showValidity}
      onChangeEmail={onChangeEmail}
      onSubmit={onSubmit}
    />
  )
}
