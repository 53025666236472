import * as React from 'react'
import {SyntheticEvent} from 'react'
import {Link as RouterLink} from 'react-router-dom'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {asHTMLAttributeValue} from '@freckle/maybe'
import {exhaustive} from '@freckle/exhaustive'

import {ButtonSizeT} from '@freckle/educator-materials/ts/components/button'
import {
  disabled as disabledStyle,
  fullWidth as fullWidthClass,
  leftIconContainer,
  rightIconContainer,
  btnPositionalStyle,
  link,
  sm,
  md,
  lg,
  xlg,
  btnPrimary,
  btnSecondary,
  btnTertiary,
  btnDanger,
  btnWarning,
  btnPremium,
} from './link.module.scss'

export type LinkStyleT =
  | 'normal'
  | 'btn-primary'
  | 'btn-secondary'
  | 'btn-tertiary'
  | 'btn-danger'
  | 'btn-warning'
  | 'btn-premium'

type LinkSizeT = ButtonSizeT
type HrefProp = string

export type Props = {
  /** `sm` or `md` or `lg` or `xlg` */
  size?: LinkSizeT
  /** Action of the button */
  onClick?: ((e: SyntheticEvent<EventTarget, Event>) => void) | null
  /** Content of the link */
  children?: React.ReactNode
  /** Link to direct to */
  href: HrefProp
  /** Additional CSS Class to add to the link */
  addClass?: string
  /** Define the style of the Link */
  style?: LinkStyleT
  disabled?: boolean
  fullWidth?: boolean
  target?: string
  rel?: string
  autoFocus?: boolean
  dataTest?: string | null
  reactRouter: boolean
  title?: string
  ariaLabel?: string
  leftIcon?: IconDefinition
  rightIcon?: IconDefinition
  tabIndex?: number
  downloadName?: string
}

/**
 * This is the standard component used for links.
 * `Link` should be used when changing page, not when performing an action.
 * When performing an action used the component `Link`.
 *
 * It contains the most used attribute of the regular HTML `<a>` tag.
 */

function Link(props: Props): React.ReactElement {
  const {
    href,
    onClick,
    children,
    dataTest,
    disabled = false,
    autoFocus,
    addClass,
    style = 'normal',
    size = 'md',
    target,
    rel,
    reactRouter,
    title,
    ariaLabel,
    fullWidth,
    leftIcon,
    rightIcon,
    tabIndex,
    downloadName,
  } = props

  const addClassStr = addClass ?? ''
  const disabledClass = disabled ? disabledStyle : ''

  const fullWidthClassName = fullWidth === true ? [fullWidthClass] : []

  const styleClass = () => {
    switch (style) {
      case null:
      case undefined:
      case 'normal':
        return null
      case 'btn-primary':
        return btnPrimary
      case 'btn-secondary':
        return btnSecondary
      case 'btn-tertiary':
        return btnTertiary
      case 'btn-danger':
        return btnDanger
      case 'btn-warning':
        return btnWarning
      case 'btn-premium':
        return btnPremium
      default:
        return exhaustive(style)
    }
  }
  const sizeClass = () => {
    switch (size) {
      case null:
      case undefined:
        return md
      case 'sm':
        return sm
      case 'md':
        return md
      case 'lg':
        return lg
      case 'xlg':
        return xlg
      default:
        return exhaustive(size)
    }
  }

  const className = [
    link,
    styleClass(),
    sizeClass(),
    disabledClass,
    addClassStr,
    ...fullWidthClassName,
  ].join(' ')

  const dataTestObj = {'data-test': asHTMLAttributeValue(dataTest)}
  const autoFocusObj = {autoFocus: autoFocus === true ? true : undefined}
  const targetObj = {target: asHTMLAttributeValue(target)}
  const relObj = {rel: asHTMLAttributeValue(rel)}
  const onClickObj = {onClick: asHTMLAttributeValue(onClick)}
  const titleObj = {title: asHTMLAttributeValue(title)}
  const ariaLabelObj = {'aria-label': asHTMLAttributeValue(ariaLabel)}

  const additionalProps = {
    ...dataTestObj,
    ...autoFocusObj,
    ...targetObj,
    ...relObj,
    ...onClickObj,
    ...titleObj,
    ...ariaLabelObj,
  }

  const leftIconNode =
    leftIcon !== undefined ? (
      <span className={leftIconContainer}>
        <FontAwesomeIcon icon={leftIcon} />
      </span>
    ) : null

  const rightIconNode =
    rightIcon !== undefined ? (
      <span className={rightIconContainer}>
        <FontAwesomeIcon icon={rightIcon} />
      </span>
    ) : null

  const linkContentInternal = (
    <>
      {leftIconNode}
      {children}
      {rightIconNode}
    </>
  )

  const linkContent = isButton(style) ? (
    <span className={btnPositionalStyle}>{linkContentInternal}</span>
  ) : (
    linkContentInternal
  )

  return reactRouter ? (
    <RouterLink to={href} className={className} {...additionalProps} tabIndex={tabIndex}>
      {linkContent}
    </RouterLink>
  ) : (
    <a
      href={href}
      className={className}
      tabIndex={tabIndex}
      download={downloadName}
      {...(additionalProps as React.HTMLProps<HTMLAnchorElement>)}
    >
      {linkContent}
    </a>
  )
}

function isButton(linkStyle?: LinkStyleT): boolean {
  switch (linkStyle) {
    case 'btn-primary':
    case 'btn-secondary':
    case 'btn-tertiary':
    case 'btn-danger':
    case 'btn-warning':
    case 'btn-premium':
      return true
    case 'normal':
    case null:
    case undefined:
      return false
    default:
      return exhaustive(linkStyle, 'LinkStyleT')
  }
}

export {Link}
