import {lazy} from 'react'

// Components

export const SocialStudiesArticlePage = lazy(
  () =>
    import(
      /* webpackChunkName: "SocialStudiesArticlePage" */ './../../../../units/social-studies/components/article-page'
    )
)

export const SocialStudiesArticleReadingAssignmentSessionReportHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "SocialStudiesArticleReadingAssignmentSessionReportHandler" */ './../../../../units/social-studies/components/reading-assignment-session-report'
    )
)

export const SocialStudiesAssignArticlePage = lazy(
  () =>
    import(
      /* webpackChunkName: "SocialStudiesAssignArticlePage" */ './../../../../units/social-studies/components/assign-article-page'
    )
)

export const SocialStudiesAssignmentSessionHistoryHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "SocialStudiesAssignmentSessionHistoryHandler" */ './../../../../units/social-studies/components/assignment-session-history'
    )
)

export const SocialStudiesAssignmentsHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "SocialStudiesAssignmentsHandler" */ './../../../../units/social-studies/common/components/assignments'
    )
)

export const SocialStudiesListingPageContainer = lazy(
  () =>
    import(
      /* webpackChunkName: "SocialStudiesListingPageContainer" */ './../../../../units/social-studies/components/listing-page'
    )
)

export const SocialStudiesSearchOrListingPage = lazy(
  () =>
    import(
      /* webpackChunkName: "SocialStudiesSearchOrListingPage" */ './../../../../units/social-studies/components/search-or-listing'
    )
)

export const SocialStudiesUnitPageHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "SocialStudiesUnitPageHandler" */ './../../../../units/social-studies/components/unit-page'
    )
)

export const SocialStudiesWritingAssignmentReportHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "SocialStudiesWritingAssignmentReportHandler" */ './../../../../units/social-studies/components/writing-assignment-session-report'
    )
)
