import * as React from 'react'
import {useLocation} from 'react-router-dom'
import {useSafeEffectExtraDeps} from '@freckle/react-hooks'
import {isEqual} from 'lodash'

export function ScrollToTop({children}: {children: React.ReactElement}): React.ReactElement {
  const location = useLocation()

  useSafeEffectExtraDeps(
    () => {
      window.scrollTo(0, 0)
    },
    [],
    {
      pathname: {value: location.pathname, comparator: isEqual},
    }
  )

  return children
}
