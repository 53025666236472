import * as React from 'react'
import {SafeTrans, useSafeTranslation} from '@freckle/educator-materials/ts/helpers/translate'
import NavDropdown from 'react-bootstrap/NavDropdown'
import MenuItem from 'react-bootstrap/DropdownItem'
import {faArrowUpRightFromSquare, faQuestionCircle} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {getFAQLink} from '@freckle/educator-materials/ts/components/support-button/links'

import {questionIcon, helpText, helpOptions, icon} from './style.module.scss'

type Props = {
  supportLink: string
  countryCode: string | null | undefined
}

export function TeacherSupportButton({countryCode, supportLink}: Props): React.ReactElement {
  const {t} = useSafeTranslation()

  const title = (
    <>
      <FontAwesomeIcon icon={faQuestionCircle} className={questionIcon} />
      <span className={helpText}>&nbsp;{t('SUPPORT_BUTTON_SHORT_HELP_TEXT')}&nbsp;</span>
    </>
  )

  const supportNode =
    countryCode === 'USA' ? <SupportChat /> : <SupportTicket supportLink={supportLink} />

  return (
    <NavDropdown id="navbar-help" title={title} className={helpOptions} alignRight>
      <MenuItem
        target="_blank"
        data-test="support-menu-help-articles-link"
        href={getFAQLink(countryCode)}
      >
        <FontAwesomeIcon icon={faArrowUpRightFromSquare} className={icon} />
        &nbsp;
        <SafeTrans i18nKey="SUPPORT_MENU_HELP_ARTICLES_ITEM_LINK_TEXT" />
      </MenuItem>
      {supportNode}
    </NavDropdown>
  )
}

const openSupportChart = (): void => {
  window.Forethought('widget', 'show')
  window.Forethought('widget', 'open')
}

function SupportChat(): React.ReactElement {
  return (
    <MenuItem data-test="support-menu-support-chat" onClick={openSupportChart}>
      <SafeTrans i18nKey="SUPPORT_MENU_SUPPORT_CHAT" />
    </MenuItem>
  )
}

type SupporTicketProps = {
  supportLink: string
}

function SupportTicket({supportLink}: SupporTicketProps): React.ReactElement {
  return (
    <MenuItem data-test="support-menu-support-ticket-link" href={supportLink}>
      <SafeTrans i18nKey="SUPPORT_MENU_SUPPORT_TICKET_ITEM_LINK_TEXT" />
    </MenuItem>
  )
}
