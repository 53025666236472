import * as React from 'react'
import {useLocation} from 'react-router-dom'
import includes from 'lodash/includes'
import {promiseObj} from '@freckle/educator-entities/ts/common/helpers/promise-obj'
import {checkLoginStatus} from '@freckle/educator-entities/ts/common/helpers/common-api-helper'
import {fetchSchools} from '@freckle/educator-entities/ts/roster/models/school'
import {fetchDistricts} from '@freckle/educator-entities/ts/roster/models/district'
import {StudentAttrs} from '@freckle/educator-entities/ts/users/models/student'
import {CourseMembershipAttrs} from '@freckle/educator-entities/ts/roster/models/course-membership'
import {CourseAttrs} from '@freckle/educator-entities/ts/roster/models/course'
import {WithResources} from '@freckle/classroom/ts/common/components/with-resources'
import {useTeacher} from '@freckle/classroom/ts/hooks/use-teacher'
import {useStudents} from '@freckle/classroom/ts/hooks/use-students'
import {useCourseMemberships} from '@freckle/educator-entities/ts/common/hooks/use-course-memberships'
import {useCourses} from '@freckle/educator-entities/ts/common/hooks/use-courses'
import {TeacherAttrs} from '@freckle/classroom/ts/users/models/teacher'
import {trackClassroomRouteChanges} from '@freckle/classroom/ts/common/helpers/analytics'
import {Routes} from '@freckle/classroom/ts/common/routers/routes'
import {displayAlert} from '@freckle/classroom/ts/common/helpers/helpers'
import {WithAuthStatus} from '@freckle/classroom/ts/common/routers/router-v2/with-auth-status'
import {checkSessionCookie as apiCheckSessionCookie} from '@freckle/classroom/ts/api/session'
import {setupAnalytics, setupRenaissanceKeepalive} from './../app-router'
import {RedirectSet} from './cookie-redirect'

const AUTH_CHECK_RETRY_PERIOD = 1000 * 60 * 15 // 15 minutes
const VALID_UNLOGGED_URLS = ['/login', '/signup', '/reset-by-email']
const LOGIN_PAGE_RETRIES = 16

type WrapperProps = React.PropsWithChildren<{}>

type Props = WrapperProps & {
  teacher: TeacherAttrs
  students: Array<StudentAttrs>
  courses: Array<CourseAttrs>
  courseMemberships: Array<CourseMembershipAttrs>
}

function MiddlewareInner({
  children,
  teacher,
  students,
  courses,
  courseMemberships,
}: Props): React.ReactElement {
  const location = useLocation()
  const [navigateToLogin, setNavigateToLogin] = React.useState(false)

  const isOnValidUnloggedUrl = React.useCallback(
    () => includes(VALID_UNLOGGED_URLS, location.pathname),
    [location]
  )

  React.useEffect(() => {
    checkLoginStatus(AUTH_CHECK_RETRY_PERIOD, isOnValidUnloggedUrl, LOGIN_PAGE_RETRIES)
  })

  React.useEffect(() => {
    const checkSessionCookie = () => {
      if (isOnValidUnloggedUrl()) {
        return
      }

      apiCheckSessionCookie().catch(() => {
        const msg = `You have been disconnected from Freckle. Please login again.`
        displayAlert('Please login', msg, () => {
          document.location.reload()
        })
      })
    }

    async function componentDidMount() {
      try {
        const {schools, districts} = await promiseObj({
          schools: fetchSchools(),
          districts: fetchDistricts(),
        })

        await setupAnalytics(teacher, students, courses, courseMemberships, schools, districts)
        await trackClassroomRouteChanges(location.pathname)

        setupRenaissanceKeepalive(teacher)
      } catch {
        setNavigateToLogin(true)
      }
      /* When the tab is opened, it will check with the API if the teacher is
       * still logged. If not a popup will show up and ask him to log again */
      window.addEventListener('focus', checkSessionCookie)
    }
    componentDidMount()

    return () => window.removeEventListener('focus', checkSessionCookie, true)
    // eslint-disable-next-line
  }, []) // we want to run this effect only once (after component mounts)

  React.useEffect(() => {
    trackClassroomRouteChanges(location.pathname)
  }, [location.pathname])

  if (navigateToLogin) {
    return (
      <RedirectSet to={location.search ? `${Routes.login()}${location.search}` : Routes.login()} />
    )
  } else {
    return <>{children}</>
  }
}

export const MiddlewareWrapper = (props: WrapperProps): React.ReactElement => {
  const location = useLocation()
  return (
    <WithAuthStatus
      render={({loggedIn}) =>
        loggedIn ? (
          <Middleware {...props} />
        ) : (
          <RedirectSet
            to={location.search ? `${Routes.login()}${location.search}` : Routes.login()}
          />
        )
      }
    />
  )
}

function Middleware(props: WrapperProps): React.ReactElement {
  const courses = useCourses()
  const courseMemberships = useCourseMemberships()
  const students = useStudents()
  const teacher = useTeacher()
  return (
    <WithResources
      resources={{teacher, students, courses, courseMemberships}}
      render={({teacher, students, courses, courseMemberships}) => {
        return (
          <MiddlewareInner
            teacher={teacher}
            students={students}
            courses={courses}
            courseMemberships={courseMemberships}
            {...props}
          />
        )
      }}
      noSpinner
    />
  )
}
