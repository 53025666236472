import {ParserT, Parser, string, record, nullable} from '@freckle/parser'
import {isProductionEnv} from '@freckle/educator-entities/ts/common/helpers/common-api-helper'

type MarketoConfigsT = {prod: MarketoConfigT; dev: MarketoConfigT}

type MarketoConfigT = {
  MARKETO_SCRIPT_SRC: string
  MARKETO_BASE_URI: string
  MARKETO_QUOTE_REQUEST_FORM_ID_BY_COUNTRY: {[ISO3CountryCode: string]: string | null | undefined}
  MARKETO_MUNCHKIN_ID: string
}

// Globals defined in index.html
declare let MARKETO_CONFIG_PROD: MarketoConfigT
declare let MARKETO_CONFIG_DEV: MarketoConfigT

const parseClassroomConfigAttrs: ParserT<MarketoConfigT> = record({
  MARKETO_SCRIPT_SRC: string(),
  MARKETO_BASE_URI: string(),
  MARKETO_QUOTE_REQUEST_FORM_ID_BY_COUNTRY: record({
    USA: nullable(string()),
    CAN: nullable(string()),
    DEFAULT: string(),
  }),
  MARKETO_MUNCHKIN_ID: string(),
})

// fail if the config is not what we expect
const CONFIG: MarketoConfigsT = {
  prod: Parser.run(MARKETO_CONFIG_PROD, parseClassroomConfigAttrs),
  dev: Parser.run(MARKETO_CONFIG_DEV, parseClassroomConfigAttrs),
}

export function getConfig(): MarketoConfigT {
  if (isProductionEnv()) {
    return CONFIG.prod
  } else {
    return CONFIG.dev
  }
}
