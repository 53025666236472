import {CONFIG} from '@freckle/educator-entities/ts/common/config'
import ApiRoutesFancy from '@freckle/educator-entities/ts/common/routers/api-routes-fancy'
import {getEnvironment} from '@freckle/educator-entities/ts/config/environment'
import {PATHS} from '@freckle/educator-entities/ts/common/helpers/paths'

const API_URL = PATHS.unversionedAPIUrl

const fancyPaths = ApiRoutesFancy.createPaths(API_URL)

/**
 * Like `legacyWithCache`, but caches the async result in a dictionary keyed on function arguments.
 *
 * Because the dictionary can be keyed on strings or numbers, the async function supplied should
 * use `CHelper.cacheKeyToNumber` or `CHelper.cacheKeyToString` internally.
 *
 * @example
 * // A teacher resource that depends on the id to fetch
 * async function _fetchTeacherResource(teacherId: number): Promise<ResourceT> { ... }
 * const fetchTeacherResource = legacyWithCacheDictionary(_fetchTeacherResource)
 *
 * const firstTeacherId = 1
 *
 * // First call hits the API
 * fetchTeacherResource(firstTeacherId).then(console.log)
 * // [resource1]
 *
 * // Subsequent calls with the same id will return the cached value:
 * fetchTeacherResource(firstTeacherId).then(console.log)
 * // [resource1]
 *
 * // Returns a different values when the argument changes:
 *
 * const secondTeacherId = 2
 *
 * fetchTeacherResource(secondTeacherId).then(console.log)
 * // [resource2]
 *
 * // Previous cache was saved:
 * fetchTeacherResource(firstTeacherId).then(console.log)
 * // [resource1]
 *
 * // Cache busting is done with the `reload` option:
 * fetchTeacherResource(firstTeacherId, {reload: true}).then(console.log)
 * // [resource1, resource3]
 */
type CacheWithDictionaryT<K, T> = {
  fetchWithCache: (
    key: K,
    options?: {
      reload: boolean
    }
  ) => Promise<T>
  markCacheStale: () => void
}

// Legacy caching helper, fetch into ResourceStatus instead
export function legacyWithCacheDictionary<K, T>(
  f: (key: K) => Promise<T>
): CacheWithDictionaryT<K, T> {
  const cache = new Map()
  let isStale = false
  const fetchWithCache = (key: K, options?: {reload: boolean}) => {
    const cached = cache.get(key)
    if (cached === undefined || (options !== undefined && options.reload) || isStale) {
      return f(key).then(value => {
        cache.set(key, value)
        isStale = false
        return value
      })
    }

    return Promise.resolve(cached)
  }

  const markCacheStale = () => {
    isStale = true
  }
  return {fetchWithCache, markCacheStale}
}

const CommonApiHelper = {
  fancyPaths,
}

export function checkInternetConnectivity(
  pingUrl: string,
  normalRetryPeriod: number,
  failedRetryPeriod: number,
  // eslint-disable-next-line @typescript-eslint/ban-types
  successCallback: Function,
  // eslint-disable-next-line @typescript-eslint/ban-types
  failureCallback: Function
) {
  // eslint-disable-next-line @typescript-eslint/ban-types
  const tryReachability: Function = () => {
    $.ajax({
      url: pingUrl,
      type: 'GET',
      // If we're in the middle of destroying the session, we don't
      // want a concurrent request to resurrect it. This is rare, but
      // it can keep a deleted teacher logged in.
      xhrFields: {
        withCredentials: false,
      },
      data: {commitTimestamp: CONFIG.COMMIT_UNIX_TIMESTAMP, commitSHA: CONFIG.COMMIT_SHA},
    })
      .done(() => {
        successCallback()
        setTimeout(tryReachability, normalRetryPeriod)
      })
      .fail(() => {
        failureCallback()
        // switch to rapid retry mode when the connection is down and prevent
        // user from interacting with the application until it's over
        setTimeout(tryReachability, failedRetryPeriod)
      })
  }

  // try to reach our servers every x secs to validate connection health
  setTimeout(tryReachability, normalRetryPeriod)
}

export function checkLoginStatus(
  retryPeriod: number,
  isOnValidUnloggedUrl: () => boolean,
  defaultLoginPageRetries: number
) {
  const checkAuth = (loginPageRetries?: number | null): void => {
    if (isOnValidUnloggedUrl()) {
      const retriesLeft =
        loginPageRetries === null || loginPageRetries === undefined
          ? defaultLoginPageRetries
          : loginPageRetries - 1
      if (retriesLeft > 0) {
        setTimeout(() => checkAuth(retriesLeft), retryPeriod)
      } else {
        document.location.reload()
        setTimeout(checkAuth, retryPeriod)
      }
    } else {
      $.ajax({
        url: fancyPaths.v2.auth_check(),
        type: 'HEAD',
      })
        .done(() => {
          setTimeout(checkAuth, retryPeriod)
        })
        .fail(() => {
          document.location.reload()
          setTimeout(checkAuth, retryPeriod)
        })
    }
  }
  setTimeout(checkAuth, retryPeriod)
}

export function isProductionEnv(): boolean {
  return getEnvironment(window.location.hostname) === 'production'
}

export function isStaging(): boolean {
  return getEnvironment(window.location.hostname) === 'staging'
}

export function isTestEnv(): boolean {
  return getEnvironment(window.location.hostname) === 'test'
}

export function isLocalhost(): boolean {
  return getEnvironment(window.location.hostname) === 'localhost'
}

export default CommonApiHelper
