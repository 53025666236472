import * as React from 'react'
import {CourseMembershipAttrs} from '@freckle/educator-entities/ts/roster/models/course-membership'
import {CourseAttrs} from '@freckle/educator-entities/ts/roster/models/course'
import {StudentAttrs} from '@freckle/educator-entities/ts/users/models/student'
import {useTeacher} from '@freckle/classroom/ts/hooks/use-teacher'
import {useStudents} from '@freckle/classroom/ts/hooks/use-students'
import {useCourses} from '@freckle/educator-entities/ts/common/hooks/use-courses'
import {useCourseMemberships} from '@freckle/educator-entities/ts/common/hooks/use-course-memberships'
import {useCurrentCourse} from '@freckle/classroom/ts/hooks/use-current-course'
import {WithResources} from '@freckle/classroom/ts/common/components/with-resources'
import {isMathOnly} from '@freckle/classroom/ts/users/models/teacher'
import {ViewStudentApp} from '@freckle/classroom/ts/common/components/sidebar/section-main/view-student-app'
import {SidebarSectionMain} from '@freckle/classroom/ts/common/components/sidebar/section-main'
import {SidebarSectionSubjects} from './section-subjects'
import {
  sidebarNavStyle,
  linkDividerTopStyle,
  linkDividerBottomStyle,
  sidebarWrapperStyle,
} from './sidebar.module.scss'

export function Sidebar(): React.ReactElement {
  const teacher = useTeacher()
  const currentCourse = useCurrentCourse()
  const courses = useCourses()
  const students = useStudents()
  const courseMemberships = useCourseMemberships()

  return (
    <WithResources
      resources={{teacher, currentCourse, courses, students, courseMemberships}}
      render={({teacher, currentCourse, courses, students, courseMemberships}) => {
        const showOnlyMath = isMathOnly(teacher)
        const sidebarContentCommonProps = {
          courses,
          students,
          courseMemberships,
          courseId: currentCourse.id,
          showOnlyMath,
        }
        return (
          <div className={sidebarWrapperStyle}>
            <div data-test="sidebar-nav" className={sidebarNavStyle}>
              <SidebarContent {...sidebarContentCommonProps} />
            </div>
          </div>
        )
      }}
    />
  )
}

function SidebarContent(props: {
  courseId: number
  courses: CourseAttrs[]
  students: StudentAttrs[]
  courseMemberships: CourseMembershipAttrs[]
  showOnlyMath: boolean
}): React.ReactElement {
  const {courseId, courses, students, courseMemberships, showOnlyMath} = props
  return (
    <>
      <SidebarSectionMain courseId={courseId} />
      <div className={linkDividerTopStyle} />
      <ViewStudentApp
        courses={courses}
        students={students}
        courseMemberships={courseMemberships}
        isActive={false}
      />
      <div className={linkDividerBottomStyle} />
      <SidebarSectionSubjects courseId={courseId} showOnlyMath={showOnlyMath} />
    </>
  )
}
