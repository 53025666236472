import {ajaxJsonCall} from '@freckle/ajax'
import {Moment} from 'moment-timezone'
import {ProductPathsT} from '@freckle/educator-entities/ts/common/helpers/products'
import CApiHelper from '@freckle/educator-entities/ts/common/helpers/common-api-helper'

export type EditableGenericAssignmentAttributesT = {title: string; startsAt?: Moment}

export function patchSubjectProductAssignment(
  productPaths: ProductPathsT,
  assignmentId: number,
  attributes: EditableGenericAssignmentAttributesT
): Promise<void> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v3.subjects.subject.products.product.subject_product_assignments.subject_product_assignment._(
      productPaths.subjectPath,
      productPaths.productPath,
      assignmentId
    ),
    method: 'PATCH',
    data: JSON.stringify(attributes),
  })
}

export function deleteSubjectProductAssignment(
  productPaths: ProductPathsT,
  assignmentId: number
): Promise<void> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v3.subjects.subject.products.product.subject_product_assignments.subject_product_assignment._(
      productPaths.subjectPath,
      productPaths.productPath,
      assignmentId
    ),
    method: 'DELETE',
  })
}
