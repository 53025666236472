import {ParserT, string, map} from '@freckle/parser'
import {SupportedLanguage, SupportedLanguages} from './supported-language'

export type LangT = SupportedLanguage

export const Languages = {
  parseT(): ParserT<LangT> {
    return map(string(), 'LangT', SupportedLanguages.unsafeParse)
  },

  format: SupportedLanguages.mkMatch<string>({
    onEn: () => 'English',
    onEs: () => 'Spanish',
    onEnGB: () => 'British English',
  }),

  ...SupportedLanguages,
}
