import map from 'lodash/map'
import find from 'lodash/find'
import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'
import findIndex from 'lodash/findIndex'
import {ajaxJsonCall} from '@freckle/ajax'
import {fromJust} from '@freckle/maybe'
import {PATHS} from '@freckle/educator-entities/ts/common/helpers/paths'
import {
  ParserT,
  Parser,
  record,
  field,
  string,
  array,
  number,
  pure,
  firstOf,
  literal,
  nullable,
  boolean,
  nullableDefault,
  any,
} from '@freckle/parser'
import {htmlTextParser} from '@freckle/educator-entities/ts/common/models/html-text'

import {
  ELAReadingQuestion,
  ELAWritingQuestion,
  Paragraph,
} from '@freckle/educator-entities/ts/ela/articles/models/article'

type ElaAssessmentQuestionArticleFiction = 'article_fiction'
type ElaAssessmentQuestionArticleNonFiction = 'article_non_fiction'
type ElaAssessmentQuestionGrammar = 'grammar'
type ElaAssessmentQuestionVocabulary = 'vocabulary'
type ElaAssessmentQuestionListening = 'listening'

export type ElaAssessmentQuestionType =
  | ElaAssessmentQuestionArticleFiction
  | ElaAssessmentQuestionArticleNonFiction
  | ElaAssessmentQuestionGrammar
  | ElaAssessmentQuestionVocabulary
  | ElaAssessmentQuestionListening

type ElaAssessmentQuestionTypesT = {
  ArticleFiction: ElaAssessmentQuestionArticleFiction
  ArticleNonFiction: ElaAssessmentQuestionArticleNonFiction
  Grammar: ElaAssessmentQuestionGrammar
  Vocabulary: ElaAssessmentQuestionVocabulary
  Listening: ElaAssessmentQuestionListening
}

export const ElaAssessmentQuestionTypes: ElaAssessmentQuestionTypesT = {
  ArticleFiction: 'article_fiction',
  ArticleNonFiction: 'article_non_fiction',
  Grammar: 'grammar',
  Vocabulary: 'vocabulary',
  Listening: 'listening',
}

type ElaAssessmentArticleFiction = {
  paragraphs: Array<Paragraph>
  writing: Array<ELAWritingQuestion>
  reading: Array<ELAReadingQuestion>
  type: 'article_fiction'
}

type ElaAssessmentArticleNonFiction = {
  paragraphs: Array<Paragraph>
  writing: Array<ELAWritingQuestion>
  reading: Array<ELAReadingQuestion>
  type: 'article_non_fiction'
}

type ElaAssessmentGrammarQuestion = {
  questionUuid: string
  questionText: string
  questionOptions: Array<string>
  questionAnswersIndexes: Array<number>
  type: 'grammar'
}

type ElaAssessmentVocabularyQuestion = {
  questionUuid: string
  questionText: string
  questionOptions: Array<string>
  questionAnswersIndexes: Array<number>
  type: 'vocabulary'
}

type ElaAssessmentListening = {
  paragraphs: Array<Paragraph>
  reading: Array<ELAReadingQuestion>
  type: 'listening'
}

type ElaAssessmentQuestion =
  | ElaAssessmentArticleFiction
  | ElaAssessmentArticleNonFiction
  | ElaAssessmentGrammarQuestion
  | ElaAssessmentVocabularyQuestion
  | ElaAssessmentListening

const elaReadingQuestionParser: ParserT<ELAReadingQuestion> = record({
  questionUuid: field(string(), 'question_uuid'),
  questionText: field(string(), 'question_text'),
  questionOptions: field(array(string()), 'question_options'),
  questionAnswersIndexes: field(array(number()), 'question_answers_indexes'),
  standardProxyRlSkill: any(),
})

const elaWritingQuestionParser: ParserT<ELAWritingQuestion> = record({
  questionUuid: field(string(), 'question_uuid'),
  questionText: field(htmlTextParser, 'question_text'),
  standardProxyRlSkills: pure([]),
})

export const elaParagraphParser: ParserT<Paragraph> = firstOf(
  record({
    tag: field(pure('text'), 'text'),
    text: string(),
  }),
  record({
    tag: field(pure('title'), 'title'),
    title: string(),
  }),
  record({
    tag: field(pure('picture_url'), 'picture_url'),
    pictureUrl: field(string(), 'picture_url'),
    caption: nullable(string()),
  }),
  record({
    tag: field(pure('picture'), 'picture'),
    picture: string(),
    caption: nullable(string()),
  }),
  record({
    tag: field(pure('audio_url'), 'audio_url'),
    audioUrl: field(string(), 'audio_url'),
  }),
  record({
    tag: field(pure('audio'), 'audio'),
    audio: string(),
  })
)

export const questionParser: ParserT<ElaAssessmentQuestion> = firstOf(
  record({
    type: literal('article_fiction'),
    paragraphs: nullableDefault(array(elaParagraphParser), []),
    writing: nullableDefault(array(elaWritingQuestionParser), []),
    reading: nullableDefault(array(elaReadingQuestionParser), []),
  }),
  record({
    type: literal('article_non_fiction'),
    paragraphs: nullableDefault(array(elaParagraphParser), []),
    writing: nullableDefault(array(elaWritingQuestionParser), []),
    reading: nullableDefault(array(elaReadingQuestionParser), []),
  }),
  record({
    type: literal('grammar'),
    questionUuid: field(string(), 'question_uuid'),
    questionText: field(string(), 'question_text'),
    questionOptions: field(array(string()), 'question_options'),
    questionAnswersIndexes: field(array(number()), 'question_answers_indexes'),
  }),
  record({
    type: literal('vocabulary'),
    questionUuid: field(string(), 'question_uuid'),
    questionText: field(string(), 'question_text'),
    questionOptions: field(array(string()), 'question_options'),
    questionAnswersIndexes: field(array(number()), 'question_answers_indexes'),
  }),
  record({
    type: literal('listening'),
    paragraphs: nullableDefault(array(elaParagraphParser), []),
    reading: nullableDefault(array(elaReadingQuestionParser), []),
  })
)

export type ElaAssessmentT = {
  assessmentContentUuid: string
  schoolGrade: number
  isFree: boolean
  questions: Array<ElaAssessmentQuestion>
}

const elaAssessmentParseR: ParserT<ElaAssessmentT> = record({
  assessmentContentUuid: field(string(), 'assessment_content_uuid'),
  schoolGrade: field(number(), 'school_grade'),
  isFree: field(boolean(), 'is_free'),
  questions: array(questionParser),
})

export const parseElaAssessment = Parser.mkRun<ElaAssessmentT>(elaAssessmentParseR)

export async function fetchElaAssessments(): Promise<Array<ElaAssessmentT>> {
  const url = `${PATHS.textAssetsUrl}/ela-assessments.json`
  const response: Array<unknown> = await ajaxJsonCall({url, method: 'GET'})
  return map(response, parseElaAssessment)
}

export function getElaAssessmentLetter(
  assessments: Array<ElaAssessmentT>,
  assessmentContentUuid: string
): string | undefined | null {
  const mAssessment = find(assessments, a => a.assessmentContentUuid === assessmentContentUuid)
  const assessment = fromJust(mAssessment, `Couldn't find ${assessmentContentUuid} in assessments`)

  const assessmentIndexInGrade = findIndex(
    sortBy(
      filter(assessments, a => a.schoolGrade === assessment.schoolGrade),
      a => a.assessmentContentUuid
    ),
    a => a.assessmentContentUuid === assessmentContentUuid
  )

  // This will return a capital letter, we don't expect this to go over F
  return assessmentIndexInGrade >= 0 ? String.fromCharCode(65 + assessmentIndexInGrade) : null
}
