import filter from 'lodash/filter'
import findIndex from 'lodash/findIndex'
import includes from 'lodash/includes'
import map from 'lodash/map'
import {
  ParserT,
  field,
  record,
  firstOf,
  merge,
  string,
  literal,
  array,
  onSelf,
  obfuscated,
} from '@freckle/parser'
import {BucketAnswerT} from '@freckle/educator-entities/ts/math/common/logic/answer-types'

import {
  MathQuestionMetadataAttrs,
  MathQuestionAttrs,
} from '@freckle/educator-entities/ts/math/common/models/questions'

export type MathQuestionBucketMetadataAttrs = {
  bucketFieldsName: Array<string>
  bucketCorrectAnswers: Array<Array<string>>
}

const parseMathQuestionBucketCorrectAnswers: ParserT<{
  bucketCorrectAnswers: Array<Array<string>>
}> = firstOf(
  record({bucketCorrectAnswers: field(array(array(string())), 'bucket-correct-answers')}),
  record({
    bucketCorrectAnswers: field(
      obfuscated(array(array(string()))),
      'obfuscated-bucket-correct-answers'
    ),
  })
)

const parseMathQuestionBucketMetadataAttrs: ParserT<MathQuestionBucketMetadataAttrs> = merge<
  {bucketFieldsName: Array<string>},
  {bucketCorrectAnswers: Array<Array<string>>}
>(
  record({
    bucketFieldsName: field(array(string()), 'bucket-fields-name'),
  }),
  parseMathQuestionBucketCorrectAnswers
)

export const parseMathQuestionBucketMetadata: ParserT<MathQuestionMetadataAttrs> = record({
  answerType: field(literal('bucket'), 'answer-type'),
  content: onSelf(parseMathQuestionBucketMetadataAttrs),
})

export function getAnswerCorrectness(
  question: MathQuestionAttrs,
  metadata: MathQuestionBucketMetadataAttrs,
  bucketAnswersIndexed: BucketAnswerT
): number {
  const {answers} = question
  const {bucketCorrectAnswers} = metadata

  const bucketCorrectAnswersIndexes = getCorrectAnswersIndexes(bucketCorrectAnswers, answers)
  const countGoodAnswers = filter(
    map(answers, (_ans, answerIndex: number) => {
      // Return -1 if good answer isn't in a bucket
      const correctAnswerIndex = findIndex(bucketCorrectAnswersIndexes, bucket =>
        includes(bucket, answerIndex)
      )
      // Return -1 if student didn't put the answer in a bucket
      const givenAnswerIndex = findIndex(bucketAnswersIndexed, bucket =>
        includes(bucket, answerIndex)
      )
      return givenAnswerIndex === correctAnswerIndex
    }),
    bool => bool
  ).length

  return (countGoodAnswers / answers.length) * 100
}

// Get indexes of correct answers
const getCorrectAnswersIndexes = (
  correctAnswers: Array<Array<string>>,
  answers: Array<string>
): Array<Array<number>> => {
  return map(correctAnswers, correctAnswersBucket => {
    return map(correctAnswersBucket, correctAnswer => {
      return findIndex(answers, ans => correctAnswer === ans)
    })
  })
}
