import * as React from 'react'
import {useSafeTranslation} from '@freckle/educator-materials/ts/helpers/translate'
import {PATHS} from '@freckle/educator-entities/ts/common/helpers/paths'
import {exhaustive} from '@freckle/exhaustive'

import {PageTypeT} from '@freckle/classroom/ts/common/components/page/page-wrapper'
import {avatarLogo} from './avatar-logo.module.scss'

type Props = {
  pageType: PageTypeT
}

const findLogoSrc = (pageType: PageTypeT) => {
  switch (pageType) {
    case 'login':
    case 'signup':
    case 'onboarding':
    case 'changePassword':
      return 'teacher-dashboard/teacherjt.png?h=130'
    case 'resetByEmail':
      return 'avatars/jt-confused.svg'
    case 'resetByEmailSuccess':
      return 'avatars/jt-success.svg'
    default:
      return exhaustive(pageType, 'findLogoSrc')
  }
}

export function AvatarLogo(props: Props): React.ReactElement {
  const {pageType} = props
  const {t} = useSafeTranslation()

  return (
    <img
      src={`${PATHS.imageAssetsUrl}/${findLogoSrc(pageType)}`}
      className={avatarLogo}
      alt={t('FRECKLE_AVATAR_LOGO_IMAGE_LINK_ALT_TEXT')}
      title={t('FRECKLE_AVATAR_LOGO_IMAGE_LINK_TITLE_TEXT')}
    />
  )
}
