import {lazy} from 'react'

// Async Handler Components

export const ElaAdaptiveSkillsPracticePreviewPrintAssignmentHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "ElaAdaptiveSkillsPracticePreviewPrintAssignmentHandler" */ './../../../../../ela/adaptive-skills/components/preview-print-assignment'
    )
)

export const ElaTargetedSkillsPracticePreviewPrintAssignmentHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "ElaTargetedSkillsPracticePreviewPrintAssignmentHandler" */ './../../../../../ela/targeted-skills/components/preview-print-assignment'
    )
)

export const ElaArticlePreviewPrintAssignmentHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "ElaArticlesPreviewPrintAssignmentHandler" */ './../../../../../ela/articles/components/preview-print-assignment'
    )
)
