import {Moment} from 'moment-timezone'
import {
  parseArticleContents,
  parseResumableArticleContents,
} from '@freckle/educator-entities/ts/common/models/generic-assignment-session/articles'
import {
  RlSkillUspIdT,
  parse as parseUspId,
} from '@freckle/educator-entities/ts/common/types/rl-skill-usp-id'

import {
  ParserT,
  firstOf,
  tag,
  number,
  string,
  nullable,
  record,
  date,
  stringEnum,
  literal,
  RecordParserT,
} from '@freckle/parser'
import {
  ElaAdaptiveSkillAssignmentPathway,
  ElaAdaptiveSkillAssignmentPathwayT,
} from '@freckle/educator-entities/ts/ela/adaptive-skills/models/ela-adaptive-skill-assignment-pathway'
import {RlDomainT, parseDomainAttrs} from '@freckle/educator-entities/ts/common/models/rl-domain'
import {RlSkillT, parseRlSkillAttrs} from './../../../common/models/rl-skill'
import {
  RlStandardWithDomainT,
  parseStandardWithDomainAttrs,
} from './../../../common/models/rl-standard'

import {GenericAssignmentSessionMetadataT} from './index'

/**
 * ELA Articles
 */

export const parseElaArticlesReadingMetadata: ParserT<GenericAssignmentSessionMetadataT> = record({
  tag: tag('ela_articles_reading'),
  contents: parseResumableArticleContents,
})

export const parseElaArticlesWritingMetadata: ParserT<GenericAssignmentSessionMetadataT> = record({
  tag: tag('ela_articles_writing'),
  contents: parseArticleContents,
})

/**
 * ELA Assessments
 */

export type ElaAssessmentMetadataT = {
  assessmentContentUuid: string
}

export const parseElaAssessmentsMetadata = record<
  {
    tag: RecordParserT<'ela_assessments'>
    contents: RecordParserT<ElaAssessmentMetadataT>
  },
  GenericAssignmentSessionMetadataT
>({
  tag: tag('ela_assessments'),
  contents: record({assessmentContentUuid: string()}),
})

/**
 * ELA Decodables
 */

export type ElaDecodablesMetadataT = {
  decodableUuid: string
}

export const parseElaDecodablesMetadata = record<
  {
    tag: RecordParserT<'ela_decodables'>
    contents: RecordParserT<ElaDecodablesMetadataT>
  },
  GenericAssignmentSessionMetadataT
>({
  tag: tag('ela_decodables'),
  contents: record({decodableUuid: string()}),
})

/**
 * ELA Adaptive Skills
 */

export type ElaAdaptiveSkillsPracticeMetadataT = {
  domain: RlDomainT | undefined | null
  rlStandard: RlStandardWithDomainT | undefined | null
  rlSkillId: RlSkillUspIdT | undefined | null
  startsAt: Moment | undefined | null
  pathway: ElaAdaptiveSkillAssignmentPathwayT | undefined | null
}

export const parseElaAdaptiveSkillsPracticeMetadata = record<
  {
    tag: RecordParserT<'ela_adaptive_skills_practice'>
    contents: RecordParserT<ElaAdaptiveSkillsPracticeMetadataT>
  },
  GenericAssignmentSessionMetadataT
>({
  tag: tag('ela_adaptive_skills_practice'),
  contents: record({
    domain: nullable(parseDomainAttrs),
    rlStandard: nullable(parseStandardWithDomainAttrs),
    rlSkillId: nullable(parseUspId),
    startsAt: nullable(date()),
    pathway: nullable(
      stringEnum('ElaAdaptiveSkillAssignmentPathwayT', ElaAdaptiveSkillAssignmentPathway.parse)
    ),
  }),
})

/**
 * ELA Targeted Skills
 */

export type ElaTargetedSkillsPracticeMetadataT = {
  standard: RlStandardWithDomainT
  skill: RlSkillT
  startsAt: Moment | undefined | null
}

export const parseElaTargetedSkillsPracticeMetadata = record<
  {
    tag: RecordParserT<'ela_targeted_skills_practice'>
    contents: RecordParserT<ElaTargetedSkillsPracticeMetadataT>
  },
  GenericAssignmentSessionMetadataT
>({
  tag: tag('ela_targeted_skills_practice'),
  contents: record({
    startsAt: nullable(date()),
    skill: parseRlSkillAttrs,
    standard: parseStandardWithDomainAttrs,
  }),
})

/**
 * ELA Focus Skills
 */

export type ElaFocusSkillsPracticeMetadataT = {
  standard: RlStandardWithDomainT
  skill: RlSkillT
  startsAt: Moment | undefined | null
}

// TODO: We are using the tag 'ela_targeted_skills_practice'
// for both targeted and focus skills practice since when the session is created on the student side,
// that's how it was tagged. Tech debt task: https://app.asana.com/0/1201335092255974/1207360706468057/f
export const parseElaFocusSkillsPracticeMetadata = record<
  {
    tag: RecordParserT<'ela_targeted_skills_practice'>
    contents: RecordParserT<ElaFocusSkillsPracticeMetadataT>
  },
  GenericAssignmentSessionMetadataT
>({
  tag: tag('ela_targeted_skills_practice'),
  contents: record({
    startsAt: nullable(date()),
    skill: parseRlSkillAttrs,
    standard: parseStandardWithDomainAttrs,
  }),
})

/**
 * ELA Word Study
 */

type ElaWordStudyAssignmentTypeT =
  | 'word_study_assignment_adaptive'
  | 'word_study_assignment_targeted'

export type ElaWordStudyMetadataT = {
  assignmentType: ElaWordStudyAssignmentTypeT
  rlStandard: RlStandardWithDomainT | undefined | null
  rlSkill: RlSkillT | undefined | null
}

const parseElaWordStudyAssignmentType: ParserT<ElaWordStudyAssignmentTypeT> = firstOf(
  literal('word_study_assignment_adaptive'),
  literal('word_study_assignment_targeted')
)

export const parseWordStudyMetadata = record<
  {
    tag: RecordParserT<'ela_word_study'>
    contents: RecordParserT<ElaWordStudyMetadataT>
  },
  GenericAssignmentSessionMetadataT
>({
  tag: tag('ela_word_study'),
  contents: record({
    assignmentType: parseElaWordStudyAssignmentType,
    rlStandard: nullable(parseStandardWithDomainAttrs),
    rlSkill: nullable(parseRlSkillAttrs),
  }),
})

/**
 * ELA Sight Words
 */

export type ElaSightWordsMetadataT = {
  level: number
}

export const parseElaSightWordsMetadata = record<
  {
    tag: RecordParserT<'ela_sight_words'>
    contents: RecordParserT<ElaSightWordsMetadataT>
  },
  GenericAssignmentSessionMetadataT
>({
  tag: tag('ela_sight_words'),
  contents: record({level: number()}),
})
