import * as React from 'react'
import {useLocation, Navigate} from 'react-router-dom'
import {promiseObj} from '@freckle/educator-entities/ts/common/helpers/promise-obj'
import {fetchSchools} from '@freckle/educator-entities/ts/roster/models/school'
import {fetchDistricts} from '@freckle/educator-entities/ts/roster/models/district'
import {PremiumLicensesAttrs} from '@freckle/educator-entities/ts/common/models/premium-licenses'
import {WithResources} from '@freckle/classroom/ts/common/components/with-resources'
import {useTeacher} from '@freckle/classroom/ts/hooks/use-teacher'
import {usePremiumLicenses} from '@freckle/classroom/ts/hooks/use-premium-licenses'
import {useStudents} from '@freckle/classroom/ts/hooks/use-students'
import {StudentAttrs} from '@freckle/educator-entities/ts/users/models/student'
import {TeacherAttrs} from '@freckle/classroom/ts/users/models/teacher'
import {Routes} from '@freckle/classroom/ts/common/routers/routes'
import {WithAuthStatus} from '@freckle/classroom/ts/common/routers/router-v2/with-auth-status'
import {initializePendo} from './../app-router'
import {RedirectSet} from './cookie-redirect'

type WrapperProps = React.PropsWithChildren<{}>

type Props = WrapperProps & {
  teacher: TeacherAttrs
  premiumLicenses: PremiumLicensesAttrs
  students: Array<StudentAttrs>
}

function PendoInner(props: Props): React.ReactElement {
  const location = useLocation()
  const [loading, setLoading] = React.useState<boolean>(true)
  const [navigateToLogin, setNavigateToLogin] = React.useState<boolean>(false)

  const {teacher, premiumLicenses, students} = props

  React.useEffect(() => {
    async function setupPendoClient() {
      try {
        const {schools, districts} = await promiseObj({
          schools: fetchSchools(),
          districts: fetchDistricts(),
        })

        initializePendo({
          teacher,
          districts,
          schools,
          premiumLicenses,
          students,
        })

        setLoading(false)
      } catch {
        setNavigateToLogin(true)
      }
    }

    setupPendoClient()
  }, [teacher, premiumLicenses, students])

  if (navigateToLogin) {
    return (
      <Navigate
        to={location.search ? `${Routes.login()}${location.search}` : Routes.login()}
        replace
      />
    )
  }

  if (loading) {
    return <></>
  }

  return <>{props.children}</>
}

export const PendoWrapper = (props: WrapperProps) => {
  const location = useLocation()
  return (
    <WithAuthStatus
      render={({loggedIn}) =>
        loggedIn ? (
          <Pendo {...props} />
        ) : (
          <RedirectSet
            to={location.search ? `${Routes.login()}${location.search}` : Routes.login()}
          />
        )
      }
    />
  )
}

function Pendo(props: WrapperProps): React.ReactElement {
  const premiumLicenses = usePremiumLicenses()
  const teacher = useTeacher()
  const students = useStudents()
  return (
    <WithResources
      resources={{teacher, premiumLicenses, students}}
      render={({teacher, premiumLicenses, students}) => {
        return (
          <PendoInner
            teacher={teacher}
            premiumLicenses={premiumLicenses}
            students={students}
            {...props}
          />
        )
      }}
      noSpinner
    />
  )
}
