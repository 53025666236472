import debounce from 'lodash/debounce'
import CApiHelper from '@freckle/educator-entities/ts/common/helpers/common-api-helper'
import {ajaxJsonCall} from '@freckle/ajax'
import {displayNotification} from '@freckle/classroom/ts/common/helpers/helpers'

//
// Have API send a verification email
//
// Throttle to do this only once every 10 min
//
// TODO: swith to server-side throttling
export const resendEmailVerification: (email: string) => void = debounce(
  function (email: string): Promise<void> {
    if (!email) {
      return Promise.resolve(undefined)
    }

    return ajaxJsonCall({
      url: CApiHelper.fancyPaths.v2.email_verifications(),
      method: 'POST',
      data: JSON.stringify({email}),
    }).then(
      () => {
        displayNotification('success', 'Email sent. Please check your inbox.')
      },
      () => {
        displayNotification('error', 'Could not send email at this point in time, try again later.')
      }
    )
  },
  60 * 1000,
  {leading: true}
)
