import * as React from 'react'
import {WithResources} from '@freckle/classroom/ts/common/components/with-resources'
import {EventsKey} from '@freckle/classroom/ts/common/helpers/analytics'
import {useTeacher} from '@freckle/classroom/ts/hooks/use-teacher'
import {DEFAULT_ANALYTICS_EVENT_KEY} from './quote-request-modal/constants'
import {QuoteRequestModal as Modal} from './quote-request-modal'

type Props = {
  analyticsEventKey?: EventsKey
  show: boolean
  onHide: () => void
}

export const QuoteRequestModal = (props: Props): React.ReactElement => {
  const {analyticsEventKey = DEFAULT_ANALYTICS_EVENT_KEY} = props
  const teacher = useTeacher()

  return (
    <WithResources
      resources={{teacher}}
      render={({teacher}) => (
        <Modal teacher={teacher} {...props} analyticsEventKey={analyticsEventKey} />
      )}
    />
  )
}
