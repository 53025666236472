type ParsedTarget =
  | {
      tag: 'link'
      el: HTMLLinkElement
    }
  | {
      tag: 'element'
      el: HTMLElement
    }
  | {
      tag: 'unknown'
    }

// N.B.: EventTarget seems identical to HTMLElement but is a distinct type
type ParsableEvent = Inexact<{
  target: EventTarget
}>

// Events do not carry well-typed target elements, so this attempts to parse and centralize
// any unavoidable casting
export const parseElementFromEvent = (e: ParsableEvent): ParsedTarget => {
  try {
    const el = e.target as HTMLLinkElement | HTMLElement

    if (
      el.tagName === 'LINK' &&
      el.getAttribute('rel') === 'stylesheet' &&
      'href' in el &&
      typeof el.href === 'string'
    ) {
      return {
        tag: 'link',
        el,
      }
    } else {
      return {
        tag: 'element',
        el,
      }
    }
  } catch (e) {
    return {
      tag: 'unknown',
    }
  }
}
