import find from 'lodash/find'
import {exhaustive} from '@freckle/exhaustive'

import {TranslateT} from '@freckle/educator-entities/ts/common/helpers/translate'

export type ElaLevelingProgramT =
  | 'development_reading_assessment'
  | 'fountas_and_pinnell'
  | 'freckle'
  | 'star_scaled_score'

type ElaLevelingProgramsT = {
  Dra: ElaLevelingProgramT
  FAndP: ElaLevelingProgramT
  FreckleTextLevel: ElaLevelingProgramT
  StarScaledScore: ElaLevelingProgramT
  all: () => Array<ElaLevelingProgramT>
  forDisplay: (elaLevelingProgram: ElaLevelingProgramT, shortVersion?: boolean) => string
  forLocalizedDisplay: (
    t: TranslateT,
    elaLevelingProgram: ElaLevelingProgramT,
    shortVersion?: boolean
  ) => string
  parse: (s: string) => ElaLevelingProgramT | undefined | null
}

export const ElaLevelingPrograms: ElaLevelingProgramsT = {
  Dra: 'development_reading_assessment',
  FAndP: 'fountas_and_pinnell',
  FreckleTextLevel: 'freckle',
  StarScaledScore: 'star_scaled_score',
  all: function (): Array<ElaLevelingProgramT> {
    return [
      ElaLevelingPrograms.Dra,
      ElaLevelingPrograms.FAndP,
      ElaLevelingPrograms.StarScaledScore,
      ElaLevelingPrograms.FreckleTextLevel,
    ]
  },
  // Deprecated - use forLocalizedDisplay instead
  forDisplay: function (elaLevelingProgram: ElaLevelingProgramT, shortVersion?: boolean): string {
    switch (elaLevelingProgram) {
      case 'development_reading_assessment':
        return shortVersion === true ? 'DRA' : 'Developmental Reading Assessment'
      case 'fountas_and_pinnell':
        return shortVersion === true ? 'F&P' : 'Fountas and Pinnell'
      case 'freckle':
        return shortVersion === true ? 'Freckle' : 'Freckle Levels'
      case 'star_scaled_score':
        return 'Star Scaled Score'
      default:
        return exhaustive(elaLevelingProgram, 'ElaLevelingProgramT')
    }
  },
  forLocalizedDisplay: function (
    t: TranslateT,
    elaLevelingProgram: ElaLevelingProgramT,
    shortVersion?: boolean
  ): string {
    switch (elaLevelingProgram) {
      case 'development_reading_assessment':
        return shortVersion === true
          ? t('ELA_LEVELING_PROGRAM_DEVELOPMENTAL_READING_ASSESSMENT_SHORT')
          : t('ELA_LEVELING_PROGRAM_DEVELOPMENTAL_READING_ASSESSMENT')
      case 'fountas_and_pinnell':
        return shortVersion === true
          ? t('ELA_LEVELING_PROGRAM_FOUNTAS_AND_PINNELL_SHORT')
          : t('ELA_LEVELING_PROGRAM_FOUNTAS_AND_PINNELL')
      case 'freckle':
        return shortVersion === true
          ? t('ELA_LEVELING_PROGRAM_FRECKLE_LEVELS_SHORT')
          : t('ELA_LEVELING_PROGRAM_FRECKLE_LEVELS')
      case 'star_scaled_score':
        return t('ELA_LEVELING_PROGRAM_STAR_SCALED_SCORE')
      default:
        return exhaustive(elaLevelingProgram, 'ElaLevelingProgramT')
    }
  },
  parse: function (s: string): ElaLevelingProgramT | undefined | null {
    return find(ElaLevelingPrograms.all(), program => s === program) ?? null
  },
}
