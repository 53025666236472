import * as React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {IconDefinition} from '@fortawesome/free-solid-svg-icons'
import {
  faExclamationTriangle,
  faExclamationCircle,
  faCheckCircle,
  faInfoCircle,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import {IconProp} from '@fortawesome/fontawesome-svg-core'
import {exhaustive} from '@freckle/exhaustive'
import {fromMaybe, maybe} from '@freckle/maybe'
import {addMaybeClassName} from '@freckle/educator-entities/ts/common/helpers/classnames'

import {Container} from '@freckle/educator-materials/ts/components/container'
import {Text} from '@freckle/educator-materials/ts/components/typography'
import {
  textStyle,
  iconWrapper,
  warningIcon,
  successIcon,
  dangerIcon,
  infoIcon,
  premiumIcon,
  dismissIcon,
  warningContainer,
  dangerContainer,
  successContainer,
  infoContainer,
  premiumContainer,
} from './alert.module.scss'

type AlertTypeT = 'warning' | 'danger' | 'success' | 'info' | 'premium'

type Props = {
  type: AlertTypeT
  /**
   * custom icon that overrides the default icons
   */
  icon?: IconDefinition
  addClass?: string
  dataTest?: string | null
  /** Content to be placed in Alert.  Text here will automatically be placed inside a
   * Text typography component with a body-2 style.
   */
  children: React.ReactNode
  dismissible?: boolean
  onDismiss?: () => void
}

export function Alert(props: Props): React.ReactElement {
  const {dataTest, children, type, icon: mIcon, dismissible, onDismiss, addClass} = props

  const {icon, containerStyle} = getIconAndContainerStyles(type, mIcon)

  const [isDismissed, setDismissed] = React.useState(false)

  function onClose() {
    setDismissed(true)
    if (onDismiss !== undefined) {
      onDismiss()
    }
  }

  const iconNode =
    icon !== null && icon !== undefined ? <div className={iconWrapper}>{icon}</div> : null

  return (
    <>
      {!isDismissed && (
        <Container
          dataTest={dataTest}
          addClass={addMaybeClassName(containerStyle, addClass)}
          size="sm"
        >
          {iconNode}

          <Text style="body-2" addClass={textStyle} htmlElement="div">
            {children}
          </Text>
          {(dismissible === true || onDismiss !== undefined) && (
            <FontAwesomeIcon
              icon={faTimes}
              className={dismissIcon}
              data-test="close-button"
              // @ts-ignore this doesn't seem to exist on `FontAwesomeIcon`'s types
              onClick={onClose}
            />
          )}
        </Container>
      )}
    </>
  )
}

type StylesT = {
  icon: React.ReactNode | undefined | null
  containerStyle: string
}

function getIconAndContainerStyles(type: AlertTypeT, mIcon?: IconDefinition | null): StylesT {
  switch (type) {
    case 'warning':
      return {
        icon: (
          <FontAwesomeIcon
            icon={fromMaybe(() => faExclamationTriangle, mIcon as IconProp)}
            className={warningIcon}
          />
        ),
        containerStyle: warningContainer,
      }

    case 'danger':
      return {
        icon: (
          <FontAwesomeIcon
            icon={fromMaybe(() => faExclamationCircle, mIcon as IconProp)}
            className={dangerIcon}
          />
        ),
        containerStyle: dangerContainer,
      }

    case 'success':
      return {
        icon: (
          <FontAwesomeIcon
            icon={fromMaybe(() => faCheckCircle, mIcon as IconProp)}
            className={successIcon}
          />
        ),
        containerStyle: successContainer,
      }

    case 'info':
      return {
        icon: (
          <FontAwesomeIcon
            icon={fromMaybe(() => faInfoCircle, mIcon as IconProp)}
            className={infoIcon}
          />
        ),
        containerStyle: infoContainer,
      }

    case 'premium':
      return {
        icon: maybe(
          () => null,
          icon => <FontAwesomeIcon icon={icon as IconProp} className={premiumIcon} />,
          mIcon
        ),
        containerStyle: premiumContainer,
      }

    default:
      return exhaustive(type, 'AlertTypeT')
  }
}
