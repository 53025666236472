import map from 'lodash/map'
import filter from 'lodash/filter'
import uniqBy from 'lodash/uniqBy'
import sortBy from 'lodash/sortBy'
import flatten from 'lodash/flatten'
import flatMap from 'lodash/flatMap'
import minBy from 'lodash/minBy'
import {ajaxJsonCall} from '@freckle/ajax'
import {LangT} from '@freckle/educator-entities/ts/common/helpers/languages'
import {PATHS} from '@freckle/educator-entities/ts/common/helpers/paths'
import {
  ParserT,
  Parser,
  boolean,
  number,
  string,
  record,
  merge,
  nullableDefault,
  nullable,
  array,
} from '@freckle/parser'
import {
  ContentArea,
  ContentAreas,
} from '@freckle/educator-entities/ts/curricula-api/generated-client/enums/content-area'
import {RlSkillUspIdT} from '@freckle/educator-entities/ts/common/types/rl-skill-usp-id'
import {RlStandardIdT} from '@freckle/educator-entities/ts/common/types/rl-standard-id'
import * as RlStandardId from '@freckle/educator-entities/ts/common/types/rl-standard-id'
import {RlDomainIdT} from '@freckle/educator-entities/ts/common/types/rl-domain-id'
import * as RlDomainId from '@freckle/educator-entities/ts/common/types/rl-domain-id'

import {RlDomainT} from '@freckle/educator-entities/ts/common/models/rl-domain'
import {parseDomainAttrs} from '@freckle/educator-entities/ts/common/models/rl-domain'
import {RlStandardSetT} from '@freckle/educator-entities/ts/common/models/rl-standard-set'
import {RlSkillT} from '@freckle/educator-entities/ts/common/models/rl-skill'

type RlStandardShared = {
  id: RlStandardIdT
  name: string // Standard "Code," e.g. "K.CC.1"
  shortName: string // "Name" for the standard, e.g. "Count to 100"
  description: string // Longer form, e.g. "Count to 100 by ones and by tens."
  progressionOrder: number
  grade: number
  domainId: RlDomainIdT
  hasElaGrammarQuestions: boolean
  hasElaPathwayContent: boolean
  hasElaWordStudyQuestions: boolean
  hasElaArticleWritingQuestions: boolean
  hasElaArticleReadingQuestions: boolean
  hasMathDok?: boolean | null // TODO: null here is awkward https://app.asana.com/0/1205102828747541/1205952740674317
  isStudentVisible: boolean
}

export type DehydratedStandard = {
  id: RlStandardIdT
}

export const parseDehydratedStandard: ParserT<DehydratedStandard> = record({
  id: RlStandardId.parse,
})

export type RlStandardT = {
  // TODO: remove this nullable type and use RlStandardWithDomainT when appropriate
  // https://app.asana.com/0/149473556304568/1200915504043803/f
  domain?: RlDomainT | null
} & RlStandardShared

export type RlStandardWithDomainT = {
  domain: RlDomainT
} & RlStandardShared

// TODO: This function is here for transitionary purposes only. It can be removed once the `domain` is removed from RlStandardT
// https://app.asana.com/0/149473556304568/1200915504043803/f
export const toRlStandard = (rlStandardWithDomain: RlStandardWithDomainT): RlStandardT => ({
  ...rlStandardWithDomain,
})

// TODO: This function is here for transitionary purposes only. It can be removed once the `domain` is removed from RlStandardT
// https://app.asana.com/0/149473556304568/1200915504043803/f
export const toRlDomain = (rlStandardWithDomain: RlStandardWithDomainT): RlDomainT => {
  return rlStandardWithDomain.domain
}

export const parseStandardAttrs: ParserT<RlStandardT> = record({
  id: RlStandardId.parse,
  name: string(),
  shortName: string(),
  description: string(),
  progressionOrder: number(),
  grade: number(),
  domainId: RlDomainId.parse,
  domain: nullable(parseDomainAttrs),
  hasElaGrammarQuestions: nullableDefault(boolean(), false),
  hasElaPathwayContent: nullableDefault(boolean(), false),
  hasElaWordStudyQuestions: nullableDefault(boolean(), false),
  hasElaArticleWritingQuestions: nullableDefault(boolean(), false),
  hasElaArticleReadingQuestions: nullableDefault(boolean(), false),
  hasMathDok: nullable(boolean()),
  isStudentVisible: nullableDefault(boolean(), true),
})

export const parseRlStandards = Parser.mkRun<RlStandardT[]>(array(parseStandardAttrs))

const parseDomainAttrsObj: ParserT<{
  domain: RlDomainT
}> = record({domain: parseDomainAttrs})

export const parseStandardWithDomainAttrs: ParserT<RlStandardWithDomainT> = merge(
  parseStandardAttrs,
  parseDomainAttrsObj
)

export const parseRlStandardWithDomain = Parser.mkRun<RlStandardWithDomainT>(
  parseStandardWithDomainAttrs
)
export const parseRlStandardWithDomains = Parser.mkRun<RlStandardWithDomainT[]>(
  array(parseStandardWithDomainAttrs)
)

/**
 * API
 */

type FetchRlStandardsParamsT = {
  domainId?: RlDomainIdT
}

export function fetchRlStandardsWithDomainWhere(
  contentArea: ContentArea,
  standardSetId: string,
  lang: LangT,
  predicate: (a: RlStandardWithDomainT) => boolean
): Promise<Array<RlStandardWithDomainT>> {
  const url = `${PATHS.textAssetsUrl}/${contentArea}/standard-sets/${standardSetId}/${lang}/standards.json`

  return ajaxJsonCall({
    url,
    method: 'GET',
  }).then(response => {
    const standards = filter(parseRlStandardWithDomains(response), predicate)
    return sortBy(standards, ['lowestGrade', 'highestGrade', 'domainId', 'progressionOrder'])
  })
}

export function fetchRlStandardsWithDomain(
  contentArea: ContentArea,
  standardSetId: string,
  lang: LangT,
  params?: FetchRlStandardsParamsT
): Promise<Array<RlStandardWithDomainT>> {
  const domainId = params && params.domainId !== undefined ? params.domainId : null
  return fetchRlStandardsWithDomainWhere(contentArea, standardSetId, lang, s =>
    domainId === null ? true : s.domainId === domainId
  )
}

export const fetchContentContainingElaRlStandardsWithDomain = (
  elaStandardSetId: string,
  lang: LangT
): Promise<Array<RlStandardWithDomainT>> =>
  fetchRlStandardsWithDomainWhere(
    ContentAreas.Ela,
    elaStandardSetId,
    lang,
    s => s.hasElaPathwayContent || s.hasElaGrammarQuestions
  )

/**
 * Grouping rl-standards by domain and name is the temporary workaround we are using
 * to address the following scenario:
 *
 * As part of our data migration from legacy fr-standards to rl-standards,
 * if N FR standards were mapped to a single state standard, preserving
 * behavior-neutral student progression required the creation of N
 * corresponding state standards (even though there really is only 1 state
 * standard).  This resulted in N state standards that look like duplicates
 * (same name and description). To address this for now, we will remove
 * duplicates within a domain and return the first standard in progression order.
 *
 * Note that if a duplicate spans across domains, we are keeping it in.
 *
 */

export function removeRlStandardNameDuplicatesWithinDomains(
  rlStandards: Array<RlStandardWithDomainT>
): Array<RlStandardWithDomainT> {
  const empty: Record<string, RlStandardWithDomainT[]> = {}
  const pairs = rlStandards.reduce((results, s) => {
    const key = `${RlDomainId.toString(s.domainId)}${s.name}`
    const existing = results[key]
    if (existing !== undefined) {
      existing.push(s)
    } else {
      results[key] = [s]
    }
    return results
  }, empty)
  const standardsToDisplay = flatMap(Object.values(pairs), standards => {
    const firstStandard = minBy(standards, s => s.progressionOrder)
    if (standards.length > 1 && firstStandard !== undefined) {
      return [firstStandard]
    } else {
      return standards
    }
  })

  return standardsToDisplay
}

export function safeExtractDomains(rlStandards: Array<RlStandardWithDomainT>): Array<RlDomainT> {
  const domains = map(rlStandards, standard => standard.domain)
  return sortBy(uniqBy(domains, domain => domain.id), ['lowestGrade', 'highestGrade'])
}

/**
 * Fetch the domains of an RL standard set
 *
 * note: If added to rl-domain.js this causes a cyclic dependency. Its presence here is temporary.
 **/
export async function fetchRlDomains(
  contentArea: ContentArea,
  standardSet: RlStandardSetT,
  lang: LangT
): Promise<Array<RlDomainT>> {
  const rlStandards = await fetchRlStandardsWithDomain(contentArea, standardSet.id, lang)
  return safeExtractDomains(rlStandards)
}

export function filterRlStandardsByGradeAndDomain(
  rlStandards: Array<RlStandardWithDomainT>,
  grade: number,
  rlDomainId: RlDomainIdT
): Array<RlStandardWithDomainT> {
  return filter(rlStandards, s => s.grade === grade && s.domainId === rlDomainId)
}

export type RlStandardMapT = Map<RlStandardIdT, RlStandardWithDomainT>
export function makeRlStandardMap(rlStandards: Array<RlStandardWithDomainT>): RlStandardMapT {
  const rlStandardsMap = new Map()

  rlStandards.forEach(rlStandard => {
    rlStandardsMap.set(rlStandard.id, rlStandard)
  })

  return rlStandardsMap
}

// Lookup table from Freckle RL Skill UspId to RL standard
export type RlStandardByRLSkillUspIdMapT = Map<RlSkillUspIdT, RlStandardWithDomainT>

export function mkRlStandardBySkillIdMap(
  rlStandards: Array<RlStandardWithDomainT>,
  rlSkills: Array<RlSkillT>
): RlStandardByRLSkillUspIdMapT {
  return new Map(
    flatten(
      map(rlSkills, rlSkill =>
        map(filter(rlStandards, rlStandard => rlStandard.id === rlSkill.standardId), rlStandard => [
          rlSkill.uspId,
          rlStandard,
        ])
      )
    )
  )
}
