import invariant from 'invariant'
import map from 'lodash/map'
import {ajaxJsonCall} from '@freckle/ajax'
import {LangT} from '@freckle/educator-entities/ts/common/helpers/languages'
import {
  Parser,
  ParserT,
  field,
  onSelf,
  record,
  boolean,
  number,
  firstOf,
  string,
  array,
  nullable,
} from '@freckle/parser'
import {exhaustive} from '@freckle/exhaustive'
import {Image, parseImage} from '@freckle/educator-entities/ts/common/models/image'
import {TaggedAnswerT} from '@freckle/educator-entities/ts/math/common/logic/answer-types'
import {PATHS} from '@freckle/educator-entities/ts/common/helpers/paths'

import {
  MathQuestionRegularMetadataAttrs,
  parseMathQuestionRegularMetadata,
  getAnswerCorrectness as regularAnswerCorrectness,
} from './question-regular'
import {
  MathQuestionMultipleChoiceMetadataAttrs,
  parseMathQuestionMultipleChoiceMetadata,
  getAnswerCorrectness as multipleChoiceAnswerCorrectness,
} from './question-multiple-choice'
import {
  MathQuestionMultipleAnswersMetadataAttrs,
  parseMathQuestionMultipleAnswersMetadata,
  getAnswerCorrectness as multipleAnswersAnswerCorrectness,
} from './question-multiple-answers'
import {
  MathQuestionBucketMetadataAttrs,
  parseMathQuestionBucketMetadata,
  getAnswerCorrectness as bucketAnswerCorrectness,
} from './question-bucket'
import {
  MathQuestionGraphExponentialMetadataAttrs,
  parseMathQuestionGraphExponentialMetadata,
  getAnswerCorrectness as graphExponentialAnswerCorrectness,
} from './question-graph-exponential'
import {
  MathQuestionGraphLinearInequalityMetadataAttrs,
  parseMathQuestionGraphLinearInequalityMetadata,
  getAnswerCorrectness as graphLinearInequalityAnswerCorrectness,
} from './question-graph-linear-inequality'
import {
  MathQuestionGraphLinearMetadataAttrs,
  parseMathQuestionGraphLinearMetadata,
  getAnswerCorrectness as graphLinearAnswerCorrectness,
} from './question-graph-linear'
import {
  MathQuestionGraphQuadraticMetadataAttrs,
  parseMathQuestionGraphQuadraticMetadata,
  getAnswerCorrectness as graphQuadraticAnswerCorrectness,
} from './question-graph-quadratic'
import {
  MathQuestionGraphScatterPointsMetadataAttrs,
  parseMathQuestionGraphScatterPointsMetadata,
  getAnswerCorrectness as graphScatterPointsAnswerCorrectness,
} from './question-graph-scatter-points'
import {
  MathQuestionFillInTheBlanksMetadataAttrs,
  parseMathQuestionFillInTheBlanksMetadata,
  fillInTheBlanksAnswerCorrectness,
} from './question-fill-in-the-blanks'

export type MathQuestionGraphMetadataAttrs =
  | {
      answerType: 'graph_exponential'
      content: MathQuestionGraphExponentialMetadataAttrs
    }
  | {
      answerType: 'graph_linear_inequality'
      content: MathQuestionGraphLinearInequalityMetadataAttrs
    }
  | {
      answerType: 'graph_linear'
      content: MathQuestionGraphLinearMetadataAttrs
    }
  | {
      answerType: 'graph_quadratic'
      content: MathQuestionGraphQuadraticMetadataAttrs
    }
  | {
      answerType: 'graph_scatter_points'
      content: MathQuestionGraphScatterPointsMetadataAttrs
    }

/* This is the new version of Math Question
 *
 * It provides a different nested structure of metadata
 * allowing to avoid inheritance
 */

export type MathQuestionMetadataAttrs =
  | {
      answerType: 'singular_or'
      content: MathQuestionRegularMetadataAttrs
    }
  | {
      answerType: 'multiple_choice'
      content: MathQuestionMultipleChoiceMetadataAttrs
    }
  | {
      answerType: 'multiple_answers'
      content: MathQuestionMultipleAnswersMetadataAttrs
    }
  | {
      answerType: 'bucket'
      content: MathQuestionBucketMetadataAttrs
    }
  | {
      answerType: 'fill_in_the_blanks'
      content: MathQuestionFillInTheBlanksMetadataAttrs
    }
  | MathQuestionGraphMetadataAttrs

const parseNewQuestionMetadata: ParserT<MathQuestionMetadataAttrs> = firstOf(
  parseMathQuestionRegularMetadata,
  parseMathQuestionMultipleChoiceMetadata,
  parseMathQuestionMultipleAnswersMetadata,
  parseMathQuestionBucketMetadata,
  parseMathQuestionGraphExponentialMetadata,
  parseMathQuestionGraphLinearInequalityMetadata,
  parseMathQuestionGraphLinearMetadata,
  parseMathQuestionGraphQuadraticMetadata,
  parseMathQuestionGraphScatterPointsMetadata,
  parseMathQuestionFillInTheBlanksMetadata
)

export type MathQuestionMapT = Map<string, MathQuestionAttrs>

export const toQuestionMap = (questions: Array<MathQuestionAttrs>): MathQuestionMapT =>
  new Map(map(questions, question => [question.id, question]))

export type MathQuestionAttrs = {
  id: string
  stemId: string
  answers: Array<string>
  worth: number
  summativeTest: boolean
  questionDiagram: Image | undefined | null
  questionText: string
  audioUrl: string | undefined | null
  metadata: MathQuestionMetadataAttrs
  isDeprecated: boolean
}

export const parseNewQuestion: ParserT<MathQuestionAttrs> = record({
  id: string(),
  stemId: field(string(), 'stem-id'),
  questionText: field(string(), 'question-text'),
  questionDiagram: field(nullable(parseImage), 'question-diagram'),
  worth: field(number(), 'worth'),
  summativeTest: field(boolean(), 'summative-test'),
  answers: field(array(string()), 'answers'),
  audioUrl: field(nullable(string()), 'audio-url'),
  metadata: onSelf(parseNewQuestionMetadata),
  isDeprecated: field(boolean(), 'is-deprecated'),
})

export const parseMathQuestion = Parser.mkRun<MathQuestionAttrs>(parseNewQuestion)

export async function fetchMathQuestionById(
  questionId: string,
  lang: LangT
): Promise<MathQuestionAttrs> {
  const url = `${PATHS.textAssetsUrl}/math/${lang}/questions/${questionId}.json`
  const response = await ajaxJsonCall({url, method: 'GET'})
  return parseMathQuestion(response)
}

export const getAnswerCorrectness = (
  question: MathQuestionAttrs,
  taggedAnswer: TaggedAnswerT
): number => {
  const metadata = question.metadata

  switch (metadata.answerType) {
    case 'singular_or':
      invariant(
        taggedAnswer.kind === 'singular_or',
        `Cannot use tagged answer kind ${taggedAnswer.kind} with singular_or question`
      )
      return regularAnswerCorrectness(metadata.content, taggedAnswer.answer)
    case 'multiple_choice':
      invariant(
        taggedAnswer.kind === 'multiple_choice',
        `Cannot use tagged answer kind ${taggedAnswer.kind} with multiple_choice question`
      )
      return multipleChoiceAnswerCorrectness(question, metadata.content, taggedAnswer.answer)
    case 'multiple_answers':
      invariant(
        taggedAnswer.kind === 'multiple_answers',
        `Cannot use tagged answer kind ${taggedAnswer.kind} with multiple_answers question`
      )
      return multipleAnswersAnswerCorrectness(question, metadata.content, taggedAnswer.answer)
    case 'bucket':
      invariant(
        taggedAnswer.kind === 'bucket',
        `Cannot use tagged answer kind ${taggedAnswer.kind} with bucket question`
      )
      return bucketAnswerCorrectness(question, metadata.content, taggedAnswer.answer)
    case 'graph_linear':
      invariant(
        taggedAnswer.kind === 'graph_linear',
        `Cannot use tagged answer kind ${taggedAnswer.kind} with graph_linear question`
      )
      return graphLinearAnswerCorrectness(metadata.content, taggedAnswer.answer)
    case 'graph_exponential':
      invariant(
        taggedAnswer.kind === 'graph_exponential',
        `Cannot use tagged answer kind ${taggedAnswer.kind} with graph_exponential question`
      )
      return graphExponentialAnswerCorrectness(metadata.content, taggedAnswer.answer)
    case 'graph_quadratic':
      invariant(
        taggedAnswer.kind === 'graph_quadratic',
        `Cannot use tagged answer kind ${taggedAnswer.kind} with graph_quadratic question`
      )
      return graphQuadraticAnswerCorrectness(metadata.content, taggedAnswer.answer)
    case 'graph_linear_inequality':
      invariant(
        taggedAnswer.kind === 'graph_linear_inequality',
        `Cannot use tagged answer kind ${taggedAnswer.kind} with graph_linear_inequality question`
      )
      return graphLinearInequalityAnswerCorrectness(metadata.content, taggedAnswer.answer)
    case 'graph_scatter_points':
      invariant(
        taggedAnswer.kind === 'graph_scatter_points',
        `Cannot use tagged answer kind ${taggedAnswer.kind} with graph_scatter_points question`
      )
      return graphScatterPointsAnswerCorrectness(metadata.content, taggedAnswer.answer)
    case 'fill_in_the_blanks':
      invariant(
        taggedAnswer.kind === 'fill_in_the_blanks',
        `Cannot use tagged answer kind ${taggedAnswer.kind} with fill_in_the_blanks question`
      )
      return fillInTheBlanksAnswerCorrectness(metadata.content.correctAnswers, taggedAnswer.answer)
    default:
      return exhaustive(metadata)
  }
}
