import {exhaustive} from '@freckle/exhaustive'
import {fromJust} from '@freckle/maybe'
import {paths} from '../../curricula-api/generated-client'

export type SupportedLanguage =
  paths['/{content-area}/standard-sets/{id}/{lang}/standards.json']['get']['parameters']['path']['lang']

const En: SupportedLanguage = 'en'
const Es: SupportedLanguage = 'es'
const EnGB: SupportedLanguage = 'en-GB'

// Get all SupportedLanguage values
function all(): SupportedLanguage[] {
  return [En, Es, EnGB]
}

// Parse SupportedLanguage from a raw string, returns null if no match
function parse(raw: string): SupportedLanguage | null {
  return all().find(e => e === raw) ?? null
}

// Parse SupportedLanguage from a raw string, returns default if no match
function parseWithDefault(raw: string, def: SupportedLanguage): SupportedLanguage {
  return parse(raw) ?? def
}

// Parse SupportedLanguage from a raw string, throws if no match
function unsafeParse(raw: string): SupportedLanguage {
  return fromJust(parse(raw), `Invalid SupportedLanguage: ${raw}`)
}

type Cases<Result> = {
  onEn: () => Result
  onEs: () => Result
  onEnGB: () => Result
}

// Make a function that will dispatch cases for SupportedLanguage
function mkMatch<Result>({onEn, onEs, onEnGB}: Cases<Result>): (v: SupportedLanguage) => Result {
  return (v: SupportedLanguage) => {
    switch (v) {
      case 'en':
        return onEn()
      case 'es':
        return onEs()
      case 'en-GB':
        return onEnGB()
      default:
        return exhaustive(v)
    }
  }
}

function match<Result>(v: SupportedLanguage, cases: Cases<Result>): Result {
  return mkMatch<Result>(cases)(v)
}

export const SupportedLanguages = {
  // Constants
  En,
  Es,
  EnGB,

  // Enumeration
  all,

  // Parsing
  parse,
  unsafeParse,
  parseWithDefault,

  // Case dispatch
  mkMatch,
  match,
}
