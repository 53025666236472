import {EventsKey} from '@freckle/classroom/ts/common/helpers/analytics'

const MESSAGE_TIMEOUT_MS = 7000

export type PaywallNotificationTypeT =
  | 'old-report'
  | 'print-without-answers'
  | 'multiple-assessments'
  | 'max-courses'
  | 'assignment-scheduling'
  | 'no-assignments-left-this-week'

type PaywallNotificationClassT = {
  notificationTimeoutId: number | undefined
  currentNotification: PaywallNotificationTypeT | null | undefined
  analyticsEventKey?: EventsKey | null | undefined
  display: (type: PaywallNotificationTypeT, analyticsEventKey?: EventsKey) => void
  clear: () => void
}

const PaywallNotification: PaywallNotificationClassT = {
  notificationTimeoutId: undefined,
  currentNotification: null,
  analyticsEventKey: null,
  display: function (type: PaywallNotificationTypeT, analyticsEventKey?: EventsKey) {
    window.clearTimeout(PaywallNotification.notificationTimeoutId)
    PaywallNotification.currentNotification = type
    PaywallNotification.analyticsEventKey = analyticsEventKey
    window.dispatchEvent(new CustomEvent('update-notification'))

    PaywallNotification.notificationTimeoutId = window.setTimeout(
      PaywallNotification.clear,
      MESSAGE_TIMEOUT_MS
    )
  },
  clear: function () {
    // Clear timeout in case this is called manually by the user
    window.clearTimeout(PaywallNotification.notificationTimeoutId)
    PaywallNotification.currentNotification = null
    // preserve analyticsEventKey even when hiding/clearing the PayWall
    // this will be reset on the next `display` call if needed
    // we do this to prevent creating an incorrect analytics event
    // PaywallNotification.analyticsEventKey = null
    window.dispatchEvent(new CustomEvent('update-notification'))
  },
}

export function getNotification(): [
  PaywallNotificationTypeT | null | undefined,
  EventsKey | null | undefined
] {
  return [PaywallNotification.currentNotification, PaywallNotification.analyticsEventKey]
}

export function displayOldReportNotification() {
  PaywallNotification.display('old-report')
}

export function displayPrintWithoutAnsNotification() {
  PaywallNotification.display('print-without-answers')
}

export function displayMaxCoursesNotification() {
  PaywallNotification.display('max-courses')
}

export function displayAssignmentSchedulingNotification() {
  PaywallNotification.display('assignment-scheduling')
}

export function displayNoAssignmentsLeftThisWeek() {
  PaywallNotification.display('no-assignments-left-this-week')
}

export const clearNotification = PaywallNotification.clear
