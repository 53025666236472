import {lazy} from 'react'

// components

export const AssessmentGrowthDetailedReportHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "AssessmentGrowthDetailedReportHandler" */ './../../../../math/assessment/components/assessment-growth-detailed-report'
    )
)

export const ClassGroupingReportHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "ClassGroupingReportHandler" */ './../../../../math/common/components/class-grouping-report'
    )
)

export const ConstructedResponsePageHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "ConstructedResponsePageHandler" */ './../../../../math/constructed-response/components'
    )
)

export const FactPracticeReportHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "FactPracticeReportHandler" */ './../../../../math/fact-practice/components/report'
    )
)

export const FactPracticeReportViewHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "FactPracticeReportViewHandler" */ './../../../../math/fact-practice/components/report/fact-practice-report-view-handler'
    )
)

export const LessonAssignmentsHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "LessonAssignmentsHandler" */ './../../../../math/ibl/components/lesson/lesson-assignments'
    )
)

export const LessonDaysHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "LessonDaysHandler" */ './../../../../math/ibl/components/lesson/lesson-days'
    )
)

export const LessonSlideshowHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "LessonSlideshowHandler" */ './../../../../math/ibl/components/lesson/slideshow'
    )
)

export const MathAdaptiveSessionReportHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "MathAdaptiveSessionReportHandler" */ './../../../../math/adaptive/components'
    )
)

export const MathAssessmentGrowthReportHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "MathAssessmentGrowthReportHandler" */ './../../../../math/assessment/components/assessment-growth-report'
    )
)

export const MathAssessmentReportHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "MathAssessmentReportHandler" */ './../../../../math/assessment/components/assessment-report'
    )
)

export const MathAssignmentsHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "MathAssignmentsHandler" */ './../../../../math/common/components/assignments'
    )
)

export const MathDiagnosticReportContainer = lazy(
  () =>
    import(
      /* webpackChunkName: "MathDiagnosticReportContainer" */ './../../../../math/levels-report/components/report'
    )
)

export const MathFactFluencyHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "MathFactFluencyHandler" */ './../../../../math/common/components/overviews/fact-fluency-overview'
    )
)

export const MathLessonListHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "MathLessonListHandler" */ './../../../../math/ibl/components/lesson/lesson-list'
    )
)

export const MathReportCardHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "MathReportCardHandler" */ './../../../../math/common/components/report-cards/math-report-card/index'
    )
)

export const MathStandardReportHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "MathStandardReportHandler" */ './../../../../math/targeted/components/report'
    )
)

export const MathTargetedSessionReportHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "MathTargetedSessionReportHandler" */ './../../../../math/targeted/components/session-history'
    )
)

export const PrintMathReportCardsHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "PrintMathReportCardsHandler" */ './../../../../math/common/components/report-cards/print-math-report-cards'
    )
)

export const PrintablesWrapper = lazy(
  () =>
    import(/* webpackChunkName: "PrintablesWrapper" */ './../../../../math/printables/components')
)

export const PrintablesExampleWrapper = lazy(
  () =>
    import(
      /* webpackChunkName: "PrintablesExampleWrapper" */ '../../../../math/printables/components/assignment/handlers/example'
    )
)

export const PrintablesAdaptiveWrapper = lazy(
  () =>
    import(
      /* webpackChunkName: "PrintablesAdaptiveWrapper" */ '../../../../math/printables/components/assignment/handlers/adaptive'
    )
)

export const PrintablesTargetedWrapper = lazy(
  () =>
    import(
      /* webpackChunkName: "PrintablesTargetedWrapper" */ '../../../../math/printables/components/assignment/handlers/targeted'
    )
)

export const ReportCardSummariesHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "ReportCardSummariesHandler" */ './../../../../math/common/components/report-cards/math-report-card-summaries/index'
    )
)

export const StandardPageHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "StandardPageHandler" */ './../../../../math/standards/components/standard-page'
    )
)

export const StandardStudentReportHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "StandardStudentReportHandler" */ './../../../../math/targeted/components/standard-student-report'
    )
)

export const AssessmentSessionHistorySessionReportHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "AssessmentSessionHistorySessionReportHandler" */ './../../../../math/assessment/components/report/assessment-session-history-session-report-handler'
    )
)

export const AssessmentSessionReportHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "AssessmentSessionReportHandler" */ './../../../../math/assessment/components/report/assessment-session-report-handler'
    )
)

export const MathTargetedAssignmentReportHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "MathTargetedAssignmentReportHandler" */ './../../../../math/targeted/components/targeted-assignment-report'
    )
)

export const MathOverviewHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "MathOverviewHandler" */ './../../../../math/common/components/content-overview'
    )
)

export const MathSkillsProgressReportHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "MathSkillsProgressReportHandler" */ './../../../../math/common/components/skills-progress-report'
    )
)

export const MathReportsOverviewHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "MathReportsOverviewHandler" */ './../../../../math/pages/math-reports-overview'
    )
)

export const GenericSessionReportHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "GenericSessionReportHandler" */ './../../../../common/components/session-report'
    )
)

export const MathPrintPreviewAssignmentHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "MathPrintAssignmentHandler"*/ '../../../../math/common/components/print-preview'
    )
)
