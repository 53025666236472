import noop from 'lodash/noop'

/**
 * Copy of bugsnag's window.onerror implementation, except error reports
 * as treated as "handled" and severityReason is "userSpecifiedSeverity"
 *
 * https://github.com/bugsnag/bugsnag-js/blob/next/packages/plugin-window-onerror/onerror.js
 */

export function getOnError(_client: unknown): () => void {
  // We report these (window.onerror) errors as `handled`

  // Temporarily stop reporting Handled errors
  // since we are exceeding the free quota in
  // bugsnag which results in sampling. Doing this
  // will allow capturing of more unhandled errors.
  return noop
}
