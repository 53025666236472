import find from 'lodash/find'

export type AutomatedAudioT = 'default' | 'always' | 'never'

export const AutomatedAudios = {
  Default: 'default' as AutomatedAudioT,
  Always: 'always' as AutomatedAudioT,
  Never: 'never' as AutomatedAudioT,

  all(): Array<AutomatedAudioT> {
    return [AutomatedAudios.Default, AutomatedAudios.Always, AutomatedAudios.Never]
  },

  parse(text: string): AutomatedAudioT | undefined | null {
    return find(AutomatedAudios.all(), value => value === text)
  },
}
