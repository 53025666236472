import {useSafeEffectExtraDeps} from '@freckle/react-hooks'
import {WrappedState} from '@freckle/educator-entities/ts/common/components/with-resources/with-resources-helpers'
import {CourseAttrs} from '@freckle/educator-entities/ts/roster/models/course'
import {useTeacher} from '@freckle/classroom/ts/hooks/use-teacher'
import {useCurrentCourseActions} from '@freckle/classroom/ts/actions/current-course'

export function useCurrentCourse(): WrappedState<CourseAttrs> {
  const teacher = useTeacher()
  const {loadDefaultCourse, currentCourse: resultFromState} = useCurrentCourseActions()

  useSafeEffectExtraDeps<{
    loadDefaultCourse: (teacherId: number) => Promise<void>
  }>(
    ({loadDefaultCourse}) => {
      if (teacher.data?.id !== undefined) {
        loadDefaultCourse(teacher.data.id)
      }
    },
    [teacher.data?.id],
    {
      loadDefaultCourse: {value: loadDefaultCourse, comparator: () => true},
    }
  )

  return resultFromState
}
