import {ParserT, string, field, record} from '@freckle/parser'

export type Image = {
  url: string
  altText: string
}

export const parseImage: ParserT<Image> = record({
  url: string(),
  altText: field(string(), 'alt-text'),
})
