import last from 'lodash/last'
import times from 'lodash/times'
import {fromJust, maybe} from '@freckle/maybe'

/*
 * Convert to Pascal/Title case
 * pascalCase('Foo bar') // FooBar
 */
export function pascalCase(str: string): string {
  return str.toLowerCase().split(' ').map(capitalizeFirstLetter).join('')
}

export function shortenTextWithEllipses(text: string, maxLength: number): string {
  return text.length > maxLength ? `${text.substr(0, maxLength - 1)}...` : text
}

export function slugify(text: string): string {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .trim() // Trim
}

/* Add commas to numbers
 * eg: 1587420 => 1,587,420
 *
 * This function is not i18n-safe. Instead try using a translation value like
 *   { x, number }
 */
export function numberToHumanFormat(x: number): string {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function toOrdinal(n: number): string {
  const s = ['th', 'st', 'nd', 'rd']
  const v = n % 100
  // TODO: https://app.asana.com/0/149473556304568/1206945111987245/f
  return n + (s[(v - 20) % 10] ?? s[v] ?? s[0]!)
}

/* This is used when setting cookies so, for example, a cookie can be sent to
  api.freckle.com when we are on classroom.freckle.com. */
export function removeHostSubdomain(url: string): string {
  return url.split('.').slice(1).join('.')
}

export function createSecureCookieString(
  name: string,
  value: string,
  domain: string,
  expiration?: Date | null,
  path?: string | null
): string {
  const domainWithoutSubdomain = removeHostSubdomain(domain)
  const expiresElem = maybe(
    () => [],
    e => [`expires=${e.toUTCString()}`],
    expiration
  )
  const pathElem = maybe(
    () => [],
    p => [`path=${p}`],
    path
  )
  const cookieElements = [
    `${name}=${value}`,
    `domain=.${domainWithoutSubdomain}`,
    'secure',
    ...expiresElem,
    ...pathElem,
  ]
  return cookieElements.join('; ')
}

export function pluralize(num: number, singularForm: string, pluralForm: string): string {
  return num === 1 ? singularForm : pluralForm
}

// iOS 11 and macOS High Sierra automatically replace single and double-quote
// keystrokes with smart quotes. This breaks student login if either the student
// or teacher is not using iOS/macOS and the other user is.
export function replaceSmartQuotes(text: string): string {
  return text.replace(/[‘’]/g, "'").replace(/[“”]/g, '"')
}

export function humanReadableCommaJoin(
  items: Array<string>,
  connective: string = 'and'
): string | undefined | null {
  switch (items.length) {
    case 0:
      return null
    case 1:
      return items[0]
    case 2: {
      const [first, second] = items
      return `${first} ${connective} ${second}`
    }
    default: {
      const allButLast = items.slice(0, -1)
      const lastElem = fromJust(last(items), 'Impossible')
      return `${allButLast.join(', ')}, ${connective} ${lastElem}`
    }
  }
}

export function replaceSpecialChars(str: string): string {
  return str
    .replace(/[ăā]/g, 'a')
    .replace(/[ĕē]/g, 'e')
    .replace(/[ĭī]/g, 'i')
    .replace(/[ŏō]/g, 'o')
    .replace(/[ŭū]/g, 'u')
}

// The content sometime contains malformed <br/> tags so we are fixing them
// We also want to replace \n with a correct <br/> tag
export function parseQuestionText(qText: string): string {
  return qText.replace(/\\n\b/g, '<br/>').replace(/(<[/]*[ ]*br[ ]*[/]*>)+/g, '<br/>')
}

// Primarily used in sight words and word study
export function textToFilename(text: string): string {
  return replaceSpecialChars(text.toLowerCase().replace(/[' -]/g, ''))
}

// Expose just the first letter of an email address and the domain
export function maskEmail(email: string): string | null {
  const firstAt = email.indexOf('@')
  if (firstAt === -1) {
    return null
  }
  const beginning = email.slice(0, firstAt)
  const rest = email.slice(firstAt)
  if (beginning.length === 0) {
    return null
  }
  return `${beginning[0]}${times(beginning.length - 1, () => '*').join('')}${rest}`
}

// Asset text may have special characters for new lines and formatting, which should be
// sanitized prior to send to a third party that expects raw text (like Polly for TTS)
export function sanitizeAssetText(text: string): string {
  return text
    .replace(/<\/?[biu]>/gi, '')
    .replace(/(<[/]*[ ]*br[ ]*[/]*>)+/g, '')
    .replace(/\\n\b/g, '')
}
