import * as React from 'react'
import {Navigate} from 'react-router-dom'
import {LoggedRoute} from '../logged-route'
import {routerV2MathAssign} from './assign'

import {
  AssessmentGrowthDetailedReportHandler,
  AssessmentSessionHistorySessionReportHandler,
  AssessmentSessionReportHandler,
  ClassGroupingReportHandler,
  ConstructedResponsePageHandler,
  FactPracticeReportHandler,
  FactPracticeReportViewHandler,
  GenericSessionReportHandler,
  LessonAssignmentsHandler,
  LessonDaysHandler,
  LessonSlideshowHandler,
  MathAdaptiveSessionReportHandler,
  MathAssessmentGrowthReportHandler,
  MathAssessmentReportHandler,
  MathAssignmentsHandler,
  MathDiagnosticReportContainer,
  MathFactFluencyHandler,
  MathLessonListHandler,
  MathOverviewHandler,
  MathPrintPreviewAssignmentHandler,
  MathReportCardHandler,
  MathReportsOverviewHandler,
  MathSkillsProgressReportHandler,
  MathStandardReportHandler,
  MathTargetedAssignmentReportHandler,
  MathTargetedSessionReportHandler,
  PrintMathReportCardsHandler,
  PrintablesAdaptiveWrapper,
  PrintablesExampleWrapper,
  PrintablesTargetedWrapper,
  PrintablesWrapper,
  ReportCardSummariesHandler,
  StandardPageHandler,
  StandardStudentReportHandler,
} from './lazy'

export const routerV2Math = [
  {
    path: '/math/session/:product/:assignmentSessionId/report',
    element: <LoggedRoute component={GenericSessionReportHandler} />,
  },
  {
    path: '/courses/:courseId/math-diagnostic-report',
    element: <LoggedRoute component={MathDiagnosticReportContainer} />,
  },
  {
    path: '/courses/:courseId/session-history/math-assessment-session/:sessionId',
    element: <LoggedRoute component={AssessmentSessionHistorySessionReportHandler} />,
  },
  {
    path: '/courses/:courseId/session-history/math-assessment/:assessmentId/session/:sessionId',
    element: <LoggedRoute component={AssessmentSessionHistorySessionReportHandler} />,
  },
  {
    path: '/math/assessment-session/:sessionId/report',
    element: <LoggedRoute component={AssessmentSessionReportHandler} />,
  },
  {
    path: '/courses/:courseId/assessment/:assessmentId/report',
    element: <LoggedRoute component={MathAssessmentReportHandler} />,
  },
  {
    path: '/math/standards/students/:studentId/report',
    element: <LoggedRoute component={StandardStudentReportHandler} />,
  },
  {
    path: '/math/goals/fact-fluency-overview/',
    element: <LoggedRoute component={MathFactFluencyHandler} />,
  },
  {
    path: '/math/adaptive-session/:assignmentSessionId/report',
    element: <LoggedRoute component={MathAdaptiveSessionReportHandler} />,
  },
  {
    path: '/courses/:courseId/standards',
    element: <LoggedRoute component={MathOverviewHandler} />,
  },
  {
    path: '/courses/:courseId/standards/:standardId',
    element: <LoggedRoute component={StandardPageHandler} />,
  },
  {
    path: '/courses/:courseId/math-report-card',
    element: <LoggedRoute component={MathReportCardHandler} />,
  },
  {
    path: '/courses/:courseId/math-standards-report',
    element: <LoggedRoute component={MathStandardReportHandler} />,
  },
  {
    path: '/courses/:courseId/print-math-report-cards',
    element: <LoggedRoute component={PrintMathReportCardsHandler} />,
  },
  {
    path: '/courses/:courseId/report-card-summaries',
    element: <LoggedRoute component={ReportCardSummariesHandler} />,
  },
  {
    path: '/math/targeted-session/:assignmentSessionId/report',
    element: <LoggedRoute component={MathTargetedSessionReportHandler} />,
  },
  {
    path: '/courses/:courseId/assignments',
    element: <LoggedRoute component={MathAssignmentsHandler} />,
  },
  {
    path: '/courses/:courseId/lessons',
    element: <LoggedRoute component={MathLessonListHandler} />,
  },
  {path: '/courses/:courseId/printables', element: <LoggedRoute component={PrintablesWrapper} />},
  {
    path: '/courses/:courseId/printables/example',
    element: <LoggedRoute component={PrintablesExampleWrapper} fullScreen />,
  },
  {
    path: '/courses/:courseId/printables/adaptive/domain/:domainId',
    element: <LoggedRoute component={PrintablesAdaptiveWrapper} fullScreen />,
  },
  {
    path: '/courses/:courseId/printables/targeted/skill/:skillId',
    element: <LoggedRoute component={PrintablesTargetedWrapper} fullScreen />,
  },
  {
    path: '/courses/:courseId/lessons/:lessonId',
    element: <LoggedRoute component={LessonDaysHandler} />,
  },
  {
    path: '/courses/:courseId/lessons/:lessonId/days/:dayNum/slideshow',
    element: <LoggedRoute component={LessonSlideshowHandler} fullScreen />,
  },
  {
    path: '/courses/:courseId/lessons/:lessonId/assignments',
    element: <LoggedRoute component={LessonAssignmentsHandler} />,
  },
  {
    path: '/courses/:courseId/class-grouping-report',
    element: <LoggedRoute component={ClassGroupingReportHandler} />,
  },
  {
    path: '/courses/:courseId/math-assessment-growth',
    element: <LoggedRoute component={MathAssessmentGrowthReportHandler} />,
  },
  {
    path: '/courses/:courseId/constructed-responses',
    element: <LoggedRoute component={ConstructedResponsePageHandler} />,
  },
  {
    path: '/courses/:courseId/math/reports',
    element: <LoggedRoute component={MathReportsOverviewHandler} />,
  },
  {
    path: '/courses/:courseId/math-assessment-growth-detailed',
    element: <LoggedRoute component={AssessmentGrowthDetailedReportHandler} />,
  },
  {
    path: '/courses/:courseId/fact-practice-report/students/:studentId/operations/:operation',
    element: <LoggedRoute component={FactPracticeReportViewHandler} />,
  },
  {
    path: '/courses/:courseId/fact-practice-reports',
    element: <LoggedRoute component={FactPracticeReportHandler} />,
  },
  {
    path: '/courses/:courseId/math/reports/targeted-practice/:assignmentId',
    element: <LoggedRoute component={MathTargetedAssignmentReportHandler} />,
  },
  {
    path: '/courses/:courseId/math/overview',
    element: <LoggedRoute component={MathOverviewHandler} />,
  },
  {path: '/math', element: <Navigate to="/courses/:courseId/math/overview" replace />},
  {
    path: '/courses/:courseId/math-skills-progress-report',
    element: <LoggedRoute component={MathSkillsProgressReportHandler} />,
  },
  {
    path: '/courses/:courseId/math/print/:product/:sessionId',
    element: <LoggedRoute component={MathPrintPreviewAssignmentHandler} fullScreen />,
  },
  ...routerV2MathAssign,
]
