import * as React from 'react'
import {useSafeEffect} from '@freckle/react-hooks'
import {Button, Props as ButtonProps} from '@freckle/educator-materials/ts/components/button'

export type Props = {
  onClick: (e: React.SyntheticEvent<EventTarget, Event>) => Promise<unknown>
} & ButtonProps

export function ButtonAsync(props: Props): React.ReactElement {
  const {onClick: onClickFromProps, disabled: initDisabled, children, ...buttonProps} = props

  const [disabled, setDisabled] = React.useState<boolean>(initDisabled ?? false)

  useSafeEffect(() => {
    setDisabled(initDisabled ?? false)
  }, [initDisabled])

  function onClick(e: React.SyntheticEvent<EventTarget, Event>) {
    if (disabled) {
      e.preventDefault()
    } else {
      setDisabled(true)
      const promise = onClickFromProps(e)
      promise.then(() => setDisabled(false))
    }
  }

  return (
    <Button {...buttonProps} disabled={disabled} onClick={onClick}>
      {children}
    </Button>
  )
}
