import {ajaxJsonCall} from '@freckle/ajax'
import {exhaustive} from '@freckle/exhaustive'

import {PATHS} from '@freckle/educator-entities/ts/common/helpers/paths'
import {WordStudyQuestionType} from '@freckle/educator-entities/ts/ela/word-study/logic/word-study-question-types'
import {WordStudyQuestion} from './../models/word-study-question'
import {parseWordStudyMultipleChoiceQuestion} from './../models/word-study-multiple-choice-question'
import {parseWordStudySortingQuestion} from './../models/word-study-sorting-question'
import {parseWordStudyInputQuestion} from './../models/word-study-input-question'

export async function fetchWordStudyQuestion(
  wordStudyQuestionsInfoId: string,
  type: WordStudyQuestionType
): Promise<WordStudyQuestion> {
  const url = `${PATHS.textAssetsUrl}/ela/word-study/questions/${wordStudyQuestionsInfoId}.json`
  const response = await ajaxJsonCall({url, method: 'GET'})
  return parseWordStudyQuestion(response, type)
}

export function parseWordStudyQuestion(response: unknown, typeFromProp?: WordStudyQuestionType) {
  const type = typeFromProp ?? identifyWordStudyQuestionType(response)
  switch (type) {
    case 'input':
      return {tag: 'input' as const, content: parseWordStudyInputQuestion(response)}
    case 'multiple_choice':
      return {
        tag: 'multiple_choice' as const,
        content: parseWordStudyMultipleChoiceQuestion(response),
      }
    case 'sorting':
      return {tag: 'sorting' as const, content: parseWordStudySortingQuestion(response)}
    default:
      return exhaustive(type)
  }
}

export function identifyWordStudyQuestionType(response: unknown): WordStudyQuestionType {
  if (response === null || typeof response !== 'object')
    throw new Error('Invalid response: identifyWordStudyQuestionType')
  if ('bucketFieldNames' in response) return 'sorting'
  if ('answers' in response) return 'multiple_choice'
  return 'input'
}
