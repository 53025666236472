import find from 'lodash/find'
import {fromJust} from '@freckle/maybe'
import {exhaustive} from '@freckle/exhaustive'
import {PATHS} from '@freckle/educator-entities/ts/common/helpers/paths'
import {ParserT, stringEnum} from '@freckle/parser'

type ElaT = 'ela'
type MathT = 'math'
type ScienceT = 'science'
type SocialStudiesT = 'social_studies'

export type SubjectT = ElaT | MathT | ScienceT | SocialStudiesT
export type SubjectPathT = 'ela' | 'math' | 'science' | 'social-studies'

export const Subjects = {
  ela: 'ela' as ElaT,
  math: 'math' as MathT,
  science: 'science' as ScienceT,
  socialStudies: 'social_studies' as SocialStudiesT,

  all(): Array<SubjectT> {
    return [Subjects.math, Subjects.ela, Subjects.socialStudies, Subjects.science]
  },

  parse(text: string): SubjectT | undefined {
    return find(Subjects.all(), value => value === text)
  },

  parseThrows(text: string): SubjectT {
    return fromJust(Subjects.parse(text), `Invalid subject ${text}`)
  },

  // Note: Do not use when interpolating a subject with other localized text
  i18nKey(subject: SubjectT): string {
    switch (subject) {
      case 'ela':
        return 'SUBJECT_ELA'
      case 'math':
        return 'SUBJECT_MATH'
      case 'science':
        return 'SUBJECT_SCIENCE'
      case 'social_studies':
        return 'SUBJECT_SOCIAL_STUDIES'
      default:
        return exhaustive(subject)
    }
  },

  // Deprecated, use `select` in locize instead
  forDisplay(subject: SubjectT): string {
    switch (subject) {
      case 'ela':
        return 'ELA'

      case 'math':
        return 'Math'

      case 'science':
        return 'Science'

      case 'social_studies':
        return 'Social Studies'

      default:
        return exhaustive(subject, 'SubjectT')
    }
  },

  descriptiveName(subject: SubjectT): string {
    switch (subject) {
      case 'ela':
        return 'English (ELA)'

      case 'math':
      case 'science':
      case 'social_studies':
        return Subjects.forDisplay(subject)

      default:
        return exhaustive(subject, 'SubjectT')
    }
  },

  iconUrl(subject: SubjectT): string {
    switch (subject) {
      case 'ela':
        return `${PATHS.imageAssetsUrl}/icons/subjects/ela.svg`
      case 'math':
        return `${PATHS.imageAssetsUrl}/icons/subjects/math.svg`
      case 'science':
        return `${PATHS.imageAssetsUrl}/icons/subjects/science.svg`
      case 'social_studies':
        return `${PATHS.imageAssetsUrl}/icons/subjects/social-studies.svg`

      default:
        return exhaustive(subject, 'SubjectT')
    }
  },
}

export const SubjectPaths = {
  elaPath: 'ela' as SubjectPathT,
  mathPath: 'math' as SubjectPathT,
  sciencePath: 'science' as SubjectPathT,
  socialStudiesPath: 'social-studies' as SubjectPathT,

  all(): Array<SubjectPathT> {
    return [
      SubjectPaths.elaPath,
      SubjectPaths.mathPath,
      SubjectPaths.sciencePath,
      SubjectPaths.socialStudiesPath,
    ]
  },

  parse(text: string): SubjectPathT | undefined {
    return find(SubjectPaths.all(), value => value === text)
  },

  parseThrows(text: string): SubjectPathT {
    return fromJust(SubjectPaths.parse(text), `Invalid subject path ${text}`)
  },

  getSubjectFromPath(subjectPath: SubjectPathT): SubjectT {
    switch (subjectPath) {
      case 'ela':
        return Subjects.ela

      case 'math':
        return Subjects.math

      case 'science':
        return Subjects.science

      case 'social-studies':
        return Subjects.socialStudies

      default:
        return exhaustive(subjectPath, 'SubjectPathT')
    }
  },

  getSubjectPath(subject: SubjectT): SubjectPathT {
    switch (subject) {
      case 'ela':
        return SubjectPaths.elaPath

      case 'math':
        return SubjectPaths.mathPath

      case 'science':
        return SubjectPaths.sciencePath

      case 'social_studies':
        return SubjectPaths.socialStudiesPath

      default:
        return exhaustive(subject, 'SubjectT')
    }
  },
}

export const parseSubject: ParserT<SubjectT> = stringEnum('SubjectT', Subjects.parse)
