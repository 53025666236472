import {ParserT, tag, record} from '@freckle/parser'

import {
  parseArticleContents,
  parseResumableArticleContents,
} from '@freckle/educator-entities/ts/common/models/generic-assignment-session/articles'

import {GenericAssignmentSessionMetadataT} from '@freckle/educator-entities/ts/common/models/generic-assignment-session'

export const parseScienceReadingMetadata: ParserT<GenericAssignmentSessionMetadataT> = record({
  tag: tag('science_articles_reading'),
  contents: parseResumableArticleContents,
})

export const parseScienceWritingMetadata: ParserT<GenericAssignmentSessionMetadataT> = record({
  tag: tag('science_articles_writing'),
  contents: parseArticleContents,
})
