import * as React from 'react'
import map from 'lodash/map'
import range from 'lodash/range'
import flatten from 'lodash/flatten'
import zip from 'lodash/zip'
import {
  addMaybeClassName,
  addMaybeClassNames,
} from '@freckle/educator-entities/ts/common/helpers/classnames'
import {Link} from '@freckle/educator-materials/ts/components/link'

import {breadcrumbs, noWrapEllipses, separator, breadcrumbsLeaf} from './breadcrumbs.module.scss'

/** Plain text to display as a breadcrumb item (for a parent, not the current item) */
type ParentBreadcrumbNameT = string

/** Text to display as the last breadcrumb item (representing the current page) */
type CurrentBreadcrumbNameT = React.ReactNode

export type BreadcrumbItemT = {
  url: string
  name: ParentBreadcrumbNameT
  addClass?: string
}

export type BreadcrumbsProps = {
  path: Array<BreadcrumbItemT>
  currentItemName: CurrentBreadcrumbNameT
  currentItemClass?: string
  enableNoWrapEllipses?: boolean
  breadcrumbsClass?: string
  reactRouter?: boolean
}

export function Breadcrumbs({
  path,
  currentItemName,
  enableNoWrapEllipses = false,
  currentItemClass,
  breadcrumbsClass,
  reactRouter = true,
}: BreadcrumbsProps): React.ReactElement {
  const className = addMaybeClassNames(breadcrumbs, [
    enableNoWrapEllipses ? ` ${noWrapEllipses}` : null,
    breadcrumbsClass,
  ])

  return (
    <div className={className} data-test="breadcrumbs">
      {renderParentList({path, reactRouter})}
      {renderCurrentBreadcrumbItem({currentItemName, currentItemClass})}
    </div>
  )
}

function renderParentList(props: {
  path: Array<BreadcrumbItemT>
  reactRouter: boolean
}): React.ReactNode {
  const {path, reactRouter} = props
  const pathList: Array<React.ReactNode> = map(path, (breadcrumbItem, index: number) =>
    renderParentBreadcrumbItem({...breadcrumbItem, index, reactRouter})
  )
  const separators: Array<React.ReactNode> = map(range(0, pathList.length), i => {
    return (
      <span key={`separator-${i}`} className={separator}>
        &#47;
      </span>
    )
  })

  return flatten(zip(pathList, separators) as Array<Array<React.ReactNode>>)
}

function renderParentBreadcrumbItem(
  props: BreadcrumbItemT & {index: number; reactRouter: boolean}
): React.ReactElement {
  const {url, addClass, name, index, reactRouter} = props
  const linkProps = {
    key: `root-${index}`,
    href: url,
    addClass,
    reactRouter,
    dataTest: `breadcrumb-link-${index}`,
  }
  return <Link {...linkProps}>{name}</Link>
}

function renderCurrentBreadcrumbItem(props: {
  currentItemName: CurrentBreadcrumbNameT
  currentItemClass: string | undefined
}): React.ReactElement {
  const {currentItemName, currentItemClass} = props
  const currentItemClassName = addMaybeClassName(breadcrumbsLeaf, currentItemClass)
  const spanProps = {className: currentItemClassName}
  return <span {...spanProps}>{currentItemName}</span>
}
