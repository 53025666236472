import {ParserT, tag, record} from '@freckle/parser'

import {
  parseArticleContents,
  parseResumableArticleContents,
} from '@freckle/educator-entities/ts/common/models/generic-assignment-session/articles'

import {GenericAssignmentSessionMetadataT} from '@freckle/educator-entities/ts/common/models/generic-assignment-session'

export const parseSocialStudiesReadingMetadata: ParserT<GenericAssignmentSessionMetadataT> = record(
  {
    tag: tag('social_studies_articles_reading'),
    contents: parseResumableArticleContents,
  }
)

export const parseSocialStudiesWritingMetadata: ParserT<GenericAssignmentSessionMetadataT> = record(
  {
    tag: tag('social_studies_articles_writing'),
    contents: parseArticleContents,
  }
)
