import * as React from 'react'
import {Navigate} from 'react-router-dom'

import {LoggedRoute} from './../logged-route'

import {
  ScienceArticlePage,
  ScienceArticleReadingAssignmentSessionReportHandler,
  ScienceAssignArticlePage,
  ScienceAssignmentSessionHistoryHandler,
  ScienceAssignmentsHandler,
  ScienceListingPageContainer,
  ScienceSearchOrListingPage,
  ScienceUnitPageHandler,
  ScienceWritingAssignmentReportHandler,
} from './lazy'

export const routerV2Science = [
  {
    path: '/science-units/:scienceUnitId/articles/:articleUuid',
    element: <LoggedRoute component={ScienceArticlePage} />,
  },
  {
    path: '/science/reports/writing/:assignmentId',
    element: <LoggedRoute component={ScienceWritingAssignmentReportHandler} />,
  },
  {
    path: '/science/reports/reading/:assignmentId/students/:studentId',
    element: <LoggedRoute component={ScienceArticleReadingAssignmentSessionReportHandler} />,
  },
  {
    path: '/science-units/:pathScienceUnitId',
    element: <LoggedRoute component={ScienceUnitPageHandler} />,
  },
  {
    path: '/courses/:courseId/science-units/:pathScienceUnitId/articles/:articleUuid/assign',
    element: <LoggedRoute component={ScienceAssignArticlePage} />,
  },
  {path: '/science-units', element: <LoggedRoute component={ScienceListingPageContainer} />},
  {
    path: '/science',
    element: <Navigate to="/science-units" replace />,
  },
  {
    path: '/science/search',
    element: <LoggedRoute component={ScienceSearchOrListingPage} />,
  },
  {
    path: '/courses/:courseId/science/assignments',
    element: <LoggedRoute component={ScienceAssignmentsHandler} />,
  },
  {
    path: '/courses/:courseId/session-history/science',
    element: <LoggedRoute component={ScienceAssignmentSessionHistoryHandler} />,
  },
]
