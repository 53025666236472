import map from 'lodash/map'
import * as React from 'react'
import {SidebarSection} from '@freckle/classroom/ts/common/components/sidebar/common/section'
import {SidebarIdT} from '@freckle/classroom/ts/common/components/sidebar/common/logic'
import {SidebarLink} from '@freckle/classroom/ts/common/components/sidebar/common/sidebar-link-button/link'
import {Routes} from '@freckle/classroom/ts/common/routers/routes'

export type SectionLinkInfoT = {
  route: string
  tabId: SidebarIdT
}

type Props = {
  courseId: number
}

export function SidebarSectionMain(props: Props): React.ReactElement {
  const {courseId} = props

  const mainSectionLinkInfo: Array<SectionLinkInfoT> = [
    {
      route: Routes.dashboard(),
      tabId: 'dashboard-tab',
    },
    {
      route: Routes.courses.activityFeed(courseId),
      tabId: 'activity-feed-tab',
    },
    {
      route: Routes.assignments(),
      tabId: 'assignments-tab',
    },
    {
      route: Routes.courses.math.reportsOverview(courseId),
      tabId: 'common-reports-tab',
    },
    {
      route: Routes.rostering.rosters(),
      tabId: 'rosters-tab',
    },
  ]

  const links = map(mainSectionLinkInfo, ({tabId, route}) => (
    <SidebarLink key={tabId} tabId={tabId} href={route} />
  ))

  return (
    <SidebarSection>
      <>{links}</>
    </SidebarSection>
  )
}
