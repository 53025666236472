// extracted by mini-css-extract-plugin
export var black = "#000";
export var blueBase = "#146eb3";
export var blueDark1 = "#0d4977";
export var blueDark2 = "#073954";
export var blueLight1 = "#1397e1";
export var blueLight2 = "#bbe7ff";
export var blueLight3 = "#e5f6ff";
export var brownBase = "#a06632";
export var brownDark1 = "#805026";
export var brownDark2 = "#5e2f22";
export var brownLight1 = "#d7ad6b";
export var brownLight2 = "#e7cea6";
export var brownLight3 = "#f9efe1";
export var collapsedDivider = "ts-common-components-navbar-user-options-user-options-module__collapsedDivider___HZbaO";
export var compactPremiumMessagingTitle = "ts-common-components-navbar-user-options-user-options-module__compactPremiumMessagingTitle___rNUVw";
export var grayBase = "#999";
export var grayDark1 = "#686c71";
export var grayDark2 = "#323232";
export var grayLight1 = "#ccc";
export var grayLight2 = "#e9e9e9";
export var grayLight3 = "#f4f4f4";
export var greenBase = "#5acc05";
export var greenDark1 = "#48a800";
export var greenDark2 = "#227622";
export var greenLight1 = "#84dc43";
export var greenLight2 = "#b5f586";
export var greenLight3 = "#d8f8c0";
export var logoutIcon = "ts-common-components-navbar-user-options-user-options-module__logoutIcon___Yb8VL";
export var neutralDark1 = "#404041";
export var orangeBase = "#ffb563";
export var orangeDark1 = "#ec863a";
export var orangeDark2 = "#c55f14";
export var orangeLight1 = "#f9cf86";
export var orangeLight2 = "#fbe7c3";
export var orangeLight3 = "#fff8ed";
export var pinkBase = "#f143aa";
export var pinkDark1 = "#cd1f86";
export var pinkDark2 = "#9e206b";
export var pinkLight1 = "#f8bbdf";
export var pinkLight2 = "#feecf7";
export var pinkLight3 = "#fff1f6";
export var premiumBadgeStyle = "ts-common-components-navbar-user-options-user-options-module__premiumBadgeStyle___DBdkT";
export var premiumMessagingTitle = "ts-common-components-navbar-user-options-user-options-module__premiumMessagingTitle___Mu7z0";
export var premiumMessagingWrapper = "ts-common-components-navbar-user-options-user-options-module__premiumMessagingWrapper___ZESIK";
export var purpleBase = "#a582f0";
export var purpleDark1 = "#7c50b4";
export var purpleDark2 = "#59338a";
export var purpleLight1 = "#cbb0fd";
export var purpleLight2 = "#eadeff";
export var purpleLight3 = "#f3ecff";
export var redBase = "#f55b5b";
export var redDark1 = "#d44a44";
export var redDark2 = "#bc2b23";
export var redLight1 = "#ffa1a1";
export var redLight2 = "#fddfdf";
export var redLight3 = "#fff1f1";
export var renaissanceHomeWrapper = "ts-common-components-navbar-user-options-user-options-module__renaissanceHomeWrapper___wCNsV";
export var teacherNameStyle = "ts-common-components-navbar-user-options-user-options-module__teacherNameStyle___I2PUz";
export var tealBase = "#00ceb5";
export var tealDark1 = "#127b6d";
export var tealDark2 = "#0c5248";
export var tealLight1 = "#8beee0";
export var tealLight2 = "#b4f4eb";
export var tealLight3 = "#ddfaf6";
export var userIcon = "ts-common-components-navbar-user-options-user-options-module__userIcon___ELoph";
export var userOptions = "ts-common-components-navbar-user-options-user-options-module__userOptions___OGs8M";
export var white = "#fff";
export var yellowBase = "#ffd670";
export var yellowDark1 = "#e8b500";
export var yellowDark2 = "#bc9300";
export var yellowLight1 = "#fef188";
export var yellowLight2 = "#fff7b2";
export var yellowLight3 = "#fffbe3";