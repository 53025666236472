import * as React from 'react'
import {addMaybeClassName} from '@freckle/educator-entities/ts/common/helpers/classnames'
import {innerHTMLText} from '@freckle/educator-entities/ts/common/models/html-text'

import {HeadingProps} from '@freckle/educator-materials/ts/components/typography/heading-props'
import {h4} from './typography.module.scss'

type Props = HeadingProps

export function H4(props: Props): React.ReactElement {
  const {children, addClass, dataTest, id} = props
  const className = addMaybeClassName(h4, addClass)
  const dataTestObj = dataTest !== null && dataTest !== undefined ? {'data-test': dataTest} : {}
  const TagToUse = props.as ?? 'h4'
  const node = React.createElement(
    TagToUse,
    {
      className,
      ...dataTestObj,
      id,
      ...(props.innerHTMLText !== undefined ? innerHTMLText(props.innerHTMLText) : {}),
    },
    children
  )
  return node
}
