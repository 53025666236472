import * as React from 'react'
import {useSafeTranslation} from '@freckle/educator-materials/ts/helpers/translate'
import {Button} from '@freckle/educator-materials/ts/components/button'

import {refreshBtn} from './dashboard-update-notification.module.scss'

function handleRefresh() {
  document.location.reload()
}

export function DashboardUpdateNotification(): React.ReactElement {
  const {t} = useSafeTranslation()

  return (
    <div>
      <p>{t('DASHBOARD_UPDATE_NOTIFICATION_ALERT')}</p>

      <Button onClick={handleRefresh} style="primary" addClass={refreshBtn}>
        {t('DASHBOARD_UPDATE_NOTIFICATION_BUTTON_REFRESH')}
      </Button>
    </div>
  )
}
