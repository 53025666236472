import * as React from 'react'
import {EventsKey, trackEvent} from '@freckle/classroom/ts/common/helpers/analytics'
import {PaywallNotificationTypeT} from '@freckle/classroom/ts/common/helpers/paywall-notification'
import {clearNotification} from '@freckle/classroom/ts/common/helpers/paywall-notification'
import Notification from '@freckle/classroom/ts/common/components/notifications-wrapper/paywall-notification/notification'
import {QuoteRequestModal} from '@freckle/classroom/ts/common/components/quote-request-modal'
import {useModal} from '@freckle/classroom/ts/hooks/use-modal'

function quoteRequestCompletedAnalyticsEventKey(
  paywallAnalyticsEventKey: EventsKey | null | undefined
): EventsKey | null | undefined {
  if (paywallAnalyticsEventKey === 'quoteRequestOpenedViaSubjectAndDomainControl') {
    return 'quoteRequestCompletedViaEnableSubjectAndDomainControl'
  }
}

type Props = {
  notification: PaywallNotificationTypeT | null | undefined
  analyticsEventKey: EventsKey | null | undefined
}

export function PaywallNotification(props: Props) {
  const {notification, analyticsEventKey} = props

  const modal = useModal()
  function onCtaClicked() {
    const eventKey = analyticsEventKey
    modal.show()
    if (eventKey !== null && eventKey !== undefined) {
      trackEvent(eventKey)
    }
  }

  const notificationComponent =
    notification !== undefined && notification !== null ? (
      <div className="paywall-notification" key="1">
        <Notification
          notificationType={notification}
          removeNotification={clearNotification}
          onCtaClicked={onCtaClicked}
        />
      </div>
    ) : null

  const mQuoteRequestCompletedAnalyticsEventKey =
    quoteRequestCompletedAnalyticsEventKey(analyticsEventKey)

  const analyticsEventKeyProp =
    mQuoteRequestCompletedAnalyticsEventKey !== null &&
    mQuoteRequestCompletedAnalyticsEventKey !== undefined
      ? {analyticsEventKey: mQuoteRequestCompletedAnalyticsEventKey}
      : {}

  return (
    <>
      {notificationComponent}
      <QuoteRequestModal {...analyticsEventKeyProp} {...modal.props} />
    </>
  )
}
