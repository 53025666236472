import isNil from 'lodash/isNil'
import {patchSubjectProductAssignment} from '@freckle/classroom/ts/api/assignment'
import {TeacherAttrs} from '@freckle/classroom/ts/users/models/teacher'
import {
  ContentArea,
  ContentAreas,
} from '@freckle/educator-entities/ts/curricula-api/generated-client/enums/content-area'
import {
  createAssignmentPromise,
  ElaAssignment,
} from '@freckle/classroom/ts/ela/adaptive-skills/logic/assignment'
import {AssignmentSubmitParamsT} from '@freckle/classroom/ts/ela/adaptive-skills/components/assign-skill-practice/assign-skill-form-container'
import {ElaAssignmentTypes} from '@freckle/educator-entities/ts/ela/assignments/ela-assignment'
import {MathAssignmentTypes} from '@freckle/educator-entities/ts/math/common/models/math-assignment'
import {
  MultiCourseAssignResponse,
  MultiCourseAssignmentPostT,
  getMultiCourseAssignmentPromise,
} from './assignments'
import {SelectedCourseToStudentIdsMapT} from './student-course-picker'

export type AssignmentParams =
  | MathAssignmentParams
  | ElaGrammarOrPassageAssignmentParams
  | ElaWordStudyAssignmentParams

type MathAssignmentParams = {
  tag: 'math'
  assignmentDataMap: MultiCourseAssignmentPostT
}

export type ElaGrammarOrPassageAssignmentParams = {
  tag: 'ela-grammar-or-passage'
  selectedStudents: SelectedCourseToStudentIdsMapT
  assignmentParams: AssignmentSubmitParamsT
}

export type ElaWordStudyAssignmentParams = {
  tag: 'ela-word-study'
  assignmentDataMap: MultiCourseAssignmentPostT
}

export async function tryAsStudent(
  contentArea: ContentArea,
  teacher: TeacherAttrs,
  assignmentParams: AssignmentParams,
  loginUrl: string,
  title?: string
) {
  if (contentArea === ContentAreas.Ela && isNil(teacher.elaStandardSet)) return

  const res =
    assignmentParams.tag === 'math' || assignmentParams.tag === 'ela-word-study'
      ? await getMultiCourseAssignmentPromise(
          contentArea === ContentAreas.Math
            ? MathAssignmentTypes.MathStandard
            : ElaAssignmentTypes.ElaWordStudy,
          {math: teacher.mathStandardSetId, ela: teacher.elaStandardSetId},
          assignmentParams.assignmentDataMap
        )
      : await createAssignmentPromise(
          assignmentParams.selectedStudents,
          assignmentParams.assignmentParams,
          undefined,
          teacher.elaStandardSet!
        )

  const assignmentId =
    assignmentParams.tag === 'math' || assignmentParams.tag === 'ela-word-study'
      ? (res as MultiCourseAssignResponse)[0]!.assignmentId
      : (res as ElaAssignment).id

  if (title !== undefined) {
    await patchSubjectProductAssignment(
      {subjectPath: contentArea === ContentAreas.Math ? 'math' : 'ela', productPath: 'targeted'},
      assignmentId,
      {title}
    )
  }

  const redirect = redirectLink(assignmentParams.tag, loginUrl, assignmentId)
  if (redirect === undefined) return
  window.open(redirect, '_blank', 'noopener')
}

function redirectLink(tag: string, loginUrl: string, assignmentId: number) {
  if (tag === 'math') return `${loginUrl}&redirect=/assignments/targeted/${assignmentId}/session`
  if (tag === 'ela-word-study')
    return `${loginUrl}&redirect=/ela/assignments/word-study/${assignmentId}`
  if (tag === 'ela-grammar-or-passage')
    return `${loginUrl}&redirect=/ela/system/targeted-skills-practice/assignments/${assignmentId}`
  return undefined
}
