import {Moment} from 'moment-timezone'
import sortBy from 'lodash/sortBy'
import {ajaxJsonCall} from '@freckle/ajax'
import {
  ParserT,
  record,
  Parser,
  string,
  number,
  date,
  array,
  boolean,
  nullable,
} from '@freckle/parser'

import CApiHelper from '@freckle/educator-entities/ts/common/helpers/common-api-helper'
import {
  IdentityProviderT,
  parser as identityProviderParser,
} from '@freckle/educator-entities/ts/roster/identity-provider'

export type CourseAttrs = {
  id: number
  name: string
  teacherId: number
  code: string
  createdAt: Moment
  updatedAt: Moment
  archived: boolean
  cleverSectionId: string | undefined | null
  classLinkCourseId: string | undefined | null
  sisId: string | undefined | null
  passwordsEnabled: boolean
  idpSubject: string | undefined | null
  renaissanceRPIdentifier: string | undefined | null
  hidden: boolean
  identityProvider: IdentityProviderT | undefined | null
  idpManaged: boolean
}

const parseAttrs: ParserT<CourseAttrs> = record({
  id: number(),
  name: string(),
  teacherId: number(),
  code: string(),
  createdAt: date(),
  updatedAt: date(),
  archived: boolean(),
  cleverSectionId: nullable(string()),
  classLinkCourseId: nullable(string()),
  sisId: nullable(string()),
  passwordsEnabled: boolean(),
  idpSubject: nullable(string()),
  renaissanceRPIdentifier: nullable(string()),
  hidden: boolean(),
  identityProvider: nullable(identityProviderParser),
  idpManaged: boolean(),
})

export const parseCourse = Parser.mkRun<CourseAttrs>(parseAttrs)

const parseCourses = Parser.mkRun<CourseAttrs[]>(array(parseAttrs))

export function patchHideCourse(courseId: number, hidden: boolean): Promise<void> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.courses._1(courseId),
    method: 'PATCH',
    data: JSON.stringify({hidden}),
  })
}

/**
 * Utilities
 */

export function isCourseActive(course: CourseAttrs): boolean {
  return !isCourseHidden(course) && !isCourseArchived(course)
}

export function isCourseHidden(course: CourseAttrs): boolean {
  return course.hidden
}

export function isCourseArchived(course: CourseAttrs): boolean {
  return course.archived
}

export const doesCourseHaveSisId = function (course: CourseAttrs): boolean {
  return course.sisId !== null
}

export async function fetchCourses(): Promise<Array<CourseAttrs>> {
  const url = CApiHelper.fancyPaths.v2.courses._0()
  const response = await ajaxJsonCall({url, method: 'GET'})
  const courses = parseCourses(response)
  return sortBy(courses, course => course.id)
}

export async function fetchSchoolCourses(schoolId: number): Promise<Array<CourseAttrs>> {
  const url = CApiHelper.fancyPaths.v2.schools.school.courses(schoolId)
  const response = await ajaxJsonCall({url, method: 'GET'})
  const courses = parseCourses(response)
  return sortBy(courses, course => course.id)
}

export type PatchParams = {
  name?: string
  passwordsEnabled?: boolean
  starIntegrationSyncEnabled?: boolean
  hidden?: boolean
}

export async function patchCourse(courseId: number, params: PatchParams): Promise<CourseAttrs> {
  const course = await ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.courses._1(courseId),
    method: 'PATCH',
    data: JSON.stringify(params),
  })
  return parseCourse(course)
}

export async function deleteCourse(courseId: number) {
  await ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.courses._1(courseId),
    method: 'DELETE',
  })
}
