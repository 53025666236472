import * as React from 'react'
import {LangT, Languages} from '@freckle/educator-entities/ts/common/helpers/languages'
import {maybe} from '@freckle/maybe'

type Props = {
  // The language of the user
  userLanguage: LangT
  // A thunk for a component to render for a non-American English user
  otherwiseRender?: () => JSX.Element
  // The component to render for an American English user
  children: JSX.Element
}

// Conditionally render a feture that we consider to be an "add on" which is
// only available for American English users.
export const AmericanEnglishExclusiveAddOn = ({
  userLanguage,
  children,
  otherwiseRender,
}: Props): JSX.Element =>
  userLanguage === Languages.En
    ? children
    : maybe(
        () => <></>,
        render => render(),
        otherwiseRender
      )
