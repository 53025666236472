import {
  ParserT,
  field,
  record,
  firstOf,
  literal,
  onSelf,
  merge,
  obfuscated,
  number,
} from '@freckle/parser'
import {
  validateVertexAndPointForQuadraticEquation,
  QuadraticEquationCoefficientsT,
} from '@freckle/educator-entities/ts/math/common/logic/equation'
import {QuadraticGraphAnswerT} from '@freckle/educator-entities/ts/math/common/logic/answer-types'
import {
  parseCommonMathGraphQuestionAttrs,
  CommonMathGraphQuestionAttrs,
} from '@freckle/educator-entities/ts/math/common/models/questions/common-parser'
import {MathQuestionMetadataAttrs} from '@freckle/educator-entities/ts/math/common/models/questions'

type MathQuestionGraphQuadraticExtraAttrs = {
  graphQuadraticEquationCoefficients: QuadraticEquationCoefficientsT
}

const rawGraphQuadraticEquationCoefficientsAttrs: ParserT<QuadraticEquationCoefficientsT> = record({
  coefficientX2: field(number(), 'coefficient-x-2'),
  coefficientX1: field(number(), 'coefficient-x-1'),
  coefficientX0: field(number(), 'coefficient-x-0'),
})

const parseGraphQuadraticEquationCoefficients: ParserT<MathQuestionGraphQuadraticExtraAttrs> =
  firstOf(
    record({
      graphQuadraticEquationCoefficients: field(
        rawGraphQuadraticEquationCoefficientsAttrs,
        'graph-quadratic-equation-coefficients'
      ),
    }),
    record({
      graphQuadraticEquationCoefficients: field(
        obfuscated(rawGraphQuadraticEquationCoefficientsAttrs),
        'obfuscated-graph-quadratic-equation-coefficients'
      ),
    })
  )

export type MathQuestionGraphQuadraticMetadataAttrs = CommonMathGraphQuestionAttrs &
  MathQuestionGraphQuadraticExtraAttrs

const parseMathQuestionGraphQuadraticAttrs: ParserT<MathQuestionGraphQuadraticMetadataAttrs> =
  merge(parseCommonMathGraphQuestionAttrs, parseGraphQuadraticEquationCoefficients)

export const parseMathQuestionGraphQuadraticMetadata: ParserT<MathQuestionMetadataAttrs> = record({
  answerType: field(literal('graph_quadratic'), 'answer-type'),
  content: onSelf(parseMathQuestionGraphQuadraticAttrs),
})

// Return :: Double (either 0 or 100)
export function getAnswerCorrectness(
  metadata: MathQuestionGraphQuadraticMetadataAttrs,
  answer: QuadraticGraphAnswerT
): number {
  const {vertex, point} = answer
  const {graphQuadraticEquationCoefficients} = metadata
  const isCorrect = validateVertexAndPointForQuadraticEquation(
    vertex,
    point,
    graphQuadraticEquationCoefficients
  )
  return isCorrect ? 100 : 0
}
