import * as React from 'react'
import {UseQueryResult} from '@tanstack/react-query'
import {DelayedSpinner} from '@freckle/educator-entities/ts/common/components/spinner-wrapper/delayed-spinner'
import {WrappedState} from '@freckle/educator-entities/ts/common/components/with-resources/with-resources-helpers'

export type Resource = {
  [name: string]: unknown
}

type WithResourcesPropsWithoutError<T extends Resource> = {
  resources: {[R in keyof T]: UseQueryResult<T[R]> | WrappedState<T[R]>}
  render: (props: T) => React.ReactElement
  noSpinner?: boolean
}

type WithResourcesPropsWithError<T extends Resource> = WithResourcesPropsWithoutError<T> & {
  error: (error: Error) => React.ReactElement | void
}

export type WithResourcesPropsWithOptionalErrorT<T extends Resource> =
  WithResourcesPropsWithoutError<T> & {
    error?: (error: Error) => React.ReactElement | void
  }

export function WithResourcesInternal<T extends Resource>({
  resources,
  render,
  error,
  noSpinner = false,
}: WithResourcesPropsWithError<T>): React.ReactElement {
  const errors = Object.values(resources)
    .map((r: UseQueryResult<T>) => r.error)
    .filter(r => r !== null) as Error[]

  const isAllLoaded = Object.values(resources).every(r => r.status === 'success')

  const isReloading = Object.values(resources).some(r => r.isFetching)

  const data = Object.keys(resources).reduce((acc: Record<string, unknown>, r) => {
    acc[r] = resources[r]!.data!
    return acc
  }, {}) as T

  if (errors[0]) return error(errors[0]) as React.ReactElement
  if (!isAllLoaded) return noSpinner ? <></> : <DelayedSpinner inline />
  return (
    <>
      {isReloading && !noSpinner && <DelayedSpinner />}
      {render(data)}
    </>
  )
}

type WithResourcesArrayPropsWithoutError<T extends Resource> = {
  resources: {[R in keyof T]: UseQueryResult<T[R]>[]}
  render: (props: {[R in keyof T]: T[R][]}) => React.ReactElement
}

type WithResourcesArrayPropsWithErrorT<T extends Resource> =
  WithResourcesArrayPropsWithoutError<T> & {
    error: (error: Error) => React.ReactElement | void
  }

export type WithResourcesArrayPropsWithOptionalErrorT<T extends Resource> =
  WithResourcesArrayPropsWithoutError<T> & {
    error?: (error: Error) => React.ReactElement | void
  }

export function WithResourcesArrayInternal<T extends Resource>({
  resources,
  render,
  error,
}: WithResourcesArrayPropsWithErrorT<T>): React.ReactElement {
  const errors = Object.values(resources)
    .flat()
    .map((r: UseQueryResult<T>) => r.error)
    .filter(r => r !== null) as Error[]

  const isAllLoaded = Object.values(resources).every((r: UseQueryResult<T>[]) =>
    r.every(r => r.status === 'success')
  )

  const isReloading = Object.values(resources).some(r => r.isFetching)

  const data = Object.keys(resources).reduce((acc: {[K in keyof T]: T[K][]}, r) => {
    acc[r as keyof T] = resources[r]!.map(r => r.data!)
    return acc
  }, {} as {[K in keyof T]: T[K][]})

  if (errors[0]) return error(errors[0]) as React.ReactElement
  if (!isAllLoaded) return <DelayedSpinner inline />
  return (
    <>
      {isReloading && <DelayedSpinner />}
      {render(data)}
    </>
  )
}
