import * as React from 'react'
import difference from 'lodash/difference'
import filter from 'lodash/filter'
import map from 'lodash/map'
import {useSafeEffectExtraDeps} from '@freckle/react-hooks'
import {CallbackFn} from '@freckle/react-hooks'
import {MaybeCleanUpFn} from '@freckle/educator-entities/ts/common/hooks/types'
import {CourseMembershipAttrs} from '@freckle/educator-entities/ts/roster/models/course-membership'
import {StudentAttrs} from '@freckle/educator-entities/ts/users/models/student'

// Get all the students for the given courseId
export function getStudentIdsforCourse(
  courseId: number,
  courseMemberships: Array<CourseMembershipAttrs>
): Array<number> {
  return map(
    filter(courseMemberships, cm => cm.courseId === courseId),
    cm => cm.studentId
  )
}

export function getStudentsforCourse(
  students: Array<StudentAttrs>,
  courseId: number,
  courseMemberships: Array<CourseMembershipAttrs>
): Array<StudentAttrs> {
  const studIdsInCourse = new Set(getStudentIdsforCourse(courseId, courseMemberships))
  return filter(students, ({id}) => studIdsInCourse.has(id))
}

type StudentsForCourseDeps = [Array<StudentAttrs>, number, Array<CourseMembershipAttrs>]

export function useStudentsForCourse(
  cb: CallbackFn<(students: Array<StudentAttrs>) => MaybeCleanUpFn>,
  [students, courseId, courseMemberships]: StudentsForCourseDeps
) {
  const studentIds = (students: Array<StudentAttrs>) => map(students, ({id}) => id)
  const courseMembershipIds = (cms: Array<CourseMembershipAttrs>) => map(cms, ({id}) => id)

  const studentsRef = React.useRef<Array<StudentAttrs>>(students)
  const courseMembershipsRef = React.useRef<Array<CourseMembershipAttrs>>(courseMemberships)

  if (difference(studentIds(students), studentIds(studentsRef.current)).length > 0) {
    studentsRef.current = students
  }

  if (
    difference(
      courseMembershipIds(courseMemberships),
      courseMembershipIds(courseMembershipsRef.current)
    ).length > 0
  ) {
    courseMembershipsRef.current = courseMemberships
  }

  useSafeEffectExtraDeps<{
    cb: (studentsForCourse: Array<StudentAttrs>) => void
  }>(
    ({cb}) => {
      const studentsForCourse = getStudentsforCourse(
        studentsRef.current,
        courseId,
        courseMembershipsRef.current
      )
      return cb(studentsForCourse)
    },
    [courseId],
    {cb}
  )
}
