import find from 'lodash/find'

export type Environment = 'us_in_school' | 'us_no_school' | 'outside_us'

type EnvironmentsT = {
  UsInSchool: Environment
  UsNoSchool: Environment
  OutsideUs: Environment
  all(): Array<Environment>
  forDisplay(environment: Environment): string
  parse(s: string): Environment | undefined | null
}

export const Environments: EnvironmentsT = {
  UsInSchool: 'us_in_school',
  UsNoSchool: 'us_no_school',
  OutsideUs: 'outside_us',
  all: function (): Array<Environment> {
    return [Environments.UsInSchool, Environments.UsNoSchool, Environments.OutsideUs]
  },
  forDisplay: function (environment: Environment): string {
    switch (environment) {
      case Environments.UsInSchool:
        return 'US, in school'
      case Environments.UsNoSchool:
        return 'US, no school'
      case Environments.OutsideUs:
        return 'Outside US'
      default:
        console.error(`Unrecognized Environment: ${environment}`)
        return 'Unknown'
    }
  },
  parse: function (s: string): Environment | null {
    return find(Environments.all(), environment => s === environment) ?? null
  },
}
