import {create} from 'zustand'
import {Languages, LangT} from '@freckle/educator-entities/ts/common/helpers/languages'

export type LanguageSetting = {
  code: LangT
  fromUrl: boolean
}

export type SettingsState = {
  langSetting: LanguageSetting
}

const defaultSettingsState: SettingsState = {
  langSetting: {
    code: Languages.En,
    fromUrl: false,
  },
}

type Actions = {
  setLanguage: (languageSetting: LanguageSetting) => void
}

export const useSettings = create<SettingsState & Actions>()(set => ({
  ...defaultSettingsState,
  setLanguage: (langSetting: LanguageSetting) => set(_ => ({langSetting})),
}))
