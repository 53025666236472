import filter from 'lodash/filter'
import {
  ParserT,
  field,
  record,
  firstOf,
  merge,
  string,
  literal,
  onSelf,
  obfuscated,
  boolean,
  tag,
  nullable,
  nonEmptyArray,
} from '@freckle/parser'
import {NonEmptyArray} from '@freckle/non-empty'

import {MathQuestionMetadataAttrs} from '@freckle/educator-entities/ts/math/common/models/questions'

export type Answer = {
  tag: 'answers'
  options: NonEmptyArray<string>
  rightText: string | undefined | null
  leftText: string | undefined | null
}
export type Text = {
  tag: 'text-only'
  text: string
}

export type Rows = NonEmptyArray<NonEmptyArray<Answer | Text>>
export type CorrectAnswers = NonEmptyArray<string>

type MathQuestionFillInTheBlanksMetadataSharedAttrs = {
  tableNumberingEnabled: boolean
  rows: Rows
  header: NonEmptyArray<string> | undefined | null
}

export type MathQuestionFillInTheBlanksMetadataAttrs = {
  tableNumberingEnabled: boolean
  correctAnswers: CorrectAnswers
  rows: Rows
  header: NonEmptyArray<string> | undefined | null
}

const parseMathQuestionFillInTheBlanksCorrectAnswers: ParserT<{
  correctAnswers: CorrectAnswers
}> = firstOf(
  record({correctAnswers: field(nonEmptyArray(string()), 'fill-in-the-blanks-correct-answers')}),
  record({
    correctAnswers: field(
      obfuscated(nonEmptyArray(string())),
      'obfuscated-fill-in-the-blanks-correct-answers'
    ),
  })
)

const parseMathQuestionFillInTheBlanksRows: ParserT<Rows> = nonEmptyArray(
  nonEmptyArray(
    firstOf(
      record({
        tag: tag('answers'),
        options: nonEmptyArray(string()),
        rightText: field(nullable(string()), 'right-text'),
        leftText: field(nullable(string()), 'left-text'),
      }),
      record({tag: tag('text-only'), text: string()})
    )
  )
)

const parseMathQuestionFillInTheBlanksMetadataAttrs: ParserT<MathQuestionFillInTheBlanksMetadataAttrs> =
  merge<
    MathQuestionFillInTheBlanksMetadataSharedAttrs,
    {
      correctAnswers: CorrectAnswers
    }
  >(
    record({
      tableNumberingEnabled: field(boolean(), 'table-numbering-enabled'),
      rows: parseMathQuestionFillInTheBlanksRows,
      header: nullable(nonEmptyArray(string())),
    }),
    parseMathQuestionFillInTheBlanksCorrectAnswers
  )

export const parseMathQuestionFillInTheBlanksMetadata: ParserT<MathQuestionMetadataAttrs> = record({
  answerType: field(literal('fill_in_the_blanks'), 'answer-type'),
  content: onSelf(parseMathQuestionFillInTheBlanksMetadataAttrs),
})

export function fillInTheBlanksAnswerCorrectness(
  correctAnswers: CorrectAnswers,
  studentAnswers: Array<{
    answer: string
    column: number
    row: number
  }>
): number {
  const numStudentCorrectAnswers = filter(
    studentAnswers,
    (answerObj, i: number) => correctAnswers[i] === answerObj.answer
  ).length

  return (numStudentCorrectAnswers / correctAnswers.length) * 100
}
