/**
 * !! NOTE !!
 * This component should be kept in the same app where it is being used.
 * Hint: Don't move it to educator-materials.
 * This is required because otherwise the ModalManager that comes along
 * with the react-bootstrap library is unable to manage multiple modals.
 * This probably happens because each app gets a different instance of
 * the library.
 */

import * as React from 'react'
import {useSafeTranslation} from '@freckle/educator-materials/ts/helpers/translate'
import Modal from 'react-bootstrap/Modal'
import ModalBody from 'react-bootstrap/ModalBody'
import {H3} from '@freckle/educator-materials/ts/components/typography'
import {Button} from '@freckle/educator-materials/ts/components/button'
import {ButtonStyleT} from '@freckle/educator-materials/ts/components/button'
import {Link} from '@freckle/educator-materials/ts/components/link'
import {SizeT} from '@freckle/educator-materials/ts/helpers/size'
import {addMaybeClassName} from '@freckle/educator-entities/ts/common/helpers/classnames'
import {useBoolean} from '@freckle/classroom/ts/hooks/use-boolean'

import {footer, modal, backdrop, linkButton} from './external-link-with-confirmation.module.scss'

type Props = {
  href: string
  show?: boolean
  target?: string
  children?: React.ReactElement
  onHide?: () => void
  // Button props
  onClick?: () => void
  size?: SizeT
  addClass?: string
  style?: ButtonStyleT
  dataTest?: string
}

export const ExternalLinkWithConfirmation = (props: Props): JSX.Element => {
  const {t} = useSafeTranslation()
  const {
    value: show,
    setTrue: setShowToTrue,
    setFalse: setShowToFalse,
  } = useBoolean(props.show ?? false)

  const {href, children, target, onHide, show: _show, onClick: _onClick, ...otherProps} = props
  const buttonProps = {...otherProps, style: otherProps.style ?? 'link'}

  const buttonPropsWithClass =
    buttonProps.style === 'link'
      ? {...buttonProps, addClass: addMaybeClassName(linkButton, buttonProps.addClass)}
      : buttonProps

  const ctaButton =
    children !== undefined ? (
      <Button onClick={setShowToTrue} {...buttonPropsWithClass}>
        {children}
      </Button>
    ) : null

  // allows the parent component to take control
  const onHideHandler = onHide ?? setShowToFalse

  const modalNode = show ? (
    <Modal
      show={show}
      animation={false}
      bsSize="sm"
      onHide={onHideHandler}
      className={modal}
      backdropClassName={backdrop}
    >
      <ModalBody>
        <H3>{t('EXTERNAL_LINK_WITH_CONFIRMATION_HEADER')}</H3>
        {t('EXTERNAL_LINK_WITH_CONFIRMATION_ARE_YOU_SURE_MESSAGE')}
        <div className={footer}>
          <Button style="tertiary" onClick={onHideHandler}>
            {t('EXTERNAL_LINK_WITH_CONFIRMATION_CANCEL_BUTTON')}
          </Button>
          <Link
            onClick={onHideHandler}
            style="btn-primary"
            href={href}
            dataTest="external-link-confirm-modal-ok"
            target={target}
            rel="noopener"
            reactRouter={false}
          >
            {t('EXTERNAL_LINK_WITH_CONFIRMATION_OK_BUTTON')}
          </Link>
        </div>
      </ModalBody>
    </Modal>
  ) : null

  return (
    <>
      {ctaButton}
      {modalNode}
    </>
  )
}
