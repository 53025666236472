import reduce from 'lodash/reduce'
import drop from 'lodash/drop'
import take from 'lodash/take'
import filter from 'lodash/filter'
import range from 'lodash/range'
import minBy from 'lodash/minBy'
import some from 'lodash/some'
import {maybe} from '@freckle/maybe'
import {toOrdinal} from '@freckle/educator-entities/ts/common/helpers/string-helper'

export function round(number: number, digits: number): number {
  return parseFloat(number.toFixed(digits))
}

export const fillArray = <A>(n: number, value: A): Array<A> => range(n).map(() => value)

export function findClosest(haystack: Array<number>, needle: number): number {
  const v = minBy(haystack, hay => Math.abs(hay - needle))

  // Returning `Infinity` may be unnecessary. It was introduced while removing
  // underscore's `_.min` which returns `Infinity` when given an empty list
  return v ?? Infinity
}

export function gradeStringToInt(grade: string): number {
  if (grade === 'K') {
    return 0
  } else {
    return parseInt(grade, 10)
  }
}

// Deprecated: use DISPLAY_GRADE with {grade} param in Locize
export function gradeIntAsString(grade: number): string {
  return grade === 0 ? 'K' : grade.toString()
}

// Deprecated: this is not i18n-friendly. Use DISPLAY_GRADE with {grade}, or DISPLAY_GRADE_ORDINAL param in Locize
export function gradeIntAsWords(grade: number, titleCase: boolean = false): string {
  if (grade === 0) {
    return 'Kindergarten'
  } else {
    return `${toOrdinal(grade)} ${titleCase ? 'Grade' : 'grade'}`
  }
}

export function validateGrade(grade: string): boolean {
  try {
    const gradeNum = gradeStringToInt(grade)
    return gradeNum >= 0 && gradeNum <= 12
  } catch (e) {
    return false
  }
}

export function mapFromArray<K, V>(array: Array<[K, V]>, pick?: (lhs: V, rhs: V) => V): Map<K, V> {
  const realPick = pick ?? ((_lhs, rhs) => rhs)
  return reduce(
    array,
    (map, [k, v2]) => {
      const v1 = map.get(k)
      return map.set(k, v1 !== undefined ? realPick(v1, v2) : v2)
    },
    new Map()
  )
}

export function setIntersection<K>(lhs: Set<K>, rhs: Set<K>): Set<K> {
  const result: Set<K> = new Set()
  lhs.forEach(x => {
    if (rhs.has(x)) {
      result.add(x)
    }
  })
  return result
}

export function toggleMembership<A>(
  value: A,
  selections: Array<A>,
  mCompareFn?: (a: A, b: A) => boolean
): Array<A> {
  const predicate = maybe(
    () => (v: A) => value === v,
    compareFn => (v: A) => compareFn(v, value),
    mCompareFn
  )
  return some(selections, predicate)
    ? filter(selections, selection => !predicate(selection))
    : selections.concat([value])
}

export function mutateAtIndex<A>(
  array: Array<A>,
  index: number,
  f: (element?: A | null) => A | undefined | null
): Array<A> {
  const newVal = f(array[index])
  if (newVal !== undefined && newVal !== null) {
    const newArray = array.slice()
    newArray.splice(index, 1, newVal)
    return newArray
  } else {
    return array
  }
}

export function calcPercentage(num: number, total: number): number {
  if (total === 0) {
    throw new Error('Cannot divided a number by 0')
  }
  return Math.floor((num * 100) / total)
}

// If `map` has a value for `key`, return it. Otherwise, associate
// `key` to the value `defaultValue` in `map` and return it.
//
// Example:
//   const wordCount = new Map()
//   forEach(words, word => {
//     wordCount.set(word, setDefault(wordCount, word, 0) + 1)
//   })
export function setDefault<K, V>(map: Map<K, V>, key: K, defaultValue: V): V {
  const value = map.get(key)
  if (value === undefined) {
    map.set(key, defaultValue)
    return defaultValue
  } else {
    return value
  }
}

// Split array into two arrays, where the first has no more than n elements

export function split<A>(
  n: number,
  items: Array<A>
): {
  prefix: Array<A>
  suffix: Array<A>
} {
  const prefix = take(items, n)
  const suffix = drop(items, n)
  return {prefix, suffix}
}
