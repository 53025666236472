import * as React from 'react'
import {useSafeTranslation} from '@freckle/educator-materials/ts/helpers/translate'
import {CourseMembershipAttrs} from '@freckle/educator-entities/ts/roster/models/course-membership'
import {Button} from '@freckle/educator-materials/ts/components/button'
import {CourseAttrs} from '@freckle/educator-entities/ts/roster/models/course'
import {StudentAttrs} from '@freckle/educator-entities/ts/users/models/student'
import {Text} from '@freckle/educator-materials/ts/components/typography'
import {faArrowUpRightFromSquare} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {ViewStudentAppButton} from '@freckle/classroom/ts/common/components/view-student-app-button'
import {linkWrapperStyle, activeStyle, primaryLinkStyle} from './style.module.scss'

type Props = {
  courses: Array<CourseAttrs>
  students: Array<StudentAttrs>
  courseMemberships: Array<CourseMembershipAttrs>
  isActive: boolean
}

export function ViewStudentApp(props: Props): React.ReactElement {
  const {t} = useSafeTranslation()
  const {courses, students, courseMemberships, isActive} = props

  const buttonText = t('SIDEBAR_VIEW_STUDENT_APP')

  return (
    <div className={`${linkWrapperStyle} ${isActive ? activeStyle : ''}`}>
      <ViewStudentAppButton
        courses={courses}
        students={students}
        courseMemberships={courseMemberships}
        render={onClickViewStudent => (
          <Button
            style="link"
            addClass={primaryLinkStyle}
            dataTest="view-student-app"
            onClick={onClickViewStudent}
          >
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />{' '}
            <Text style="subtitle-2" htmlElement="span">
              {buttonText}
            </Text>
          </Button>
        )}
      />
    </div>
  )
}
