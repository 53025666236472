import {ParserT, Parser, string} from '@freckle/parser'

export type RlSubSkillUspIdT = string

export const parse: ParserT<RlSubSkillUspIdT> = Parser.map(
  string(),
  'RlSubSkillUspIdT.fromString',
  fromString
)

export function fromString(rlSubSkillUspId: string): RlSubSkillUspIdT {
  return rlSubSkillUspId
}

export function toString(rlSubSkillUspId: RlSubSkillUspIdT): string {
  return rlSubSkillUspId
}
