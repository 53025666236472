import {lazy} from 'react'

// Async Handler Components

export const ElaAssessmentReportHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "ElaAssessmentReportHandler" */ '../../../../../ela/assessment/components/assessment-report'
    )
)

export const ElaAssessmentSessionReportHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "ElaAssessmentSessionReportHandler" */ '../../../../../ela/assessment/components/assessment-session-report'
    )
)

export const ElaAssignmentSessionHistoryReportHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "ElaAssignmentSessionHistoryReportHandler" */ '../../../../../ela/common/components/assignment-session-history-report'
    )
)

export const ElaGroupsReportHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "ElaGroupsReportHandler" */ '../../../../../ela/common/components/groups-report'
    )
)

export const ElaReportCardViewHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "ElaReportCardViewHandler" */ '../../../../../ela/common/components/report-cards/ela-report-card-view-handler'
    )
)

export const ElaReportCardsHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "ElaReportCardsHandler" */ '../../../../../ela/common/components/report-cards'
    )
)

export const PerformanceByStandardReportHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "PerformanceByStandardReportHandler" */ '../../../../../ela/common/components/performance-by-standard-report'
    )
)

export const PrintElaReportCardsHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "PrintElaReportCardsHandler" */ '../../../../../ela/common/components/report-cards/print-ela-report-cards-handler'
    )
)

export const ElaStandardStudentReportHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "ElaStandardStudentReportHandler" */ '../../../../../ela/common/components/standard-student-report'
    )
)

export const ElaLevelsReportHandler = lazy(
  () => import(/* webpackChunkName: "ElaLevelsReportHandler" */ '../../../../../ela/levels-report')
)

export const ElaReportsOverviewHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "ElaReportsOverviewHandler" */ '../../../../../ela/pages/ela-reports-overview'
    )
)

export const ElaAssessmentAssignmentsReportHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "ElaAssessmentAssignmentsReportHandler" */ '../../../../../ela/assessment/components/assessment-assignments-report'
    )
)

export const ElaWordStudyMatrixReportHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "ElaWordStudyMatrixReportHandler" */ '../../../../../ela/word-study/components/word-study-matrix-report'
    )
)

export const ElaSkillsProgressReportHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "ElaWordStudyMatrixReportHandler" */ '../../../../../ela/skills-progress'
    )
)

export const ElaAdaptiveSkillsReportHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "ElaAdaptiveSkillsReportHandler" */ '../../../../../ela/adaptive-skills/components/adaptive-skills-report'
    )
)

export const ElaTargetedSkillsReportHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "ElaTargetedSkillsReportHandler" */ '../../../../../ela/targeted-skills/components/targeted-skills-report'
    )
)

export const ElaFocusSkillsReportHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "ElaFocusSkillsReportHandler" */ '../../../../../ela/focus-skills/components/focus-skills-report'
    )
)

export const WordStudySessionReportHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "WordStudySessionReportHandler" */ '../../../../../ela/word-study/components/word-study-session-report'
    )
)

export const ElaArticleReadingAssignmentSessionReportHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "ElaArticleReadingAssignmentSessionReportHandler" */ '../../../../../ela/articles/components/reading-assignment-report'
    )
)

export const ElaWritingAssignmentReportHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "ElaWritingAssignmentReportHandler" */ '../../../../../ela/articles/components/writing-assignment-session-report'
    )
)

export const ElaArticleClassActivityHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "ElaArticleClassActivityHandler" */ '../../../../../ela/articles/components/article-class-activity'
    )
)

export const ElaCorrelationPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ElaCorrelationPage" */ '../../../../../ela/text-levels/components/ela-correlation'
    )
)
