export type Leaderboard = 'team' | 'individual' | 'no_leaderboard'

type LeaderboardsT = {
  Team: Leaderboard
  Individual: Leaderboard
  NoLeaderboard: Leaderboard
  all(): Array<Leaderboard>
  parse(s: string): Leaderboard | undefined | null
  parseOrError(s: string): Leaderboard
}

export const Leaderboards: LeaderboardsT = {
  Team: 'team',
  Individual: 'individual',
  NoLeaderboard: 'no_leaderboard',
  all: function (): Array<Leaderboard> {
    return [Leaderboards.Individual, Leaderboards.Team, Leaderboards.NoLeaderboard]
  },
  parse: function (s: string): Leaderboard | undefined {
    return Leaderboards.all().find(leaderboard => s === leaderboard)
  },
  parseOrError: function (s: string): Leaderboard {
    const parsedLeaderboard = Leaderboards.parse(s)
    if (parsedLeaderboard === null || parsedLeaderboard === undefined)
      throw new Error('couldnt parse leaderboard')
    return parsedLeaderboard
  },
}
