import * as React from 'react'
import {LoggedRoute} from './../../logged-route'
import {
  ElaAdaptiveSkillsPracticePreviewPrintAssignmentHandler,
  ElaArticlePreviewPrintAssignmentHandler,
  ElaTargetedSkillsPracticePreviewPrintAssignmentHandler,
} from './lazy'

export const routerV2ElaPrint = [
  {
    path: '/courses/:courseId/ela/print/adaptive-skills/:assignmentId',
    element: (
      <LoggedRoute component={ElaAdaptiveSkillsPracticePreviewPrintAssignmentHandler} fullScreen />
    ),
  },
  {
    path: '/courses/:courseId/ela/print/targeted-skills/:assignmentSessionId',
    element: (
      <LoggedRoute component={ElaTargetedSkillsPracticePreviewPrintAssignmentHandler} fullScreen />
    ),
  },
  {
    path: '/courses/:courseId/ela/print/articles/:assignmentSessionId',
    element: <LoggedRoute component={ElaArticlePreviewPrintAssignmentHandler} fullScreen />,
  },
  {
    path: '/courses/:courseId/ela/print/article/:articleUuid/level/:articleLevel',
    element: <LoggedRoute component={ElaArticlePreviewPrintAssignmentHandler} fullScreen />,
  },
]
