import * as React from 'react'
import {ResponsiveBreakpointKey} from '@freckle/educator-materials/ts/helpers/responsive-helper'
import {useResponsiveInfo} from '@freckle/educator-materials/ts/hooks/use-responsive-info'

type Props = {
  children: React.ReactNode
}

/**
 * The DEFAULT_BREAKPOINT is only used when a component does not have a matching
 * Provider above it in the tree. This can be helpful for testing components in isolation
 * without wrapping them.
 */

const DEFAULT_BREAKPOINT: ResponsiveBreakpointKey = 'desktop'

const BreakpointContext = React.createContext<ResponsiveBreakpointKey>(DEFAULT_BREAKPOINT)
BreakpointContext.displayName = 'BreakpointContext'

function BreakpointProvider(props: Props): React.ReactElement {
  const breakpoint = useResponsiveInfo()

  return (
    <BreakpointContext.Provider value={breakpoint}>{props.children}</BreakpointContext.Provider>
  )
}

export {BreakpointContext, BreakpointProvider}
