import filter from 'lodash/filter'
import includes from 'lodash/includes'
import map from 'lodash/map'
import some from 'lodash/some'
import every from 'lodash/some'
import sortBy from 'lodash/sortBy'
import sumBy from 'lodash/sumBy'
import find from 'lodash/find'
import isNil from 'lodash/isNil'
import levenshtein from 'fast-levenshtein'
import {SchoolAttrs} from '@freckle/educator-entities/ts/roster/models/school'
import {StudentAttrs} from '@freckle/educator-entities/ts/users/models/student'
import {CourseAttrs} from '@freckle/educator-entities/ts/roster/models/course'
import {CourseMembershipAttrs} from '@freckle/educator-entities/ts/roster/models/course-membership'
import {getStudentsforCourse} from '@freckle/educator-entities/ts/users/logic/students'
import {wasUploaded} from '@freckle/educator-entities/ts/users/models/student'
import StorageHelper from '@freckle/educator-entities/ts/common/helpers/storage-helper'
import {ActiveVisibleCoursesT} from '@freckle/educator-entities/ts/roster/helpers/tagged-courses'
import {nameForSorting} from '@freckle/educator-entities/ts/users/models/student'
import {
  isIdpManaged,
  isManagedByClever,
  isManagedByClassLink,
  isManagedByRenaissance,
  isManagedByCSV,
} from '@freckle/educator-entities/ts/roster/identity-provider'

type ShouldIdpMergeT = 'no-idp-merge' | 'idp-merge'
const localStorageCoursesMergedKey = 'courses-merged'

export function shouldRedirectToIdpMergeRosterPage(
  schools: Array<SchoolAttrs>,
  activeAndVisibleCourses: ActiveVisibleCoursesT<CourseAttrs>,
  students: Array<StudentAttrs>,
  courseMemberships: Array<CourseMembershipAttrs>
): ShouldIdpMergeT {
  // Teacher is not affiliated with a School
  const mSchool = schools[0]
  if (!mSchool) {
    return 'no-idp-merge'
  }

  // Check LocalStorage if teacher has already done the merging
  const hasDoneIdpMerging = StorageHelper.getLocalStoragePropertyDefault('hasDoneIdpMerging', false)
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (hasDoneIdpMerging) {
    return 'no-idp-merge'
  }

  const teacherHasMixedManagedRoster = doesTeacherHaveMixedManagedRoster(
    activeAndVisibleCourses,
    students,
    courseMemberships
  )
  const hasManagedStudents = some(
    students,
    student => isIdpManaged(student) || wasUploaded(student)
  )

  const hasManuallyAddedStudents = some(
    students,
    student => !isIdpManaged(student) && !wasUploaded(student) && !student.isTeacher
  )

  const teacherHasMixedManagedStudents = hasManagedStudents && hasManuallyAddedStudents

  return teacherHasMixedManagedRoster && teacherHasMixedManagedStudents
    ? 'idp-merge'
    : 'no-idp-merge'
}

function doesTeacherHaveMixedManagedRoster(
  activeAndVisibleCourses: ActiveVisibleCoursesT<CourseAttrs>,
  students: Array<StudentAttrs>,
  courseMemberships: Array<CourseMembershipAttrs>
): boolean {
  const cleverCourses = filter(activeAndVisibleCourses.courses, course => isManagedByClever(course))
  const classLinkCourses = filter(activeAndVisibleCourses.courses, course =>
    isManagedByClassLink(course)
  )
  const renaissanceCourses = filter(activeAndVisibleCourses.courses, course =>
    isManagedByRenaissance(course)
  )
  const nonIdPCourses = filter(
    activeAndVisibleCourses.courses,
    course => !includes([...cleverCourses, ...classLinkCourses, ...renaissanceCourses], course)
  )

  const rosterHasIdpManagedCourses =
    !!cleverCourses.length || !!classLinkCourses.length || !!renaissanceCourses.length
  const hasMixedIdPRoster = rosterHasIdpManagedCourses && nonIdPCourses.length > 0

  const hasMixedDistrictManagedCourses = doesRosterHaveMixedDistrictManagedCourses(
    nonIdPCourses,
    students,
    courseMemberships
  )

  return hasMixedIdPRoster || hasMixedDistrictManagedCourses
}

function doesRosterHaveMixedDistrictManagedCourses(
  nonIdPCourses: Array<CourseAttrs>,
  students: Array<StudentAttrs>,
  courseMemberships: Array<CourseMembershipAttrs>
): boolean {
  // non idp courses with csv uploaded students
  const csvUploadCourses = filter(nonIdPCourses, course => {
    const courseStudents = getStudentsforCourse(students, course.id, courseMemberships)
    return (
      courseStudents.length > 0 &&
      every(courseStudents, student => {
        const sisId = student.sisId
        const source = student.source
        return source === 'upload' && !isNil(sisId)
      })
    )
  })
  const rosterHasCsvUploadedCourses = csvUploadCourses.length > 0

  // non idp courses with manually added students
  const manualCourses = filter(nonIdPCourses, course => {
    const courseStudents = getStudentsforCourse(students, course.id, courseMemberships)
    return !includes(csvUploadCourses, course) && courseStudents.length > 0
  })

  const rosterHasManualSourceCourses = manualCourses.length > 0
  const hasMixedCsvUploadedCourses = rosterHasCsvUploadedCourses && rosterHasManualSourceCourses
  return hasMixedCsvUploadedCourses
}

export function studentBestMatch(
  student: StudentAttrs,
  idpStudents: Array<StudentAttrs>
): Array<StudentAttrs> {
  const sorted = sortBy(idpStudents, s => nameForSorting(s))
  const scored: [StudentAttrs, number][] = map(sorted, (std: StudentAttrs) => {
    const score = sumBy(
      [scoreDistance, scoreExactMatch, scoreFirstSubset, scoreFirstNamesWithLastNameInitial],
      f => f(student, std)
    )
    return [std, score]
  })
  const sortedAndScored: [StudentAttrs, number][] = sortBy(
    scored,
    ([_std, score]: [StudentAttrs, number]) => -score
  )
  const matches: [StudentAttrs, number][] = filter(
    sortedAndScored,
    ([_std, score]: [StudentAttrs, number]) => score !== 0
  )
  return map(matches, ([std, _score]: [StudentAttrs, number]) => std)
}

const DISTANCE_THRESHOLD = 3

function scoreDistance(current: StudentAttrs, candidate: StudentAttrs): number {
  return levenshtein.get(
    caseFold(`${current.firstName}${current.lastName}`),
    caseFold(`${candidate.firstName}${candidate.lastName}`)
  ) <= DISTANCE_THRESHOLD
    ? 1
    : 0
}

function scoreExactMatch(current: StudentAttrs, candidate: StudentAttrs): number {
  return caseFold(current.firstName) === caseFold(candidate.firstName) &&
    caseFold(current.lastName) === caseFold(candidate.lastName)
    ? 1
    : 0
}

function scoreFirstSubset(current: StudentAttrs, candidate: StudentAttrs): number {
  return caseFold(current.lastName) === caseFold(candidate.lastName) &&
    (includes(caseFold(current.firstName), caseFold(candidate.firstName)) ||
      includes(caseFold(candidate.firstName), caseFold(current.firstName)))
    ? 1
    : 0
}

function scoreFirstNamesWithLastNameInitial(
  current: StudentAttrs,
  candidate: StudentAttrs
): number {
  return caseFold(current.firstName) === caseFold(candidate.firstName) &&
    caseFold(current.lastName)[0] === caseFold(candidate.lastName)[0]
    ? 1
    : 0
}

function caseFold(str: string): string {
  return str.toLowerCase().trim()
}

export function areAllStudentsInCourseManaged(students: Array<StudentAttrs>): boolean {
  const areAllStudentsManaged =
    students.length > 0 &&
    every(
      students,
      student => (isIdpManaged(student) || isManagedByCSV(student)) && !student.isTeacher
    )
  return areAllStudentsManaged
}

export function addCourseToLocalStorage(courseId: number) {
  const coursesMergedIds: number[] =
    StorageHelper.getLocalStorageProperty(localStorageCoursesMergedKey) ?? []
  const courseIdInStorage = find(coursesMergedIds, id => id === courseId)
  if (courseIdInStorage === undefined) {
    StorageHelper.setLocalStorageProperty(localStorageCoursesMergedKey, [
      ...coursesMergedIds,
      courseId,
    ])
  }
}

export function isCourseInMergedListInLocalStorage(courseId: number): boolean {
  const coursesMergedIds =
    StorageHelper.getLocalStorageProperty<number[]>(localStorageCoursesMergedKey) ?? []
  const courseIdAlreadyInStorage = find(coursesMergedIds, id => id === courseId)
  return courseIdAlreadyInStorage !== undefined
}
