import find from 'lodash/find'

export type TeacherRole =
  | 'teacher'
  | 'instructional_coach'
  | 'special_education_teacher'
  | 'curriculum_coordinator'
  | 'director_of_curriculum'
  | 'rti_coordinator'
  | 'tech_coordinator'
  | 'director_of_technology'
  | 'assistant_principal'
  | 'principal'
  | 'associate_superintendent'
  | 'superintendent'
  | 'other_district_level_administrator'
  | 'parent'
  | 'student'
  | 'other'

type TeacherRolesT = {
  Teacher: TeacherRole
  InstructionalCoach: TeacherRole
  SpecialEducationTeacher: TeacherRole
  CurriculumCoordinator: TeacherRole
  DirectorOfCurriculum: TeacherRole
  RtiCoordinator: TeacherRole
  TechCoordinator: TeacherRole
  DirectorOfTechnology: TeacherRole
  AssistantPrincipal: TeacherRole
  Principal: TeacherRole
  AssociateSuperintendent: TeacherRole
  Superintendent: TeacherRole
  OtherDistrictLevelAdministrator: TeacherRole
  Parent: TeacherRole
  Student: TeacherRole
  Other: TeacherRole
  all(): Array<TeacherRole>
  forDisplay(role: TeacherRole): string
  parse(s: string): TeacherRole | undefined | null
}

export const TeacherRoles: TeacherRolesT = {
  Teacher: 'teacher',
  InstructionalCoach: 'instructional_coach',
  SpecialEducationTeacher: 'special_education_teacher',
  CurriculumCoordinator: 'curriculum_coordinator',
  DirectorOfCurriculum: 'director_of_curriculum',
  RtiCoordinator: 'rti_coordinator',
  TechCoordinator: 'tech_coordinator',
  DirectorOfTechnology: 'director_of_technology',
  AssistantPrincipal: 'assistant_principal',
  Principal: 'principal',
  AssociateSuperintendent: 'associate_superintendent',
  Superintendent: 'superintendent',
  OtherDistrictLevelAdministrator: 'other_district_level_administrator',
  Parent: 'parent',
  Student: 'student',
  Other: 'other',
  all: function (): Array<TeacherRole> {
    return [
      TeacherRoles.Teacher,
      TeacherRoles.InstructionalCoach,
      TeacherRoles.SpecialEducationTeacher,
      TeacherRoles.CurriculumCoordinator,
      TeacherRoles.DirectorOfCurriculum,
      TeacherRoles.RtiCoordinator,
      TeacherRoles.TechCoordinator,
      TeacherRoles.DirectorOfTechnology,
      TeacherRoles.AssistantPrincipal,
      TeacherRoles.Principal,
      TeacherRoles.AssociateSuperintendent,
      TeacherRoles.Superintendent,
      TeacherRoles.OtherDistrictLevelAdministrator,
      TeacherRoles.Parent,
      TeacherRoles.Student,
      TeacherRoles.Other,
    ]
  },
  forDisplay: function (role: TeacherRole): string {
    switch (role) {
      case TeacherRoles.Teacher:
        return 'Teacher'
      case TeacherRoles.InstructionalCoach:
        return 'Instructional Coach'
      case TeacherRoles.SpecialEducationTeacher:
        return 'Special Education Teacher'
      case TeacherRoles.CurriculumCoordinator:
        return 'Curriculum Coordinator'
      case TeacherRoles.DirectorOfCurriculum:
        return 'Director of Curriculum'
      case TeacherRoles.RtiCoordinator:
        return 'RTI Coordinator'
      case TeacherRoles.TechCoordinator:
        return 'Tech Coordinator'
      case TeacherRoles.DirectorOfTechnology:
        return 'Director of Technology'
      case TeacherRoles.AssistantPrincipal:
        return 'Assistant Principal'
      case TeacherRoles.Principal:
        return 'Principal'
      case TeacherRoles.AssociateSuperintendent:
        return 'Associate Superintendent'
      case TeacherRoles.Superintendent:
        return 'Superintendent'
      case TeacherRoles.OtherDistrictLevelAdministrator:
        return 'Other District-Level Administrator'
      case TeacherRoles.Parent:
        return 'Parent'
      case TeacherRoles.Student:
        return 'Student'
      case TeacherRoles.Other:
        return 'Other'
      default:
        console.error(`Unrecognized TeacherRole: ${role}`)
        return 'Other'
    }
  },
  parse: function (s: string): TeacherRole | null {
    return find(TeacherRoles.all(), role => s === role) ?? null
  },
}

export function isAParent(role?: TeacherRole | null): boolean {
  return role === TeacherRoles.Parent
}
