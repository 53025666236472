import * as React from 'react'
import map from 'lodash/map'
import {exhaustive} from '@freckle/exhaustive'
import {asHTMLAttributeValue} from '@freckle/maybe'
import {addMaybeClassName} from '@freckle/educator-entities/ts/common/helpers/classnames'

import {
  body1Style,
  body2Style,
  body3Style,
  subtitle1Style,
  subtitle2Style,
  subtitle3Style,
  label1Style,
  label2Style,
  label3Style,
  caption1Style,
  caption2Style,
  subheaderStyle,
  practiceSmStyle,
  practiceMdStyle,
  practiceLgStyle,
  practiceXlgStyle,
  practiceBoldSmStyle,
  practiceBoldMdStyle,
  practiceBoldLgStyle,
  practiceBoldXlgStyle,
  bold,
  italic,
  muted,
  centered,
  noBottomMargin,
  inheritColor,
} from '@freckle/educator-materials/ts/components/typography/text.module.scss'

export type StyleT =
  | 'body-1'
  | 'body-2'
  | 'body-3'
  | 'subheader'
  | 'subtitle-1'
  | 'subtitle-2'
  | 'subtitle-3'
  | 'label-1'
  | 'label-2'
  | 'label-3'
  | 'caption-1'
  | 'caption-2'
  | 'practice-sm'
  | 'practice-md'
  | 'practice-lg'
  | 'practice-xlg'
  | 'practice-bold-sm'
  | 'practice-bold-md'
  | 'practice-bold-lg'
  | 'practice-bold-xlg'

export type ModifierT =
  | 'bold'
  | 'italic'
  | 'muted'
  | 'centered'
  | 'no-bottom-margin'
  | 'inherit-color'

type Props = {
  id?: string
  children?: React.ReactNode
  style?: StyleT
  modifiers?: ModifierT[]
  htmlElement?: 'div' | 'p' | 'span' | 'h1' | string
  addClass?: string | null
  dataTest?: string | null
}

function Text(props: Props): React.ReactElement {
  const {
    id,
    children,
    style = 'body-1',
    addClass,
    dataTest,
    modifiers = [],
    htmlElement = 'p',
  } = props
  const baseClassName = getBaseClassname(style, modifiers)
  const className = addMaybeClassName(baseClassName, addClass)

  const idObj = {id: asHTMLAttributeValue(id)}
  const dataTestObj = {'data-test': asHTMLAttributeValue(dataTest)}
  switch (style) {
    case 'body-1':
    case 'body-2':
    case 'body-3':
    case 'subtitle-1':
    case 'subtitle-2':
    case 'subtitle-3':
    case 'label-1':
    case 'label-2':
    case 'label-3':
    case 'subheader':
    case 'practice-sm':
    case 'practice-md':
    case 'practice-lg':
    case 'practice-xlg':
    case 'practice-bold-sm':
    case 'practice-bold-md':
    case 'practice-bold-lg':
    case 'practice-bold-xlg':
      return React.createElement(
        htmlElement,
        {
          className,
          ...idObj,
          ...dataTestObj,
        },
        children
      )
    case 'caption-1':
    case 'caption-2':
      return (
        <div className={className} {...idObj} {...dataTestObj}>
          {children}
        </div>
      )
    default:
      return exhaustive(style)
  }
}

function getBaseClassname(style: StyleT, modifiers: Array<ModifierT>): string {
  const modifierClass = getModifiersClass(modifiers)
  switch (style) {
    case 'body-1':
      return addMaybeClassName(body1Style, modifierClass)
    case 'body-2':
      return addMaybeClassName(body2Style, modifierClass)
    case 'body-3':
      return addMaybeClassName(body3Style, modifierClass)
    case 'subtitle-1':
      return addMaybeClassName(subtitle1Style, modifierClass)
    case 'subtitle-2':
      return addMaybeClassName(subtitle2Style, modifierClass)
    case 'subtitle-3':
      return addMaybeClassName(subtitle3Style, modifierClass)
    case 'label-1':
      return addMaybeClassName(label1Style, modifierClass)
    case 'label-2':
      return addMaybeClassName(label2Style, modifierClass)
    case 'label-3':
      return addMaybeClassName(label3Style, modifierClass)
    case 'caption-1':
      return addMaybeClassName(caption1Style, modifierClass)
    case 'caption-2':
      return addMaybeClassName(caption2Style, modifierClass)
    case 'subheader':
      return addMaybeClassName(subheaderStyle, modifierClass)
    case 'practice-sm':
      return addMaybeClassName(practiceSmStyle, modifierClass)
    case 'practice-md':
      return addMaybeClassName(practiceMdStyle, modifierClass)
    case 'practice-lg':
      return addMaybeClassName(practiceLgStyle, modifierClass)
    case 'practice-xlg':
      return addMaybeClassName(practiceXlgStyle, modifierClass)
    case 'practice-bold-sm':
      return addMaybeClassName(practiceBoldSmStyle, modifierClass)
    case 'practice-bold-md':
      return addMaybeClassName(practiceBoldMdStyle, modifierClass)
    case 'practice-bold-lg':
      return addMaybeClassName(practiceBoldLgStyle, modifierClass)
    case 'practice-bold-xlg':
      return addMaybeClassName(practiceBoldXlgStyle, modifierClass)
    default:
      return exhaustive(style)
  }
}

function getModifiersClass(modifiers: Array<ModifierT>): string | undefined | null {
  return modifiers.length === 0 ? null : map(modifiers, getModifierClass).join(' ')
}

function getModifierClass(modifier: ModifierT): string {
  switch (modifier) {
    case 'bold':
      return bold
    case 'italic':
      return italic
    case 'muted':
      return muted
    case 'centered':
      return centered
    case 'no-bottom-margin':
      return noBottomMargin
    case 'inherit-color':
      return inheritColor
    default:
      return exhaustive(modifier, 'ModifierT')
  }
}

export {Text}
