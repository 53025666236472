import * as React from 'react'
import {useAuth, AuthStatus} from '@freckle/classroom/ts/actions/auth'
import {useSafeEffectExtraDeps} from '@freckle/react-hooks'

type Props = {
  render: (authState: AuthStatus) => React.ReactElement
}

export function WithAuthStatus(props: Props): React.ReactElement {
  const {checkAuthStatus, authStatus} = useAuth()
  const {render} = props

  useSafeEffectExtraDeps<{checkAuthStatus: () => Promise<void>}>(
    ({checkAuthStatus}) => {
      checkAuthStatus()
    },
    [],
    {checkAuthStatus: {value: checkAuthStatus, comparator: () => true}}
  )

  return authStatus.hasCheckedAuth ? render(authStatus) : <></>
}
