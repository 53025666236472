import * as React from 'react'
import {useSafeTranslation} from '@freckle/educator-materials/ts/helpers/translate'
import NavItem from 'react-bootstrap/NavItem'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowUpRightFromSquare, faHeart} from '@fortawesome/free-solid-svg-icons'
import {Text} from '@freckle/educator-materials/ts/components/typography'
import {Link} from '@freckle/educator-materials/ts/components/link'
import {Flex} from '@freckle/educator-materials/ts/components/flex'
import {Routes} from '@freckle/classroom/ts/common/routers/routes'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import {Popover} from '@freckle/educator-materials/ts/components/popover'
import {earnRewardsLink, earnRewardsIconStyle, popoverStyle} from './earn-rewards.module.scss'

type Props = {}

export function EarnRewards(_props: Props): React.ReactElement {
  const {t} = useSafeTranslation()
  const [focus, setFocus] = React.useState(false)
  const [hover, setHover] = React.useState(false)
  // use show so we can force the tooltip to show if we hover over the tooltip
  return (
    <OverlayTrigger
      show={focus || hover}
      placement="bottom"
      overlay={
        <Popover
          id="earn_rewards_external_link_hover"
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          addClass={popoverStyle}
        >
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
          &nbsp;
          {t('EXTERNAL_LINK_HOVER')}
        </Popover>
      }
    >
      <NavItem
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      >
        <Link
          size="md"
          style="normal"
          addClass={earnRewardsLink}
          href={Routes.referralProgram()}
          target="_blank"
          reactRouter={false}
        >
          <Flex alignItems="center">
            <FontAwesomeIcon icon={faHeart} className={earnRewardsIconStyle} />
            <Text style="subtitle-2" htmlElement="span">
              {t('EARN_REWARDS_NAV_ITEM')}
            </Text>
          </Flex>
        </Link>
      </NavItem>
    </OverlayTrigger>
  )
}
