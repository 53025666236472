import isNil from 'lodash/isNil'

export class HttpError extends Error {
  status: number | undefined | null
  readyState: number | undefined | null

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(err: any) {
    super(createErrorMessage(err))
    this.name = 'HttpError'
    // These propeties are using in filterAndNotify for bugsnag
    this.status = err?.status
    this.readyState = err?.readyState
  }
}

function createErrorMessage(err: { status: number | undefined, responseText: string | undefined} | undefined | null): string {
  return !isNil(err?.status) && !isNil(err?.responseText)
    ? `Unexpected HTTP error: ${err?.status} - ${err?.responseText}`
    : `Unexpected HTTP error`
}
