import * as React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faXmark} from '@fortawesome/free-solid-svg-icons'

import {useSafeTranslation} from '@freckle/educator-materials/ts/helpers/translate'
import {H2, H4, Text} from '@freckle/educator-materials/ts/components/typography'
import {Button} from '@freckle/educator-materials/ts/components/button'
import {ButtonAsync} from '@freckle/educator-materials/ts/components/button-async'
import {Image} from '@freckle/educator-materials/ts/components/img'
import {Flex} from '@freckle/educator-materials/ts/components/flex'
import {PATHS} from '@freckle/educator-entities/ts/common/helpers/paths'
import StorageHelper from '@freckle/educator-entities/ts/common/helpers/storage-helper'
import {ContentAreas} from '@freckle/educator-entities/ts/curricula-api/generated-client/enums/content-area'
import {CourseMembershipAttrs} from '@freckle/educator-entities/ts/roster/models/course-membership'
import {CourseAttrs} from '@freckle/educator-entities/ts/roster/models/course'
import {StudentAttrs} from '@freckle/educator-entities/ts/users/models/student'
import {TeacherAttrs} from '@freckle/classroom/ts/users/models/teacher'
import {MultiCourseAssignmentPostT} from '@freckle/classroom/ts/common/logic/assignments'
import {tryAsStudent} from '@freckle/classroom/ts/common/logic/try-as-student'
import {getStudentTeacherLoginUrl} from '@freckle/classroom/ts/common/logic/sign-in-as-student'
import {useAsync} from '@freckle/educator-entities/ts/common/hooks/use-async'
import {fetchQuestionAlignment, MathQuestionResponseT} from '@freckle/classroom/ts/api/question'

import {
  fgpBannerContainer,
  icon,
  content,
  newfeatureAlert,
  announcement,
  tryFgpButton,
  tryFgp,
  readButton,
  text,
  xContainer,
  x,
} from './fgp-banner.module.scss'

type Props = {
  teacher: TeacherAttrs
  students: StudentAttrs[]
  courses: CourseAttrs[]
  courseMemberships: CourseMembershipAttrs[]
  hide: () => void
}

export function FGPBanner({
  teacher,
  students,
  courses,
  courseMemberships,
  hide,
}: Props): React.ReactElement {
  const {t} = useSafeTranslation()
  const mathFGPSupportLink =
    'https://www.renaissance.com/product_update/introducing-freckle-guided-practice-for-math/'

  const teacherStudent = students.find(student => student.isTeacher)
  const teacherStudentCourseId = courseMemberships.find(
    courseMembership => courseMembership.studentId === teacherStudent?.id
  )?.courseId

  const questionAlignment = useAsync(
    () =>
      fetchQuestionAlignment(teacher.language, 'math', teacher.mathStandardSetId, ['65a25759cb98']),
    [
      'fetchQuestionAlignment',
      teacher.language,
      'math',
      teacher.mathStandardSetId,
      ['65a25759cb98'],
    ]
  )

  const questionAlignmentData = questionAlignment.data as MathQuestionResponseT | undefined

  const postData =
    questionAlignmentData !== undefined &&
    questionAlignmentData.questions[0] &&
    questionAlignmentData.alignments[0]
      ? {
          newOrRetry: {
            tag: 'new',
            contents: {
              totalWorth: questionAlignmentData.questions[0].worth,
              standardId: questionAlignmentData.alignments[0].standard,
            },
          },
          questions: [
            {
              questionId: questionAlignmentData.alignments[0].question,
              skillPair: {skillId: questionAlignmentData.alignments[0].skill},
            },
          ],
        }
      : undefined

  const assignmentDataMap =
    postData !== undefined
      ? new Map([[teacherStudentCourseId!, {studentIds: [teacherStudent?.id], postData}]])
      : undefined

  const loginUrl = getStudentTeacherLoginUrl(courses, students, courseMemberships)

  const showTryNowButton =
    teacherStudent !== undefined &&
    teacherStudentCourseId !== undefined &&
    loginUrl !== undefined &&
    assignmentDataMap !== undefined

  return (
    <div
      className={fgpBannerContainer}
      style={{backgroundImage: `url(${PATHS.imageAssetsUrl}/fgp-banner-1x.png)`}}
    >
      <Flex>
        <Image
          width={'122'}
          src={`${PATHS.imageAssetsUrl}/icons/math-fgp-banner-icon.svg`}
          alt={t('MATH_FGP_BANNER_ALT_TEXT')}
          className={icon}
        />
        <div className={content}>
          <Text style="subtitle-2" addClass={newfeatureAlert}>
            {t('NEW_FEATURE_ALERT')}
          </Text>
          <H2 addClass={announcement}>{t('FRECKLE_GUIDED_PRACTICE_ANNOUNCEMENT')}</H2>
          {showTryNowButton && (
            <ButtonAsync
              style="secondary"
              dataTest="math-fgp-banner-try"
              addClass={tryFgpButton}
              onClick={async () => {
                await tryAsStudent(
                  ContentAreas.Math,
                  teacher,
                  {tag: 'math', assignmentDataMap: assignmentDataMap as MultiCourseAssignmentPostT},
                  loginUrl,
                  'Freckle Guided Practice Demo'
                )
                hide()
              }}
            >
              <H4 addClass={tryFgp}>{t('TRY_FRECKLE_GUIDED_PRACTICE')}</H4>
            </ButtonAsync>
          )}
          <Button
            style="link"
            addClass={readButton}
            dataTest="math-fgp-banner-read"
            onClick={() => {
              window.open(mathFGPSupportLink, '_blank', 'noopener')
              hide()
            }}
          >
            <Text style="body-1" addClass={text}>
              {t('READ_HOW_IT_WORKS')}
            </Text>
          </Button>
        </div>
        <div data-test="math-fgp-banner-close" className={xContainer}>
          <FontAwesomeIcon
            icon={faXmark}
            size="2xl"
            className={x}
            onClick={() => {
              const currentCount = StorageHelper.getLocalStorageProperty('mathFGPBanner') as number
              StorageHelper.setLocalStorageProperty('mathFGPBanner', currentCount + 1)
              hide()
            }}
          />
        </div>
      </Flex>
    </div>
  )
}
