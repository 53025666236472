import {ParserT, Parser, record, merge, array, number} from '@freckle/parser'

import {
  Word,
  parseWord,
} from '@freckle/educator-entities/ts/ela/word-study/logic/word-study-question-types'
import {
  WordStudyQuestionCommonAttrs,
  parseWordStudyQuestionCommonAttrs,
} from '@freckle/educator-entities/ts/ela/word-study/models/word-study-question'

type WordStudySortingQuestionCorrectAnswer = {
  indexes: Array<Array<number>>
}

export type WordStudyAnswerSorting = Array<Array<string>>

type WordStudySortingQuestionExtraAttrs = {
  answers: Array<Word>
  correctAnswers: WordStudySortingQuestionCorrectAnswer
  bucketFieldNames: Array<Word>
}

export type WordStudySortingQuestionAttrs = WordStudyQuestionCommonAttrs &
  WordStudySortingQuestionExtraAttrs

const parseWordStudySortingQuestionExtraAttrs: ParserT<WordStudySortingQuestionExtraAttrs> = record(
  {
    answers: array(parseWord),
    correctAnswers: record({indexes: array(array(number()))}),
    bucketFieldNames: array(parseWord),
  }
)

const parseWordStudySortingQuestionAttrs: ParserT<WordStudySortingQuestionAttrs> = merge(
  parseWordStudySortingQuestionExtraAttrs,
  parseWordStudyQuestionCommonAttrs
)

export const parseWordStudySortingQuestion = Parser.mkRun<WordStudySortingQuestionAttrs>(
  parseWordStudySortingQuestionAttrs
)
