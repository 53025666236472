import * as React from 'react'

import {section} from './sidebar-section.module.scss'

type Props = {
  children: React.ReactElement
}

export function SidebarSection({children}: Props): React.ReactElement {
  return (
    <div className={section}>
      <ul className="list-unstyled">{children}</ul>
    </div>
  )
}
