import {exhaustive} from '@freckle/exhaustive'
import {logError} from '@freckle/classroom/ts/common/helpers/exception-handlers/bugsnag-client'
import find from 'lodash/find'
import {matchRoutes} from 'react-router-dom'

export type SidebarIdT =
  | 'dashboard-tab'
  | 'activity-feed-tab'
  | 'assignments-tab'
  | 'rosters-tab'
  | 'common-reports-tab'
  | 'math-tab'
  | 'ela-tab'
  | 'social-studies-tab'
  | 'science-tab'
  | 'none'

type SidebarIdsT = {
  Dashboard: SidebarIdT
  ActivityFeed: SidebarIdT
  Assignments: SidebarIdT
  Reports: SidebarIdT
  Rosters: SidebarIdT
  MathContent: SidebarIdT
  ElaContent: SidebarIdT
  SocialStudiesUnits: SidebarIdT
  ScienceUnits: SidebarIdT
  None: SidebarIdT
}

const SidebarIds: SidebarIdsT = {
  Dashboard: 'dashboard-tab',
  ActivityFeed: 'activity-feed-tab',
  Assignments: 'assignments-tab',
  Reports: 'common-reports-tab',
  Rosters: 'rosters-tab',
  MathContent: 'math-tab',
  ElaContent: 'ela-tab',
  SocialStudiesUnits: 'social-studies-tab',
  ScienceUnits: 'science-tab',

  //for routes like teacher-onboarding that do not use the sidebar.
  None: 'none',
}

export function getDataTestFromTabId(tabId: SidebarIdT): string {
  switch (tabId) {
    case 'dashboard-tab':
      return 'sidebar-link-dashboard'
    case 'activity-feed-tab':
      return 'sidebar-link-activity-feed'
    case 'assignments-tab':
      return 'sidebar-link-assignments'
    case 'common-reports-tab':
      return 'sidebar-link-reports'
    case 'rosters-tab':
      return 'sidebar-link-roster'
    case 'math-tab':
      return 'sidebar-link-math'
    case 'ela-tab':
      return 'sidebar-link-ela'
    case 'social-studies-tab':
      return 'sidebar-link-social-studies'
    case 'science-tab':
      return 'sidebar-link-science'
    case 'none':
      return 'sidebar-link-none'

    default:
      return exhaustive(tabId, 'getDataTestFromTabId')
  }
}

export function getSidebarLinkTranslateKeyFromTabId(tabId: SidebarIdT): string {
  switch (tabId) {
    case 'dashboard-tab':
      return 'SIDEBAR_DASHBOARD'
    case 'activity-feed-tab':
      return 'SIDEBAR_ACTIVITY_FEED'
    case 'assignments-tab':
      return 'SIDEBAR_ASSIGNMENTS'
    case 'common-reports-tab':
      return 'SIDEBAR_COMMON_REPORTS'
    case 'rosters-tab':
      return 'SIDEBAR_ROSTER'
    case 'math-tab':
      return 'SIDEBAR_MATH'
    case 'ela-tab':
      return 'SIDEBAR_ELA'
    case 'social-studies-tab':
      return 'SIDEBAR_SOCIAL_STUDIES'
    case 'science-tab':
      return 'SIDEBAR_SCIENCE'
    case 'none':
      return ''
    default:
      return exhaustive(tabId)
  }
}

const routes = [
  {
    paths: ['/dashboard'],
    tab: SidebarIds.Dashboard,
  },
  {
    paths: [
      '//courses/:courseId/activity-feed',
      '/courses/:courseId/ela/reports/focus-skills/:assignmentId/students/:studentId',
    ],
    tab: SidebarIds.ActivityFeed,
  },
  {
    paths: [
      '/assignments',
      '/courses/:courseId/ela/assignments',
      '/courses/:courseId/ela/targeted-skill-practice/assign',
      '/courses/:courseId/assignments/adaptive',
      '/courses/:courseId/assignments/number-facts',
      '/courses/:courseId/assignments/targeted',
      '/courses/:courseId/assignments/assessments',
      '/courses/:courseId/assignments/fact-practice',
      '/courses/:courseId/assignments/targeted-depth-of-knowledge-practice',
      '/courses/:courseId/assignments',
      '/courses/:courseId/science/assignments',
      '/courses/:courseId/social-studies/assignments',
      '/math/targeted-session/:assignmentSessionId/report',
      '/courses/:courseId/ela/reports/targeted-skills/:assignmentId/students/:studentId',
    ],
    tab: SidebarIds.Assignments,
  },
  {
    paths: [
      '/courses/:courseId/student-goals',
      '/courses/:courseId/ela/student-goals',
      '/courses/:courseId/ela/report-cards',
      '/courses/:courseId/ela/report-cards/:studentId',
      '/courses/:courseId/ela/reports',
      '/courses/:courseId/ela/reports/performance-by-standard',
      '/courses/:courseId/ela/groups-report',
      '/courses/:courseId/session-history/ela-article',
      '/courses/:courseId/ela/reports/levels',
      '/courses/:courseId/ela/reports/assessments',
      '/courses/:courseId/ela/word-study-matrix',
      '/courses/:courseId/ela-skills-progress-report',
      '/courses/:courseId/math-skills-progress-report',
      '/courses/:courseId/math-diagnostic-report',
      '/courses/:courseId/math-report-card',
      '/courses/:courseId/math-standards-report',
      '/courses/:courseId/ela/report-card',
      '/courses/:courseId/class-grouping-report',
      '/courses/:courseId/math-assessment-growth',
      '/courses/:courseId/math/reports',
      '/courses/:courseId/fact-practice-reports',
      '/courses/:courseId/fact-practice-report/students/:studentId/operations/:operation',
      '/courses/:courseId/report-card-summaries',
    ],
    tab: SidebarIds.Reports,
  },
  {
    paths: [
      '/courses/:courseId/ela/articles/:articleUuid/assign',
      '/courses/:courseId/ela/assessments',
      '/courses/:courseId/ela/word-study',
      '/courses/:courseId/ela/decodable/assign',
      '/courses/:courseId/ela/articles',
      '/courses/:courseId/ela/articles/:articleUuid',
      '/courses/:courseId/ela/standards',
      '/ela/standards/:elaDomainCode/:elaStandardCode',
      '/courses/:courseId/ela/overview',
      '/ela/standards/students/:studentId/report',
      '/courses/:courseId/ela/print-ela-report-cards',
      '/ela/reports/assessment/:assignmentId',
      '/courses/:courseId/ela/reports/assessment-sessions/:assignmentSessionId',
      '/ela/reports/writing/:assignmentId',
      '/courses/:courseId/ela/reports/reading/:assignmentId/students/:studentId',
      '/courses/:courseId/ela/reports/adaptive-skills/:assignmentId/students/:studentId',
      '/courses/:courseId/ela/reports/adaptive-grammar/:assignmentId/students/:studentId',
      '/courses/:courseId/ela/reports/targeted-grammar/:assignmentId/students/:studentId',
      '/courses/:courseId/ela/reports/word-study/:assignmentSessionId/students/:studentId',
      '/courses/:courseId/session-history/ela-article/:articleUuid',
      '/ela/correlation',
    ],
    tab: SidebarIds.ElaContent,
  },
  {
    paths: [
      '/courses/:courseId/session-history/math-assessment-session/:sessionId',
      '/courses/:courseId/session-history/math-assessment/:assessmentId/session/:sessionId',
      '/math/assessment-session/:sessionId/report',
      '/courses/:courseId/assessment/:assessmentId/report',
      '/math/standards/students/:studentId/report',
      '/math/goals/fact-fluency-overview/',
      '/math/adaptive-session/:assignmentSessionId/report',
      '/courses/:courseId/standards',
      '/courses/:courseId/standards/:standardId',
      '/courses/:courseId/print-math-report-cards',
      '/courses/:courseId/lessons',
      '/courses/:courseId/printables',
      '/courses/:courseId/lessons/:lessonId',
      '/courses/:courseId/lessons/:lessonId/days/:dayNum/slideshow',
      '/courses/:courseId/lessons/:lessonId/assignments',
      '/courses/:courseId/constructed-responses',
      '/courses/:courseId/math-assessment-growth-detailed',
      '/courses/:courseId/math/reports/targeted-practice/:assignmentId',
      '/courses/:courseId/math/overview',
      '/courses/:courseId/math-focus-skills-report',
      '/math/session/:product/:assignmentSessionId/report',
    ],
    tab: SidebarIds.MathContent,
  },
  {
    paths: [
      '/rosters',
      '/rosters/archived-roster',
      '/rosters/create',
      '/rosters/courses/:courseId/edit',
      '/rosters/login-cards',
      '/courses/:courseId/students/:studentId/edit',
    ],
    tab: SidebarIds.Rosters,
  },
  {
    paths: [
      '/science-units/:scienceUnitId/articles/:articleUuid',
      '/science/reports/writing/:assignmentId',
      '/science/reports/reading/:assignmentId/students/:studentId',
      '/science-units/:pathScienceUnitId',
      '/courses/:courseId/science-units/:pathScienceUnitId/articles/:articleUuid/assign',
      '/science-units',
      '/science/search',
      '/courses/:courseId/session-history/science',
    ],
    tab: SidebarIds.ScienceUnits,
  },
  {
    paths: [
      '/social-studies-units/:socialStudiesUnitId/articles/:articleUuid',
      '/social-studies/reports/writing/:assignmentId',
      '/social-studies/reports/reading/:assignmentId/students/:studentId',
      '/social-studies-units/:pathSocialStudiesUnitId',
      '/courses/:courseId/social-studies-units/:pathSocialStudiesUnitId/articles/:articleUuid/assign',
      '/social-studies-units',
      '/social-studies/search',
      '/courses/:courseId/session-history/social-studies',
    ],
    tab: SidebarIds.SocialStudiesUnits,
  },
  {
    paths: [
      '/default-course/*',
      '/teacher-onboarding',
      '/parent-welcome',
      '/mobile-reminder',
      '/referral-program',
      '/settings',
      '/ela-article-debugger',
      '/courses/:courseId/ela/print/targeted-skills/:assignmentSessionId',
      '/courses/:courseId/ela/print/articles/:assignmentSessionId',
    ],
    tab: SidebarIds.None,
  },
]

export function getTabIdForRoute(routePathName: string): SidebarIdT {
  const match = find(routes, ({paths}) => {
    const matches = matchRoutes(
      paths.map(path => ({path})),
      routePathName
    )
    return matches !== null && matches.length > 0
  })
  if (match === undefined) {
    logError(new Error(`Route "${routePathName}" doesn't have a matching sidebar item`))
    return SidebarIds.None
  }
  return match.tab
}
