import {useState} from 'react'
import {fromMaybe} from '@freckle/maybe'

export type UseModalResult = {
  // Hide modal
  hide: () => void
  // Show modal
  show: () => void
  // Hide or show a modal
  setVisible: (v: boolean) => void
  // Whether the modal is visible
  isVisible: boolean
  // Standard modal props
  props: {show: boolean; onHide: () => void}
}

export type UseModalsResult<ModalId> = UseTaggedModalsResult<ModalId, ModalId>

export type UseTaggedModalsResult<Modal, ModalId> = {
  // Hide all modals
  hide: () => void
  // Show a specific modal by tag
  show: (m: Modal) => void
  // Create a function which will show a specific modal by tag
  shows: (m: Modal) => () => void
  // Whether a modal is visible, by tag
  isVisible: (id: ModalId) => boolean
  // Standard modal props, by tag
  props: (id: ModalId) => {show: boolean; onHide: () => void}
  // Current visible modal nor `null` if not visible
  visible: Modal | null
}

// Handles modal visibility state for components with a single modal
export function useModal(initialVisible?: boolean): UseModalResult {
  const modal = useModals<'modal'>(initialVisible === undefined ? undefined : 'modal')
  const show = modal.visible === 'modal'

  return {
    hide: modal.hide,
    show: () => modal.show('modal'),
    setVisible: visible => (visible ? modal.show('modal') : modal.hide()),
    isVisible: show,
    props: {show, onHide: modal.hide},
  }
}

// Handles modal visibility state for components with multiple modals, without
// associated modal state
export function useModals<T extends string>(initialVisible?: T): UseModalsResult<T> {
  return useModalInner<T, T>(x => x, initialVisible)
}

// Handles modal visibility state for components with multiple modals, with
// associated modal state (e.g. tagged data, tied to modal's visiblity)
export function useTaggedModals<T extends {tag: TTag}, TTag extends string = T['tag']>(
  initialVisible?: T
): UseTaggedModalsResult<T, TTag> {
  return useModalInner<T, TTag>(x => x.tag, initialVisible)
}

// Hook controlling modal visibility. Hides state and helps to ensure that we
// only show a single modal on a page at a time.
function useModalInner<Modal, ModalId>(
  equalOn: (a: Modal) => ModalId,
  initialVisible: Modal | undefined
): UseTaggedModalsResult<Modal, ModalId> {
  // `visible` is `null` when no modal is visible.
  const [visible, setVisibleModal] = useState<Modal | null>(fromMaybe(() => null, initialVisible))
  const hide = () => setVisibleModal(null)
  const isVisible = (id: ModalId) => visible !== null && id === equalOn(visible)
  return {
    visible,
    isVisible,
    hide,
    show: setVisibleModal,
    shows: id => () => setVisibleModal(id),
    props: id => ({show: isVisible(id), onHide: hide}),
  }
}
