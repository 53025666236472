import {CourseMembershipAttrs} from '@freckle/educator-entities/ts/roster/models/course-membership'
import {getStudentIdsforCourse} from '@freckle/educator-entities/ts/users/logic/students'
import {StudentAttrs} from '@freckle/educator-entities/ts/users/models/student'

export function forCourse(
  students: Array<StudentAttrs>,
  courseId: number,
  courseMemberships: Array<CourseMembershipAttrs>
): Array<StudentAttrs> {
  const studIdsInCourse = new Set(getStudentIdsforCourse(courseId, courseMemberships))
  const allStudents = students.filter(s => studIdsInCourse.has(s.id))
  return allStudents
}

export function hasStudentWithName(
  students: Array<StudentAttrs>,
  firstName: string,
  lastName: string
): boolean {
  return students.some(
    student =>
      student.firstName.toLowerCase() === firstName.toLowerCase() &&
      student.lastName.toLowerCase() === lastName.toLowerCase()
  )
}
