import * as React from 'react'

import {
  ActivityFeedHandler,
  AssignmentsHandler,
  DashboardHandler,
  MobileReminderHandler,
  ParentWelcomeHandler,
  ReferralProgramHandler,
  SettingsHandler,
  StudentGoalsHandler,
  TeacherOnboardingHandler,
  DefaultCourseRedirectHandler,
} from './lazy'
import {LoggedRoute} from './../logged-route'

export const routerV2Common = [
  {
    path: '/dashboard',
    element: <LoggedRoute component={DashboardHandler} />,
  },
  {
    path: '/courses/:courseId/activity-feed',
    element: <LoggedRoute component={ActivityFeedHandler} />,
  },
  {
    path: '/courses/:courseId/student-goals',
    element: <LoggedRoute component={StudentGoalsHandler} />,
  },
  {
    path: '/courses/:courseId/ela/student-goals',
    element: <LoggedRoute component={StudentGoalsHandler} />,
  },
  {
    path: '/assignments',
    element: <LoggedRoute component={AssignmentsHandler} />,
  },
  {
    path: '/teacher-onboarding',
    element: <LoggedRoute component={TeacherOnboardingHandler} fullScreen />,
  },
  {
    path: '/parent-welcome',
    element: <LoggedRoute component={ParentWelcomeHandler} dashboardType="minimal" />,
  },
  {
    path: '/mobile-reminder',
    element: <LoggedRoute component={MobileReminderHandler} fullScreen />,
  },
  {
    path: '/referral-program',
    element: <LoggedRoute component={ReferralProgramHandler} fullScreen />,
  },
  {
    path: '/settings',
    element: <LoggedRoute component={SettingsHandler} />,
  },
  {
    path: '/default-course/*',
    element: <LoggedRoute component={DefaultCourseRedirectHandler} />,
  },
]
