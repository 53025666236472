import * as React from 'react'
import {SplitWrapper} from '@freckle/educator-entities/ts/common/components/experiment'
import {getOrCreateSplitIOAnonymousId} from '@freckle/classroom/ts/common/helpers/split-io-factory'

type Props = React.PropsWithChildren<{}>

export function AnonymousSplitIOWrapper(props: Props): React.ReactElement {
  const anonymousCookieId = getOrCreateSplitIOAnonymousId()
  const trafficType = 'anonymous'
  return (
    <SplitWrapper configKey={anonymousCookieId} trafficType={trafficType} logImpression={() => {}}>
      {props.children}
    </SplitWrapper>
  )
}
