import BugsnagHelper, {
  BugsnagEventT,
  ErrorContext,
} from '@freckle/educator-entities/ts/common/helpers/exception-handlers/bugsnag-helper'
import {Client} from '@bugsnag/js'
import {mmap} from '@freckle/maybe'
import {Globals} from '@freckle/classroom/ts/common/models/globals'

export const bugsnagClient: Client = BugsnagHelper.init(
  (): ErrorContext => ({
    user: mmap(id => ({id}), Globals.userId),
  })
)

export function logError(err: Error, onError?: (event: BugsnagEventT) => void) {
  BugsnagHelper.logError(err, onError)
}

export function logErrorAsUnhandled(err: Error) {
  BugsnagHelper.logErrorAsUnhandled(err)
}
