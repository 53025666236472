import {lazy} from 'react'

export const ElaArticleDebuggerHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "ElaArticleDebuggerHandler" */ './../../../../ela/debugger/components'
    )
)

export const ElaOverviewHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "ElaOverviewHandler" */ './../../../../ela/common/components/content-overview'
    )
)

export const StandardsPageHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "StandardsPageHandler" */ './../../../../ela/standards/components/standard-page'
    )
)
