import * as React from 'react'
import {BreadcrumbsProps} from '@freckle/educator-materials/ts/components/breadcrumbs'
import {addMaybeClassName} from '@freckle/educator-entities/ts/common/helpers/classnames'
import {PageHeader, PageTitleT} from '@freckle/educator-materials/ts/components/page-header'
import {exhaustive} from '@freckle/exhaustive'
import {DisableHelperBarT} from '@freckle/classroom/ts/common/components/dashboard'
import {pageHeaderContainerWithBreadcrumbs, pageHeaderContainer} from './page-wrapper.module.scss'

type Props = {
  pageHeaderText?: PageTitleT
  secondaryHeaderContent?: React.ReactElement
  children: React.ReactElement
  disablePageHeader?: DisableHelperBarT
  breadcrumbsConfig?: BreadcrumbsProps | null | undefined
  breadcrumbsClass?: string
  headerClass?: string
  dataTestHeader?: string
}

function renderPageHeader(props: {
  pageHeaderText?: PageTitleT
  secondaryHeaderContent?: React.ReactElement
  disablePageHeader?: DisableHelperBarT
  breadcrumbsConfig?: BreadcrumbsProps | null | undefined
  breadcrumbsClass?: string
  headerClass?: string
  dataTestHeader?: string
}): React.ReactElement {
  const {dataTestHeader} = props
  const disablePageHeader = props.disablePageHeader ?? 'never'

  const headerClass = addMaybeClassName(
    props.breadcrumbsConfig ? pageHeaderContainerWithBreadcrumbs : pageHeaderContainer,
    props.headerClass
  )
  switch (disablePageHeader) {
    case 'always':
      return <></>
    case 'never':
      return (
        <div className={headerClass}>
          <PageHeader
            title={props.pageHeaderText}
            breadcrumbsConfig={props.breadcrumbsConfig}
            breadcrumbsClass={props.breadcrumbsClass}
            dataTest={dataTestHeader}
          />
          {props.secondaryHeaderContent}
        </div>
      )
    case 'print':
      return (
        <div className="hidden-print">
          <div className={headerClass}>
            <PageHeader title={props.pageHeaderText} breadcrumbsConfig={props.breadcrumbsConfig} />
            {props.secondaryHeaderContent}
          </div>
        </div>
      )
    default:
      return exhaustive(disablePageHeader, 'renderPageHeader')
  }
}

export function PageWrapper(props: Props): React.ReactElement {
  const {
    pageHeaderText,
    secondaryHeaderContent,
    children,
    disablePageHeader,
    breadcrumbsConfig,
    breadcrumbsClass,
    headerClass,
    dataTestHeader,
  } = props
  return (
    <>
      {renderPageHeader({
        pageHeaderText,
        secondaryHeaderContent,
        disablePageHeader,
        breadcrumbsConfig,
        breadcrumbsClass,
        headerClass,
        dataTestHeader,
      })}
      <div className="row no-margin-row">
        <div id="inner-content" className="col-12">
          {children}
        </div>
      </div>
    </>
  )
}
