import * as React from 'react'
import {TransitionGroup, CSSTransition} from 'react-transition-group'
import {useSafeEffect} from '@freckle/react-hooks'
import {
  NotificationProps,
  Notification,
} from '@freckle/classroom/ts/common/components/notifications-wrapper/notification'
import {EventsKey} from '@freckle/classroom/ts/common/helpers/analytics'
import {getNotification as getPaywallNotification} from '@freckle/classroom/ts/common/helpers/paywall-notification'
import {PaywallNotificationTypeT} from '@freckle/classroom/ts/common/helpers/paywall-notification'
import NotificationManager from '@freckle/classroom/ts/common/components/notifications-wrapper/notification-manager'
import {PaywallNotification} from './paywall-notification'

export function NotificationWrapper(): React.ReactElement {
  const [paywallNotification, setPaywallNotification] = React.useState<
    PaywallNotificationTypeT | null | undefined
  >(null)
  const [paywallAnalyticsEventKey, setPaywallAnalyticsEventKey] = React.useState<
    EventsKey | null | undefined
  >(null)
  const [notificationProps, setNotificationProps] = React.useState<
    NotificationProps | null | undefined
  >(null)

  const updateNotification = () => {
    const [updatedPaywallNotification, updatedPaywallAnalyticsEventKey] = getPaywallNotification()
    const notificationProps = NotificationManager.notificationProps

    setPaywallNotification(updatedPaywallNotification)
    setPaywallAnalyticsEventKey(updatedPaywallAnalyticsEventKey)
    setNotificationProps(notificationProps)
  }

  useSafeEffect(() => {
    window.addEventListener('update-notification', updateNotification, false)
    return () => {
      window.removeEventListener('update-notification', updateNotification, false)
    }
  }, [])

  const transition = (x: React.ReactElement) => (
    <CSSTransition
      classNames="notification" // eslint-disable-line i18next/no-literal-string
      timeout={300}
    >
      {x}
    </CSSTransition>
  )

  const notification =
    notificationProps !== null && notificationProps !== undefined
      ? transition(<Notification {...notificationProps} onClose={onCloseNotification} />)
      : null

  return (
    <div className="notifications top-right">
      <TransitionGroup>
        {transition(
          <PaywallNotification
            notification={paywallNotification}
            analyticsEventKey={paywallAnalyticsEventKey}
          />
        )}
        {notification}
      </TransitionGroup>
    </div>
  )
}

function onCloseNotification() {
  NotificationManager.remove()
}
