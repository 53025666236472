import * as React from 'react'
import {Navigate} from 'react-router-dom'

import {LoggedRoute} from './../logged-route'
import {SocialStudiesTakedown} from './../../../../common/components/social-studies-takedown'
import {
  SocialStudiesArticlePage,
  SocialStudiesArticleReadingAssignmentSessionReportHandler,
  SocialStudiesAssignArticlePage,
  SocialStudiesAssignmentSessionHistoryHandler,
  SocialStudiesAssignmentsHandler,
  SocialStudiesListingPageContainer,
  SocialStudiesSearchOrListingPage,
  SocialStudiesUnitPageHandler,
  SocialStudiesWritingAssignmentReportHandler,
} from './lazy'

export const routerV2SocialStudies = [
  {
    path: '/social-studies-units/:socialStudiesUnitId/articles/:articleUuid',
    element: (
      <LoggedRoute
        component={() => <SocialStudiesTakedown component={SocialStudiesArticlePage} />}
      />
    ),
  },
  {
    path: '/social-studies/reports/writing/:assignmentId',
    element: <LoggedRoute component={SocialStudiesWritingAssignmentReportHandler} />,
  },
  {
    path: '/social-studies/reports/reading/:assignmentId/students/:studentId',
    element: <LoggedRoute component={SocialStudiesArticleReadingAssignmentSessionReportHandler} />,
  },
  {
    path: '/social-studies-units/:pathSocialStudiesUnitId',
    element: (
      <LoggedRoute
        component={() => <SocialStudiesTakedown component={SocialStudiesUnitPageHandler} />}
      />
    ),
  },
  {
    path: '/courses/:courseId/social-studies-units/:pathSocialStudiesUnitId/articles/:articleUuid/assign',
    element: (
      <LoggedRoute
        component={() => <SocialStudiesTakedown component={SocialStudiesAssignArticlePage} />}
      />
    ),
  },
  {
    path: '/social-studies-units',
    element: (
      <LoggedRoute
        component={() => <SocialStudiesTakedown component={SocialStudiesListingPageContainer} />}
      />
    ),
  },
  {path: '/social-studies', element: <Navigate to="/social-studies-units" replace />},
  {
    path: '/social-studies/search',
    element: (
      <LoggedRoute
        component={() => <SocialStudiesTakedown component={SocialStudiesSearchOrListingPage} />}
      />
    ),
  },
  {
    path: '/courses/:courseId/social-studies/assignments',
    element: <LoggedRoute component={SocialStudiesAssignmentsHandler} />,
  },
  {
    path: '/courses/:courseId/session-history/social-studies',
    element: <LoggedRoute component={SocialStudiesAssignmentSessionHistoryHandler} />,
  },
]
