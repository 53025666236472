/* @flow */

class PATHS_TYPE_paths {
  docs(): string { return this.root + '/docs'; }
  faviconsvg(): string { return this.root + '/favicon.svg'; }
  health_check(): string { return this.root + '/health-check'; }
  openapijson(): string { return this.root + '/openapi.json'; }
  robotstxt(): string { return this.root + '/robots.txt'; }
  routesjs(): string { return this.root + '/routes.js'; }
  static_pages : PATHS_TYPE_paths_static_pages;
  v2 : PATHS_TYPE_paths_v2;
  v3 : PATHS_TYPE_paths_v3;
  version(): string { return this.root + '/version'; }

  root: string;
  constructor(root: string) {
    this.root = root;
    this.static_pages = new PATHS_TYPE_paths_static_pages(root);
    this.v2 = new PATHS_TYPE_paths_v2(root);
    this.v3 = new PATHS_TYPE_paths_v3(root);
  }
}

class PATHS_TYPE_paths_static_pages {
  docs(): string { return this.root + '/docs'; }
  faviconsvg(): string { return this.root + '/favicon.svg'; }
  health_check(): string { return this.root + '/health-check'; }
  openapijson(): string { return this.root + '/openapi.json'; }
  robotstxt(): string { return this.root + '/robots.txt'; }
  routesjs(): string { return this.root + '/routes.js'; }
  version(): string { return this.root + '/version'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2 {
  admins : PATHS_TYPE_paths_v2_admins;
  assignment_sessions : PATHS_TYPE_paths_v2_assignment_sessions;
  auth_check(): string { return this.root + '/2/auth-check'; }
  boom(): string { return this.root + '/2/boom'; }
  colleagues(): string { return this.root + '/2/colleagues'; }
  connection_check(): string { return this.root + '/2/connection-check'; }
  console : PATHS_TYPE_paths_v2_console;
  course_memberships : PATHS_TYPE_paths_v2_course_memberships;
  courses : PATHS_TYPE_paths_v2_courses;
  demo_districts : PATHS_TYPE_paths_v2_demo_districts;
  districts : PATHS_TYPE_paths_v2_districts;
  educator_academy : PATHS_TYPE_paths_v2_educator_academy;
  ela : PATHS_TYPE_paths_v2_ela;
  ela_assessment_session_report : PATHS_TYPE_paths_v2_ela_assessment_session_report;
  ela_assignment_answers : PATHS_TYPE_paths_v2_ela_assignment_answers;
  ela_assignments : PATHS_TYPE_paths_v2_ela_assignments;
  ela_snapshots : PATHS_TYPE_paths_v2_ela_snapshots;
  ela_vocab_words : PATHS_TYPE_paths_v2_ela_vocab_words;
  email_verifications(): string { return this.root + '/2/email-verifications'; }
  ibl_assignments : PATHS_TYPE_paths_v2_ibl_assignments;
  ibl_sessions(): string { return this.root + '/2/ibl-sessions'; }
  item_ownerships : PATHS_TYPE_paths_v2_item_ownerships;
  items : PATHS_TYPE_paths_v2_items;
  license_allocations : PATHS_TYPE_paths_v2_license_allocations;
  licenses : PATHS_TYPE_paths_v2_licenses;
  math : PATHS_TYPE_paths_v2_math;
  math_adaptive_assignments : PATHS_TYPE_paths_v2_math_adaptive_assignments;
  math_assessment_sessions : PATHS_TYPE_paths_v2_math_assessment_sessions;
  math_assessments : PATHS_TYPE_paths_v2_math_assessments;
  math_assignments : PATHS_TYPE_paths_v2_math_assignments;
  math_fact_practice_snapshots : PATHS_TYPE_paths_v2_math_fact_practice_snapshots;
  math_fact_practice_usage(): string { return this.root + '/2/math-fact-practice-usage'; }
  math_targeted_assignment_sessions : PATHS_TYPE_paths_v2_math_targeted_assignment_sessions;
  math_targeted_assignments : PATHS_TYPE_paths_v2_math_targeted_assignments;
  passwords : PATHS_TYPE_paths_v2_passwords;
  periscope_embeds : PATHS_TYPE_paths_v2_periscope_embeds;
  ping(): string { return this.root + '/2/ping'; }
  premium_licenses(): string { return this.root + '/2/premium-licenses'; }
  referrals : PATHS_TYPE_paths_v2_referrals;
  school_admins : PATHS_TYPE_paths_v2_school_admins;
  schools : PATHS_TYPE_paths_v2_schools;
  science_assignments : PATHS_TYPE_paths_v2_science_assignments;
  science_writing_assignment_answers : PATHS_TYPE_paths_v2_science_writing_assignment_answers;
  session_summaries(): string { return this.root + '/2/session-summaries'; }
  sight_words(a: string): string { return this.root + '/2/sight-words/' + a + ''; }
  sight_words_level(): string { return this.root + '/2/sight-words-level'; }
  sight_words_sessions(): string { return this.root + '/2/sight-words-sessions'; }
  sloth(): string { return this.root + '/2/sloth'; }
  social_studies_assignments : PATHS_TYPE_paths_v2_social_studies_assignments;
  social_studies_writing_assignment_answers : PATHS_TYPE_paths_v2_social_studies_writing_assignment_answers;
  static_redirect(): string { return this.root + '/2/static-redirect'; }
  student_ping(): string { return this.root + '/2/student-ping'; }
  student_shares(): string { return this.root + '/2/student-shares'; }
  students : PATHS_TYPE_paths_v2_students;
  students_idp_overlays : PATHS_TYPE_paths_v2_students_idp_overlays;
  teacher_ping(): string { return this.root + '/2/teacher-ping'; }
  teacher_relogin_redirect(): string { return this.root + '/2/teacher-relogin-redirect'; }
  teacher_static_redirect(): string { return this.root + '/2/teacher-static-redirect'; }
  teachers : PATHS_TYPE_paths_v2_teachers;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.admins = new PATHS_TYPE_paths_v2_admins(root);
    this.assignment_sessions = new PATHS_TYPE_paths_v2_assignment_sessions(root);
    this.console = new PATHS_TYPE_paths_v2_console(root);
    this.course_memberships = new PATHS_TYPE_paths_v2_course_memberships(root);
    this.courses = new PATHS_TYPE_paths_v2_courses(root);
    this.demo_districts = new PATHS_TYPE_paths_v2_demo_districts(root);
    this.districts = new PATHS_TYPE_paths_v2_districts(root);
    this.educator_academy = new PATHS_TYPE_paths_v2_educator_academy(root);
    this.ela = new PATHS_TYPE_paths_v2_ela(root);
    this.ela_assessment_session_report = new PATHS_TYPE_paths_v2_ela_assessment_session_report(root);
    this.ela_assignment_answers = new PATHS_TYPE_paths_v2_ela_assignment_answers(root);
    this.ela_assignments = new PATHS_TYPE_paths_v2_ela_assignments(root);
    this.ela_snapshots = new PATHS_TYPE_paths_v2_ela_snapshots(root);
    this.ela_vocab_words = new PATHS_TYPE_paths_v2_ela_vocab_words(root);
    this.ibl_assignments = new PATHS_TYPE_paths_v2_ibl_assignments(root);
    this.item_ownerships = new PATHS_TYPE_paths_v2_item_ownerships(root);
    this.items = new PATHS_TYPE_paths_v2_items(root);
    this.license_allocations = new PATHS_TYPE_paths_v2_license_allocations(root);
    this.licenses = new PATHS_TYPE_paths_v2_licenses(root);
    this.math = new PATHS_TYPE_paths_v2_math(root);
    this.math_adaptive_assignments = new PATHS_TYPE_paths_v2_math_adaptive_assignments(root);
    this.math_assessment_sessions = new PATHS_TYPE_paths_v2_math_assessment_sessions(root);
    this.math_assessments = new PATHS_TYPE_paths_v2_math_assessments(root);
    this.math_assignments = new PATHS_TYPE_paths_v2_math_assignments(root);
    this.math_fact_practice_snapshots = new PATHS_TYPE_paths_v2_math_fact_practice_snapshots(root);
    this.math_targeted_assignment_sessions = new PATHS_TYPE_paths_v2_math_targeted_assignment_sessions(root);
    this.math_targeted_assignments = new PATHS_TYPE_paths_v2_math_targeted_assignments(root);
    this.passwords = new PATHS_TYPE_paths_v2_passwords(root);
    this.periscope_embeds = new PATHS_TYPE_paths_v2_periscope_embeds(root);
    this.referrals = new PATHS_TYPE_paths_v2_referrals(root);
    this.school_admins = new PATHS_TYPE_paths_v2_school_admins(root);
    this.schools = new PATHS_TYPE_paths_v2_schools(root);
    this.science_assignments = new PATHS_TYPE_paths_v2_science_assignments(root);
    this.science_writing_assignment_answers = new PATHS_TYPE_paths_v2_science_writing_assignment_answers(root);
    this.social_studies_assignments = new PATHS_TYPE_paths_v2_social_studies_assignments(root);
    this.social_studies_writing_assignment_answers = new PATHS_TYPE_paths_v2_social_studies_writing_assignment_answers(root);
    this.students = new PATHS_TYPE_paths_v2_students(root);
    this.students_idp_overlays = new PATHS_TYPE_paths_v2_students_idp_overlays(root);
    this.teachers = new PATHS_TYPE_paths_v2_teachers(root);
  }
}

class PATHS_TYPE_paths_v2_educator_academy {
  user(): string { return this.root + '/2/educator-academy/user'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_console {
  agent(): string { return this.root + '/2/console/agent'; }
  backdoor(): string { return this.root + '/2/console/backdoor'; }
  impersonations(): string { return this.root + '/2/console/impersonations'; }
  jobs(): string { return this.root + '/2/console/jobs'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_admins {
  impersonations(): string { return this.root + '/2/admins/impersonations'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_math_assessments {
  _(a: number): string { return this.root + '/2/math-assessments/' + a.toString() + ''; }
  assessment_answers1(a: number): string { return this.root + '/2/math-assessments/' + a.toString() + '/assessment-answers'; }
  assessment_answers2(a: number, aa: number): string { return this.root + '/2/math-assessments/' + a.toString() + '/assessment-answers/' + aa.toString() + ''; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_math_assessment_sessions {
  assessment_answers(a: number): string { return this.root + '/2/math-assessment-sessions/' + a.toString() + '/assessment-answers'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_math_fact_practice_snapshots {
  latest(): string { return this.root + '/2/math-fact-practice-snapshots/latest'; }
  latest3(): string { return this.root + '/2/math-fact-practice-snapshots/latest3'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_students {
  _0(): string { return this.root + '/2/students'; }
  _1(a: number): string { return this.root + '/2/students/' + a.toString() + ''; }
  activity_feed(a: number): string { return this.root + '/2/students/' + a.toString() + '/activity-feed'; }
  archive(a: number, aa: number): string { return this.root + '/2/students/' + a.toString() + '/archive/' + aa.toString() + ''; }
  change_course(a: number): string { return this.root + '/2/students/' + a.toString() + '/change-course'; }
  co_teachers(a: Array<number>): string { return this.root + '/2/students/' + a.map(function(a1) { return a1.toString() }).join(',') + '/co-teachers'; }
  ela_report_card(a: number): string { return this.root + '/2/students/' + a.toString() + '/ela-report-card'; }
  ela_snapshots(a: number): string { return this.root + '/2/students/' + a.toString() + '/ela-snapshots'; }
  goals : PATHS_TYPE_paths_v2_students_goals;
  math_number_basics_snapshots_latest(a: number): string { return this.root + '/2/students/' + a.toString() + '/math/number-basics/snapshots/latest'; }
  me(): string { return this.root + '/2/students/me'; }
  me_ela_progress(): string { return this.root + '/2/students/me/ela-progress'; }
  me_ela_snapshots_latest(): string { return this.root + '/2/students/me/ela-snapshots/latest'; }
  me_incomplete_assignment_count(): string { return this.root + '/2/students/me/incomplete-assignment-count'; }
  me_link_renaissance(): string { return this.root + '/2/students/me/link-renaissance'; }
  me_login_events(): string { return this.root + '/2/students/me/login-events'; }
  me_math_adaptive_pretest_status(): string { return this.root + '/2/students/me/math-adaptive-pretest-status'; }
  me_math_adaptive_stats_student_domain_month_latest(): string { return this.root + '/2/students/me/math-adaptive-stats-student-domain-month/latest'; }
  me_progress_and_growth(): string { return this.root + '/2/students/me/progress-and-growth'; }
  me_time_spent(): string { return this.root + '/2/students/me/time-spent'; }
  sessions(): string { return this.root + '/2/students/sessions'; }
  standard_stats(a: number): string { return this.root + '/2/students/' + a.toString() + '/standard-stats'; }
  student_placements : PATHS_TYPE_paths_v2_students_student_placements;
  student_reports : PATHS_TYPE_paths_v2_students_student_reports;
  students_duplicates : PATHS_TYPE_paths_v2_students_students_duplicates;
  teachers(a: number): string { return this.root + '/2/students/' + a.toString() + '/teachers'; }
  word_study_sessions(a: number): string { return this.root + '/2/students/' + a.toString() + '/word-study-sessions'; }

  root: string;
  constructor(root: string) {
    this.root = root;
    this.goals = new PATHS_TYPE_paths_v2_students_goals(root);
    this.student_placements = new PATHS_TYPE_paths_v2_students_student_placements(root);
    this.student_reports = new PATHS_TYPE_paths_v2_students_student_reports(root);
    this.students_duplicates = new PATHS_TYPE_paths_v2_students_students_duplicates(root);
  }
}

class PATHS_TYPE_paths_v2_students_students_duplicates {
  _(): string { return this.root + '/2/students/duplicates'; }
  merge(): string { return this.root + '/2/students/duplicates/merge'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_students_goals {
  _(a: number): string { return this.root + '/2/students/' + a.toString() + '/goals'; }
  goal : PATHS_TYPE_paths_v2_students_goals_goal;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.goal = new PATHS_TYPE_paths_v2_students_goals_goal(root);
  }
}

class PATHS_TYPE_paths_v2_students_goals_goal {
  _(a: number, aa: number): string { return this.root + '/2/students/' + a.toString() + '/goals/' + aa.toString() + ''; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_students_student_reports {
  student_reports_ela : PATHS_TYPE_paths_v2_students_student_reports_student_reports_ela;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.student_reports_ela = new PATHS_TYPE_paths_v2_students_student_reports_student_reports_ela(root);
  }
}

class PATHS_TYPE_paths_v2_students_student_reports_student_reports_ela {
  student_reports_ela_standards : PATHS_TYPE_paths_v2_students_student_reports_student_reports_ela_student_reports_ela_standards;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.student_reports_ela_standards = new PATHS_TYPE_paths_v2_students_student_reports_student_reports_ela_student_reports_ela_standards(root);
  }
}

class PATHS_TYPE_paths_v2_students_student_reports_student_reports_ela_student_reports_ela_standards {
  student_reports_ela_standard : PATHS_TYPE_paths_v2_students_student_reports_student_reports_ela_student_reports_ela_standards_student_reports_ela_standard;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.student_reports_ela_standard = new PATHS_TYPE_paths_v2_students_student_reports_student_reports_ela_student_reports_ela_standards_student_reports_ela_standard(root);
  }
}

class PATHS_TYPE_paths_v2_students_student_reports_student_reports_ela_student_reports_ela_standards_student_reports_ela_standard {
  sessions(a: number, aa: string): string { return this.root + '/2/students/' + a.toString() + '/reports/ela/standards/' + aa + '/sessions'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_students_student_placements {
  student_placements_math : PATHS_TYPE_paths_v2_students_student_placements_student_placements_math;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.student_placements_math = new PATHS_TYPE_paths_v2_students_student_placements_student_placements_math(root);
  }
}

class PATHS_TYPE_paths_v2_students_student_placements_student_placements_math {
  _(a: number): string { return this.root + '/2/students/' + a.toString() + '/placements/math'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_students_idp_overlays {
  _0(): string { return this.root + '/2/student-idp-overlays'; }
  _1(a: number): string { return this.root + '/2/student-idp-overlays/' + a.toString() + ''; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_items {
  _(): string { return this.root + '/2/items'; }
  item : PATHS_TYPE_paths_v2_items_item;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.item = new PATHS_TYPE_paths_v2_items_item(root);
  }
}

class PATHS_TYPE_paths_v2_items_item {
  _(a: number): string { return this.root + '/2/items/' + a.toString() + ''; }
  item_ownership(a: number): string { return this.root + '/2/items/' + a.toString() + '/item-ownership'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_item_ownerships {
  _(): string { return this.root + '/2/item-ownerships'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_courses {
  _0(): string { return this.root + '/2/courses'; }
  _1(a: number): string { return this.root + '/2/courses/' + a.toString() + ''; }
  activity_feed(a: number): string { return this.root + '/2/courses/' + a.toString() + '/activity-feed'; }
  class_codes(a: string): string { return this.root + '/2/courses/class-codes/' + a + ''; }
  class_grouping_report_domain_growth(a: number): string { return this.root + '/2/courses/' + a.toString() + '/class-grouping-report/domain-growth'; }
  courses_reports_math : PATHS_TYPE_paths_v2_courses_courses_reports_math;
  current(): string { return this.root + '/2/courses/current'; }
  ela_class_activity1(a: number): string { return this.root + '/2/courses/' + a.toString() + '/ela-class-activity'; }
  ela_class_activity2(a: number, aa: number): string { return this.root + '/2/courses/' + a.toString() + '/ela-class-activity/' + aa.toString() + ''; }
  ela_reading_levels(a: number): string { return this.root + '/2/courses/' + a.toString() + '/ela-reading-levels'; }
  enabled_practice_modes(a: number): string { return this.root + '/2/courses/' + a.toString() + '/enabled-practice-modes'; }
  experts_ela_adaptive_skills(a: number): string { return this.root + '/2/courses/' + a.toString() + '/experts/ela-adaptive-skills'; }
  experts_math_adaptive(a: number): string { return this.root + '/2/courses/' + a.toString() + '/experts/math-adaptive'; }
  experts_word_study(a: number): string { return this.root + '/2/courses/' + a.toString() + '/experts/word-study'; }
  idp_student_merge(a: number): string { return this.root + '/2/courses/' + a.toString() + '/idp-student-merge'; }
  math_adaptive_stats_student_domain_month_latest(a: number): string { return this.root + '/2/courses/' + a.toString() + '/math-adaptive-stats-student-domain-month/latest'; }
  math_diagnostics(a: number): string { return this.root + '/2/courses/' + a.toString() + '/math-diagnostics'; }
  math_fact_practice_levels(a: number): string { return this.root + '/2/courses/' + a.toString() + '/math-fact-practice-levels'; }
  math_report_card_summaries(a: number): string { return this.root + '/2/courses/' + a.toString() + '/math-report-card-summaries'; }
  reports_ela_groups_by_level(a: number): string { return this.root + '/2/courses/' + a.toString() + '/reports/ela/groups-by-level'; }
  reports_ela_performance_by_standard(a: number): string { return this.root + '/2/courses/' + a.toString() + '/reports/ela/performance-by-standard'; }
  student_goals(a: number): string { return this.root + '/2/courses/' + a.toString() + '/student-goals'; }
  student_highlights(a: number): string { return this.root + '/2/courses/' + a.toString() + '/student-highlights'; }
  student_standard_stats(a: number): string { return this.root + '/2/courses/' + a.toString() + '/student-standard-stats'; }
  students(a: number): string { return this.root + '/2/courses/' + a.toString() + '/students'; }
  students_subjects_enabled_practice_modes(a: number, aa: number, aaa: string): string { return this.root + '/2/courses/' + a.toString() + '/students/' + aa.toString() + '/subjects/' + aaa + '/enabled-practice-modes'; }
  subjects(a: number): string { return this.root + '/2/courses/' + a.toString() + '/subjects'; }
  subjects_enabled_practice_modes(a: number, aa: string): string { return this.root + '/2/courses/' + a.toString() + '/subjects/' + aa + '/enabled-practice-modes'; }
  teacher(a: number): string { return this.root + '/2/courses/' + a.toString() + '/teacher'; }
  unmanaged(): string { return this.root + '/2/courses/unmanaged'; }
  word_study_levels_latest_per_student(a: number): string { return this.root + '/2/courses/' + a.toString() + '/word-study-levels/latest-per-student'; }

  root: string;
  constructor(root: string) {
    this.root = root;
    this.courses_reports_math = new PATHS_TYPE_paths_v2_courses_courses_reports_math(root);
  }
}

class PATHS_TYPE_paths_v2_courses_courses_reports_math {
  courses_reports_math_targeted : PATHS_TYPE_paths_v2_courses_courses_reports_math_courses_reports_math_targeted;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.courses_reports_math_targeted = new PATHS_TYPE_paths_v2_courses_courses_reports_math_courses_reports_math_targeted(root);
  }
}

class PATHS_TYPE_paths_v2_courses_courses_reports_math_courses_reports_math_targeted {
  courses_reports_math_targeted_assignments : PATHS_TYPE_paths_v2_courses_courses_reports_math_courses_reports_math_targeted_courses_reports_math_targeted_assignments;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.courses_reports_math_targeted_assignments = new PATHS_TYPE_paths_v2_courses_courses_reports_math_courses_reports_math_targeted_courses_reports_math_targeted_assignments(root);
  }
}

class PATHS_TYPE_paths_v2_courses_courses_reports_math_courses_reports_math_targeted_courses_reports_math_targeted_assignments {
  courses_reports_math_targeted_assignment : PATHS_TYPE_paths_v2_courses_courses_reports_math_courses_reports_math_targeted_courses_reports_math_targeted_assignments_courses_reports_math_targeted_assignment;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.courses_reports_math_targeted_assignment = new PATHS_TYPE_paths_v2_courses_courses_reports_math_courses_reports_math_targeted_courses_reports_math_targeted_assignments_courses_reports_math_targeted_assignment(root);
  }
}

class PATHS_TYPE_paths_v2_courses_courses_reports_math_courses_reports_math_targeted_courses_reports_math_targeted_assignments_courses_reports_math_targeted_assignment {
  question_performance(a: number, aa: number): string { return this.root + '/2/courses/' + a.toString() + '/reports/math/targeted/assignments/' + aa.toString() + '/question-performance'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_course_memberships {
  _0(): string { return this.root + '/2/course-memberships'; }
  _1(a: number): string { return this.root + '/2/course-memberships/' + a.toString() + ''; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_districts {
  _(): string { return this.root + '/2/districts'; }
  district : PATHS_TYPE_paths_v2_districts_district;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.district = new PATHS_TYPE_paths_v2_districts_district(root);
  }
}

class PATHS_TYPE_paths_v2_districts_district {
  _(a: number): string { return this.root + '/2/districts/' + a.toString() + ''; }
  district_identity_providers : PATHS_TYPE_paths_v2_districts_district_district_identity_providers;
  district_school_admins : PATHS_TYPE_paths_v2_districts_district_district_school_admins;
  district_students : PATHS_TYPE_paths_v2_districts_district_district_students;
  rosters : PATHS_TYPE_paths_v2_districts_district_rosters;
  schools(a: number): string { return this.root + '/2/districts/' + a.toString() + '/schools'; }

  root: string;
  constructor(root: string) {
    this.root = root;
    this.district_identity_providers = new PATHS_TYPE_paths_v2_districts_district_district_identity_providers(root);
    this.district_school_admins = new PATHS_TYPE_paths_v2_districts_district_district_school_admins(root);
    this.district_students = new PATHS_TYPE_paths_v2_districts_district_district_students(root);
    this.rosters = new PATHS_TYPE_paths_v2_districts_district_rosters(root);
  }
}

class PATHS_TYPE_paths_v2_districts_district_rosters {
  _(a: number): string { return this.root + '/2/districts/' + a.toString() + '/rosters'; }
  sis_id_sync(a: number): string { return this.root + '/2/districts/' + a.toString() + '/rosters/sis-id-sync'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_districts_district_district_school_admins {
  district_school_admin : PATHS_TYPE_paths_v2_districts_district_district_school_admins_district_school_admin;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.district_school_admin = new PATHS_TYPE_paths_v2_districts_district_district_school_admins_district_school_admin(root);
  }
}

class PATHS_TYPE_paths_v2_districts_district_district_school_admins_district_school_admin {
  school_memberships(a: number, aa: number): string { return this.root + '/2/districts/' + a.toString() + '/school-admins/' + aa.toString() + '/school-memberships'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_districts_district_district_students {
  district_students_placements : PATHS_TYPE_paths_v2_districts_district_district_students_district_students_placements;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.district_students_placements = new PATHS_TYPE_paths_v2_districts_district_district_students_district_students_placements(root);
  }
}

class PATHS_TYPE_paths_v2_districts_district_district_students_district_students_placements {
  district_students_placements_content_area : PATHS_TYPE_paths_v2_districts_district_district_students_district_students_placements_district_students_placements_content_area;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.district_students_placements_content_area = new PATHS_TYPE_paths_v2_districts_district_district_students_district_students_placements_district_students_placements_content_area(root);
  }
}

class PATHS_TYPE_paths_v2_districts_district_district_students_district_students_placements_district_students_placements_content_area {
  _(a: number, aa: string): string { return this.root + '/2/districts/' + a.toString() + '/students/placements/' + aa + ''; }
  fast_bridge(a: number, aa: string): string { return this.root + '/2/districts/' + a.toString() + '/students/placements/' + aa + '/fast-bridge'; }
  reading(a: number, aa: string): string { return this.root + '/2/districts/' + a.toString() + '/students/placements/' + aa + '/reading'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_districts_district_district_identity_providers {
  associations(a: number): string { return this.root + '/2/districts/' + a.toString() + '/identity-providers/associations'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_demo_districts {
  _(): string { return this.root + '/2/demo-districts'; }
  external(): string { return this.root + '/2/demo-districts/external'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_schools {
  _(): string { return this.root + '/2/schools'; }
  provision(): string { return this.root + '/2/schools/provision'; }
  reports_math_adaptive_grade_level(a: Array<number>): string { return this.root + '/2/schools/' + a.map(function(a1) { return a1.toString() }).join(',') + '/reports/math-adaptive-grade-level'; }
  school : PATHS_TYPE_paths_v2_schools_school;
  school_admins_school_memberships(a: Array<number>, aa: number): string { return this.root + '/2/schools/' + a.map(function(a1) { return a1.toString() }).join(',') + '/school-admins/' + aa.toString() + '/school-memberships'; }
  search(): string { return this.root + '/2/schools/search'; }

  root: string;
  constructor(root: string) {
    this.root = root;
    this.school = new PATHS_TYPE_paths_v2_schools_school(root);
  }
}

class PATHS_TYPE_paths_v2_schools_school {
  _(a: number): string { return this.root + '/2/schools/' + a.toString() + ''; }
  assessments(a: number): string { return this.root + '/2/schools/' + a.toString() + '/assessments'; }
  assessments2(a: number): string { return this.root + '/2/schools/' + a.toString() + '/assessments2'; }
  colleagues(a: number): string { return this.root + '/2/schools/' + a.toString() + '/colleagues'; }
  courses(a: number): string { return this.root + '/2/schools/' + a.toString() + '/courses'; }
  deletion_info(a: number): string { return this.root + '/2/schools/' + a.toString() + '/deletion-info'; }
  licenses(a: number): string { return this.root + '/2/schools/' + a.toString() + '/licenses'; }
  merge(a: number): string { return this.root + '/2/schools/' + a.toString() + '/merge'; }
  school_identity_providers : PATHS_TYPE_paths_v2_schools_school_school_identity_providers;
  school_math_assessments_assignment : PATHS_TYPE_paths_v2_schools_school_school_math_assessments_assignment;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.school_identity_providers = new PATHS_TYPE_paths_v2_schools_school_school_identity_providers(root);
    this.school_math_assessments_assignment = new PATHS_TYPE_paths_v2_schools_school_school_math_assessments_assignment(root);
  }
}

class PATHS_TYPE_paths_v2_schools_school_school_math_assessments_assignment {
  answers(a: number, aa: number): string { return this.root + '/2/schools/' + a.toString() + '/math/assessments/assignments/' + aa.toString() + '/answers'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_schools_school_school_identity_providers {
  associations(a: number): string { return this.root + '/2/schools/' + a.toString() + '/identity-providers/associations'; }
  sync(a: number): string { return this.root + '/2/schools/' + a.toString() + '/identity-providers/sync'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_licenses {
  _(): string { return this.root + '/2/licenses'; }
  license : PATHS_TYPE_paths_v2_licenses_license;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.license = new PATHS_TYPE_paths_v2_licenses_license(root);
  }
}

class PATHS_TYPE_paths_v2_licenses_license {
  _(a: number): string { return this.root + '/2/licenses/' + a.toString() + ''; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_license_allocations {
  license_allocation : PATHS_TYPE_paths_v2_license_allocations_license_allocation;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.license_allocation = new PATHS_TYPE_paths_v2_license_allocations_license_allocation(root);
  }
}

class PATHS_TYPE_paths_v2_license_allocations_license_allocation {
  _(a: number): string { return this.root + '/2/license-allocations/' + a.toString() + ''; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_teachers {
  _0(): string { return this.root + '/2/teachers'; }
  _1(a: number): string { return this.root + '/2/teachers/' + a.toString() + ''; }
  accounts(): string { return this.root + '/2/teachers/accounts'; }
  affiliation(a: number): string { return this.root + '/2/teachers/' + a.toString() + '/affiliation'; }
  affiliations(): string { return this.root + '/2/teachers/affiliations'; }
  courses_default(a: number): string { return this.root + '/2/teachers/' + a.toString() + '/courses/default'; }
  idp_clever(a: number): string { return this.root + '/2/teachers/' + a.toString() + '/idp/clever'; }
  impressions(a: number): string { return this.root + '/2/teachers/' + a.toString() + '/impressions'; }
  invite(): string { return this.root + '/2/teachers/invite'; }
  license_allocations(a: number): string { return this.root + '/2/teachers/' + a.toString() + '/license-allocations'; }
  me : PATHS_TYPE_paths_v2_teachers_me;
  reminders_finish_onboarding(a: number): string { return this.root + '/2/teachers/' + a.toString() + '/reminders/finish-onboarding'; }
  sessions(): string { return this.root + '/2/teachers/sessions'; }
  sessions_school_admin_impersonations(): string { return this.root + '/2/teachers/sessions/school-admin-impersonations'; }
  teacher_claimed_free_resources : PATHS_TYPE_paths_v2_teachers_teacher_claimed_free_resources;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.me = new PATHS_TYPE_paths_v2_teachers_me(root);
    this.teacher_claimed_free_resources = new PATHS_TYPE_paths_v2_teachers_teacher_claimed_free_resources(root);
  }
}

class PATHS_TYPE_paths_v2_teachers_me {
  _(): string { return this.root + '/2/teachers/me'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_teachers_teacher_claimed_free_resources {
  _(a: number): string { return this.root + '/2/teachers/' + a.toString() + '/claimed-free-resources'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_school_admins {
  _(): string { return this.root + '/2/school-admins'; }
  bulk_upload(): string { return this.root + '/2/school-admins/bulk-upload'; }
  forgot_password(): string { return this.root + '/2/school-admins/forgot-password'; }
  impression(a: number): string { return this.root + '/2/school-admins/' + a.toString() + '/impression'; }
  school_admin : PATHS_TYPE_paths_v2_school_admins_school_admin;
  school_admins_me : PATHS_TYPE_paths_v2_school_admins_school_admins_me;
  sessions(): string { return this.root + '/2/school-admins/sessions'; }
  teacher_sessions(): string { return this.root + '/2/school-admins/teacher-sessions'; }

  root: string;
  constructor(root: string) {
    this.root = root;
    this.school_admin = new PATHS_TYPE_paths_v2_school_admins_school_admin(root);
    this.school_admins_me = new PATHS_TYPE_paths_v2_school_admins_school_admins_me(root);
  }
}

class PATHS_TYPE_paths_v2_school_admins_school_admins_me {
  _(): string { return this.root + '/2/school-admins/me'; }
  dashboard(): string { return this.root + '/2/school-admins/me/dashboard'; }
  teachers(): string { return this.root + '/2/school-admins/me/teachers'; }
  teachers_missing_emails(): string { return this.root + '/2/school-admins/me/teachers-missing-emails'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_school_admins_school_admin {
  _(a: number): string { return this.root + '/2/school-admins/' + a.toString() + ''; }
  credentials(a: number): string { return this.root + '/2/school-admins/' + a.toString() + '/credentials'; }
  school_admin_rosters : PATHS_TYPE_paths_v2_school_admins_school_admin_school_admin_rosters;
  school_admin_verifications : PATHS_TYPE_paths_v2_school_admins_school_admin_school_admin_verifications;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.school_admin_rosters = new PATHS_TYPE_paths_v2_school_admins_school_admin_school_admin_rosters(root);
    this.school_admin_verifications = new PATHS_TYPE_paths_v2_school_admins_school_admin_school_admin_verifications(root);
  }
}

class PATHS_TYPE_paths_v2_school_admins_school_admin_school_admin_verifications {
  _(a: number, aa: string): string { return this.root + '/2/school-admins/' + a.toString() + '/verifications/' + aa + ''; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_school_admins_school_admin_school_admin_rosters {
  _(a: number): string { return this.root + '/2/school-admins/' + a.toString() + '/rosters'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_passwords {
  _(): string { return this.root + '/2/passwords'; }
  resets(): string { return this.root + '/2/passwords/resets'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_ela_vocab_words {
  _(a: Array<string>): string { return this.root + '/2/vocab-words/' + a.map(function(a1) { return a1 }).join(',') + ''; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_ela_assignments {
  _(): string { return this.root + '/2/ela-assignments'; }
  ela_adaptive_skills_assignments : PATHS_TYPE_paths_v2_ela_assignments_ela_adaptive_skills_assignments;
  ela_article_reading_assignments : PATHS_TYPE_paths_v2_ela_assignments_ela_article_reading_assignments;
  ela_article_writing_assignments : PATHS_TYPE_paths_v2_ela_assignments_ela_article_writing_assignments;
  ela_assessment_assignments : PATHS_TYPE_paths_v2_ela_assignments_ela_assessment_assignments;
  ela_decodables_assignments : PATHS_TYPE_paths_v2_ela_assignments_ela_decodables_assignments;
  ela_targeted_skills_assignments : PATHS_TYPE_paths_v2_ela_assignments_ela_targeted_skills_assignments;
  ela_word_study_assignments : PATHS_TYPE_paths_v2_ela_assignments_ela_word_study_assignments;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.ela_adaptive_skills_assignments = new PATHS_TYPE_paths_v2_ela_assignments_ela_adaptive_skills_assignments(root);
    this.ela_article_reading_assignments = new PATHS_TYPE_paths_v2_ela_assignments_ela_article_reading_assignments(root);
    this.ela_article_writing_assignments = new PATHS_TYPE_paths_v2_ela_assignments_ela_article_writing_assignments(root);
    this.ela_assessment_assignments = new PATHS_TYPE_paths_v2_ela_assignments_ela_assessment_assignments(root);
    this.ela_decodables_assignments = new PATHS_TYPE_paths_v2_ela_assignments_ela_decodables_assignments(root);
    this.ela_targeted_skills_assignments = new PATHS_TYPE_paths_v2_ela_assignments_ela_targeted_skills_assignments(root);
    this.ela_word_study_assignments = new PATHS_TYPE_paths_v2_ela_assignments_ela_word_study_assignments(root);
  }
}

class PATHS_TYPE_paths_v2_ela_assignments_ela_adaptive_skills_assignments {
  answers(a: number): string { return this.root + '/2/ela-assignments/adaptive-skills/' + a.toString() + '/answers'; }
  exit(a: Array<number>): string { return this.root + '/2/ela-assignments/adaptive-skills/' + a.map(function(a1) { return a1.toString() }).join(',') + '/exit'; }
  passages(a: number): string { return this.root + '/2/ela-assignments/adaptive-skills/' + a.toString() + '/passages'; }
  session(a: number): string { return this.root + '/2/ela-assignments/adaptive-skills/' + a.toString() + '/session'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_ela_assignments_ela_targeted_skills_assignments {
  ela_targeted_skills_assignment : PATHS_TYPE_paths_v2_ela_assignments_ela_targeted_skills_assignments_ela_targeted_skills_assignment;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.ela_targeted_skills_assignment = new PATHS_TYPE_paths_v2_ela_assignments_ela_targeted_skills_assignments_ela_targeted_skills_assignment(root);
  }
}

class PATHS_TYPE_paths_v2_ela_assignments_ela_targeted_skills_assignments_ela_targeted_skills_assignment {
  answers(a: number): string { return this.root + '/2/ela-assignments/targeted-skills/' + a.toString() + '/answers'; }
  exit(a: number): string { return this.root + '/2/ela-assignments/targeted-skills/' + a.toString() + '/exit'; }
  passages(a: number): string { return this.root + '/2/ela-assignments/targeted-skills/' + a.toString() + '/passages'; }
  sessions(a: number): string { return this.root + '/2/ela-assignments/targeted-skills/' + a.toString() + '/sessions'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_ela_assignments_ela_article_reading_assignments {
  ela_article_reading_assignment : PATHS_TYPE_paths_v2_ela_assignments_ela_article_reading_assignments_ela_article_reading_assignment;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.ela_article_reading_assignment = new PATHS_TYPE_paths_v2_ela_assignments_ela_article_reading_assignments_ela_article_reading_assignment(root);
  }
}

class PATHS_TYPE_paths_v2_ela_assignments_ela_article_reading_assignments_ela_article_reading_assignment {
  answers(a: Array<number>): string { return this.root + '/2/ela-assignments/reading/' + a.map(function(a1) { return a1.toString() }).join(',') + '/answers'; }
  answers_retries(a: Array<number>): string { return this.root + '/2/ela-assignments/reading/' + a.map(function(a1) { return a1.toString() }).join(',') + '/answers/retries'; }
  exit(a: Array<number>): string { return this.root + '/2/ela-assignments/reading/' + a.map(function(a1) { return a1.toString() }).join(',') + '/exit'; }
  sessions(a: Array<number>): string { return this.root + '/2/ela-assignments/reading/' + a.map(function(a1) { return a1.toString() }).join(',') + '/sessions'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_ela_assignments_ela_article_writing_assignments {
  ela_article_writing_assignment : PATHS_TYPE_paths_v2_ela_assignments_ela_article_writing_assignments_ela_article_writing_assignment;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.ela_article_writing_assignment = new PATHS_TYPE_paths_v2_ela_assignments_ela_article_writing_assignments_ela_article_writing_assignment(root);
  }
}

class PATHS_TYPE_paths_v2_ela_assignments_ela_article_writing_assignments_ela_article_writing_assignment {
  answers(a: Array<number>): string { return this.root + '/2/ela-assignments/writing/' + a.map(function(a1) { return a1.toString() }).join(',') + '/answers'; }
  sessions(a: Array<number>): string { return this.root + '/2/ela-assignments/writing/' + a.map(function(a1) { return a1.toString() }).join(',') + '/sessions'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_ela_assignments_ela_assessment_assignments {
  ela_assessment_assignment : PATHS_TYPE_paths_v2_ela_assignments_ela_assessment_assignments_ela_assessment_assignment;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.ela_assessment_assignment = new PATHS_TYPE_paths_v2_ela_assignments_ela_assessment_assignments_ela_assessment_assignment(root);
  }
}

class PATHS_TYPE_paths_v2_ela_assignments_ela_assessment_assignments_ela_assessment_assignment {
  answers(a: Array<number>): string { return this.root + '/2/ela-assignments/assessments/' + a.map(function(a1) { return a1.toString() }).join(',') + '/answers'; }
  ela_assessment_report(a: Array<number>): string { return this.root + '/2/ela-assignments/assessments/' + a.map(function(a1) { return a1.toString() }).join(',') + '/ela-assessment-report'; }
  sessions(a: Array<number>): string { return this.root + '/2/ela-assignments/assessments/' + a.map(function(a1) { return a1.toString() }).join(',') + '/sessions'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_ela_assignments_ela_decodables_assignments {
  _(): string { return this.root + '/2/ela-assignments/decodables'; }
  ela_decodables_assignment : PATHS_TYPE_paths_v2_ela_assignments_ela_decodables_assignments_ela_decodables_assignment;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.ela_decodables_assignment = new PATHS_TYPE_paths_v2_ela_assignments_ela_decodables_assignments_ela_decodables_assignment(root);
  }
}

class PATHS_TYPE_paths_v2_ela_assignments_ela_decodables_assignments_ela_decodables_assignment {
  answers(a: Array<number>): string { return this.root + '/2/ela-assignments/decodables/' + a.map(function(a1) { return a1.toString() }).join(',') + '/answers'; }
  sessions(a: Array<number>): string { return this.root + '/2/ela-assignments/decodables/' + a.map(function(a1) { return a1.toString() }).join(',') + '/sessions'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_ela_assignments_ela_word_study_assignments {
  _(a: number): string { return this.root + '/2/ela-assignments/word-study/' + a.toString() + ''; }
  ela_word_study_assignment : PATHS_TYPE_paths_v2_ela_assignments_ela_word_study_assignments_ela_word_study_assignment;
  ela_word_study_sessions : PATHS_TYPE_paths_v2_ela_assignments_ela_word_study_assignments_ela_word_study_sessions;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.ela_word_study_assignment = new PATHS_TYPE_paths_v2_ela_assignments_ela_word_study_assignments_ela_word_study_assignment(root);
    this.ela_word_study_sessions = new PATHS_TYPE_paths_v2_ela_assignments_ela_word_study_assignments_ela_word_study_sessions(root);
  }
}

class PATHS_TYPE_paths_v2_ela_assignments_ela_word_study_assignments_ela_word_study_sessions {
  progress(): string { return this.root + '/2/ela-assignments/word-study/sessions/progress'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_ela_assignments_ela_word_study_assignments_ela_word_study_assignment {
  sessions(a: Array<number>): string { return this.root + '/2/ela-assignments/word-study/' + a.map(function(a1) { return a1.toString() }).join(',') + '/sessions'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_ela_assessment_session_report {
  _(a: number): string { return this.root + '/2/ela-assessment-session-report/' + a.toString() + ''; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_ela_assignment_answers {
  ela_writing_assignment_answer : PATHS_TYPE_paths_v2_ela_assignment_answers_ela_writing_assignment_answer;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.ela_writing_assignment_answer = new PATHS_TYPE_paths_v2_ela_assignment_answers_ela_writing_assignment_answer(root);
  }
}

class PATHS_TYPE_paths_v2_ela_assignment_answers_ela_writing_assignment_answer {
  _(a: number): string { return this.root + '/2/ela-assignment-answers/writing/' + a.toString() + ''; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_ela_snapshots {
  _(): string { return this.root + '/2/ela-snapshots'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_ibl_assignments {
  completion(a: number): string { return this.root + '/2/ibl-assignments/' + a.toString() + '/completion'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_assignment_sessions {
  adaptive_answers(a: number): string { return this.root + '/2/assignment-sessions/adaptive/' + a.toString() + '/answers'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_math_assignments {
  _(): string { return this.root + '/2/math-assignments'; }
  assignment_sessions_is_any_session_complete(): string { return this.root + '/2/math-assignments/assignment-sessions/is-any-session-complete'; }
  math_adaptive_assignment : PATHS_TYPE_paths_v2_math_assignments_math_adaptive_assignment;
  math_assessment_assignments : PATHS_TYPE_paths_v2_math_assignments_math_assessment_assignments;
  math_fact_practice_assignments : PATHS_TYPE_paths_v2_math_assignments_math_fact_practice_assignments;
  math_ibl_assignments : PATHS_TYPE_paths_v2_math_assignments_math_ibl_assignments;
  math_standard_assignments : PATHS_TYPE_paths_v2_math_assignments_math_standard_assignments;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.math_adaptive_assignment = new PATHS_TYPE_paths_v2_math_assignments_math_adaptive_assignment(root);
    this.math_assessment_assignments = new PATHS_TYPE_paths_v2_math_assignments_math_assessment_assignments(root);
    this.math_fact_practice_assignments = new PATHS_TYPE_paths_v2_math_assignments_math_fact_practice_assignments(root);
    this.math_ibl_assignments = new PATHS_TYPE_paths_v2_math_assignments_math_ibl_assignments(root);
    this.math_standard_assignments = new PATHS_TYPE_paths_v2_math_assignments_math_standard_assignments(root);
  }
}

class PATHS_TYPE_paths_v2_math_assignments_math_adaptive_assignment {
  assignment_sessions(a: Array<number>): string { return this.root + '/2/math-assignments/adaptive/' + a.map(function(a1) { return a1.toString() }).join(',') + '/assignment-sessions'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_math_assignments_math_assessment_assignments {
  assignment_sessions(a: Array<number>): string { return this.root + '/2/math-assignments/assessment/' + a.map(function(a1) { return a1.toString() }).join(',') + '/assignment-sessions'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_math_assignments_math_fact_practice_assignments {
  assignment_sessions(a: Array<number>): string { return this.root + '/2/math-assignments/fact-practice/' + a.map(function(a1) { return a1.toString() }).join(',') + '/assignment-sessions'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_math_assignments_math_ibl_assignments {
  assignment_sessions(a: Array<number>): string { return this.root + '/2/math-assignments/ibl/' + a.map(function(a1) { return a1.toString() }).join(',') + '/assignment-sessions'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_math_assignments_math_standard_assignments {
  assignment_sessions(a: Array<number>): string { return this.root + '/2/math-assignments/standard/' + a.map(function(a1) { return a1.toString() }).join(',') + '/assignment-sessions'; }
  questions(a: Array<number>): string { return this.root + '/2/math-assignments/standard/' + a.map(function(a1) { return a1.toString() }).join(',') + '/questions'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_math_targeted_assignments {
  _(a: number): string { return this.root + '/2/math-targeted-assignments/' + a.toString() + ''; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_math_adaptive_assignments {
  _(a: number): string { return this.root + '/2/math-adaptive-assignments/' + a.toString() + ''; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_math_targeted_assignment_sessions {
  answers(a: number): string { return this.root + '/2/math-targeted-assignment-sessions/' + a.toString() + '/answers'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_referrals {
  _(): string { return this.root + '/2/referrals'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_math {
  number_facts : PATHS_TYPE_paths_v2_math_number_facts;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.number_facts = new PATHS_TYPE_paths_v2_math_number_facts(root);
  }
}

class PATHS_TYPE_paths_v2_math_number_facts {
  levels(): string { return this.root + '/2/math/number-facts/levels'; }
  number_facts_assignments : PATHS_TYPE_paths_v2_math_number_facts_number_facts_assignments;
  questions(): string { return this.root + '/2/math/number-facts/questions'; }
  sessions(): string { return this.root + '/2/math/number-facts/sessions'; }

  root: string;
  constructor(root: string) {
    this.root = root;
    this.number_facts_assignments = new PATHS_TYPE_paths_v2_math_number_facts_number_facts_assignments(root);
  }
}

class PATHS_TYPE_paths_v2_math_number_facts_number_facts_assignments {
  sessions(a: Array<number>): string { return this.root + '/2/math/number-facts/assignments/' + a.map(function(a1) { return a1.toString() }).join(',') + '/sessions'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_social_studies_assignments {
  _(): string { return this.root + '/2/social-studies-assignments'; }
  social_studies_reading_assignments : PATHS_TYPE_paths_v2_social_studies_assignments_social_studies_reading_assignments;
  social_studies_writing_assignments : PATHS_TYPE_paths_v2_social_studies_assignments_social_studies_writing_assignments;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.social_studies_reading_assignments = new PATHS_TYPE_paths_v2_social_studies_assignments_social_studies_reading_assignments(root);
    this.social_studies_writing_assignments = new PATHS_TYPE_paths_v2_social_studies_assignments_social_studies_writing_assignments(root);
  }
}

class PATHS_TYPE_paths_v2_social_studies_assignments_social_studies_reading_assignments {
  _(a: number): string { return this.root + '/2/social-studies-assignments/reading/' + a.toString() + ''; }
  social_studies_reading_assignment : PATHS_TYPE_paths_v2_social_studies_assignments_social_studies_reading_assignments_social_studies_reading_assignment;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.social_studies_reading_assignment = new PATHS_TYPE_paths_v2_social_studies_assignments_social_studies_reading_assignments_social_studies_reading_assignment(root);
  }
}

class PATHS_TYPE_paths_v2_social_studies_assignments_social_studies_reading_assignments_social_studies_reading_assignment {
  answers(a: Array<number>): string { return this.root + '/2/social-studies-assignments/reading/' + a.map(function(a1) { return a1.toString() }).join(',') + '/answers'; }
  answers_retries(a: Array<number>): string { return this.root + '/2/social-studies-assignments/reading/' + a.map(function(a1) { return a1.toString() }).join(',') + '/answers/retries'; }
  exit(a: Array<number>): string { return this.root + '/2/social-studies-assignments/reading/' + a.map(function(a1) { return a1.toString() }).join(',') + '/exit'; }
  sessions(a: Array<number>): string { return this.root + '/2/social-studies-assignments/reading/' + a.map(function(a1) { return a1.toString() }).join(',') + '/sessions'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_social_studies_assignments_social_studies_writing_assignments {
  _(a: number): string { return this.root + '/2/social-studies-assignments/writing/' + a.toString() + ''; }
  social_studies_writing_assignment : PATHS_TYPE_paths_v2_social_studies_assignments_social_studies_writing_assignments_social_studies_writing_assignment;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.social_studies_writing_assignment = new PATHS_TYPE_paths_v2_social_studies_assignments_social_studies_writing_assignments_social_studies_writing_assignment(root);
  }
}

class PATHS_TYPE_paths_v2_social_studies_assignments_social_studies_writing_assignments_social_studies_writing_assignment {
  answers(a: Array<number>): string { return this.root + '/2/social-studies-assignments/writing/' + a.map(function(a1) { return a1.toString() }).join(',') + '/answers'; }
  sessions(a: Array<number>): string { return this.root + '/2/social-studies-assignments/writing/' + a.map(function(a1) { return a1.toString() }).join(',') + '/sessions'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_social_studies_writing_assignment_answers {
  _(a: number): string { return this.root + '/2/social-studies-writing-assignments-answers/' + a.toString() + ''; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_science_assignments {
  _(): string { return this.root + '/2/science-assignments'; }
  science_reading_assignments : PATHS_TYPE_paths_v2_science_assignments_science_reading_assignments;
  science_writing_assignments : PATHS_TYPE_paths_v2_science_assignments_science_writing_assignments;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.science_reading_assignments = new PATHS_TYPE_paths_v2_science_assignments_science_reading_assignments(root);
    this.science_writing_assignments = new PATHS_TYPE_paths_v2_science_assignments_science_writing_assignments(root);
  }
}

class PATHS_TYPE_paths_v2_science_assignments_science_reading_assignments {
  _(a: number): string { return this.root + '/2/science-assignments/reading/' + a.toString() + ''; }
  science_reading_assignment : PATHS_TYPE_paths_v2_science_assignments_science_reading_assignments_science_reading_assignment;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.science_reading_assignment = new PATHS_TYPE_paths_v2_science_assignments_science_reading_assignments_science_reading_assignment(root);
  }
}

class PATHS_TYPE_paths_v2_science_assignments_science_reading_assignments_science_reading_assignment {
  answers(a: Array<number>): string { return this.root + '/2/science-assignments/reading/' + a.map(function(a1) { return a1.toString() }).join(',') + '/answers'; }
  answers_retries(a: Array<number>): string { return this.root + '/2/science-assignments/reading/' + a.map(function(a1) { return a1.toString() }).join(',') + '/answers/retries'; }
  exit(a: Array<number>): string { return this.root + '/2/science-assignments/reading/' + a.map(function(a1) { return a1.toString() }).join(',') + '/exit'; }
  sessions(a: Array<number>): string { return this.root + '/2/science-assignments/reading/' + a.map(function(a1) { return a1.toString() }).join(',') + '/sessions'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_science_assignments_science_writing_assignments {
  _(a: number): string { return this.root + '/2/science-assignments/writing/' + a.toString() + ''; }
  science_writing_assignment : PATHS_TYPE_paths_v2_science_assignments_science_writing_assignments_science_writing_assignment;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.science_writing_assignment = new PATHS_TYPE_paths_v2_science_assignments_science_writing_assignments_science_writing_assignment(root);
  }
}

class PATHS_TYPE_paths_v2_science_assignments_science_writing_assignments_science_writing_assignment {
  answers(a: Array<number>): string { return this.root + '/2/science-assignments/writing/' + a.map(function(a1) { return a1.toString() }).join(',') + '/answers'; }
  sessions(a: Array<number>): string { return this.root + '/2/science-assignments/writing/' + a.map(function(a1) { return a1.toString() }).join(',') + '/sessions'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_science_writing_assignment_answers {
  _(a: number): string { return this.root + '/2/science-writing-assignments-answers/' + a.toString() + ''; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_ela {
  article_search(): string { return this.root + '/2/ela/article-search'; }
  ela_adaptive_skills : PATHS_TYPE_paths_v2_ela_ela_adaptive_skills;
  ela_decodables : PATHS_TYPE_paths_v2_ela_ela_decodables;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.ela_adaptive_skills = new PATHS_TYPE_paths_v2_ela_ela_adaptive_skills(root);
    this.ela_decodables = new PATHS_TYPE_paths_v2_ela_ela_decodables(root);
  }
}

class PATHS_TYPE_paths_v2_ela_ela_adaptive_skills {
  contents(): string { return this.root + '/2/ela/adaptive-skills/contents'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_ela_ela_decodables {
  highest(): string { return this.root + '/2/ela/decodables/highest'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v2_periscope_embeds {
  districts_growth(): string { return this.root + '/2/periscope-embeds/districts/growth'; }
  school_usage(): string { return this.root + '/2/periscope-embeds/school-usage'; }
  schools_growth(a: number): string { return this.root + '/2/periscope-embeds/schools/' + a.toString() + '/growth'; }
  skill_details(a: string): string { return this.root + '/2/periscope-embeds/skill-details/' + a + ''; }
  students_growth(a: number): string { return this.root + '/2/periscope-embeds/students/' + a.toString() + '/growth'; }
  teachers_growth(a: number): string { return this.root + '/2/periscope-embeds/teachers/' + a.toString() + '/growth'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v3 {
  assignment_count : PATHS_TYPE_paths_v3_assignment_count;
  content_area : PATHS_TYPE_paths_v3_content_area;
  content_feedback(): string { return this.root + '/3/content-feedback'; }
  languages : PATHS_TYPE_paths_v3_languages;
  login_cards(): string { return this.root + '/3/login-cards'; }
  operations : PATHS_TYPE_paths_v3_operations;
  renaissance : PATHS_TYPE_paths_v3_renaissance;
  subjects : PATHS_TYPE_paths_v3_subjects;
  v3_courses : PATHS_TYPE_paths_v3_v3_courses;
  v3_schools : PATHS_TYPE_paths_v3_v3_schools;
  v3_students : PATHS_TYPE_paths_v3_v3_students;
  v3_teachers : PATHS_TYPE_paths_v3_v3_teachers;
  v3_webhooks : PATHS_TYPE_paths_v3_v3_webhooks;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.assignment_count = new PATHS_TYPE_paths_v3_assignment_count(root);
    this.content_area = new PATHS_TYPE_paths_v3_content_area(root);
    this.languages = new PATHS_TYPE_paths_v3_languages(root);
    this.operations = new PATHS_TYPE_paths_v3_operations(root);
    this.renaissance = new PATHS_TYPE_paths_v3_renaissance(root);
    this.subjects = new PATHS_TYPE_paths_v3_subjects(root);
    this.v3_courses = new PATHS_TYPE_paths_v3_v3_courses(root);
    this.v3_schools = new PATHS_TYPE_paths_v3_v3_schools(root);
    this.v3_students = new PATHS_TYPE_paths_v3_v3_students(root);
    this.v3_teachers = new PATHS_TYPE_paths_v3_v3_teachers(root);
    this.v3_webhooks = new PATHS_TYPE_paths_v3_v3_webhooks(root);
  }
}

class PATHS_TYPE_paths_v3_assignment_count {
  _(): string { return this.root + '/3/assignment-count'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v3_languages {
  _(): string { return this.root + '/3/languages'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v3_subjects {
  subject : PATHS_TYPE_paths_v3_subjects_subject;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.subject = new PATHS_TYPE_paths_v3_subjects_subject(root);
  }
}

class PATHS_TYPE_paths_v3_subjects_subject {
  academic_vocabulary(a: string): string { return this.root + '/3/subjects/' + a + '/academic-vocabulary'; }
  products : PATHS_TYPE_paths_v3_subjects_subject_products;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.products = new PATHS_TYPE_paths_v3_subjects_subject_products(root);
  }
}

class PATHS_TYPE_paths_v3_subjects_subject_products {
  product : PATHS_TYPE_paths_v3_subjects_subject_products_product;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.product = new PATHS_TYPE_paths_v3_subjects_subject_products_product(root);
  }
}

class PATHS_TYPE_paths_v3_subjects_subject_products_product {
  subject_product_assignments : PATHS_TYPE_paths_v3_subjects_subject_products_product_subject_product_assignments;
  subject_product_sessions : PATHS_TYPE_paths_v3_subjects_subject_products_product_subject_product_sessions;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.subject_product_assignments = new PATHS_TYPE_paths_v3_subjects_subject_products_product_subject_product_assignments(root);
    this.subject_product_sessions = new PATHS_TYPE_paths_v3_subjects_subject_products_product_subject_product_sessions(root);
  }
}

class PATHS_TYPE_paths_v3_subjects_subject_products_product_subject_product_assignments {
  subject_product_assignment : PATHS_TYPE_paths_v3_subjects_subject_products_product_subject_product_assignments_subject_product_assignment;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.subject_product_assignment = new PATHS_TYPE_paths_v3_subjects_subject_products_product_subject_product_assignments_subject_product_assignment(root);
  }
}

class PATHS_TYPE_paths_v3_subjects_subject_products_product_subject_product_assignments_subject_product_assignment {
  _(a: string, aa: string, aaa: number): string { return this.root + '/3/subjects/' + a + '/products/' + aa + '/assignments/' + aaa.toString() + ''; }
  passages(a: string, aa: string, aaa: number): string { return this.root + '/3/subjects/' + a + '/products/' + aa + '/assignments/' + aaa.toString() + '/passages'; }
  sessions(a: string, aa: string, aaa: number): string { return this.root + '/3/subjects/' + a + '/products/' + aa + '/assignments/' + aaa.toString() + '/sessions'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v3_subjects_subject_products_product_subject_product_sessions {
  _(a: string, aa: string): string { return this.root + '/3/subjects/' + a + '/products/' + aa + '/sessions'; }
  subject_product_session : PATHS_TYPE_paths_v3_subjects_subject_products_product_subject_product_sessions_subject_product_session;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.subject_product_session = new PATHS_TYPE_paths_v3_subjects_subject_products_product_subject_product_sessions_subject_product_session(root);
  }
}

class PATHS_TYPE_paths_v3_subjects_subject_products_product_subject_product_sessions_subject_product_session {
  _(a: string, aa: string, aaa: number): string { return this.root + '/3/subjects/' + a + '/products/' + aa + '/sessions/' + aaa.toString() + ''; }
  subject_product_session_answers : PATHS_TYPE_paths_v3_subjects_subject_products_product_subject_product_sessions_subject_product_session_subject_product_session_answers;
  subject_product_session_attempts : PATHS_TYPE_paths_v3_subjects_subject_products_product_subject_product_sessions_subject_product_session_subject_product_session_attempts;
  subject_product_session_sentiments : PATHS_TYPE_paths_v3_subjects_subject_products_product_subject_product_sessions_subject_product_session_subject_product_session_sentiments;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.subject_product_session_answers = new PATHS_TYPE_paths_v3_subjects_subject_products_product_subject_product_sessions_subject_product_session_subject_product_session_answers(root);
    this.subject_product_session_attempts = new PATHS_TYPE_paths_v3_subjects_subject_products_product_subject_product_sessions_subject_product_session_subject_product_session_attempts(root);
    this.subject_product_session_sentiments = new PATHS_TYPE_paths_v3_subjects_subject_products_product_subject_product_sessions_subject_product_session_subject_product_session_sentiments(root);
  }
}

class PATHS_TYPE_paths_v3_subjects_subject_products_product_subject_product_sessions_subject_product_session_subject_product_session_attempts {
  _(a: string, aa: string, aaa: number): string { return this.root + '/3/subjects/' + a + '/products/' + aa + '/sessions/' + aaa.toString() + '/attempts'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v3_subjects_subject_products_product_subject_product_sessions_subject_product_session_subject_product_session_answers {
  _(a: string, aa: string, aaa: number): string { return this.root + '/3/subjects/' + a + '/products/' + aa + '/sessions/' + aaa.toString() + '/answers'; }
  retries(a: string, aa: string, aaa: number): string { return this.root + '/3/subjects/' + a + '/products/' + aa + '/sessions/' + aaa.toString() + '/answers/retries'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v3_subjects_subject_products_product_subject_product_sessions_subject_product_session_subject_product_session_sentiments {
  subject_product_session_sentiment : PATHS_TYPE_paths_v3_subjects_subject_products_product_subject_product_sessions_subject_product_session_subject_product_session_sentiments_subject_product_session_sentiment;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.subject_product_session_sentiment = new PATHS_TYPE_paths_v3_subjects_subject_products_product_subject_product_sessions_subject_product_session_subject_product_session_sentiments_subject_product_session_sentiment(root);
  }
}

class PATHS_TYPE_paths_v3_subjects_subject_products_product_subject_product_sessions_subject_product_session_subject_product_session_sentiments_subject_product_session_sentiment {
  _(a: string, aa: string, aaa: number, aaaa: string): string { return this.root + '/3/subjects/' + a + '/products/' + aa + '/sessions/' + aaa.toString() + '/sentiments/' + aaaa + ''; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v3_v3_courses {
  _(): string { return this.root + '/3/courses'; }
  v3_course : PATHS_TYPE_paths_v3_v3_courses_v3_course;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.v3_course = new PATHS_TYPE_paths_v3_v3_courses_v3_course(root);
  }
}

class PATHS_TYPE_paths_v3_v3_courses_v3_course {
  course_ela_adaptive_skills : PATHS_TYPE_paths_v3_v3_courses_v3_course_course_ela_adaptive_skills;
  course_math_assessments : PATHS_TYPE_paths_v3_v3_courses_v3_course_course_math_assessments;
  course_subjects : PATHS_TYPE_paths_v3_v3_courses_v3_course_course_subjects;
  math_adaptive_domains_practiced(a: number): string { return this.root + '/3/courses/' + a.toString() + '/math-adaptive-domains-practiced'; }

  root: string;
  constructor(root: string) {
    this.root = root;
    this.course_ela_adaptive_skills = new PATHS_TYPE_paths_v3_v3_courses_v3_course_course_ela_adaptive_skills(root);
    this.course_math_assessments = new PATHS_TYPE_paths_v3_v3_courses_v3_course_course_math_assessments(root);
    this.course_subjects = new PATHS_TYPE_paths_v3_v3_courses_v3_course_course_subjects(root);
  }
}

class PATHS_TYPE_paths_v3_v3_courses_v3_course_course_subjects {
  course_subject : PATHS_TYPE_paths_v3_v3_courses_v3_course_course_subjects_course_subject;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.course_subject = new PATHS_TYPE_paths_v3_v3_courses_v3_course_course_subjects_course_subject(root);
  }
}

class PATHS_TYPE_paths_v3_v3_courses_v3_course_course_subjects_course_subject {
  course_products : PATHS_TYPE_paths_v3_v3_courses_v3_course_course_subjects_course_subject_course_products;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.course_products = new PATHS_TYPE_paths_v3_v3_courses_v3_course_course_subjects_course_subject_course_products(root);
  }
}

class PATHS_TYPE_paths_v3_v3_courses_v3_course_course_subjects_course_subject_course_products {
  course_product : PATHS_TYPE_paths_v3_v3_courses_v3_course_course_subjects_course_subject_course_products_course_product;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.course_product = new PATHS_TYPE_paths_v3_v3_courses_v3_course_course_subjects_course_subject_course_products_course_product(root);
  }
}

class PATHS_TYPE_paths_v3_v3_courses_v3_course_course_subjects_course_subject_course_products_course_product {
  course_subject_product_printable_assignments : PATHS_TYPE_paths_v3_v3_courses_v3_course_course_subjects_course_subject_course_products_course_product_course_subject_product_printable_assignments;
  course_subject_product_sessions : PATHS_TYPE_paths_v3_v3_courses_v3_course_course_subjects_course_subject_course_products_course_product_course_subject_product_sessions;
  course_subject_product_settings : PATHS_TYPE_paths_v3_v3_courses_v3_course_course_subjects_course_subject_course_products_course_product_course_subject_product_settings;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.course_subject_product_printable_assignments = new PATHS_TYPE_paths_v3_v3_courses_v3_course_course_subjects_course_subject_course_products_course_product_course_subject_product_printable_assignments(root);
    this.course_subject_product_sessions = new PATHS_TYPE_paths_v3_v3_courses_v3_course_course_subjects_course_subject_course_products_course_product_course_subject_product_sessions(root);
    this.course_subject_product_settings = new PATHS_TYPE_paths_v3_v3_courses_v3_course_course_subjects_course_subject_course_products_course_product_course_subject_product_settings(root);
  }
}

class PATHS_TYPE_paths_v3_v3_courses_v3_course_course_subjects_course_subject_course_products_course_product_course_subject_product_sessions {
  _(a: number, aa: string, aaa: string): string { return this.root + '/3/courses/' + a.toString() + '/subjects/' + aa + '/products/' + aaa + '/sessions'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v3_v3_courses_v3_course_course_subjects_course_subject_course_products_course_product_course_subject_product_settings {
  _(a: number, aa: string, aaa: string, aaaa: number): string { return this.root + '/3/courses/' + a.toString() + '/subjects/' + aa + '/products/' + aaa + '/settings/' + aaaa.toString() + ''; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v3_v3_courses_v3_course_course_subjects_course_subject_course_products_course_product_course_subject_product_printable_assignments {
  _(a: number, aa: string, aaa: string): string { return this.root + '/3/courses/' + a.toString() + '/subjects/' + aa + '/products/' + aaa + '/printable-assignments'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v3_v3_courses_v3_course_course_math_assessments {
  domain_growth_report(a: number): string { return this.root + '/3/courses/' + a.toString() + '/math-assessments/domain-growth-report'; }
  growth_report(a: number): string { return this.root + '/3/courses/' + a.toString() + '/math-assessments/growth-report'; }
  standard_growth_report(a: number): string { return this.root + '/3/courses/' + a.toString() + '/math-assessments/standard-growth-report'; }
  student_growth_report(a: number): string { return this.root + '/3/courses/' + a.toString() + '/math-assessments/student-growth-report'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v3_v3_courses_v3_course_course_ela_adaptive_skills {
  adaptive_progresses(a: number): string { return this.root + '/3/courses/' + a.toString() + '/ela-adaptive-skills/adaptive-progresses'; }
  skill_groups_report(a: number): string { return this.root + '/3/courses/' + a.toString() + '/ela-adaptive-skills/skill-groups-report'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v3_v3_schools {
  v3_school : PATHS_TYPE_paths_v3_v3_schools_v3_school;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.v3_school = new PATHS_TYPE_paths_v3_v3_schools_v3_school(root);
  }
}

class PATHS_TYPE_paths_v3_v3_schools_v3_school {
  school_math : PATHS_TYPE_paths_v3_v3_schools_v3_school_school_math;
  school_subjects : PATHS_TYPE_paths_v3_v3_schools_v3_school_school_subjects;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.school_math = new PATHS_TYPE_paths_v3_v3_schools_v3_school_school_math(root);
    this.school_subjects = new PATHS_TYPE_paths_v3_v3_schools_v3_school_school_subjects(root);
  }
}

class PATHS_TYPE_paths_v3_v3_schools_v3_school_school_math {
  standard_set(a: number): string { return this.root + '/3/schools/' + a.toString() + '/math/standard-set'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v3_v3_schools_v3_school_school_subjects {
  school_subject : PATHS_TYPE_paths_v3_v3_schools_v3_school_school_subjects_school_subject;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.school_subject = new PATHS_TYPE_paths_v3_v3_schools_v3_school_school_subjects_school_subject(root);
  }
}

class PATHS_TYPE_paths_v3_v3_schools_v3_school_school_subjects_school_subject {
  school_products : PATHS_TYPE_paths_v3_v3_schools_v3_school_school_subjects_school_subject_school_products;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.school_products = new PATHS_TYPE_paths_v3_v3_schools_v3_school_school_subjects_school_subject_school_products(root);
  }
}

class PATHS_TYPE_paths_v3_v3_schools_v3_school_school_subjects_school_subject_school_products {
  school_product : PATHS_TYPE_paths_v3_v3_schools_v3_school_school_subjects_school_subject_school_products_school_product;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.school_product = new PATHS_TYPE_paths_v3_v3_schools_v3_school_school_subjects_school_subject_school_products_school_product(root);
  }
}

class PATHS_TYPE_paths_v3_v3_schools_v3_school_school_subjects_school_subject_school_products_school_product {
  sessions(a: number, aa: string, aaa: string): string { return this.root + '/3/schools/' + a.toString() + '/subjects/' + aa + '/products/' + aaa + '/sessions'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v3_v3_students {
  _(): string { return this.root + '/3/students'; }
  impressions(): string { return this.root + '/3/students/impressions'; }
  presence_events(): string { return this.root + '/3/students/presence-events'; }
  v3_student : PATHS_TYPE_paths_v3_v3_students_v3_student;
  v3_students_duplicates : PATHS_TYPE_paths_v3_v3_students_v3_students_duplicates;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.v3_student = new PATHS_TYPE_paths_v3_v3_students_v3_student(root);
    this.v3_students_duplicates = new PATHS_TYPE_paths_v3_v3_students_v3_students_duplicates(root);
  }
}

class PATHS_TYPE_paths_v3_v3_students_v3_students_duplicates {
  merge(): string { return this.root + '/3/students/duplicates/merge'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v3_v3_students_v3_student {
  practice_summary(a: number): string { return this.root + '/3/students/' + a.toString() + '/practice-summary'; }
  student_math : PATHS_TYPE_paths_v3_v3_students_v3_student_student_math;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.student_math = new PATHS_TYPE_paths_v3_v3_students_v3_student_student_math(root);
  }
}

class PATHS_TYPE_paths_v3_v3_students_v3_student_student_math {
  domain_statuses(a: number): string { return this.root + '/3/students/' + a.toString() + '/math/domain-statuses'; }
  progress(a: number): string { return this.root + '/3/students/' + a.toString() + '/math/progress'; }
  report_card(a: number): string { return this.root + '/3/students/' + a.toString() + '/math/report-card'; }
  student_math_adaptive : PATHS_TYPE_paths_v3_v3_students_v3_student_student_math_student_math_adaptive;
  suggested_domain(a: number): string { return this.root + '/3/students/' + a.toString() + '/math/suggested-domain'; }

  root: string;
  constructor(root: string) {
    this.root = root;
    this.student_math_adaptive = new PATHS_TYPE_paths_v3_v3_students_v3_student_student_math_student_math_adaptive(root);
  }
}

class PATHS_TYPE_paths_v3_v3_students_v3_student_student_math_student_math_adaptive {
  student_math_adaptive_rewards : PATHS_TYPE_paths_v3_v3_students_v3_student_student_math_student_math_adaptive_student_math_adaptive_rewards;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.student_math_adaptive_rewards = new PATHS_TYPE_paths_v3_v3_students_v3_student_student_math_student_math_adaptive_student_math_adaptive_rewards(root);
  }
}

class PATHS_TYPE_paths_v3_v3_students_v3_student_student_math_student_math_adaptive_student_math_adaptive_rewards {
  _1(a: number): string { return this.root + '/3/students/' + a.toString() + '/math/adaptive/rewards'; }
  _2(a: number, aa: number): string { return this.root + '/3/students/' + a.toString() + '/math/adaptive/rewards/' + aa.toString() + ''; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v3_v3_teachers {
  _(): string { return this.root + '/3/teachers'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v3_content_area {
  content_area_adaptive : PATHS_TYPE_paths_v3_content_area_content_area_adaptive;
  content_area_adaptive_progresses : PATHS_TYPE_paths_v3_content_area_content_area_adaptive_progresses;
  content_area_assessment : PATHS_TYPE_paths_v3_content_area_content_area_assessment;
  content_area_constructed_responses : PATHS_TYPE_paths_v3_content_area_content_area_constructed_responses;
  content_area_depth_of_knowledge : PATHS_TYPE_paths_v3_content_area_content_area_depth_of_knowledge;
  content_area_diagnostic : PATHS_TYPE_paths_v3_content_area_content_area_diagnostic;
  content_area_domains : PATHS_TYPE_paths_v3_content_area_content_area_domains;
  content_area_ibls : PATHS_TYPE_paths_v3_content_area_content_area_ibls;
  content_area_questions : PATHS_TYPE_paths_v3_content_area_content_area_questions;
  content_area_skill_proficiencies : PATHS_TYPE_paths_v3_content_area_content_area_skill_proficiencies;
  content_area_skills : PATHS_TYPE_paths_v3_content_area_content_area_skills;
  standard_sets(a: string): string { return this.root + '/3/' + a + '/standard-sets'; }

  root: string;
  constructor(root: string) {
    this.root = root;
    this.content_area_adaptive = new PATHS_TYPE_paths_v3_content_area_content_area_adaptive(root);
    this.content_area_adaptive_progresses = new PATHS_TYPE_paths_v3_content_area_content_area_adaptive_progresses(root);
    this.content_area_assessment = new PATHS_TYPE_paths_v3_content_area_content_area_assessment(root);
    this.content_area_constructed_responses = new PATHS_TYPE_paths_v3_content_area_content_area_constructed_responses(root);
    this.content_area_depth_of_knowledge = new PATHS_TYPE_paths_v3_content_area_content_area_depth_of_knowledge(root);
    this.content_area_diagnostic = new PATHS_TYPE_paths_v3_content_area_content_area_diagnostic(root);
    this.content_area_domains = new PATHS_TYPE_paths_v3_content_area_content_area_domains(root);
    this.content_area_ibls = new PATHS_TYPE_paths_v3_content_area_content_area_ibls(root);
    this.content_area_questions = new PATHS_TYPE_paths_v3_content_area_content_area_questions(root);
    this.content_area_skill_proficiencies = new PATHS_TYPE_paths_v3_content_area_content_area_skill_proficiencies(root);
    this.content_area_skills = new PATHS_TYPE_paths_v3_content_area_content_area_skills(root);
  }
}

class PATHS_TYPE_paths_v3_content_area_content_area_adaptive {
  answers(a: string): string { return this.root + '/3/' + a + '/adaptive/answers'; }
  content_area_adaptive_questions : PATHS_TYPE_paths_v3_content_area_content_area_adaptive_content_area_adaptive_questions;
  content_area_adaptive_rewards : PATHS_TYPE_paths_v3_content_area_content_area_adaptive_content_area_adaptive_rewards;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.content_area_adaptive_questions = new PATHS_TYPE_paths_v3_content_area_content_area_adaptive_content_area_adaptive_questions(root);
    this.content_area_adaptive_rewards = new PATHS_TYPE_paths_v3_content_area_content_area_adaptive_content_area_adaptive_rewards(root);
  }
}

class PATHS_TYPE_paths_v3_content_area_content_area_adaptive_content_area_adaptive_questions {
  arbitrary(a: string): string { return this.root + '/3/' + a + '/adaptive/questions/arbitrary'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v3_content_area_content_area_adaptive_content_area_adaptive_rewards {
  _(a: string): string { return this.root + '/3/' + a + '/adaptive/rewards'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v3_content_area_content_area_adaptive_progresses {
  _(a: string): string { return this.root + '/3/' + a + '/adaptive-progresses'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v3_content_area_content_area_assessment {
  content_area_assessment_questions : PATHS_TYPE_paths_v3_content_area_content_area_assessment_content_area_assessment_questions;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.content_area_assessment_questions = new PATHS_TYPE_paths_v3_content_area_content_area_assessment_content_area_assessment_questions(root);
  }
}

class PATHS_TYPE_paths_v3_content_area_content_area_assessment_content_area_assessment_questions {
  arbitrary(a: string): string { return this.root + '/3/' + a + '/assessments/questions/arbitrary'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v3_content_area_content_area_constructed_responses {
  _(a: string): string { return this.root + '/3/' + a + '/constructed-responses'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v3_content_area_content_area_depth_of_knowledge {
  question_sets(a: string): string { return this.root + '/3/' + a + '/depth-of-knowledge/question-sets'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v3_content_area_content_area_diagnostic {
  content_area_diagnostic_questions : PATHS_TYPE_paths_v3_content_area_content_area_diagnostic_content_area_diagnostic_questions;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.content_area_diagnostic_questions = new PATHS_TYPE_paths_v3_content_area_content_area_diagnostic_content_area_diagnostic_questions(root);
  }
}

class PATHS_TYPE_paths_v3_content_area_content_area_diagnostic_content_area_diagnostic_questions {
  arbitrary(a: string): string { return this.root + '/3/' + a + '/diagnostic/questions/arbitrary'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v3_content_area_content_area_domains {
  content_area_domains_exclusions : PATHS_TYPE_paths_v3_content_area_content_area_domains_content_area_domains_exclusions;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.content_area_domains_exclusions = new PATHS_TYPE_paths_v3_content_area_content_area_domains_content_area_domains_exclusions(root);
  }
}

class PATHS_TYPE_paths_v3_content_area_content_area_domains_content_area_domains_exclusions {
  _(a: string): string { return this.root + '/3/' + a + '/domains/exclusions'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v3_content_area_content_area_ibls {
  _(a: string): string { return this.root + '/3/' + a + '/ibls'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v3_content_area_content_area_questions {
  arbitrary(a: string): string { return this.root + '/3/' + a + '/questions/arbitrary'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v3_content_area_content_area_skills {
  _(a: string): string { return this.root + '/3/' + a + '/skills'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v3_content_area_content_area_skill_proficiencies {
  _(a: string): string { return this.root + '/3/' + a + '/skill-proficiencies'; }
  progress(a: string): string { return this.root + '/3/' + a + '/skill-proficiencies/progress'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v3_operations {
  operation : PATHS_TYPE_paths_v3_operations_operation;

  root: string;
  constructor(root: string) {
    this.root = root;
    this.operation = new PATHS_TYPE_paths_v3_operations_operation(root);
  }
}

class PATHS_TYPE_paths_v3_operations_operation {
  _(a: number): string { return this.root + '/3/operations/' + a.toString() + ''; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v3_renaissance {
  home(): string { return this.root + '/3/renaissance/home'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}

class PATHS_TYPE_paths_v3_v3_webhooks {
  _(): string { return this.root + '/3/webhooks'; }
  recurly(): string { return this.root + '/3/webhooks/recurly'; }

  root: string;
  constructor(root: string) {
    this.root = root;
  }
}


var PATHS: PATHS_TYPE_paths = new PATHS_TYPE_paths('');

export default {   createPaths: function (root: string): PATHS_TYPE_paths {     return new PATHS_TYPE_paths(root);   }}