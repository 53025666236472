import * as React from 'react'
import {faArrowUpRightFromSquare} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useLocation} from 'react-router-dom'
import StorageHelper from '@freckle/educator-entities/ts/common/helpers/storage-helper'
import {SafeTrans} from '@freckle/educator-materials/ts/helpers/translate'
import {exhaustive} from '@freckle/exhaustive'
import {Button} from '@freckle/educator-materials/ts/components/button'
import {maybe, mthen} from '@freckle/maybe'
import {TeacherAttrs} from '@freckle/classroom/ts/users/models/teacher'
import {ExternalLinkWithConfirmation} from '@freckle/classroom/ts/common/components/external-link-with-confirmation'
import {resendEmailVerification} from '@freckle/classroom/ts/common/helpers/email'
import {Sidebar} from '@freckle/classroom/ts/common/components/sidebar'
import {NavbarHandler} from '@freckle/classroom/ts/common/components/navbar'
import {AuthenticatedFooter} from '@freckle/educator-materials/ts/components/footer/authenticated-footer'
import {useTeacher} from '@freckle/classroom/ts/hooks/use-teacher'
import {FGPBanner} from '@freckle/classroom/ts/math/common/components/content-overview/fgp-banner'
import {WithResources} from '@freckle/classroom/ts/common/components/with-resources'
import {useCourses} from '@freckle/educator-entities/ts/common/hooks/use-courses'
import {useCourseMemberships} from '@freckle/educator-entities/ts/common/hooks/use-course-memberships'
import {useStudents} from '@freckle/classroom/ts/hooks/use-students'
import {
  mainWrapper,
  mainWrapperNoOffset,
  contentWrapperGray,
  contentWrapper,
  externalLinkIcon,
  learnMoreIcon,
} from './dashboard.module.scss'

export type DisableHelperBarT = 'always' | 'print' | 'never'

export type DashboardTypeT = 'normal' | 'minimal'

type Props = {
  dashboardType?: DashboardTypeT
  fullScreen?: boolean
  children: React.ReactElement
}

export function Dashboard(props: Props): React.ReactElement {
  const {children, dashboardType: mDashboardType, fullScreen: mFullScreen} = props

  const route = useLocation()
  const mathFGPLocalStorageValue = StorageHelper.getLocalStorageProperty('mathFGPBanner')
  const isMathStandardsRoute = /^\/courses\/\d+\/math\/overview$/.test(route.pathname)
  const showMathFGPBanner =
    isMathStandardsRoute && mathFGPLocalStorageValue !== 3 && mathFGPLocalStorageValue !== 2
  const [mathBannerClosed, setMathBannerClosed] = React.useState(false)

  const dashboardType = mDashboardType ?? 'normal'
  const fullScreen = mFullScreen === true

  const courses = useCourses()
  const students = useStudents()
  const courseMemberships = useCourseMemberships()
  const teacherResource = useTeacher()
  const mTeacher = teacherResource.data

  if (fullScreen) {
    return children
  }

  const showMinimalDashboard = dashboardType === 'minimal'
  const isEmailVerified = maybe(
    () => true,
    ({emailVerified}) => emailVerified,
    mTeacher
  )
  const showUnverifiedEmailBanner = !isEmailVerified && !showMinimalDashboard
  const unverifiedEmailBanner = showUnverifiedEmailBanner
    ? mthen(mTeacher, teacher => <UnverifiedEmailBanner teacher={teacher} />)
    : null

  const sidebarNode = !showMinimalDashboard ? <Sidebar /> : null
  const wrapperClass = showMinimalDashboard
    ? `${mainWrapper} ${mainWrapperNoOffset}`
    : `${mainWrapper}`
  const minimalDashboardClass = showMinimalDashboard ? contentWrapperGray : contentWrapper

  return (
    <WithResources
      resources={{teacherResource, students, courses, courseMemberships}}
      render={({teacherResource, students, courses, courseMemberships}) => (
        <div>
          {unverifiedEmailBanner}
          <NavbarHandler />
          {sidebarNode}
          <div className={wrapperClass}>
            <div className={minimalDashboardClass}>
              {showMathFGPBanner && !mathBannerClosed && (
                <FGPBanner
                  teacher={teacherResource}
                  students={students}
                  courses={courses}
                  courseMemberships={courseMemberships}
                  hide={() => setMathBannerClosed(true)}
                />
              )}
              <DashboardContent dashboardType={dashboardType}>{children}</DashboardContent>
            </div>
          </div>
          <AuthenticatedFooter />
        </div>
      )}
    />
  )
}

function DashboardContent(props: {
  children: React.ReactElement
  dashboardType: DashboardTypeT
}): React.ReactElement {
  const {dashboardType, children} = props

  switch (dashboardType) {
    case 'minimal':
      return children
    case 'normal':
      return (
        <div className="container">
          <div className="row">
            <div id="content" className="col-12">
              {children}
            </div>
          </div>
        </div>
      )
    default:
      return exhaustive(dashboardType, 'DashboardTypeT')
  }
}

function UnverifiedEmailBanner({teacher}: {teacher: TeacherAttrs}): React.ReactElement {
  const onClickResendEmailVerification = (teacher: TeacherAttrs) =>
    resendEmailVerification(teacher.email)

  return (
    <>
      <div id="email-verification-alert" className="alert alert-warning fixed-alert hidden-print">
        <span>
          <SafeTrans i18nKey="DASHBOARD_UNVERIFIED_EMAIL_MESSAGE" />
        </span>
        &nbsp;
        <FontAwesomeIcon
          icon={faArrowUpRightFromSquare}
          className={externalLinkIcon}
          onClick={() => onClickResendEmailVerification(teacher)}
        />
        &nbsp;
        <SafeTrans
          i18nKey="DASHBOARD_UNVERIFIED_EMAIL_RESEND_BUTTON"
          components={{
            resendButton: (
              <Button style="link" onClick={() => onClickResendEmailVerification(teacher)} />
            ),
          }}
        />
        &nbsp;|&nbsp;
        <ExternalLinkWithConfirmation
          addClass={learnMoreIcon}
          target="_blank"
          href="https://help2.renaissance.com/freckle/205310677"
        >
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} className={externalLinkIcon} />
        </ExternalLinkWithConfirmation>
        &nbsp;
        <SafeTrans
          i18nKey="DASHBOARD_UNVERIFIED_EMAIL_LEARN_MORE_LINK"
          components={{
            learnMoreLink: (
              <ExternalLinkWithConfirmation
                target="_blank"
                href="https://help2.renaissance.com/freckle/205310677"
              />
            ),
          }}
        />
      </div>
      <div className="fixed-alert-spacer" />
    </>
  )
}
