import {
  ParserT,
  field,
  record,
  firstOf,
  merge,
  literal,
  onSelf,
  obfuscated,
  number,
} from '@freckle/parser'
import {
  validatePointsForLinearEquation,
  LinearEquationCoefficientsT,
} from '@freckle/educator-entities/ts/math/common/logic/equation'
import {LinearGraphAnswerT} from '@freckle/educator-entities/ts/math/common/logic/answer-types'
import {
  CommonMathGraphQuestionAttrs,
  parseCommonMathGraphQuestionAttrs,
} from '@freckle/educator-entities/ts/math/common/models/questions/common-parser'
import {MathQuestionMetadataAttrs} from '@freckle/educator-entities/ts/math/common/models/questions'

type MathQuestionGraphLinearExtraAttrs = {
  graphLinearEquationCoefficients: LinearEquationCoefficientsT
}

const rawGraphLinearEquationCoefficientsAttrs: ParserT<LinearEquationCoefficientsT> = record({
  coefficientX1: field(number(), 'coefficient-x-1'),
  coefficientX0: field(number(), 'coefficient-x-0'),
})

const parseGraphLinearEquationCoefficients: ParserT<MathQuestionGraphLinearExtraAttrs> = firstOf(
  record({
    graphLinearEquationCoefficients: field(
      rawGraphLinearEquationCoefficientsAttrs,
      'graph-linear-equation-coefficients'
    ),
  }),
  record({
    graphLinearEquationCoefficients: field(
      obfuscated(rawGraphLinearEquationCoefficientsAttrs),
      'obfuscated-graph-linear-equation-coefficients'
    ),
  })
)

export type MathQuestionGraphLinearMetadataAttrs = CommonMathGraphQuestionAttrs &
  MathQuestionGraphLinearExtraAttrs

const parseMathQuestionGraphLinearAttrs: ParserT<MathQuestionGraphLinearMetadataAttrs> = merge(
  parseCommonMathGraphQuestionAttrs,
  parseGraphLinearEquationCoefficients
)

export const parseMathQuestionGraphLinearMetadata: ParserT<MathQuestionMetadataAttrs> = record({
  answerType: field(literal('graph_linear'), 'answer-type'),
  content: onSelf(parseMathQuestionGraphLinearAttrs),
})

// Return :: Double (either 0 or 100)
export function getAnswerCorrectness(
  metadata: MathQuestionGraphLinearMetadataAttrs,
  answer: LinearGraphAnswerT
): number {
  const {graphLinearEquationCoefficients} = metadata

  const allCorrect = validatePointsForLinearEquation(answer.points, graphLinearEquationCoefficients)
  return allCorrect ? 100 : 0
}
