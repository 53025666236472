/* Preferred usage
 *
 * import {RlDomainStandardT} from './rl-domain-standard'
 * import * as RlDomainStandard from './rl-domain-standard'
 *
 * const rlDomainStandard = Parser.run(response, RlDomainStandard.parse)
 * const key = RlDomainStandard.toKey(rlDomainStandard)
 */

import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import {ParserT, array, record} from '@freckle/parser'
import * as RlDomainId from '@freckle/educator-entities/ts/common/types/rl-domain-id'
import * as RlStandardId from '@freckle/educator-entities/ts/common/types/rl-standard-id'
import {RlDomainT} from '@freckle/educator-entities/ts/common/models/rl-domain'
import {parseDomainAttrs} from '@freckle/educator-entities/ts/common/models/rl-domain'

import {RlStandardT} from '@freckle/educator-entities/ts/common/models/rl-standard'
import {parseStandardAttrs} from '@freckle/educator-entities/ts/common/models/rl-standard'

export type RlDomainStandardT = {
  domain: RlDomainT
  standards: Array<RlStandardT>
}

export const parse: ParserT<RlDomainStandardT> = record({
  domain: parseDomainAttrs,
  standards: array(parseStandardAttrs),
})

export function toKey(domainStandard: RlDomainStandardT): string {
  const {domain, standards} = domainStandard
  const domainKey = RlDomainId.toString(domain.id)
  const standardIds = sortBy(map(standards, standard => RlStandardId.toString(standard.id)))
  return standardIds.length === 0 ? domainKey : `${domainKey}-${standardIds.join('-')}`
}
