import * as React from 'react'
import {TranslateT} from '@freckle/educator-entities/ts/common/helpers/translate'
import {useSafeTranslation} from '@freckle/educator-materials/ts/helpers/translate'

import {Button} from '@freckle/educator-materials/ts/components/button'
import {PATHS} from '@freckle/educator-entities/ts/common/helpers/paths'
import {H3} from '@freckle/educator-materials/ts/components/typography'
import {exhaustive} from '@freckle/exhaustive'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimes} from '@fortawesome/free-solid-svg-icons'
import {PaywallNotificationTypeT} from '@freckle/classroom/ts/common/helpers/paywall-notification'

function getPaywallMessage(t: TranslateT, type: PaywallNotificationTypeT): React.ReactElement {
  switch (type) {
    case 'old-report':
      return <span>{t('PAYWALL_NOTIFICATION_OLD_REPORT_COPY')}</span>
    case 'print-without-answers':
      return <span>{t('PAYWALL_NOTIFICATION_PRINT_WIHOUT_ANSWERS_COPY')}</span>
    case 'multiple-assessments':
      return <span>{t('PAYWALL_NOTIFICATION_MULTIPLE_ASSESSMENTS_COPY')}</span>
    case 'max-courses':
      return <span>{t('PAYWALL_NOTIFICATION_MAX_COURSES_COPY')}</span>
    case 'assignment-scheduling':
      return <span>{t('PAYWALL_NOTIFICATION_ASSIGNMENT_SCHEDULING_COPY')}</span>
    case 'no-assignments-left-this-week':
      return <span>{t('PAYWALL_NOTIFICATION_NO_ASSIGNMENTSS_LEFT_COPY')}</span>

    default:
      return exhaustive(type, 'getPaywallMessage')
  }
}

type Props = {
  notificationType: PaywallNotificationTypeT
  removeNotification: () => void
  onCtaClicked: () => void
}

export default function PaywallNotification(props: Props): React.ReactElement {
  const {notificationType, removeNotification, onCtaClicked} = props
  const {t} = useSafeTranslation()

  const paywallMessage = getPaywallMessage(t, notificationType)

  const button = (
    <Button onClick={onCtaClicked} style="primary" addClass="paywall-notifications-action">
      {t('PAYWALL_NOTIFICATION_CTA_BUTTON_TEXT')}
    </Button>
  )

  return (
    <div className="paywall-notification-wrapper" data-test="paywall-notification">
      <span onClick={removeNotification}>
        <FontAwesomeIcon icon={faTimes} className="close-notification" />
      </span>
      <H3>{t('PAYWALL_NOTIFICATION_HEADER_TITLE')}</H3>
      <p className="paywall-message">{paywallMessage}</p>
      {button}

      <img
        className="paywall-notifications-jt"
        src={`${PATHS.imageAssetsUrl}/teacher-dashboard/winning-piggy.png?w=68&h=103`}
        alt=""
        title=""
      />
    </div>
  )
}
