import includes from 'lodash/includes'
import isEqual from 'lodash/isEqual'

import {ParserT, field, record, onSelf, literal} from '@freckle/parser'
import {mapMaybes} from '@freckle/maybe'
import {MAAnswerT} from '@freckle/educator-entities/ts/math/common/logic/answer-types'

import {
  MathQuestionMetadataAttrs,
  MathQuestionAttrs,
} from '@freckle/educator-entities/ts/math/common/models/questions'
import {
  MathQuestionRegularMetadataAttrs,
  parseMathQuestionRegularExtraAttrs,
} from './question-regular'

export type MathQuestionMultipleAnswersMetadataAttrs = MathQuestionRegularMetadataAttrs

const parseMathQuestionMultipleAnswersExtraAttrs = parseMathQuestionRegularExtraAttrs

export const parseMathQuestionMultipleAnswersMetadata: ParserT<MathQuestionMetadataAttrs> = record({
  answerType: field(literal('multiple_answers'), 'answer-type'),
  content: onSelf(parseMathQuestionMultipleAnswersExtraAttrs),
})

// Answer given by the student is always converted in an array
// answers :: [String]
// Return :: Number (eg 100 or 0)
export function getAnswerCorrectness(
  question: MathQuestionAttrs,
  metadata: MathQuestionMultipleAnswersMetadataAttrs,
  answersIndexes: MAAnswerT
): number {
  const {answers} = question
  const {correctAnswers} = metadata
  const correctAnswersIndexes = getCorrectAnswersIndexes(correctAnswers, answers)
  return isEqual(correctAnswersIndexes.sort(), answersIndexes.sort()) ? 100 : 0
}

// Get sorted Indexes of correct answers
// correctAnswers :: [String]
// answers :: [String]
// return :: [Number]
function getCorrectAnswersIndexes(
  correctAnswers: Array<string>,
  answers: Array<string>
): Array<number> {
  const studentCorrectAnswers = mapMaybes(answers, (ans, index) => {
    if (includes(correctAnswers, ans)) {
      return index
    } else {
      return null
    }
  })
  return [...studentCorrectAnswers].sort()
}
