import * as React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {exhaustive} from '@freckle/exhaustive'
import {addMaybeClassName} from '@freckle/educator-entities/ts/common/helpers/classnames'
import {IconDefinition} from '@fortawesome/free-solid-svg-icons'

import {
  badge,
  pill,
  leftIconContainer,
  defaultBadge,
  primary,
  success,
  danger,
  warning,
  info,
  tag,
  premium,
  prerequisite,
  mastered,
  practicing,
  notPracticed,
} from './badge.module.scss'

export type BadgeStyleT =
  | 'default'
  | 'primary'
  | 'success'
  | 'warning'
  | 'info'
  | 'danger'
  | 'tag'
  | 'premium'
  | 'prerequisite'
  | 'mastered'
  | 'practicing'
  | 'not_practiced'

type Props = {
  style?: BadgeStyleT
  children: React.ReactNode
  rounded?: boolean
  leftIcon?: IconDefinition
  addClass?: string
  dataTest?: string
}

export function Badge(props: Props): React.ReactElement {
  const {children, style = 'default', rounded = false, addClass, dataTest, leftIcon} = props

  const leftIconNode =
    leftIcon !== undefined ? (
      <span className={leftIconContainer}>
        <FontAwesomeIcon icon={leftIcon} />
      </span>
    ) : null

  const className = addMaybeClassName(getClassName(style, rounded), addClass)

  return (
    <div className={className} data-test={dataTest}>
      {leftIconNode}
      {children}
    </div>
  )
}

function getClassName(style: BadgeStyleT, rounded: boolean): string {
  switch (style) {
    case 'default':
      return addBaseClass(defaultBadge, rounded)
    case 'primary':
      return addBaseClass(primary, rounded)
    case 'success':
      return addBaseClass(success, rounded)
    case 'warning':
      return addBaseClass(warning, rounded)
    case 'info':
      return addBaseClass(info, rounded)
    case 'danger':
      return addBaseClass(danger, rounded)
    case 'tag':
      return addBaseClass(tag, rounded)
    case 'premium':
      return addBaseClass(premium, rounded)
    case 'prerequisite':
      return addBaseClass(prerequisite, rounded)
    case 'mastered':
      return addBaseClass(mastered, rounded)
    case 'practicing':
      return addBaseClass(practicing, rounded)
    case 'not_practiced':
      return addBaseClass(notPracticed, rounded)
    default:
      return exhaustive(style, `Invalid badge style ${style}`)
  }
}

function addBaseClass(className: string, rounded: boolean): string {
  return rounded ? `${pill} ${className}` : `${badge} ${className}`
}
