import map from 'lodash/map'
import snakeCase from 'lodash/snakeCase'
import isNil from 'lodash/isNil'
import {mmap} from '@freckle/maybe'
import {
  isStaging,
  isTestEnv,
  isLocalhost,
  isProductionEnv,
} from '@freckle/educator-entities/ts/common/helpers/common-api-helper'
import {Environments} from '@freckle/educator-entities/ts/common/helpers/environment'
import {TeacherRoles} from '@freckle/educator-entities/ts/common/helpers/teacher-role'
import {SchoolAttrs} from '@freckle/educator-entities/ts/roster/models/school'
import {DistrictAttrs} from '@freckle/educator-entities/ts/roster/models/district'
import {PremiumLicensesAttrs} from '@freckle/educator-entities/ts/common/models/premium-licenses'
import {
  isAnyPremium,
  isMathPremium,
  isElaPremium,
} from '@freckle/educator-entities/ts/common/logic/premium-access'
import {setupRenaissanceKeepaliveIfNecessary} from '@freckle/educator-entities/ts/common/helpers/renaissance-keepalive'
import {StudentAttrs} from '@freckle/educator-entities/ts/users/models/student'
import {CourseMembershipAttrs} from '@freckle/educator-entities/ts/roster/models/course-membership'
import {CourseAttrs} from '@freckle/educator-entities/ts/roster/models/course'
import {
  isManagedByClassLink,
  isManagedByClever,
  isManagedByRenaissance,
} from '@freckle/educator-entities/ts/roster/identity-provider'
import {TeacherAttrs} from '@freckle/classroom/ts/users/models/teacher'
import {getCookie} from '@freckle/classroom/ts/common/helpers/cookies'
import {displayNotification} from '@freckle/classroom/ts/common/helpers/helpers'
import {getBiggestCourse} from '@freckle/classroom/ts/roster/logic/courses'
import {Globals} from '@freckle/classroom/ts/common/models/globals'
import {
  setupAnalytics as setupAnalyticsTools,
  setTeacherProperties,
  setSchoolAndDistrictProperties,
} from '@freckle/classroom/ts/common/helpers/analytics'
import {schoolHasStarIntegration} from '@freckle/educator-entities/ts/roster/models/school'

function getStudentGrades(students: Array<StudentAttrs>): Set<number> {
  const grades = new Set(map(students, student => student.grade))
  return grades
}

export async function setupAnalytics(
  teacher: TeacherAttrs,
  students: Array<StudentAttrs>,
  courses: Array<CourseAttrs>,
  courseMemberships: Array<CourseMembershipAttrs>,
  schools: Array<SchoolAttrs>,
  districts: Array<DistrictAttrs>
): Promise<void> {
  if (Globals.hasAnalytics) {
    return Promise.resolve(undefined)
  }
  Globals.hasAnalytics = true

  // Needed for BugsnagClient
  Globals.userId = teacher.id

  await setupAnalyticsTools(teacher.id, teacher.email)

  const school = schools[0] ?? null
  const district = districts[0] ?? null
  const numOfStudents = students.length
  const defaultCourse = getBiggestCourse(courses, students, courseMemberships)

  const [environment, role] = [teacher.environment, teacher.role]
  const teacherProperties = {
    email: teacher.email,
    first_name: teacher.firstName,
    last_name: teacher.lastName,
    created_at: teacher.createdAt,
    number_students: numOfStudents,
    default_class_code: defaultCourse.code,
    teacher_is_managed_by_clever: isManagedByClever(teacher),
    teacher_is_managed_by_classlink: isManagedByClassLink(teacher),
    teacher_is_managed_by_renaissance: isManagedByRenaissance(teacher),
    teacher_environment: environment ? Environments.forDisplay(environment) : null,
    teacher_role: role ? TeacherRoles.forDisplay(role) : null,
    utm_source: getCookie('utm_source'),
    utm_medium: getCookie('utm_medium'),
    utm_campaign: getCookie('utm_campaign'),
  }
  const schoolProperties = school
    ? {
        school_name: school.name,
        school_id: school.id,
        school_state: school.administrativeArea ?? '',
        school_is_managed_by_clever: isManagedByClever(school),
        school_is_managed_by_classlink: isManagedByClassLink(school),
        school_active_idp: school.activeIdp,
      }
    : null

  const districtProperties = district
    ? {
        district_name: district.name,
        district_id: district.id,
      }
    : null

  await Promise.all([
    setTeacherProperties(teacher.id, teacherProperties),
    setSchoolAndDistrictProperties(teacher.id, schoolProperties, districtProperties),
  ])
}

export function initializePendo({
  teacher,
  districts,
  schools,
  premiumLicenses,
  students,
}: {
  teacher: TeacherAttrs
  districts: Array<DistrictAttrs>
  schools: Array<SchoolAttrs>
  premiumLicenses: PremiumLicensesAttrs
  students: Array<StudentAttrs>
}) {
  if (isStaging() || isTestEnv() || isLocalhost()) {
    return
  }
  const district = districts[0] ?? null
  const school = schools[0] ?? null
  const environment = teacher.environment
  const tagEnvironment = (value: string | number): string => {
    const env = isTestEnv() ? 'test-' : isProductionEnv() ? '' : 'dev-'
    return `${env}${value}`
  }

  type PendoVisitorIdT = {
    id: string | number
    type: 'global' | 'rgp' | 'local'
  }

  const getPrimaryVisitorIdData = (teacher: TeacherAttrs): PendoVisitorIdT => {
    if (teacher.gurId !== null && teacher.gurId !== '') {
      return {
        id: teacher.gurId,
        type: 'global',
      }
    } else if (
      teacher.renaissanceRPIdentifier !== null &&
      teacher.renaissanceRPIdentifier !== undefined
    ) {
      return {
        id: teacher.renaissanceRPIdentifier,
        type: 'rgp',
      }
    } else {
      return {
        id: `${teacher.id}-teacher`, //appending -teacher to differential teacher and student ids
        type: 'local',
      }
    }
  }

  // Renaissance Pendo instance initialization
  window.pendo.initialize({
    visitor: {
      // standard metadata
      id: tagEnvironment(getPrimaryVisitorIdData(teacher).id),
      lang_fr: teacher.language,
      role_fr: teacher.role,

      // basic data for Renaissance Pendo
      visitor_id_type: tagEnvironment(getPrimaryVisitorIdData(teacher).type),
      global_user_id: teacher.gurId,
      rgp_user_id: teacher.renaissanceRPIdentifier,
      local_user_id: teacher.id,
      user_type: teacher.role === 'parent' ? 'parent' : 'teacher', //user_type should be values for OneRoster role, outlined here: https://www.imsglobal.org/oneroster-v11-final-specification#_Toc480452025
      crm_account_number: school?.crmAccountNumber,

      // custom metadata
      app_fr: 'classroom',
      focus_mode_fr: teacher.focusMode,
      leaderboard_fr: teacher.leaderboard,
      environment_fr: !isNil(environment) ? Environments.forDisplay(environment) : null,
      has_class_link_id_fr: !isNil(teacher.classLinkTeacherId),
      has_clever_id_fr: !isNil(teacher.cleverTeacherId),
      is_managed_by_clever_fr: isManagedByClever(teacher),
      is_managed_by_classlink_fr: isManagedByClassLink(teacher),
      is_managed_by_renaissance_fr: isManagedByRenaissance(teacher),
      managed_by_idp_fr: isManagedByRenaissance(teacher)
        ? 'renaissance'
        : isManagedByClever(teacher)
        ? 'clever'
        : isManagedByClassLink(teacher)
        ? 'class_link'
        : '',
      course_subjects_fr: mmap(x => x.join(','), teacher.courseSubjects),
      onboarding_grades_fr: mmap(x => x.join(','), teacher.onboardingGrades),
      student_grades_fr: mmap(x => x.join(','), Array.from(getStudentGrades(students))),
      created_at_fr: teacher.createdAt.toISOString(),
      renaissance_id_fr: teacher.renaissanceRPIdentifier,
      //teacher has students with star reading placements
      has_students_with_star_reading_placements_fr: teacher.hasStarReadingPlacements,
      //teacher has students with star math placements
      has_students_with_star_math_placements_fr: teacher.hasStarMathPlacements,
      //whether teacher's school has star integration enabled
      has_school_with_star_enabled_fr: school ? schoolHasStarIntegration(school) : false,
      has_premium_math_fr: isMathPremium(premiumLicenses),
      has_premium_ela_fr: isElaPremium(premiumLicenses),
    },
    account: {
      // standard metadata
      id: district ? tagEnvironment(`${district.name}-district-${district.id}`) : null,
      name: district ? district.name : null,
      is_paying_fr: isAnyPremium(premiumLicenses),

      // custom metadata
      school_id_fr: school ? school.id : null,
      school_active_idp_fr: school?.activeIdp,
      district_id_fr: district ? district.id : null,
      administrative_area_fr: district ? district.administrativeArea : null,
      has_class_link_id_fr: !isNil(district?.classLinkDistrictId),
      has_clever_id_fr: !isNil(district?.cleverDistrictId),
    },
  })
}

// check that Pendo is loaded before calling it
function safePendoTrack(name: string, payload: Object) {
  if (window.pendo?.track !== undefined) {
    window.pendo.track(name, payload)
  } else {
    console.warn('Freckle Pendo instance is not loaded.')
  }
}

export function updatePendoAuthedSplitIOTreatments(split: string, treatment: string) {
  if (isStaging() || isTestEnv() || isLocalhost()) {
    return
  }
  if (window.pendo?.updateOptions !== undefined) {
    const visitor: {[s: string]: string} = {}
    visitor[`split_io_${snakeCase(split)}`] = treatment
    window.pendo.updateOptions({visitor})
  } else {
    console.warn('Unable to update Pendo with Split data. Pendo is not loaded.')
  }
}

type StudentPreTPAssignmentTracking = {
  key: 'preTPStudentRecommendationTracking'
  eventData: Object
}
export function pendoTrackPreTPStudentAssignment({key, eventData}: StudentPreTPAssignmentTracking) {
  safePendoTrack(key, eventData)
}

export function showErrorHandlerAndFail(routeStr: string, msg: string) {
  console.error(`${routeStr}: ${msg}`)
  displayNotification('error', msg)
  return
}

export function setupRenaissanceKeepalive(teacher: TeacherAttrs) {
  //setupRenaissanceKeepaliveIfNecessary is IIFE based.
  //Running multiple times will not create multiple intervals.
  setupRenaissanceKeepaliveIfNecessary(teacher.renaissanceKeepalive)
}
