import flatMap from 'lodash/flatMap'
import filter from 'lodash/filter'
import uniq from 'lodash/uniq'
import reduce from 'lodash/reduce'
import toPairs from 'lodash/toPairs'
import maxBy from 'lodash/maxBy'
import {ajaxJsonCall} from '@freckle/ajax'
import {getStudentIdsforCourse} from '@freckle/educator-entities/ts/users/logic/students'
import {maybe} from '@freckle/maybe'
import CApiHelper from '@freckle/educator-entities/ts/common/helpers/common-api-helper'
import StorageHelper from '@freckle/educator-entities/ts/common/helpers/storage-helper'
import {
  getFakeFirstName,
  getFakeLastName,
} from '@freckle/educator-entities/ts/common/helpers/mock-demo'
import {
  StudentAttrs,
  parseStudentAttrs,
  isStarLinkedGeneric,
} from '@freckle/educator-entities/ts/users/models/student'
import {exhaustive} from '@freckle/exhaustive'
import {CourseMembershipAttrs} from '@freckle/educator-entities/ts/roster/models/course-membership'
import {CourseAttrs} from '@freckle/educator-entities/ts/roster/models/course'
import {
  CoursesTagT,
  getActiveAndHiddenCourses,
  getActiveAndVisibleCourses,
  getArchivedCourses,
  getActiveCourses,
} from '@freckle/educator-entities/ts/roster/helpers/tagged-courses'
import {Parser} from '@freckle/parser'

function parseStudent(response: unknown): StudentAttrs {
  const parsedResponse: StudentAttrs = Parser.run(response, parseStudentAttrs)
  const demoMode = StorageHelper.getLocalStoragePropertyDefault('demoMode', false)
  const updatedResponse = {
    ...parsedResponse,
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    firstName: demoMode ? getFakeFirstName(parsedResponse.firstName) : parsedResponse.firstName,
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    lastName: demoMode ? getFakeLastName(parsedResponse.lastName) : parsedResponse.lastName,
  }
  return updatedResponse
}

function parseStudents(response: Array<unknown>): Array<StudentAttrs> {
  return response.map(parseStudent)
}

export function archiveForCourse(studentId: number, courseId: number): Promise<{deleted: boolean}> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.students.archive(studentId, courseId),
    method: 'POST',
  })
}

export async function fetchStudents(): Promise<Array<StudentAttrs>> {
  const response: unknown[] = await ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.students._0(),
    method: 'GET',
  })
  return parseStudents(response)
}

export function getStudentsForTaggedCourses(
  courseTag: CoursesTagT,
  courses: Array<CourseAttrs>,
  courseMemberships: Array<CourseMembershipAttrs>,
  students: Array<StudentAttrs>,
  excludeTeacherStudent: boolean = true
): Array<StudentAttrs> {
  function getStudents(filteredCourses: Array<CourseAttrs>): Array<StudentAttrs> {
    return flatMap(filteredCourses, ({id: courseId}) => {
      const filteredStudents = getStudentsForCourse(
        courseId,
        courseMemberships,
        students,
        excludeTeacherStudent
      )

      return filteredStudents
    })
  }

  switch (courseTag) {
    case 'all-courses':
      return getStudents(courses)
    case 'active-and-hidden':
      return getStudents(getActiveCourses(courses).courses)
    case 'active-and-visible':
      return getStudents(getActiveAndVisibleCourses(courses).courses)
    case 'archived-only':
      return getStudents(getArchivedCourses(courses).courses)
    case 'hidden-only':
      return getStudents(getActiveAndHiddenCourses(courses).courses)
    default:
      return exhaustive(courseTag, 'CoursesTagT')
  }
}

/**
 * Note that this will exclude the teacherStudent by default.
 */

export function getStudentsForCourse(
  courseId: number,
  courseMemberships: Array<CourseMembershipAttrs>,
  students: Array<StudentAttrs>,
  excludeTeacherStudent: boolean = true
): Array<StudentAttrs> {
  const studentIds = getStudentIdsforCourse(courseId, courseMemberships)

  const uniqueStudentIds = uniq(studentIds)
  const studentsInCourse = filter(students, s =>
    excludeTeacherStudent
      ? uniqueStudentIds.includes(s.id) && !s.isTeacher
      : uniqueStudentIds.includes(s.id)
  )

  return studentsInCourse
}

export function isConnectedToStar(student: StudentAttrs): boolean {
  return isStarLinkedGeneric(student)
}

// Given a set of students in a course, return the mode of the grade
export function calculateModalGrade(students: Array<StudentAttrs>): number | null | undefined {
  // Group each grade with its frequency
  const studentGradeFrequency = reduce(
    students,
    (acc, student) => {
      const grade = student.grade
      const count = acc[grade]
      return {
        ...acc,
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        [grade]: count !== null && count !== undefined ? count + 1 : 1,
      }
    },
    {} as {[v: number]: number}
  )

  // Convert into pairs in order to sort by frequency
  const gradeFrequencyPairs: Array<[string, number]> = toPairs(studentGradeFrequency)
  const modalGradePair = maxBy(gradeFrequencyPairs, ([_grade, freq]) => freq)

  // The grade is a string since it was put into an Object key. Parse it to an int if defined
  return maybe(
    () => null,
    ([grade, _freq]) => parseInt(grade, 10),
    modalGradePair
  )
}
