import {Moment} from 'moment-timezone'

import {NonEmptyArray} from '@freckle/non-empty'
import {
  ParserT,
  date,
  array,
  number,
  string,
  nullable,
  record,
  nonEmptyArray,
  tag,
  boolean,
  oneOf,
  RecordParserT,
} from '@freckle/parser'
import {RlDomainT} from '@freckle/educator-entities/ts/common/models/rl-domain'
import {parseDomainAttrs} from '@freckle/educator-entities/ts/common/models/rl-domain'
import {GenericAssignmentSessionMetadataT} from '@freckle/educator-entities/ts/common/models/generic-assignment-session'
import {FactPracticeOperationT} from '@freckle/educator-entities/ts/math/fact-practice/helpers/fact-practice-operation'
import {mathFactPracticeOperationTypeParser} from '@freckle/educator-entities/ts/math/fact-practice/models/math-fact-practice-operation'
import {parseFactPracticeSnapshot} from '@freckle/educator-entities/ts/math/fact-practice/models/math-fact-practice-snapshot'
import {FactPracticeSnapshotAttrs} from '@freckle/educator-entities/ts/math/fact-practice/models/math-fact-practice-snapshot'

import {RlSkillT} from './../../../common/models/rl-skill'
import {SessionTypeT} from './session-type'
import {
  RlStandardT,
  RlStandardWithDomainT,
  parseStandardWithDomainAttrs,
} from './../../../common/models/rl-standard'
import {parseStandardAttrs} from './../../../common/models/rl-standard'
import {parseRlSkillAttrs} from './../../../common/models/rl-skill'
import {MathAssessmentTopicT} from './../../../math/assessment/types/assessment-topic'
import * as MathAssessmentTopic from './../../../math/assessment/types/assessment-topic'

/**
 * Math Adaptive
 */

export type MathAdaptiveMetadataT = {
  domain: RlDomainT
  currentStandard: RlStandardT | undefined | null
  startsAt: Moment | undefined | null
}

export const parseMathAdaptiveMetadata = record<
  {
    tag: RecordParserT<'math_adaptive'>
    contents: RecordParserT<MathAdaptiveMetadataT>
  },
  GenericAssignmentSessionMetadataT
>({
  tag: tag('math_adaptive'),
  contents: record({
    startsAt: nullable(date()),
    domain: parseDomainAttrs,
    currentStandard: nullable(parseStandardAttrs),
  }),
})

/**
 * Math Targeted
 */

export type MathTargetedMetadataT = {
  standard: RlStandardWithDomainT
  skill: RlSkillT | undefined | null
  startsAt: Moment | undefined | null
  originalAssignmentId: number | undefined | null
  numQuestionsAnswered: number | undefined | null
  totalWorth: number | undefined | null
  completedWorth: number | undefined | null
  isSupported: boolean | undefined | null
  numQuestions: number
  type: SessionTypeT
}

const parseMathTargetedSessionType: ParserT<SessionTypeT> = oneOf(`SessionTypeT`, [
  'practice',
  'prerequisite',
  'review',
])

export const parseMathTargetedMetadata = record<
  {
    tag: RecordParserT<'math_targeted'>
    contents: RecordParserT<MathTargetedMetadataT>
  },
  GenericAssignmentSessionMetadataT
>({
  tag: tag('math_targeted'),
  contents: record({
    standard: parseStandardWithDomainAttrs,
    skill: nullable(parseRlSkillAttrs),
    startsAt: nullable(date()),
    originalAssignmentId: nullable(number()),
    numQuestionsAnswered: nullable(number()),
    totalWorth: nullable(number()),
    completedWorth: nullable(number()),
    isSupported: nullable(boolean()),
    numQuestions: number(),
    type: parseMathTargetedSessionType,
  }),
})

/**
 * Math Focus Skills Practice
 */

export type MathFocusSkillsPracticeMetadataT = {
  standard: RlStandardWithDomainT
  skill: RlSkillT | undefined | null
  startsAt: Moment | undefined | null
  originalAssignmentId: number | undefined | null
  numQuestionsAnswered: number | undefined | null
  totalWorth: number | undefined | null
  completedWorth: number | undefined | null
  isSupported: boolean | undefined | null
  numQuestions: number
  type: SessionTypeT
}

export const parseMathFocusSkillsPracticeMetadata = record<
  {
    tag: RecordParserT<'focus_skills_practice'>
    contents: RecordParserT<MathTargetedMetadataT>
  },
  GenericAssignmentSessionMetadataT
>({
  tag: tag('focus_skills_practice'),
  contents: record({
    standard: parseStandardWithDomainAttrs,
    skill: nullable(parseRlSkillAttrs),
    startsAt: nullable(date()),
    originalAssignmentId: nullable(number()),
    numQuestionsAnswered: nullable(number()),
    totalWorth: nullable(number()),
    completedWorth: nullable(number()),
    isSupported: nullable(boolean()),
    numQuestions: number(),
    type: parseMathTargetedSessionType,
  }),
})

/**
 * Math Assessments
 */

export type MathAssessmentMetadataT = {
  topic: MathAssessmentTopicT
  startsAt: Moment | undefined | null
}

const parseMathAssessmentsContents: ParserT<MathAssessmentMetadataT> = record({
  topic: MathAssessmentTopic.parse,
  startsAt: nullable(date()),
})

export const parseMathAssessmentsMetadata: ParserT<GenericAssignmentSessionMetadataT> = record({
  tag: tag('math_assessments'),
  contents: parseMathAssessmentsContents,
})

/**
 * Math Ibls
 */

export type MathIblMetadataT = {
  lessonId: number
  day: number
  title: string
  standard: RlStandardWithDomainT
}

export const parseMathIblsMetadata = record<
  {
    tag: RecordParserT<'math_ibls'>
    contents: RecordParserT<MathIblMetadataT>
  },
  GenericAssignmentSessionMetadataT
>({
  tag: tag('math_ibls'),
  contents: record({
    lessonId: number(),
    day: number(),
    title: string(),
    standard: parseStandardWithDomainAttrs,
  }),
})

/**
 * Math Number Facts
 */

export const parseMathNumberFactsMetadata = record<
  {
    tag: RecordParserT<'math_number_facts'>
  },
  GenericAssignmentSessionMetadataT
>({tag: tag('math_number_facts')})

/**
 * Math Number Basics
 */

export type MathNumberBasicsMetadataT = {
  skillCode: string
}

export const parseMathNumberBasicsMetadata = record<
  {
    tag: RecordParserT<'math_number_basics'>
    contents: RecordParserT<MathNumberBasicsMetadataT>
  },
  GenericAssignmentSessionMetadataT
>({
  tag: tag('math_number_basics'),
  contents: record({skillCode: string()}),
})

/**
 * Math Fact Practice
 */

type MathFactPracticeOperationSnapshotMetadataT = {
  operation: FactPracticeOperationT
  snapshot: FactPracticeSnapshotAttrs | undefined | null
}

export type MathFactPracticeMetadataT = {
  operations: NonEmptyArray<FactPracticeOperationT>
  snapshots: Array<MathFactPracticeOperationSnapshotMetadataT>
  startsAt: Moment | undefined | null
}

export const parseMathFactPracticeMetadata = record<
  {
    tag: RecordParserT<'math_fact_practice'>
    contents: RecordParserT<MathFactPracticeMetadataT>
  },
  GenericAssignmentSessionMetadataT
>({
  tag: tag('math_fact_practice'),
  contents: record({
    operations: nonEmptyArray(mathFactPracticeOperationTypeParser),
    snapshots: array(
      record({
        operation: mathFactPracticeOperationTypeParser,
        snapshot: nullable(parseFactPracticeSnapshot),
      })
    ),
    startsAt: nullable(date()),
  }),
})

/**
 * Math Depth of Knowledge
 */

export type MathDepthOfKnowledgeMetadataT = {
  questionSetUuid: string
}

export const parseMathDepthOfKnowledgeMetadata = record<
  {
    tag: RecordParserT<'math_depth_of_knowledge_practice'>
    contents: RecordParserT<MathDepthOfKnowledgeMetadataT>
  },
  GenericAssignmentSessionMetadataT
>({
  tag: tag('math_depth_of_knowledge_practice'),
  contents: record({questionSetUuid: string()}),
})
/**
 * Math Targeted Depth of Knowledge
 */

export type MathTargetedDepthOfKnowledgeMetadataT = {
  questionSetUuid: string
  standard: RlStandardWithDomainT
}

export const parseMathTargetedDepthOfKnowledgeMetadata = record<
  {
    tag: RecordParserT<'math_targeted_depth_of_knowledge_practice'>
    contents: RecordParserT<MathTargetedDepthOfKnowledgeMetadataT>
  },
  GenericAssignmentSessionMetadataT
>({
  tag: tag('math_targeted_depth_of_knowledge_practice'),
  contents: record({
    questionSetUuid: string(),
    standard: parseStandardWithDomainAttrs,
  }),
})
