import StorageHelper from '@freckle/educator-entities/ts/common/helpers/storage-helper'
import {fromMaybe} from '@freckle/maybe'

export type LoginTypeT = 'email' | 'google'

const emailLoginType: LoginTypeT = 'email'
const googleLoginType: LoginTypeT = 'google'

const LoginHelper = {
  emailLoginType,

  setEmailLogin: (email: string | null | undefined) => {
    LoginHelper.setLoginType(emailLoginType)
    const strEmail = fromMaybe(() => '', email)
    LoginHelper.setLoginEmail(strEmail)
  },

  googleLoginType,

  setGoogleLogin: () => {
    LoginHelper.setLoginType(googleLoginType)
  },

  loginTypeKey: 'login-type',

  getLoginType: (): LoginTypeT | null | undefined => {
    return StorageHelper.getLocalStorageProperty(LoginHelper.loginTypeKey)
  },

  setLoginType: (type: LoginTypeT) => {
    StorageHelper.setLocalStorageProperty(LoginHelper.loginTypeKey, type)
  },

  clearLoginType: () => {
    StorageHelper.clearLocalStorageProperty(LoginHelper.loginTypeKey)
  },

  loginEmailKey: 'login-email',

  getLoginEmail: (): string | null | undefined => {
    return StorageHelper.getLocalStorageProperty(LoginHelper.loginEmailKey)
  },

  setLoginEmail: (email: string) => {
    StorageHelper.setLocalStorageProperty(LoginHelper.loginEmailKey, email)
  },

  clearLoginEmail: () => {
    StorageHelper.clearLocalStorageProperty(LoginHelper.loginEmailKey)
  },

  googleSignupKey: 'new-google-signup',

  setIsNewGoogleSignup: () => {
    StorageHelper.setLocalStorageProperty(LoginHelper.googleSignupKey, true)
  },

  getIsNewGoogleSignup: () => {
    return StorageHelper.getLocalStorageProperty(LoginHelper.googleSignupKey)
  },

  clearIsNewGoogleSignup: () => {
    StorageHelper.clearLocalStorageProperty(LoginHelper.googleSignupKey)
  },
}

export default LoginHelper
