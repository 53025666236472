/* IE Polyfill for Custom Event
 *
 * IE doesn't support the `Event` constructor, so we are using `CustomEvent` API.
 * This file create the Polyfill for us to use the CustomEvent constructor across
 * all browsers
 *
 * Source
 * https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent
 */

;(function () {
  if (typeof window.CustomEvent === 'function') {
    return false
  }

  function CustomEvent<T>(
    event: string,
    {
      bubbles = false,
      cancelable = false,
      detail,
    }: {bubbles: boolean; cancelable: boolean; detail: T}
  ): CustomEvent<T> {
    const evt = document.createEvent('CustomEvent')
    evt.initCustomEvent(event, bubbles, cancelable, detail)
    return evt
  }

  CustomEvent.prototype = window.Event.prototype

  //@ts-ignore: TS doesn't allow adding to `window`
  window.CustomEvent = CustomEvent
})()

export {}
