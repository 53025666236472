import * as React from 'react'
import {addMaybeClassName} from '@freckle/educator-entities/ts/common/helpers/classnames'
import {innerHTMLText} from '@freckle/educator-entities/ts/common/models/html-text'

import {HeadingProps} from '@freckle/educator-materials/ts/components/typography/heading-props'
import {h1} from './typography.module.scss'

type Props = HeadingProps

export function H1(props: Props): React.ReactElement {
  const {children, addClass, dataTest = 'page-title', id} = props
  const className = addMaybeClassName(h1, addClass)
  const tagToUse = props.as ?? 'h1'
  const node = React.createElement(
    tagToUse,
    {
      className,
      'data-test': dataTest,
      id,
      ...(props.innerHTMLText !== undefined ? innerHTMLText(props.innerHTMLText) : {}),
    },
    children
  )

  return node
}
