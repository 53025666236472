import {ParserT, Parser, record, merge, string} from '@freckle/parser'

import {
  WordStudyQuestionCommonAttrs,
  parseWordStudyQuestionCommonAttrs,
} from './../models/word-study-question'

type WordStudyInputQuestionCorrectAnswer = {
  value: string
}

type WordStudyInputQuestionExtraAttrs = {
  correctAnswers: WordStudyInputQuestionCorrectAnswer
}

export type WordStudyInputQuestionAttrs = WordStudyQuestionCommonAttrs &
  WordStudyInputQuestionExtraAttrs

const parseWordStudyInputQuestionExtraAttrs: ParserT<WordStudyInputQuestionExtraAttrs> = record({
  correctAnswers: record({value: string()}),
})

const parseWordStudyInputQuestionAttrs: ParserT<WordStudyInputQuestionAttrs> = merge(
  parseWordStudyInputQuestionExtraAttrs,
  parseWordStudyQuestionCommonAttrs
)

export const parseWordStudyInputQuestion = Parser.mkRun<WordStudyInputQuestionAttrs>(
  parseWordStudyInputQuestionAttrs
)
