import {Moment} from 'moment-timezone'
import {ParserT, record, string, nullable, date, number} from '@freckle/parser'

export type ArticleMetadataT = {
  articleUuid: string
  articleLevelId: string
  startsAt: Moment | undefined | null
}

export const parseArticleContents: ParserT<ArticleMetadataT> = record({
  articleUuid: string(),
  articleLevelId: string(),
  startsAt: nullable(date()),
})

export type ResumableArticleMetadataT = {
  coinsEarned: number
} & ArticleMetadataT

export const parseResumableArticleContents: ParserT<ResumableArticleMetadataT> = record({
  articleUuid: string(),
  articleLevelId: string(),
  startsAt: nullable(date()),
  coinsEarned: number(),
})
