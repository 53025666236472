import {UseQueryOptions, UseQueryResult} from '@tanstack/react-query'
import {useAsync} from '@freckle/educator-entities/ts/common/hooks/use-async'
import {CourseAttrs, fetchCourses} from '@freckle/educator-entities/ts/roster/models/course'

export function useCourses(
  options?: Partial<UseQueryOptions<CourseAttrs[]>>
): UseQueryResult<CourseAttrs[]> {
  const defaultOptions = {staleTime: 60 * 1000}
  return useAsync(fetchCourses, ['fetchCourses'], {...defaultOptions, ...options})
}
