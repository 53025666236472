import * as React from 'react'
import {SyntheticEvent} from 'react'
import {SafeTrans} from '@freckle/educator-materials/ts/helpers/translate'
import {Button} from '@freckle/educator-materials/ts/components/button'
import {useModal} from '@freckle/classroom/ts/hooks/use-modal'

import {QuoteRequestModal} from './quote-request-modal'

type Props = {
  linkText?: string
}

export function QuoteRequestModalLink(props: Props): React.ReactElement {
  const modal = useModal()

  function openAndAction(event: SyntheticEvent<EventTarget, Event>) {
    event.preventDefault()
    modal.show()
  }

  const {linkText} = props
  const defaultedLinkText =
    linkText === undefined ? (
      <SafeTrans i18nKey="QUOTE_REQUEST_MODAL_LINK_REQUEST_QUOTE_LINK" />
    ) : (
      <>{linkText}</>
    )
  return (
    <>
      <Button style="link" onClick={openAndAction}>
        <strong>{defaultedLinkText}</strong>
      </Button>
      <QuoteRequestModal {...modal.props} />
    </>
  )
}
