import {
  ParserT,
  field,
  record,
  firstOf,
  merge,
  literal,
  number,
  onSelf,
  obfuscated,
} from '@freckle/parser'
import {
  validatePointsForExponentialEquation,
  ExponentialEquationCoefficientsT,
} from '@freckle/educator-entities/ts/math/common/logic/equation'
import {ExponentialGraphAnswerT} from '@freckle/educator-entities/ts/math/common/logic/answer-types'
import {
  CommonMathGraphQuestionAttrs,
  parseCommonMathGraphQuestionAttrs,
} from '@freckle/educator-entities/ts/math/common/models/questions/common-parser'
import {MathQuestionMetadataAttrs} from '@freckle/educator-entities/ts/math/common/models/questions'

type MathQuestionGraphExponentialExtraAttrs = {
  graphExponentialEquationCoefficients: ExponentialEquationCoefficientsT
}

const rawGraphExponentialEquationCoefficientsAttrs: ParserT<ExponentialEquationCoefficientsT> =
  record({
    coefficientA: field(number(), 'coefficient-a'),
    coefficientB: field(number(), 'coefficient-b'),
    coefficientC: field(number(), 'coefficient-c'),
  })

const parseGraphExponentialEquationCoefficients: ParserT<MathQuestionGraphExponentialExtraAttrs> =
  firstOf(
    record({
      graphExponentialEquationCoefficients: field(
        rawGraphExponentialEquationCoefficientsAttrs,
        'graph-exponential-equation-coefficients'
      ),
    }),
    record({
      graphExponentialEquationCoefficients: field(
        obfuscated(rawGraphExponentialEquationCoefficientsAttrs),
        'obfuscated-graph-exponential-equation-coefficients'
      ),
    })
  )

export type MathQuestionGraphExponentialMetadataAttrs = CommonMathGraphQuestionAttrs &
  MathQuestionGraphExponentialExtraAttrs

const parseMathQuestionGraphExponentialAttrs: ParserT<MathQuestionGraphExponentialMetadataAttrs> =
  merge(parseCommonMathGraphQuestionAttrs, parseGraphExponentialEquationCoefficients)

export const parseMathQuestionGraphExponentialMetadata: ParserT<MathQuestionMetadataAttrs> = record(
  {
    answerType: field(literal('graph_exponential'), 'answer-type'),
    content: onSelf(parseMathQuestionGraphExponentialAttrs),
  }
)

// Return :: Double (either 0 or 100)
export function getAnswerCorrectness(
  metadata: MathQuestionGraphExponentialMetadataAttrs,
  answer: ExponentialGraphAnswerT
): number {
  const {graphExponentialEquationCoefficients} = metadata
  const isCorrect = validatePointsForExponentialEquation(
    answer.points,
    graphExponentialEquationCoefficients
  )
  return isCorrect ? 100 : 0
}
