import {mmap} from '@freckle/maybe'
import {exhaustive} from '@freckle/exhaustive'

import {
  MathAssignmentOptionT,
  MathAssignmentTypes,
} from '@freckle/educator-entities/ts/math/common/models/math-assignment'
import {MathAssignmentTypeT} from '@freckle/educator-entities/ts/math/common/models/math-assignment'
import {LangT} from '@freckle/educator-entities/ts/common/helpers/languages'
import {toFragment} from '@freckle/educator-entities/ts/common/helpers/routers/query-params'
import {TaggedRlSkillId} from '@freckle/educator-entities/ts/math/common/helpers/math-standard-re-arch-type-helpers'
import {FactPracticeOperationT} from '@freckle/educator-entities/ts/math/fact-practice/helpers/fact-practice-operation'
import {RlDomainIdT} from '@freckle/educator-entities/ts/common/types/rl-domain-id'
import {RlStandardIdT} from '@freckle/educator-entities/ts/common/types/rl-standard-id'
import {DateOption} from '@freckle/educator-entities/ts/common/helpers/date-options'
import CApiHelper from '@freckle/educator-entities/ts/common/helpers/common-api-helper'
import {RlSkillUspIdT} from '@freckle/educator-entities/ts/common/types/rl-skill-usp-id'
import {SupportSettingT} from '@freckle/classroom/ts/math/common/components/add-assignment/targeted-assignments/support-setting'
import {ElaAssignmentOptionT} from '@freckle/classroom/ts/ela/common/models/ela-product'
import {ElaAssignmentTypeT} from '@freckle/classroom/ts/common/logic/assign-article'

export type TargetedPracticeReportTabT = 'students' | 'questions'

export type RosterStudentEditPageTab = 'details' | 'dashboard' | 'support-settings'

export type FactPracticeReportTabNameT = 'activity' | 'basic-operations' | 'integers-and-fractions'

type ElaAssignmentReport = 'assessment' | 'reading' | 'writing'

type MathAssignmentsAssessmentParamT = {
  domainIds?: Array<RlDomainIdT>
  standardIds: Array<RlStandardIdT>
}

export type AssignTargetedMathSubtypeT = 'practice' | 'prerequisite' | 'review'

export type AssignWordStudyPracticeParamsT =
  | {tag: 'elaStandardId'; elaStandardId: RlStandardIdT; studentIds?: Array<number>}
  | {tag: 'elaSkillUspId'; elaSkillUspId: RlSkillUspIdT; studentIds?: Array<number>}

const mathRoutes = {
  overview: (courseId: number, params?: {tab?: string; grade?: number; skillSetId?: number}) => {
    const checkedParams = params ?? {}
    return toFragment(`/courses/${courseId}/math/overview`, {tab: 'content', ...checkedParams})
  },
  factFluencyOverview: () => '/math/goals/fact-fluency-overview',
  targetedSessionReport: (assignmentSessionId: number) =>
    `/math/targeted-session/${assignmentSessionId}/report`,
  adaptiveSessionReport: (assignmentSessionId: number) =>
    `/math/adaptive-session/${assignmentSessionId}/report`,
  assessmentSessionReport: (assignmentSessionId: number) =>
    `/math/assessment-session/${assignmentSessionId}/report`,
  standardsStudentReport: (
    studentId: number,
    params: {
      standardId?: string
      domainId?: string
      from?: number
      to?: number
      'date-option'?: DateOption
      practiceType?: MathAssignmentOptionT
    }
  ) =>
    toFragment(`/math/standards/students/${studentId}/report`, {
      ...params,
    }),
  assignmentSessionReport: (product: string, assignmentSessionId: number) =>
    `/math/session/${product}/${assignmentSessionId}/report`,
  standards: (courseId: number, params?: {grade?: number; skillSetId?: number}) => {
    const checkedParams = params ?? {}
    const tab = 'standards'
    return toFragment(`/courses/${courseId}/math/overview`, {...checkedParams, tab})
  },
  standard: (courseId: number, standardId: string) =>
    `/courses/${courseId}/standards/${standardId}`,
  previewPrintAssignment: (courseId: number, product: string, sessionId: number) =>
    `/courses/${courseId}/math/print/${product}/${sessionId}`,
}

const elaRoutes = {
  elaAssignmentReports: (assignmentType: ElaAssignmentReport, assignmentId: number) =>
    `/ela/reports/${assignmentType}/${assignmentId}`,
  elaReportsAssessmentSessions: (courseId: number, assignmentSessionId: number) =>
    `/courses/${courseId}/ela/reports/assessment-sessions/${assignmentSessionId}`,
  overview: (courseId: number, params?: {grade?: number; tab?: string}) => {
    const checkedParams = params ?? {}
    return toFragment(`/courses/${courseId}/ela/overview`, {tab: 'content', ...checkedParams})
  },
  article: (courseId: number, articleUuid: string) =>
    `/courses/${courseId}/ela/articles/${articleUuid}`,
  articles: (courseId: number, standardId?: string) =>
    toFragment(`/courses/${courseId}/ela/articles`, {
      standardId,
    }),
  standard: (domainId: RlDomainIdT, standardId: RlStandardIdT) =>
    `/ela/standards/${domainId}/${standardId}`,
  assignmentReadingStudentReports: (courseId: number, assignmentId: number, studentId: number) =>
    `/courses/${courseId}/ela/reports/reading/${assignmentId}/students/${studentId}`,
  assignmentWritingReports: (assignmentId: number) => `/ela/reports/writing/${assignmentId}`,
  assignmentTargetedStudentReport: (courseId: number, assignmentId: number, studentId: number) =>
    `/courses/${courseId}/ela/reports/targeted-skills/${assignmentId}/students/${studentId}`,
  assignmentFocusSkillsStudentReport: (courseId: number, assignmentId: number, studentId: number) =>
    `/courses/${courseId}/ela/reports/focus-skills/${assignmentId}/students/${studentId}`,
  assignmentAdaptiveSkillStudentReport: (
    courseId: number,
    assignmentId: number,
    studentId: number
  ) => `/courses/${courseId}/ela/reports/adaptive-skills/${assignmentId}/students/${studentId}`,
  assignmentAdaptiveGrammarStudentReport: (
    courseId: number,
    assignmentId: number,
    studentId: number
  ) => `/courses/${courseId}/ela/reports/adaptive-grammar/${assignmentId}/students/${studentId}`,
  assignmentTargetedGrammarStudentReport: (
    courseId: number,
    assignmentId: number,
    studentId: number
  ) => `/courses/${courseId}/ela/reports/targeted-grammar/${assignmentId}/students/${studentId}`,
  assignmentWordStudyStudentReport: (
    courseId: number,
    assignmentSessionId: number,
    studentId: number
  ) => `/courses/${courseId}/ela/reports/word-study/${assignmentSessionId}/students/${studentId}`,
  textLevelCorrelationChart: () => `/ela/correlation`,
  standardsStudentReport: (
    studentId: number,
    params?: {
      from?: number
      to?: number
      'date-option'?: DateOption
      standardId?: RlStandardIdT
      domainId?: string
      practiceType?: ElaAssignmentOptionT
    }
  ) => toFragment(`/ela/standards/students/${studentId}/report`, {...params}),
  previewPrintTargetedSkillsPractice: (courseId: number, sessionId: number) =>
    `/courses/${courseId}/ela/print/targeted-skills/${sessionId}`,
  previewPrintAdaptiveSkillsPractice: (courseId: number, assignmentId: number) =>
    `/courses/${courseId}/ela/print/adaptive-skills/${assignmentId}`,
  previewPrintArticleFromSession: (courseId: number, sessionId: number) =>
    `/courses/${courseId}/ela/print/articles/${sessionId}`,
  previewPrintArticleFromUuidAndLevel: (courseId: number, uuid: string, level: string) =>
    `/courses/${courseId}/ela/print/article/${uuid}/level/${level}`,
}

const scienceRoutes = {
  units: () => '/science-units',
  unit: (scienceUnitId: string, lesson?: number | null | undefined) =>
    toFragment(`/science-units/${scienceUnitId}`, {lesson}),
  unitArticle: (scienceUnitId: string, articleUuid: string) =>
    `/science-units/${scienceUnitId}/articles/${articleUuid}`,
  readingAssignmentSessionReport: (assignmentId: number, studentId: number) =>
    `/science/reports/reading/${assignmentId}/students/${studentId}`,
  writingAssignmentReport: (assignmentId: number) => `/science/reports/writing/${assignmentId}`,
  search: (query: string) => toFragment('/science/search', {q: query}),
}

const socialStudiesRoutes = {
  units: () => '/social-studies-units',
  unit: (socialStudiesUnitId: string, lesson?: number | null | undefined) =>
    toFragment(`/social-studies-units/${socialStudiesUnitId}`, {lesson}),
  unitArticle: (socialStudiesUnitId: string, articleUuid: string) =>
    `/social-studies-units/${socialStudiesUnitId}/articles/${articleUuid}`,
  readingAssignmentSessionReport: (assignmentId: number, studentId: number) =>
    `/social-studies/reports/reading/${assignmentId}/students/${studentId}`,
  writingAssignmentReport: (assignmentId: number) =>
    `/social-studies/reports/writing/${assignmentId}`,
  search: (query: string) => toFragment('/social-studies/search', {q: query}),
}

const authRoutes = {
  signup: () => '/signup',
  login: () => '/login',
  resetByEmail: () => '/reset-by-email',
}

const rosteringRoutes = {
  rosters: (expandedCourses?: number[]) => toFragment('/rosters', {expandedCourses}),
  create: () => '/rosters/create',
  editClass: (courseId: number) => `/rosters/courses/${courseId}/edit`,
  archivedRoster: () => '/rosters/archived-roster',
  loginCards: () => '/rosters/login-cards',
  loginCardsForCourse: (courseId: number) => `/rosters/login-cards?courseId=${courseId}`,
  loginCardForStudent: (
    studentId: number,
    courseId: number,
    displayPassword?: boolean | null | undefined
  ) => toFragment('/rosters/login-cards', {studentId, courseId, displayPassword}),
}

export const Routes = {
  ...authRoutes,

  settings: () => '/settings',
  assignments: () => '/assignments',
  ssoLogoutToRenaissance: () => CApiHelper.fancyPaths.v3.renaissance.home(),
  mobileReminder: () => '/mobile-reminder',

  dashboard: () => '/dashboard',

  referralProgram: () => '/referral-program',

  rostering: rosteringRoutes,

  school: () => '/school',

  teacherOnboarding: () => '/teacher-onboarding',
  parentWelcome: () => '/parent-welcome',

  math: mathRoutes,

  ela: elaRoutes,

  socialStudies: socialStudiesRoutes,

  science: scienceRoutes,

  courses: {
    rostersCoursesStudentEdit: (
      courseId: number,
      studentId: number,
      tab?: RosterStudentEditPageTab
    ) =>
      tab !== undefined
        ? toFragment(`/courses/${courseId}/students/${studentId}/edit`, {tab})
        : `/courses/${courseId}/students/${studentId}/edit`,
    math: {
      reportsOverview: (courseId: number) => `/courses/${courseId}/math/reports`,
      assignments: (courseId: number) => `/courses/${courseId}/assignments`,
      sessionHistory: (courseId: number, assignmentType: MathAssignmentTypeT) =>
        `/courses/${courseId}/session-history/${MathAssignmentTypes.forDisplay(assignmentType)}`,
      sessionHistoryAssignment: (
        courseId: number,
        assignmentType: MathAssignmentTypeT,
        assignmentId: number
      ) =>
        `/courses/${courseId}/session-history/${MathAssignmentTypes.forDisplay(
          assignmentType
        )}/${assignmentId}`,
      sessionHistoryAssignmentSession: (
        courseId: number,
        assignmentType: MathAssignmentTypeT,
        assignmentId: number,
        sessionId: number
      ) =>
        `/courses/${courseId}/session-history/${MathAssignmentTypes.forDisplay(
          assignmentType
        )}/${assignmentId}/session/${sessionId}`,
      sessionHistoryAssessmentSession: (courseId: number, sessionId: number) =>
        `/courses/${courseId}/session-history/math-assessment-session/${sessionId}`,
      reportCardSummaries: (courseId: number) => `/courses/${courseId}/report-card-summaries`,
      reportCard: (
        courseId: number,
        student: number | null | undefined = null,
        queryParams: Object = {}
      ) => {
        return toFragment(`/courses/${courseId}/math-report-card`, {
          student: student ?? {},
          ...queryParams,
        })
      },
      printReportCards: (courseId: number) =>
        toFragment(`/courses/${courseId}/print-math-report-cards`),
      factPracticeReports: (
        courseId: number,
        tabName: FactPracticeReportTabNameT | null | undefined,
        initialStudentId?: number
      ) =>
        toFragment(`/courses/${courseId}/fact-practice-reports`, {
          tabName,
          'student-id': initialStudentId,
        }),
      factPracticeReport: (courseId: number, studentId: number, operation: string) =>
        `/courses/${courseId}/fact-practice-report/students/${studentId}/operations/${operation}`,
      standardsReport: (courseId: number) => `/courses/${courseId}/math-standards-report`,
      levelsReport: (courseId: number) => `/courses/${courseId}/math-diagnostic-report`,
      focusSkillsReport: (courseId: number) => `/courses/${courseId}/math-focus-skills-report`,
      skillsProgressReport: (courseId: number) =>
        `/courses/${courseId}/math-skills-progress-report`,
      assessmentGrowthReport: (courseId: number) => `/courses/${courseId}/math-assessment-growth`,
      assessmentGrowthDetailed: (courseId: number, assignmentIds: Array<number>) =>
        toFragment(`/courses/${courseId}/math-assessment-growth-detailed`, {assignmentIds}),
      classGroupingReport: (courseId: number) => `/courses/${courseId}/class-grouping-report`,
      targetedPracticeReport: (
        courseId: number,
        assignmentId: number,
        tab?: TargetedPracticeReportTabT
      ) =>
        toFragment(`/courses/${courseId}/math/reports/targeted-practice/${assignmentId}`, {
          tab,
        }),
      assignIblLesson: (courseId: number, lessonId: number, studentIds?: Array<number>) =>
        toFragment(`/courses/${courseId}/lessons/${lessonId}/assignments`, {studentIds}),
      assignTargetedDepthOfKnowledgePractice: (
        courseId: number,
        students?: Array<number>,
        domainId?: RlDomainIdT,
        standardId?: RlStandardIdT
      ) =>
        toFragment(`/courses/${courseId}/assignments/targeted-depth-of-knowledge-practice`, {
          students,
          domainId,
          standardId,
        }),
      assignNumberFacts: (courseId: number, students?: Array<number>) =>
        toFragment(`/courses/${courseId}/assignments/number-facts`, {
          students,
        }),

      assignFactPractice: (
        courseId: number,
        students?: Array<number>,
        operations?: Array<FactPracticeOperationT>
      ) =>
        toFragment(`/courses/${courseId}/assignments/fact-practice`, {
          students,
          operations,
        }),
      lessonsLesson: (courseId: number, lessonId: number) =>
        `/courses/${courseId}/lessons/${lessonId}`,
      assignLesson: (
        courseId: number,
        lessonId: number,
        students?: Array<number>,
        days?: Array<number>
      ) =>
        toFragment(`/courses/${courseId}/lessons/${lessonId}/assignments`, {
          students,
          days,
        }),
      assignAdaptive: (
        courseId: number,
        students?: Array<number>,
        domainId?: RlDomainIdT | null | undefined
      ) =>
        toFragment(`/courses/${courseId}/assignments/adaptive`, {
          students,
          domainId,
        }),
      assignAssessments: (
        courseId: number,
        students?: Array<number>,
        grade?: number,
        params?: MathAssignmentsAssessmentParamT
      ) => {
        if (params === undefined) {
          return toFragment(`/courses/${courseId}/assignments/assessments`, {
            students,
            grade,
          })
        } else {
          return toFragment(`/courses/${courseId}/assignments/assessments`, {
            students,
            grade,
            domainIds: params.domainIds,
            standardIds: params.standardIds,
          })
        }
      },
      assessmentReport: (courseId: number, assessmentId: number) =>
        `/courses/${courseId}/assessment/${assessmentId}/report`,
      assignTargeted: ({
        courseId,
        numQuestions,
        students,
        params,
      }: {
        courseId: number
        numQuestions?: number | null | undefined
        students?: Array<number>
        params?:
          | {
              standardId?: RlStandardIdT
              taggedSkillId?: TaggedRlSkillId | null | undefined
              supportSetting?: SupportSettingT
              subType?: AssignTargetedMathSubtypeT
            }
          | null
          | undefined
      }) => {
        const baseQueryParams = {students, numQuestions}
        const additionalQueryParams =
          params !== null && params !== undefined
            ? {
                subType: params.subType,
                ...(params.supportSetting !== undefined
                  ? {supportSetting: params.supportSetting}
                  : {}),
                standardId: params.standardId,
                skillId: mmap(sId => sId.value, params.taggedSkillId),
              }
            : {}
        return toFragment(`/courses/${courseId}/assignments/targeted`, {
          ...baseQueryParams,
          ...additionalQueryParams,
        })
      },

      lessons: (courseId: number) => `/courses/${courseId}/lessons`,
      lesson: (courseId: number, lessonId: number) => `/courses/${courseId}/lessons/${lessonId}`,
      lessonDaySlideshow: (courseId: number, lessonId: number, dayNum: number, lang: LangT) =>
        `/courses/${courseId}/lessons/${lessonId}/days/${dayNum}/slideshow?language=${lang}`,
      printables: (
        courseId: number,
        params?: {domainId?: RlDomainIdT; standardId?: RlStandardIdT}
      ) => toFragment(`/courses/${courseId}/printables`, params),
      printableAssignments: {
        // This also accepts a special `includeQuestion` parameter for testing
        // specific questions (give it a math question ID)
        example: (courseId: number) => `/courses/${courseId}/printables/example`,
        adaptive: (courseId: number, domainId: RlDomainIdT) =>
          `/courses/${courseId}/printables/adaptive/domain/${domainId}`,
        targeted: (courseId: number, skillId: RlSkillUspIdT, subSkillId: RlSkillUspIdT | null) =>
          toFragment(`/courses/${courseId}/printables/targeted/skill/${skillId}`, {subSkillId}),
      },
      constructedResponses: (
        courseId: number,
        params?: {domainId?: RlDomainIdT; standardCode?: string}
      ) => toFragment(`/courses/${courseId}/constructed-responses`, params),
    },
    ela: {
      reportsOverview: (courseId: number) => `/courses/${courseId}/ela/reports`,
      articleSessionHistory: (courseId: number) =>
        `/courses/${courseId}/session-history/ela-article`,
      classActivityArticleDetail: (courseId: number, articleUuid: string) =>
        `/courses/${courseId}/session-history/ela-article/${articleUuid}`,
      groupReport: (courseId: number) => `/courses/${courseId}/ela/groups-report`,
      performanceByStandardReport: (
        courseId: number,
        params?: {
          from?: number
          to?: number
          dateOption?: DateOption
        }
      ) => toFragment(`/courses/${courseId}/ela/reports/performance-by-standard`, params),
      studentGoals: (courseId: number) => `/courses/${courseId}/ela/student-goals`,
      reportsAssessments: (courseId: number) => `/courses/${courseId}/ela/reports/assessments`,
      assessments: (
        courseId: number,
        params?: {students?: Array<number>; assessmentUuid?: string}
      ) => toFragment(`/courses/${courseId}/ela/assessments`, params),
      wordStudy: (courseId: number) => `/courses/${courseId}/ela/word-study`,
      assignTargetedPracticeSkill: (
        courseId: number,
        params?: {
          students?: Array<number>
          elaDomainId?: RlDomainIdT | null | undefined
          elaStandardId?: RlStandardIdT | null | undefined
          elaSkillUspId?: RlSkillUspIdT | null | undefined
        }
      ) => toFragment(`/courses/${courseId}/ela/targeted-skill-practice/assign`, params),
      assignWordStudyPractice: (courseId: number, maybeParams?: AssignWordStudyPracticeParamsT) => {
        const queryParams = mmap(params => {
          switch (params.tag) {
            case 'elaStandardId':
              return {elaStandardId: params.elaStandardId, studentIds: params.studentIds}
            case 'elaSkillUspId':
              return {elaSkillUspId: params.elaSkillUspId, studentIds: params.studentIds}
            default:
              return exhaustive(params)
          }
        }, maybeParams)

        return toFragment(`/courses/${courseId}/ela/word-study`, queryParams)
      },
      assignAdaptiveWordStudyPractice: (
        courseId: number,
        queryParams?: {
          studentIds?: Array<number>
          type?: 'Adaptive'
        }
      ) => {
        return toFragment(`/courses/${courseId}/ela/word-study`, queryParams)
      },
      assignDecodable: (courseId: number, students?: Array<number>, decodableUuid?: string) =>
        toFragment(`/courses/${courseId}/ela/decodable/assign`, {
          students,
          decodableUuid,
        }),
      assignments: (courseId: number) => `/courses/${courseId}/ela/assignments`,
      reportCards: (courseId: number) => `/courses/${courseId}/ela/report-cards`,
      printElaReportCards: (courseId: number) => `/courses/${courseId}/ela/print-ela-report-cards`,
      reportCard: (
        courseId: number,
        studentId: number | null | undefined = null,
        queryParams: Object = {}
      ) => {
        return toFragment(`/courses/${courseId}/ela/report-card`, {
          studentId: studentId ?? {},
          ...queryParams,
        })
      },
      wordStudyMatrix: (courseId: number) => `/courses/${courseId}/ela/word-study-matrix`,
      assignArticle: (
        courseId: number,
        articleUuid: string,
        students?: Array<number>,
        type?: ElaAssignmentTypeT
      ) =>
        toFragment(`/courses/${courseId}/ela/articles/${articleUuid}/assign`, {
          students,
          type,
        }),
      levelsReport: (courseId: number) => `/courses/${courseId}/ela/reports/levels`,
      standards: (courseId: number, params?: {grade?: number | null | undefined}) => {
        const checkedParams = params ?? {}
        const tab = 'standards'
        return toFragment(`/courses/${courseId}/ela/overview`, {...checkedParams, tab})
      },
      skillsProgressReport: (courseId: number) => `/courses/${courseId}/ela-skills-progress-report`,
    },
    socialStudies: {
      assignUnitArticle: (
        courseId: number,
        socialStudiesUnit: string,
        articleUuid: string,
        type?: 'Reading' | 'Writing'
      ) =>
        toFragment(
          `/courses/${courseId}/social-studies-units/${socialStudiesUnit}/articles/${articleUuid}/assign`,
          {type}
        ),
      assignments: (courseId: number) => `/courses/${courseId}/social-studies/assignments`,
      assignmentSessionsHistory: (courseId: number) =>
        `/courses/${courseId}/session-history/social-studies`,
    },
    science: {
      assignUnitArticle: (
        courseId: number,
        scienceUnit: string,
        articleUuid: string,
        type?: 'Reading' | 'Writing'
      ) =>
        toFragment(
          `/courses/${courseId}/science-units/${scienceUnit}/articles/${articleUuid}/assign`,
          {type}
        ),
      assignments: (courseId: number) => `/courses/${courseId}/science/assignments`,
      assignmentSessionsHistory: (courseId: number) =>
        `/courses/${courseId}/session-history/science`,
    },

    activityFeed: (courseId: number) => `/courses/${courseId}/activity-feed`,
    studentGoals: (courseId: number) => `/courses/${courseId}/student-goals`,
  },
}
