import * as React from 'react'
import {Dashboard, DashboardTypeT} from '@freckle/classroom/ts/common/components/dashboard'
import {NotificationWrapper} from '@freckle/classroom/ts/common/components/notifications-wrapper'
import {MiddlewareWrapper} from './middleware'
import {AuthedSplitIOWrapper} from './split-io-wrapper/authed-split-io-wrapper'
import {PendoWrapper} from './pendo-wrapper'

type Props = {
  dashboardType?: DashboardTypeT
  fullScreen?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.ComponentType<any>
}

export function LoggedRoute(props: Props): React.ReactElement {
  const {component: Component, dashboardType, fullScreen} = props
  return (
    <MiddlewareWrapper>
      <PendoWrapper>
        <AuthedSplitIOWrapper>
          <Dashboard dashboardType={dashboardType} fullScreen={fullScreen}>
            <Component />
          </Dashboard>
        </AuthedSplitIOWrapper>
      </PendoWrapper>
      <NotificationWrapper />
    </MiddlewareWrapper>
  )
}
