import * as React from 'react'
import {useSafeTranslation} from '@freckle/educator-materials/ts/helpers/translate'
import {exhaustive} from '@freckle/exhaustive'
import {BasicModal} from '@freckle/educator-materials/ts/components/basic-modal'
import {Text} from '@freckle/educator-materials/ts/components/typography'
import {Link} from '@freckle/educator-materials/ts/components/link'
import {maybe} from '@freckle/maybe'
import {useSafeCallback} from '@freckle/react-hooks'
import {TeacherAttrs} from '@freckle/classroom/ts/users/models/teacher'
import {trackEvent} from '@freckle/classroom/ts/common/helpers/analytics'
import {EventsKey} from '@freckle/classroom/ts/common/helpers/analytics'
import {MarketoForm} from '../marketo-form'
import {DEFAULT_ANALYTICS_EVENT_KEY} from './constants'

type StepT = {tag: 'quote-form'} | {tag: 'quote-success'; followUpUrl: string}

export type Props = {
  analyticsEventKey: EventsKey
  show: boolean
  onHide: () => void
  teacher: TeacherAttrs
}

export function QuoteRequestModal(props: Props): React.ReactElement {
  const {t} = useSafeTranslation()
  const {show, teacher, analyticsEventKey = DEFAULT_ANALYTICS_EVENT_KEY} = props
  const [step, setStep] = React.useState<StepT>({tag: 'quote-form'})

  function handleSuccess(mktoFormId: string, followUpUrl: string): void {
    setStep({tag: 'quote-success', followUpUrl})
    trackEvent(analyticsEventKey, {mktoFormId})
  }

  function onHide() {
    props.onHide()
  }

  function MarketoFormWrapper(): React.ReactElement {
    const onSuccess = useSafeCallback<(a: {mktoFormId: string; followUpUrl: string}) => void>(
      () =>
        ({mktoFormId, followUpUrl}) =>
          handleSuccess(mktoFormId, followUpUrl),
      []
    )
    const countryCode = maybe(
      () => null,
      teacher => teacher.countryCode,
      teacher
    )
    return <MarketoForm onSuccess={onSuccess} countryCode={countryCode} />
  }

  let modalAttrs: {
    dataTest: string
    header: string
    body: React.ReactElement
    ctaButtons: React.ReactElement[]
  }
  switch (step.tag) {
    case 'quote-form':
      modalAttrs = {
        dataTest: 'quote-request-modal',
        header: t('QUOTE_REQUEST_MODAL_QUOTE_FORM_HEADER'),
        body: <MarketoFormWrapper />,
        ctaButtons: [],
      }
      break
    case 'quote-success':
      modalAttrs = {
        dataTest: 'quote-request-modal-success',
        header: t('QUOTE_REQUEST_MODAL_QUOTE_SUCCESS_HEADER'),
        body: (
          <>
            <Text>{t('QUOTE_REQUEST_MODAL_QUOTE_SUCCESS_THANKS_COPY')}</Text>
          </>
        ),
        ctaButtons: [
          <Link
            key="quote-request-modal-success-calendar-link"
            style="btn-primary"
            href={step.followUpUrl}
            target="_blank"
            rel="noopener"
            dataTest="quote-request-modal-success-calendar-link"
            reactRouter={false}
          >
            {t('QUOTE_REQUEST_MODAL_QUOTE_SUCCESS_SCHEDULE_BUTTON')}
          </Link>,
        ],
      }
      break
    default:
      modalAttrs = exhaustive(step, 'render')
  }

  return (
    <BasicModal show={show} onHide={onHide} title={modalAttrs.header}>
      {modalAttrs.body}
    </BasicModal>
  )
}
