import * as React from 'react'
import {useSafeEffect} from '@freckle/react-hooks'
import {Spinner} from '@freckle/educator-entities/ts/common/components/spinner-wrapper/spinner'

const SPINNER_DELAY = 300

type Props = {
  text?: string | null | undefined
  inline?: boolean | null | undefined
}

export function DelayedSpinner(props: Props): React.ReactElement {
  const timer = React.useRef<ReturnType<typeof setTimeout> | undefined>()

  const {text, inline} = props
  const [showSpinner, setShowSpinner] = React.useState<boolean>(false)

  useSafeEffect(() => {
    timer.current = setTimeout(setShowSpinner, SPINNER_DELAY, true)
    return () => {
      clearTimeout(timer.current)
      timer.current = undefined
    }
  }, [])

  return showSpinner ? <Spinner text={text} inline={inline} /> : <></>
}
