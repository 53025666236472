import {ParserT, Parser, string} from '@freckle/parser'

export type RlDomainIdT = string

export const parse: ParserT<RlDomainIdT> = Parser.map(
  string(),
  'RlDomainIdT.fromString',
  fromString
)

export function fromString(rlDomainId: string): RlDomainIdT {
  return rlDomainId
}

export function toString(rlDomainId: RlDomainIdT): string {
  return rlDomainId
}

export function isEqual(a: RlDomainIdT, b: RlDomainIdT): boolean {
  return a === b
}
