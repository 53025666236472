import {lazy} from 'react'

export const ArchivedRostersHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "ArchivedRostersHandler" */ './../../../../roster/components/rosters/archived-roster-handler'
    )
)

export const LoginCardHandler = lazy(
  () =>
    import(/* webpackChunkName: "LoginCardHandler" */ './../../../../common/components/login-cards')
)

export const RostersHandler = lazy(
  () => import(/* webpackChunkName: "RostersHandler" */ './../../../../roster/components/rosters')
)

export const RosterStudentEditPageHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "RosterStudentEditPageHandler" */ './../../../../roster/components/rosters/student-edit-page'
    )
)

export const AddCoursePageHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "AddClassPageHandler" */ '../../../../roster/course-page/components/add-course-page-handler'
    )
)

export const EditCoursePageHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "EditCoursePageHandler" */ '../../../../roster/course-page/components/edit-course-page-handler'
    )
)
