import {lazy} from 'react'

// Components

export const MathAdaptiveAssignmentsHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "MathAdaptiveAssignmentsHandler" */ './../../../../../math/common/components/add-assignment/common/adaptive-assignments'
    )
)

export const MathAssessmentAssignmentsHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "MathAssessmentAssignmentsHandler" */ './../../../../../math/common/components/add-assignment/common/math-assessment-assignments'
    )
)

export const MathFactPracticeHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "MathFactPracticeHandler" */ './../../../../../math/common/components/add-assignment/common/fact-practice-assignments'
    )
)

export const MathNumberFactsAssignmentsHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "MathNumberFactsAssignmentsHandler" */ './../../../../../math/common/components/add-assignment/common/number-facts-assignments'
    )
)

export const MathTargetedAssignmentsHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "MathTargetedAssignmentsHandler" */ './../../../../../math/common/components/add-assignment/common/targeted-assignments'
    )
)

export const MathTargetedDepthOfKnowledgePracticeAssignmentsHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "MathTargetedDepthOfKnowledgePracticeAssignmentsHandler" */ './../../../../../math/common/components/add-assignment/common/targeted-depth-of-knowledge-practice-assignments'
    )
)
