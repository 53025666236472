import omit from 'lodash/omit'
import {LangT} from '@freckle/educator-entities/ts/common/helpers/languages'
import CApiHelper from '@freckle/educator-entities/ts/common/helpers/common-api-helper'
import {ContentAreas} from '@freckle/educator-entities/ts/curricula-api/generated-client/enums/content-area'
import {ajaxJsonCall} from '@freckle/ajax'
import {ProductPathsT} from '@freckle/educator-entities/ts/common/helpers/products'
import {
  ajaxSettingsWithEcho,
  genericFetchWithEcho,
  FetchEchoError,
} from '@freckle/educator-entities/ts/common/helpers/api-helper'
import {toFragment} from '@freckle/educator-entities/ts/common/helpers/routers/query-params'
import {CONFIG} from '@freckle/educator-entities/ts/common/config'
import {ReadAloudSupportT} from '@freckle/educator-entities/ts/common/models/read-aloud-support'
import {AutomatedAudioT} from '@freckle/educator-entities/ts/common/models/automated-audio'
import {logErrorAsUnhandled} from '@freckle/classroom/ts/common/helpers/exception-handlers/bugsnag-client'
import {
  ConstructedResponseT,
  parseConstructedResponses,
} from '@freckle/classroom/ts/math/constructed-response/models/constructed-response'
import {PayloadT} from '@freckle/classroom/ts/common/logic/assign-article'

import {logout} from '@freckle/classroom/ts/common/helpers/logout'

export type SetStudentAudioSupportPayloadT = {
  readAloudSupport: ReadAloudSupportT
  automatedAudio: AutomatedAudioT
  studentId: number
}

type SetStudentStoreLimitPayloadT = {
  studentId: number
  storeLimit: number | null | undefined
}

type TeacherSignupReqT = {
  'first-name': string
  'last-name': string
  email: string
  password: string
  'phone-number': string | null | undefined
  language: LangT | null | undefined
}

//
// Figure out which URL to use to talk to the API based on client URL
//

async function fetchWithEcho(url: string, init?: RequestInit): Promise<Response> {
  try {
    const response = await genericFetchWithEcho(url, init)
    return response
  } catch (error) {
    if (error instanceof FetchEchoError) {
      logErrorAsUnhandled(error)
      logout()
    }
    throw error
  }
}

export function checkInternetConnectivity(
  pingUrl: string,
  normalRetryPeriod: number,
  failedRetryPeriod: number,
  successCallback: () => void,
  failureCallback: () => void
) {
  const queryParams = {
    commitTimestamp: CONFIG.COMMIT_UNIX_TIMESTAMP,
    commitSHA: CONFIG.COMMIT_SHA,
  }
  const url = toFragment(pingUrl, queryParams)

  const onFailure = () => {
    failureCallback()
    // switch to rapid retry mode when the connection is down and prevent
    // user from interacting with the application until it's over
    setTimeout(tryReachability, failedRetryPeriod)
  }

  const tryReachability = async () => {
    try {
      const response = await fetchWithEcho(url, {
        // If we're in the middle of destroying the session, we don't
        // want a concurrent request to resurrect it. This is rare, but
        // it can keep a deleted teacher logged in.
        credentials: 'omit',
      })
      if (response.ok) {
        successCallback()
        setTimeout(tryReachability, normalRetryPeriod)
      } else {
        // This branch catches all non-network errors like 500 from the API
        onFailure()
      }
    } catch (e) {
      // This branch catches network errors and CORS misconfiguration
      onFailure()
    }
  }

  // try to reach our servers every x secs to validate connection health
  setTimeout(tryReachability, normalRetryPeriod)
}

//
// Configure jQuery's ajax defaults
//
export function setupAjax() {
  $.ajaxSetup(
    ajaxSettingsWithEcho(null, echoError => {
      logErrorAsUnhandled(echoError)
      logout()
    })
  )
}

//
// Create a teacher account
//
export function signupTeacher(data: TeacherSignupReqT): Promise<void> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.teachers.accounts(),
    method: 'POST',
    data: JSON.stringify(data),
  })
}

//
// Have the API tell the browser to remove the current teacher session
// cookie. The client cannot interact with the cookie.
//
export function deleteSessionCookie(): Promise<{redirectUrl?: string}> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.teachers.sessions(),
    method: 'DELETE',
  })
}

export function disconnectCleverAtTeacherLevel(teacherId: number): Promise<void> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.teachers.idp_clever(teacherId),
    method: 'DELETE',
  })
}

export function setStudentAudioSupport(student: SetStudentAudioSupportPayloadT): Promise<void> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.students._1(student.studentId),
    method: 'PATCH',
    data: JSON.stringify(omit(student, 'studentId')),
  })
}

export function setStudentStoreLimit(payload: SetStudentStoreLimitPayloadT): Promise<void> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.students._1(payload.studentId),
    method: 'PATCH',
    data: JSON.stringify(omit(payload, 'studentId')),
  })
}

// Merge student
export function mergeStudent(
  sourceStudentId: number,
  targetStudentId: number,
  removeSourceCourseMemberships: boolean
): Promise<void> {
  // Transferring course memberships will put the target student in all the courses
  // that the source student was in. This  is desirable when a merge should result
  // in a "copied" student rather than a "moved" student. Otherwise, the source memberships
  // should be removed
  const cmsStrategy = removeSourceCourseMemberships
    ? '&remove-source-course-memberships'
    : '&transfer-course-memberships'

  return ajaxJsonCall({
    url: `${CApiHelper.fancyPaths.v2.students._1(
      sourceStudentId
    )}?duplicate-of-id=${targetStudentId}${cmsStrategy}`,
    method: 'DELETE',
  })
}

// Share students or move them to another course
export function bulkPatchStudents(
  patch: ({teacherId: number} | ({fromCourseId: number} & {courseId: number})) & {
    studentIds: Array<number>
  }
): Promise<void> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.students._0(),
    method: 'PATCH',
    data: JSON.stringify(patch),
    timeout: 0,
  })
}

export function enrollStudentInCourse(studentId: number, courseId: number): Promise<void> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.students._0(),
    method: 'PATCH',
    data: JSON.stringify({courseId, studentIds: [studentId]}),
    timeout: 0,
  })
}

//Share Students
export function shareStudents(
  payload: Array<{studentId: number; targetTeacherId: number}>
): Promise<void> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.student_shares(),
    method: 'POST',
    data: JSON.stringify(payload),
  })
}

//Dismiss shared or transferred students
export function dismissSharedOrTransferredStudents(
  payload: Array<{studentId: number}>
): Promise<void> {
  return ajaxJsonCall({
    url: `${CApiHelper.fancyPaths.v2.student_shares()}`,
    method: 'DELETE',
    data: JSON.stringify(payload),
  })
}

export function createSocialStudiesAssignment(payload: PayloadT): Promise<void> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.social_studies_assignments._(),
    method: 'POST',
    data: JSON.stringify(payload),
  })
}

export function createScienceAssignment(payload: PayloadT): Promise<void> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.science_assignments._(),
    method: 'POST',
    data: JSON.stringify(payload),
  })
}

export function transferStudents(
  payload: Array<{studentId: number; targetTeacherId: number}>
): Promise<void> {
  if (payload.length === 0) {
    throw new Error('transferStudents: Must specify at least one student to transfer')
  }
  return ajaxJsonCall({
    url: `${CApiHelper.fancyPaths.v2.student_shares()}?should-transfer=true`,
    method: 'POST',
    data: JSON.stringify(payload),
  })
}

export function idpMergeStudents(
  courseId: number,
  payload: Array<{studentId: number; idpStudentId: number}>
): Promise<void> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.courses.idp_student_merge(courseId),
    method: 'POST',
    data: JSON.stringify(payload),
    timeout: 0,
  })
}

type ArticleFilterResults = {
  results: string[]
}

type ArticleFilterResult = {
  result: null | undefined | ArticleFilterResults
}

export function elaArticleFilterBySearchInput(searchInput: string): Promise<ArticleFilterResult> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.ela.article_search(),
    method: 'GET',
    data: {searchInput},
  })
}

export function updateCourseGradeLevel(courseId: number, gradeLevel: number): Promise<void> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.courses.students(courseId),
    method: 'PATCH',
    data: JSON.stringify({gradeLevel}),
  })
}

export function deleteCourseMembership(courseMembershipId: number): Promise<void> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.course_memberships._1(courseMembershipId),
    method: 'DELETE',
  })
}

export function sendTeacherReminderEmail(teacherId: number): Promise<void> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.teachers.reminders_finish_onboarding(teacherId),
    method: 'POST',
  })
}

export function saveSplitImpression(
  teacherId: number,
  split: string,
  treatment: string,
  label: string
): Promise<void> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.teachers.impressions(teacherId),
    method: 'POST',
    data: JSON.stringify({split, treatment, label}),
  })
}

export function getSessionAttempts(
  productPaths: ProductPathsT,
  sessionId: number
): Promise<Array<number>> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v3.subjects.subject.products.product.subject_product_sessions.subject_product_session.subject_product_session_attempts._(
      productPaths.subjectPath,
      productPaths.productPath,
      sessionId
    ),
    method: 'GET',
  })
}

export function getConstructedResponses(
  mathStandardSetId: string
): Promise<Array<ConstructedResponseT>> {
  return ajaxJsonCall({
    url: `${CApiHelper.fancyPaths.v3.content_area.content_area_constructed_responses._(
      ContentAreas.Math
    )}?standardSetId=${mathStandardSetId}`,
    method: 'GET',
  }).then(parseConstructedResponses)
}

// Create or update a user on Northpass
export function educatorAcademyUser(teacherId: number): Promise<void> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.educator_academy.user(),
    method: 'PUT',
    data: JSON.stringify({teacherId}),
  })
}
