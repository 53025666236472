import {UseQueryOptions, UseQueryResult} from '@tanstack/react-query'
import {useAsync} from '@freckle/educator-entities/ts/common/hooks/use-async'
import {
  CourseMembershipAttrs,
  fetchCourseMemberships,
} from '@freckle/educator-entities/ts/roster/models/course-membership'

export function useCourseMemberships(
  options?: Partial<UseQueryOptions<CourseMembershipAttrs[]>>
): UseQueryResult<CourseMembershipAttrs[]> {
  const defaultOptions = {staleTime: 60 * 1000}
  return useAsync(fetchCourseMemberships, ['fetchCourseMemberships'], {
    ...defaultOptions,
    ...options,
  })
}
