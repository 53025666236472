import {UseQueryOptions, UseQueryResult} from '@tanstack/react-query'
import {useAsync} from '@freckle/educator-entities/ts/common/hooks/use-async'
import {SchoolAttrs, fetchSchools} from '@freckle/educator-entities/ts/roster/models/school'

export function useSchools(
  options?: Partial<UseQueryOptions<SchoolAttrs[]>>
): UseQueryResult<SchoolAttrs[]> {
  const defaultOptions = {staleTime: 60 * 1000}
  return useAsync(fetchSchools, ['fetchSchools'], {
    ...defaultOptions,
    ...options,
  })
}
