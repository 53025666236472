import find from 'lodash/find'
import {exhaustive} from '@freckle/exhaustive'
import {stringEnum, ParserT} from '@freckle/parser'

export type MathAssignmentTypeT =
  | 'MathAdaptive'
  | 'MathAssessment'
  | 'MathFactPractice'
  | 'MathIbl'
  | 'MathStandard'
  | 'MathNumberFacts'
  | 'MathDepthOfKnowledgePractice'
  | 'MathTargetedDepthOfKnowledgePractice'

type AllMathAssignmentsOptionT = 'all'
export const AllMathAssignmentsOption: AllMathAssignmentsOptionT = 'all'

export type MathAssignmentOptionT = MathAssignmentTypeT | AllMathAssignmentsOptionT

type MathAssignmentTypesT = {
  MathAdaptive: MathAssignmentTypeT
  MathStandard: MathAssignmentTypeT
  MathAssessment: MathAssignmentTypeT
  MathIbl: MathAssignmentTypeT
  MathFactPractice: MathAssignmentTypeT
  MathNumberFacts: MathAssignmentTypeT
  MathDepthOfKnowledgePractice: MathAssignmentTypeT
  MathTargetedDepthOfKnowledgePractice: MathAssignmentTypeT
  all: () => Array<MathAssignmentTypeT>
  forDisplay: (mathAssignmentType: MathAssignmentTypeT) => string
  parse: (text: string) => MathAssignmentTypeT | undefined | null
}

export const MathAssignmentTypes: MathAssignmentTypesT = {
  MathAdaptive: 'MathAdaptive',
  MathAssessment: 'MathAssessment',
  MathFactPractice: 'MathFactPractice',
  MathIbl: 'MathIbl',
  MathStandard: 'MathStandard',
  MathNumberFacts: 'MathNumberFacts',
  MathDepthOfKnowledgePractice: 'MathDepthOfKnowledgePractice',
  MathTargetedDepthOfKnowledgePractice: 'MathTargetedDepthOfKnowledgePractice',
  all: function (): Array<MathAssignmentTypeT> {
    return [
      MathAssignmentTypes.MathAdaptive,
      MathAssignmentTypes.MathStandard,
      MathAssignmentTypes.MathAssessment,
      MathAssignmentTypes.MathIbl,
      MathAssignmentTypes.MathFactPractice,
      MathAssignmentTypes.MathNumberFacts,
      MathAssignmentTypes.MathDepthOfKnowledgePractice,
      MathAssignmentTypes.MathTargetedDepthOfKnowledgePractice,
    ]
  },
  forDisplay: function (assignmentType: MathAssignmentTypeT): string {
    switch (assignmentType) {
      case 'MathAdaptive':
        return 'math-adaptive'
      case 'MathStandard':
        return 'math-standard'
      case 'MathAssessment':
        return 'math-assessment'
      case 'MathIbl':
        return 'math-ibl'
      case 'MathFactPractice':
        return 'math-fact-practice'
      case 'MathNumberFacts':
        return 'math-number-facts'
      case 'MathDepthOfKnowledgePractice':
        return 'math-depth-of-knowledge-practice'
      case 'MathTargetedDepthOfKnowledgePractice':
        return 'math-targeted-depth-of-knowledge-practice'
      default:
        return exhaustive(assignmentType, 'MathAssignmentTypeT')
    }
  },
  parse: function (text: string): MathAssignmentTypeT | undefined | null {
    const trimmed = text.trim()
    return find(MathAssignmentTypes.all(), assignmentType => assignmentType === trimmed)
  },
}

export const parse: ParserT<MathAssignmentTypeT> = stringEnum(
  'MathAssignmentTypeT',
  MathAssignmentTypes.parse
)
