/*
 * ValidationErrorT represents the well-formed errors we expect from a Backend 400 response. This
 * module includes that type as well as a function for parsing any HTTP Response into a list of
 * them. If the response is a well-formed 400, you will get its actual validationErrors values.
 *
 */
import {NonEmptyArray} from '@freckle/non-empty'
import {
  ParserT,
  Parser,
  literal,
  nonEmptyArray,
  nullable,
  record,
  string,
  typeOf,
} from '@freckle/parser'

export type ValidationErrorT = {
  name: string
  message: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  details: any | undefined | null
}

const validationErrorParser: ParserT<ValidationErrorT> = record({
  name: string(),
  message: string(),
  details: nullable(typeOf('object')),
})

type Response = {status: number; responseJSON: {validationErrors: NonEmptyArray<ValidationErrorT>}}

/* Parse a response to validation errors. The response must be a 400 and have such errors. */
export const validationErrorsParser: ParserT<NonEmptyArray<ValidationErrorT>> = Parser.map<
  Response,
  NonEmptyArray<ValidationErrorT>
>(
  record({
    status: literal(400),
    responseJSON: record({
      validationErrors: nonEmptyArray(validationErrorParser),
    }),
  }),
  'projectValidationErrors',
  (result: {
    responseJSON: {
      validationErrors: NonEmptyArray<ValidationErrorT>
    }
  }) => result.responseJSON.validationErrors
)
