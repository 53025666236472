import filter from 'lodash/filter'
import last from 'lodash/last'
import sortBy from 'lodash/sortBy'
import intersection from 'lodash/intersection'
import range from 'lodash/range'
import {ParserT, Parser, number, string, record, array} from '@freckle/parser'

import {RlDomainIdT} from '@freckle/educator-entities/ts/common/types/rl-domain-id'
import * as RlDomainId from '@freckle/educator-entities/ts/common/types/rl-domain-id'

export type DehydratedDomain = {
  id: RlDomainIdT
}

export const parseDehydratedDomain: ParserT<DehydratedDomain> = record({
  id: RlDomainId.parse,
})

export type RlDomainT = {
  name: string
  id: RlDomainIdT
  lowestGrade: number
  highestGrade: number
  k5ThumbnailImage: string
  k5PathwayImage: string
  msThumbnailImage: string
  msPathwayImage: string
}

export const parseDomainAttrs: ParserT<RlDomainT> = record({
  id: RlDomainId.parse,
  name: string(),
  lowestGrade: number(),
  highestGrade: number(),
  k5ThumbnailImage: string(),
  k5PathwayImage: string(),
  msThumbnailImage: string(),
  msPathwayImage: string(),
})

export const parseRlDomain = Parser.mkRun<RlDomainT>(parseDomainAttrs)

export const parseRlDomains = Parser.mkRun<Array<RlDomainT>>(array(parseDomainAttrs))

export function firstDomainCoveringGrade(
  domains: Array<RlDomainT>,
  grade: number
): RlDomainT | undefined | null {
  const sortedDomains = sortBy(domains, d => [d.lowestGrade, d.highestGrade])
  const coveringDomains = filter(sortedDomains, d => domainHasGrade(d, grade))

  if (coveringDomains.length === 0) {
    // This grade is higher than *all* available Domains, so we pluck the last (highest) possible
    // Domain.
    return last(sortedDomains)
  }

  return coveringDomains[0]
}

export function filterDomainsByGrade(domains: Array<RlDomainT>, grade: number): Array<RlDomainT> {
  return filter(domains, d => domainHasGrade(d, grade))
}

export function domainRangeOverlaps(
  domain: RlDomainT,
  testRange: {
    min: number
    max: number
  }
): boolean {
  const domainRange = range(domain.lowestGrade, domain.highestGrade + 1)
  const tRange = range(testRange.min, testRange.max + 1)

  return intersection(domainRange, tRange).length > 0
}

export function rejectDomainsByRange(
  domains: Array<RlDomainT>,
  testRange: {
    min: number
    max: number
  }
): Array<RlDomainT> {
  return filter(domains, d => !domainRangeOverlaps(d, testRange))
}

export function domainHasGrade(domain: RlDomainT, grade: number): boolean {
  return grade >= domain.lowestGrade && grade <= domain.highestGrade
}

export const domainGrades = (domain: RlDomainT): number[] =>
  range(domain.lowestGrade, domain.highestGrade + 1)

// TODO: Add Financial Literacy Content https://app.asana.com/0/1201335092255974/1203684205766628
export function domainHasPrintables(domain: RlDomainT): boolean {
  const flRegex = new RegExp('financial literacy', 'i')
  return !flRegex.test(domain.name)
}

export function domainIdIsForWritingDomain(domainId: RlDomainIdT): boolean {
  return domainId.endsWith('W')
}
