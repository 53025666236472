import {Moment} from 'moment-timezone'
import includes from 'lodash/includes'
import filter from 'lodash/filter'
import find from 'lodash/find'
import sumBy from 'lodash/sumBy'
import map from 'lodash/map'
import {ajaxJsonCall} from '@freckle/ajax'
import CApiHelper from '@freckle/educator-entities/ts/common/helpers/common-api-helper'
import {legacyWithCacheDictionary} from '@freckle/educator-entities/ts/common/helpers/common-api-helper'
import {ParserT, Parser, record, number, array, date} from '@freckle/parser'
import {parseSubject, SubjectT} from '@freckle/educator-entities/ts/common/helpers/subjects'
import {toFragment} from '@freckle/educator-entities/ts/common/helpers/routers/query-params'
import {getTimeSpan} from '@freckle/educator-entities/ts/common/helpers/assignment-context'

export type ResourcesForCourseT = {
  courseId: number
  claimedFreeResources: number
}

export type ClaimedFreeResourcesT = {
  courses: Array<ResourcesForCourseT>
  timeSpan: {
    from: Moment
    to: Moment
  }
  premiumSubjects: Array<SubjectT>
  maxFreeResourcesPerCourse: number
}

const parseResourcesForCourse: ParserT<ResourcesForCourseT> = record({
  courseId: number(),
  claimedFreeResources: number(),
})

const parse: ParserT<ClaimedFreeResourcesT> = record({
  courses: array(parseResourcesForCourse),
  timeSpan: record({
    from: date(),
    to: date(),
  }),
  premiumSubjects: array(parseSubject),
  maxFreeResourcesPerCourse: number(),
})

const parseClaimedFreeResources = Parser.mkRun<ClaimedFreeResourcesT>(parse)

async function _fetchClaimedFreeResources(teacherId: number): Promise<ClaimedFreeResourcesT> {
  const url = toFragment(
    CApiHelper.fancyPaths.v2.teachers.teacher_claimed_free_resources._(teacherId),
    getTimeSpan()
  )
  return ajaxJsonCall({
    url,
    method: 'GET',
  }).then(parseClaimedFreeResources)
}

const {fetchWithCache, markCacheStale} = legacyWithCacheDictionary<number, ClaimedFreeResourcesT>(
  _fetchClaimedFreeResources
)

export const fetchClaimedFreeResourcesForCurrentWeek = fetchWithCache
export const markClaimedFreeResourcesStale = markCacheStale

export function getDisabledCourseIds(
  currentSubject: SubjectT | null | undefined,
  claimedFreeResources: ClaimedFreeResourcesT
): Array<number> {
  const {maxFreeResourcesPerCourse, courses, premiumSubjects} = claimedFreeResources

  if (
    currentSubject !== null &&
    currentSubject !== undefined &&
    includes(premiumSubjects, currentSubject)
  ) {
    return []
  }

  const coursesWithNoResources = filter(
    courses,
    ({claimedFreeResources}) => claimedFreeResources >= maxFreeResourcesPerCourse
  )
  return map(coursesWithNoResources, ({courseId}) => courseId)
}

export function getNumRemainingFreeResourcesForCourse(
  claimedFreeResources: ClaimedFreeResourcesT,
  courseId: number
): number | null {
  const {maxFreeResourcesPerCourse, courses} = claimedFreeResources
  const course = find(courses, resourcesForCourse => resourcesForCourse.courseId === courseId)
  if (course === undefined) {
    return null
  }
  return Math.max(maxFreeResourcesPerCourse - course.claimedFreeResources, 0)
}

export function hasRemainingFreeResourcesInAnyCourse(
  claimedFreeResources: ClaimedFreeResourcesT
): boolean {
  const {maxFreeResourcesPerCourse, courses} = claimedFreeResources
  const totalRemainingFreeResources = sumBy(
    courses,
    ({claimedFreeResources}) => maxFreeResourcesPerCourse - claimedFreeResources
  )
  return totalRemainingFreeResources > 0
}

export function canReassignInCourse(
  claimedFreeResources: ClaimedFreeResourcesT,
  courseId: number,
  isSubjectPremium: boolean
): boolean {
  if (isSubjectPremium) return true
  const resources = getNumRemainingFreeResourcesForCourse(claimedFreeResources, courseId)
  return resources === null || resources > 0
}
