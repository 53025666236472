import * as React from 'react'
import {usePremiumLicenses} from '@freckle/classroom/ts/hooks/use-premium-licenses'
import {useTeacher} from '@freckle/classroom/ts/hooks/use-teacher'
import {WithResources} from '@freckle/classroom/ts/common/components/with-resources'

import {NavbarContainer} from './navbar-container'

export function NavbarHandler(): React.ReactElement {
  const teacher = useTeacher()
  const premiumLicenses = usePremiumLicenses()

  return (
    <WithResources
      resources={{teacher, premiumLicenses}}
      render={data => <NavbarContainer {...data} />}
    />
  )
}
