import Spinner from 'spin'
import alertify from 'alertifyjs'
import {TranslateT} from '@freckle/educator-entities/ts/common/helpers/translate'

type CourseCreationPermission = 'allowed' | 'premium-cap-reached' | 'free-cap-reached'

export const maxCoursesForPremium: number = 50
export const maxCoursesForFreeEdition: number = 15

// This API is weird, change it to something more sane
export function addSpinner(): void {
  const opts = {
    lines: 13,
    length: 20,
    width: 5,
    radius: 30,
    corners: 1,
    rotate: 0,
    direction: 1,
    color: '#001F60',
    speed: 1,
    trail: 60,
    shadow: false,
    hwaccel: false,
    className: 'spinner',
    zIndex: 2e9,
    top: '-20px',
    left: '-5px',
  }
  const target = document.getElementById('js-spinner-div')
  new Spinner(opts).spin(target)
}

export function showSpinner() {
  updateSpinnerWrapperDisplayMode('block')
  updateSpinnerDivDisplayMode('block')
}

export function hideSpinner() {
  updateSpinnerWrapperDisplayMode('none')
  updateSpinnerDivDisplayMode('none')
}

export function showSpinnerUntil(promise: Promise<void>): Promise<void> {
  showSpinner()
  return promise.finally(hideSpinner)
}

export function isAllowedToCreateCourse(
  isPremium: boolean,
  numCourses: number
): CourseCreationPermission {
  if (isPremium && numCourses >= maxCoursesForPremium) {
    return 'premium-cap-reached'
  } else if (!isPremium && numCourses >= maxCoursesForFreeEdition) {
    return 'free-cap-reached'
  } else {
    return 'allowed'
  }
}

export function displayAlert(title: string, message: string, onOk: Function | null | undefined) {
  alertify.alert(title, message, onOk)
}

export function displayNotification(
  type: string | null | undefined,
  message: string,
  wait?: number | null | undefined,
  callback?: Function | null | undefined
) {
  alertify.set('notifier', 'position', 'top-right')
  const paramType = type ?? 'default'
  const paramWait = wait ?? 5
  const paramCallback = callback ? callback : {}

  const errorIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
  <path d="M19 9.5C19 14.7479 14.7463 19 9.5 19C4.25366 19 0 14.7479 0 9.5C0 4.2552 4.25366 0 9.5 0C14.7463 0 19 4.2552 19 9.5ZM9.5 11.4153C8.52682 11.4153 7.7379 12.2042 7.7379 13.1774C7.7379 14.1506 8.52682 14.9395 9.5 14.9395C10.4732 14.9395 11.2621 14.1506 11.2621 13.1774C11.2621 12.2042 10.4732 11.4153 9.5 11.4153ZM7.82704 5.0815L8.1112 10.2912C8.12449 10.535 8.32606 10.7258 8.57019 10.7258H10.4298C10.6739 10.7258 10.8755 10.535 10.8888 10.2912L11.173 5.0815C11.1873 4.81819 10.9777 4.59677 10.714 4.59677H8.28599C8.02229 4.59677 7.81268 4.81819 7.82704 5.0815Z" fill="white"/>
</svg>`
  const successIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
  <path d="M0.5 9.5C0.5 4.25273 4.75273 0 10 0C15.2473 0 19.5 4.25273 19.5 9.5C19.5 14.7473 15.2473 19 10 19C4.75273 19 0.5 14.7473 0.5 9.5ZM14.2973 7.85977C14.7018 7.45527 14.7018 6.79473 14.2973 6.39023C13.8928 5.98574 13.2322 5.98574 12.8277 6.39023L8.8125 10.4055L7.17227 8.76523C6.76777 8.36074 6.10723 8.36074 5.70273 8.76523C5.29824 9.16973 5.29824 9.83027 5.70273 10.2348L8.07773 12.6098C8.48223 13.0143 9.14277 13.0143 9.54727 12.6098L14.2973 7.85977Z" fill="white"/>
</svg>`
  const premiumIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
  <path d="M8.98075 1.05447L6.66169 5.75652L1.4731 6.51297C0.542634 6.64792 0.169737 7.79502 0.844502 8.45203L4.59833 12.11L3.71048 17.2772C3.55067 18.2113 4.5344 18.9109 5.35833 18.4741L10 16.0343L14.6417 18.4741C15.4656 18.9073 16.4493 18.2113 16.2895 17.2772L15.4017 12.11L19.1555 8.45203C19.8303 7.79502 19.4574 6.64792 18.5269 6.51297L13.3383 5.75652L11.0193 1.05447C10.6037 0.216344 9.39981 0.20569 8.98075 1.05447Z" fill="#0C5248"/>
</svg>`

  let toastIcon = ''
  if (paramType === 'success') {
    toastIcon = successIcon
  } else if (paramType === 'error') {
    toastIcon = errorIcon
  } else if (paramType === 'warning') {
    toastIcon = premiumIcon
  }

  alertify.notify(
    `<div class='toastMessage'>${toastIcon} ${message}</div>`,
    paramType,
    paramWait,
    paramCallback
  )
}

export function displayConfirm(
  title: string,
  message: string,
  onOk: Function | null | undefined,
  onCancel: Function | null | undefined
) {
  alertify.confirm(title, message, onOk, onCancel)
}

export function handleError<TError>(
  t: TranslateT,
  prettifier: (error: TError, t: TranslateT) => string | null | undefined
): Function {
  const defaultMsg = t('COMMON_ERROR_MESSAGE')
  return handleErrorWithDefault(defaultMsg, t, prettifier)
}

function handleErrorWithDefault<TError>(
  defaultMsg: string,
  t: TranslateT,
  prettifier: (error: TError, t: TranslateT) => string | null | undefined
): Function {
  // Backbone error handlers get the model first and then the response object
  // Raw api calls get the response object first
  return (first: XMLHttpRequest | {status: undefined}, second: XMLHttpRequest) => {
    const response = first.status === undefined ? second : first
    let errorMsg = null
    if (response.status === 403 || response.status === 404) {
      errorMsg = t('COMMON_UNAUTHORIZED_ERROR')
    }
    if (response.status === 400) {
      const errorsArray = JSON.parse(response.responseText).errors
      const parsedError = errorsArray !== undefined && errorsArray[0]
      errorMsg = prettifier(parsedError, t)
    }
    // catch-all error msg
    if (errorMsg === null || errorMsg === undefined) {
      errorMsg = defaultMsg
    }
    displayNotification('error', errorMsg)
  }
}

// capitalizes all the words in a sentence.
export function capitalize(s: string): string {
  return s.replace(/\b\w/g, l => l.toUpperCase())
}

function updateSpinnerWrapperDisplayMode(newDisplay: 'block' | 'none') {
  const spinnerWrapperNodes = document.getElementsByClassName('spinner-wrapper')
  const spinnerWrapperNode = spinnerWrapperNodes[0] as HTMLElement
  spinnerWrapperNode.style.display = newDisplay
}

function updateSpinnerDivDisplayMode(newDisplay: 'block' | 'none') {
  const spinnerDivNodes = document.getElementsByClassName('spinner-div')
  const spinnerDivNode = spinnerDivNodes[0] as HTMLElement
  spinnerDivNode.style.display = newDisplay
}
