import * as React from 'react'
import {LoggedRoute} from './../../logged-route'
import {
  ArticlesComponentContainer,
  AssignDecodablePage,
  ElaArticleDescriptionHandler,
  ElaAssignArticleComponentContainer,
  ElaAssignAssessmentHandler,
  ElaAssignSkillPracticeHandler,
  ElaAssignWordStudyHandler,
  ElaAssignmentsHandler,
} from './lazy'

export const routerV2ElaAssign = [
  {
    path: '/courses/:courseId/ela/assignments',
    element: <LoggedRoute component={ElaAssignmentsHandler} />,
  },
  {
    path: '/courses/:courseId/ela/targeted-skill-practice/assign',
    element: <LoggedRoute component={ElaAssignSkillPracticeHandler} />,
  },
  {
    path: '/courses/:courseId/ela/articles/:articleUuid/assign',
    element: <LoggedRoute component={ElaAssignArticleComponentContainer} />,
  },
  {
    path: '/courses/:courseId/ela/assessments',
    element: <LoggedRoute component={ElaAssignAssessmentHandler} />,
  },
  {
    path: '/courses/:courseId/ela/word-study',
    element: <LoggedRoute component={ElaAssignWordStudyHandler} />,
  },
  {
    path: '/courses/:courseId/ela/decodable/assign',
    element: <LoggedRoute component={AssignDecodablePage} />,
  },
  {
    path: '/courses/:courseId/ela/articles',
    element: <LoggedRoute component={ArticlesComponentContainer} />,
  },
  {
    path: '/courses/:courseId/ela/articles/:articleUuid',
    element: <LoggedRoute component={ElaArticleDescriptionHandler} />,
  },
]
