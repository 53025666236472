import moment, {Moment} from 'moment-timezone'
import partial from 'lodash/partial'
import {exhaustive} from '@freckle/exhaustive'
import {fromJust, mmap, maybe, fromMaybe} from '@freckle/maybe'
import {AssignmentContextArg} from '@freckle/educator-entities/ts/common/helpers/assignment-context'
import {
  createElaAdaptiveSkillsAssignment,
  createElaTargetedSkillsPracticeAssignment,
  CreateElaTargetedParams,
} from '@freckle/classroom/ts/ela/adaptive-skills/helpers/api-helper'
import {SelectedCourseToStudentIdsMapT} from '@freckle/classroom/ts/common/logic/student-course-picker'
import {RlStandardSetT} from '@freckle/educator-entities/ts/common/models/rl-standard-set'
import {RlDomainIdT} from '@freckle/educator-entities/ts/common/types/rl-domain-id'
import {
  AssignmentSubmitParamsT,
  SelectedContentT,
} from '@freckle/classroom/ts/ela/adaptive-skills/components/assign-skill-practice/assign-skill-form-container'

export type ElaAssignment = {
  id: number
  isGrammarAssignment?: boolean
}

function createMultipleCoursePromises(
  requestFn: (studentIds: Array<number>, contextArg: AssignmentContextArg) => Promise<{id: number}>,
  selectedStudents: SelectedCourseToStudentIdsMapT,
  assignmentStartsAt: Moment
): Promise<{id: number}> {
  const courseIds: number[] = Array.from(selectedStudents.keys())

  const requests: Promise<{id: number} | null>[] = courseIds.map(courseId =>
    maybe(
      () => Promise.resolve(null),
      (studentIds: number[]) =>
        requestFn(studentIds, {
          courseId,
          assignmentStartsAt,
        }) as Promise<{id: number} | null>,
      selectedStudents.get(courseId)
    )
  )

  return Promise.all(requests).then(createdAssignments => {
    const createdAssignment = fromJust(createdAssignments[0], 'No assignments created')
    return {id: createdAssignment.id}
  })
}

export function createAssignmentPromise(
  selectedStudents: SelectedCourseToStudentIdsMapT,
  assignmentParams: AssignmentSubmitParamsT,
  selectedStartsAt: Moment | null | undefined,
  elaStandardSet: RlStandardSetT
): Promise<ElaAssignment> {
  const startsAt = fromMaybe(() => moment(), selectedStartsAt)
  const startsAtMilliseconds = mmap(startsAt => startsAt.valueOf(), selectedStartsAt)

  if (assignmentParams.assignmentType === 'adaptive') {
    const {selectedDomain} = assignmentParams

    const requestFn = (
      domainId: RlDomainIdT,
      startsAt: number | null | undefined,
      studentIds: Array<number>,
      contextArg: AssignmentContextArg
    ) =>
      createElaAdaptiveSkillsAssignment(
        domainId,
        elaStandardSet.id,
        startsAt,
        studentIds,
        contextArg
      ).then(res => ({
        id: res.assignmentId,
      }))

    return createMultipleCoursePromises(
      partial(requestFn, selectedDomain.id, startsAtMilliseconds),
      selectedStudents,
      startsAt
    ).then(({id}) => ({
      id,
    }))
  } else {
    const {selectedSkillId, selectedStandardId, selectedContent, skillsForQuestions} =
      assignmentParams
    const params = makeTargetedParams(selectedContent)
    const requestFn = (studentIds: Array<number>, contextArg: AssignmentContextArg) =>
      createElaTargetedSkillsPracticeAssignment(
        selectedSkillId,
        selectedStandardId ?? null,
        elaStandardSet.id,
        skillsForQuestions,
        startsAtMilliseconds,
        params,
        studentIds,
        contextArg
      ).then(({assignmentId: id}) => ({id}))
    return createMultipleCoursePromises(requestFn, selectedStudents, startsAt).then(({id}) => ({
      id,
    }))
  }
}

const makeTargetedParams = (selectedContent: SelectedContentT): CreateElaTargetedParams => {
  switch (selectedContent.tag) {
    case 'grammar-practice':
      return {questionIds: selectedContent.questions.map(q => q.uuid)}
    case 'skills-practice':
      return {contentIds: selectedContent.contentIds}
    default:
      return exhaustive(selectedContent)
  }
}
