import filter from 'lodash/filter'
import map from 'lodash/map'
import {Moment} from 'moment-timezone'
import {NonEmptyArray} from '@freckle/non-empty'
import {maybe} from '@freckle/maybe'
import {
  ParserT,
  Parser,
  number,
  string,
  array,
  field,
  date,
  record,
  nullable,
  nonEmptyArray,
} from '@freckle/parser'
import {ajaxJsonCall} from '@freckle/ajax'

import CApiHelper from '@freckle/educator-entities/ts/common/helpers/common-api-helper'
import {parseElaParagraph} from '@freckle/educator-entities/ts/ela/articles/models/article'
import {Paragraph} from '@freckle/educator-entities/ts/ela/articles/models/article'
import {PATHS} from '@freckle/educator-entities/ts/common/helpers/paths'
import {Products} from '@freckle/educator-entities/ts/common/helpers/products'
import {RlSkillUspIdT} from '@freckle/educator-entities/ts/common/types/rl-skill-usp-id'

// An ElaPassage parameterized by the type of its questions
type GenericElaPassageT<Q> = {
  uuid: string
  paragraphs: Array<Paragraph>
  questions: Array<Q>
  author: string | null | undefined
  datePublished: Moment | null | undefined
  context: NonEmptyArray<string> | null | undefined
}

export type ElaSkillsPracticeQuestionT = {
  uuid: string
  text: string
  options: Array<string>
  answersIndexes: Array<number>
}

export type ElaPassageT = GenericElaPassageT<ElaSkillsPracticeQuestionT>

const elaAdaptiveQuestionParser: ParserT<ElaSkillsPracticeQuestionT> = record({
  uuid: field(string(), 'id'),
  text: field(string(), 'question'),
  options: array(string()),
  answersIndexes: array(number()),
})

const elaPassageParser: ParserT<ElaPassageT> = record({
  uuid: string(),
  paragraphs: Parser.map(array(parseElaParagraph), 'filterDeprecatedPicture', paragraphs =>
    filter(paragraphs, ({tag}) => tag !== 'picture')
  ),
  questions: array(elaAdaptiveQuestionParser),
  author: nullable(string()),
  datePublished: nullable(date()),
  context: nullable(nonEmptyArray(string())),
})

export const parseElaPassage = Parser.mkRun<ElaPassageT>(elaPassageParser)

export async function fetchElaPassage(passageUuid: string): Promise<ElaPassageT> {
  const url = `${PATHS.textAssetsUrl}/ela/skills-practice/passages/${passageUuid}.json`
  const response = await ajaxJsonCall({url, method: 'GET'})
  return parseElaPassage(response)
}

const elaPassageShell: ParserT<{uuid: string; skillId: RlSkillUspIdT | undefined | null}> = record({
  uuid: string(),
  skillId: nullable(string()),
})

export const parseElaPassageShell = Parser.mkRun<{uuid: string; skillId?: RlSkillUspIdT | null}>(
  elaPassageShell
)

async function fetchElaPassagesForAssignment(
  url: string,
  studentId?: number
): Promise<Array<ElaPassageT>> {
  const data = maybe(
    () => ({}),
    id => ({'student-id': id}),
    studentId
  )

  const response: Array<unknown> = await ajaxJsonCall({url, method: 'GET', data})
  console.log({response})
  const elaPassageIdForAssignment = map(response, parseElaPassageShell)
  console.log({elaPassageIdForAssignment})
  return Promise.all(
    map(elaPassageIdForAssignment, async ({uuid}) => {
      return await fetchElaPassage(uuid)
    })
  )
}

export async function fetchElaPassagesForAdaptiveAssignment(
  assignmentId: number,
  studentId: number
): Promise<Array<ElaPassageT>> {
  const url =
    CApiHelper.fancyPaths.v2.ela_assignments.ela_adaptive_skills_assignments.passages(assignmentId)
  return fetchElaPassagesForAssignment(url, studentId)
}

export async function fetchElaPassagesForTargetedAssignment(
  assignmentId: number
): Promise<Array<ElaPassageT>> {
  const url =
    CApiHelper.fancyPaths.v2.ela_assignments.ela_targeted_skills_assignments.ela_targeted_skills_assignment.passages(
      assignmentId
    )
  return fetchElaPassagesForAssignment(url)
}

export async function fetchElaPassagesForFocusSkillsAssignment(
  assignmentId: number
): Promise<Array<ElaPassageT>> {
  const path = Products.getPaths('ela_focus_skills_practice')
  const url =
    CApiHelper.fancyPaths.v3.subjects.subject.products.product.subject_product_assignments.subject_product_assignment.passages(
      path.subjectPath,
      path.productPath,
      assignmentId
    )
  return fetchElaPassagesForAssignment(url)
}
