import * as React from 'react'
import {Navigate} from 'react-router-dom'

import {LoggedRoute} from './../logged-route'
import {
  AddCoursePageHandler,
  ArchivedRostersHandler,
  EditCoursePageHandler,
  LoginCardHandler,
  RosterStudentEditPageHandler,
  RostersHandler,
} from './lazy'

export const routerV2Roster = [
  {path: '/rosters', element: <LoggedRoute component={RostersHandler} />},
  {path: '/rosters/create', element: <LoggedRoute component={AddCoursePageHandler} />},
  {
    path: '/rosters/courses/:courseId/edit',
    element: <LoggedRoute component={EditCoursePageHandler} />,
  },
  {path: '/rosters/archived-roster', element: <LoggedRoute component={ArchivedRostersHandler} />},
  {path: '/rosters/login-cards', element: <LoggedRoute component={LoginCardHandler} />},
  {path: '/login-cards', element: <Navigate to="/rosters/login-cards" replace />},
  {
    path: '/courses/:courseId/students/:studentId/edit',
    element: <LoggedRoute component={RosterStudentEditPageHandler} />,
  },
]
