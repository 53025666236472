import {
  ParserT,
  record,
  nullableDefined,
  boolean,
  number,
  string,
  stringEnum,
} from '@freckle/parser'

import {
  WordStudyQuestionType,
  WordStudyQuestionTypes,
} from '@freckle/educator-entities/ts/ela/word-study/logic/word-study-question-types'
import {WordStudyMultipleChoiceQuestionAttrs} from '@freckle/educator-entities/ts/ela/word-study/models/word-study-multiple-choice-question'
import {WordStudyInputQuestionAttrs} from '@freckle/educator-entities/ts/ela/word-study/models/word-study-input-question'
import {WordStudySortingQuestionAttrs} from './word-study-sorting-question'

export type WordStudyQuestion =
  | {
      tag: 'input'
      content: WordStudyInputQuestionAttrs
    }
  | {
      tag: 'sorting'
      content: WordStudySortingQuestionAttrs
    }
  | {
      tag: 'multiple_choice'
      content: WordStudyMultipleChoiceQuestionAttrs
    }

export type WordStudyQuestionCommonAttrs = {
  id: string
  content: string
  contentAudioUrl: string | null
  hasQuestionAudio: boolean
  diagram: string | null
  imageDescription: string | null
  imageUrl: string | null
  wordAudioUrl: string | null
  type: WordStudyQuestionType
  worth: number
}

export const parseWordStudyQuestionCommonAttrs: ParserT<WordStudyQuestionCommonAttrs> = record({
  content: string(),
  contentAudioUrl: nullableDefined(string()),
  diagram: nullableDefined(string()),
  hasQuestionAudio: boolean(),
  id: string(),
  imageDescription: nullableDefined(string()),
  imageUrl: nullableDefined(string()),
  type: stringEnum('WordStudyQuestionType', WordStudyQuestionTypes.parse),
  wordAudioUrl: nullableDefined(string()),
  worth: number(),
})
