import {exhaustive} from '@freckle/exhaustive'
import {WordStudyQuestion} from '@freckle/educator-entities/ts/ela/word-study/models/word-study-question'
import {parseWordStudyQuestion} from '@freckle/educator-entities/ts/ela/word-study/helpers/fetcher'
import {GrammarQuestionT, parseGrammarQuestion} from './grammar-question'
import {ElaPassageT, parseElaPassage} from './ela-passage'

export type TaggedElaQuestionAttrs =
  | TaggedWordStudyQuestionCommonAttrs
  | TaggedGrammarQuestionT
  | TaggedElaPassageT

export type TaggedWordStudyQuestionCommonAttrs = {
  tag: 'ela-word-study'
  content: WordStudyQuestion
}
export type TaggedGrammarQuestionT = {tag: 'ela-grammar'; content: GrammarQuestionT}
export type TaggedElaPassageT = {tag: 'ela-passage'; content: ElaPassageT}

export type ElaQuestionParseInput = {
  tag: 'word_study' | 'grammar' | 'passage'
  value: unknown
}

export const parseElaQuestion = (q: ElaQuestionParseInput): TaggedElaQuestionAttrs => {
  switch (q.tag) {
    case 'word_study':
      return {
        tag: 'ela-word-study',
        content: parseWordStudyQuestion(q.value),
      }
    case 'grammar':
      return {tag: 'ela-grammar', content: parseGrammarQuestion(q.value)}
    case 'passage':
      return {tag: 'ela-passage', content: parseElaPassage(q.value)}
    default:
      exhaustive(q.tag, 'parseElaQuestion')
  }
}
