import {HttpError} from '@freckle/educator-entities/ts/common/exceptions/http-error'
import {components, paths} from '@freckle/educator-entities/ts/curricula-api/generated-client'
import createClient from 'openapi-fetch'
import {PATHS} from '@freckle/educator-entities/ts/common/helpers/paths'

export type DecodableInfoT = components['schemas']['Decodable']

const _client = createClient<paths>({baseUrl: PATHS.textAssetsUrl})

export const fetchDecodableInfos = (): Promise<Array<DecodableInfoT>> =>
  _client
    .GET('/decodables.json')
    .then(({response, data}) =>
      response.ok && data !== undefined ? data : Promise.reject(new HttpError(response))
    )
