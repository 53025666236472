import * as React from 'react'
import {Navigate} from 'react-router-dom'
import {showErrorHandlerAndFail} from '@freckle/classroom/ts/common/routers/app-router'
import {Routes} from '@freckle/classroom/ts/common/routers/routes'

export const SignUpPageHandler = React.lazy(
  () => import(/* webpackChunkName: "SignUpPage" */ './../../components/page/signup-page')
)

export const LoginPageHandler = React.lazy(
  () => import(/* webpackChunkName: "LoginPage" */ './../../components/page/login-page/login')
)

export const SsoMismatchedSchoolHandler = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SsoMismatchedSchool" */ './../../components/page/sso-mismatched-school'
    )
)

export const ChangePasswordPageHandler = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ChangePasswordPage" */ './../../components/page/change-password-page/handler'
    )
)

export const DeletedLoginHandler = React.lazy(
  () => import(/* webpackChunkName: "DeletedLoginHandler" */ './../../../pages/deleted-login')
)

export function ErrorRootBoundary() {
  const [errorHandled, setErrorHandled] = React.useState(false)

  React.useEffect(() => {
    showErrorHandlerAndFail('Error', 'Something went wrong. Please try again later.')
    setErrorHandled(true)
  }, [])

  if (errorHandled) {
    return <Navigate to={Routes.dashboard()} replace />
  }

  return null
}
