import * as React from 'react'
import {Navigate} from 'react-router-dom'
import {exhaustive} from '@freckle/exhaustive'
import {SharedOrTransferredStudentT} from '@freckle/classroom/ts/roster/models/shared-or-transferred-student'
import {getActiveAndVisibleCourses} from '@freckle/educator-entities/ts/roster/helpers/tagged-courses'
import {useAsync} from '@freckle/educator-entities/ts/common/hooks/use-async/index'
import {StudentAttrs} from '@freckle/educator-entities/ts/users/models/student'
import {CourseMembershipAttrs} from '@freckle/educator-entities/ts/roster/models/course-membership'
import {CourseAttrs} from '@freckle/educator-entities/ts/roster/models/course'
import {SchoolAttrs} from '@freckle/educator-entities/ts/roster/models/school'
import {WithResources} from '@freckle/classroom/ts/common/components/with-resources'
import {useTeacher} from '@freckle/classroom/ts/hooks/use-teacher'
import {useSchools} from '@freckle/educator-entities/ts/common/hooks/use-schools'
import {useStudents} from '@freckle/classroom/ts/hooks/use-students'
import {useCourseMemberships} from '@freckle/educator-entities/ts/common/hooks/use-course-memberships'
import {useCourses} from '@freckle/educator-entities/ts/common/hooks/use-courses'
import {hasStudentWithName} from '@freckle/classroom/ts/users/collections/students'
import {fetchSharedOrTransferredStudents} from '@freckle/classroom/ts/roster/models/shared-or-transferred-student'
import LoginHelper from '@freckle/classroom/ts/common/helpers/login-helper'
import {TeacherAttrs} from '@freckle/classroom/ts/users/models/teacher'
import {isScreenTooSmallForMeaningfulInteraction} from '@freckle/classroom/ts/common/helpers/device'
import {shouldRedirectToIdpMergeRosterPage} from '@freckle/classroom/ts/roster/logic/idp-merging'
import {Routes} from '@freckle/classroom/ts/common/routers/routes'
import {WithAuthStatus} from '@freckle/classroom/ts/common/routers/router-v2/with-auth-status'
import {RedirectConsume} from './cookie-redirect'

//
// Navigate to first page
//
function getDefaultPageRoute(
  teacher: TeacherAttrs,
  students: Array<StudentAttrs>,
  sharedStudents: Array<SharedOrTransferredStudentT>
): string {
  const teacherFirstName = teacher.firstName
  const teacherLastName = teacher.lastName
  const teacherRole = teacher.role
  const environment = teacher.environment

  const hasPendingStudents =
    sharedStudents.filter(share => share.targetTeacherId === teacher.id).length > 0

  const teacherOnlyHasHerselfAsStudent =
    students.length === 1 && hasStudentWithName(students, teacherFirstName, teacherLastName)
  const environmentIsSet = environment !== undefined && environment !== null

  const teacherIsParent = teacherRole === 'parent'

  if ((students.length === 0 || teacherOnlyHasHerselfAsStudent) && !environmentIsSet) {
    return Routes.teacherOnboarding()
  } else if (teacherIsParent) {
    return Routes.parentWelcome()
  } else if (hasPendingStudents) {
    return Routes.rostering.rosters()
  } else {
    if (isScreenTooSmallForMeaningfulInteraction()) {
      return Routes.mobileReminder()
    } else {
      return Routes.dashboard()
    }
  }
}

type Props = {
  teacher: TeacherAttrs
  students: Array<StudentAttrs>
  courses: Array<CourseAttrs>
  courseMemberships: Array<CourseMembershipAttrs>
  schools: Array<SchoolAttrs>
  sharedStudents: Array<SharedOrTransferredStudentT>
}

const AuthRedirect = (props: Props) => {
  const {teacher, students, courses, courseMemberships, schools, sharedStudents} = props
  LoginHelper.setLoginEmail(teacher.email)
  const redirectToRosterPage = shouldRedirectToIdpMergeRosterPage(
    schools,
    getActiveAndVisibleCourses(courses),
    students,
    courseMemberships
  )

  switch (redirectToRosterPage) {
    case 'idp-merge':
      return <Navigate to={Routes.rostering.rosters()} replace />
    case 'no-idp-merge':
      return (
        <RedirectConsume
          defaultRoute={() => getDefaultPageRoute(teacher, students, sharedStudents)}
        />
      )
    default:
      return exhaustive(redirectToRosterPage)
  }
}

type WrapperProps = React.PropsWithChildren<{}>

// Wraps a public route, to redirect to a default page if the user has already authed
export function AuthRedirectWrapper({children}: WrapperProps): React.ReactElement {
  return (
    <WithAuthStatus render={({loggedIn}) => (loggedIn ? <AuthedRedirect /> : <>{children}</>)} />
  )
}

function AuthedRedirect(): React.ReactElement {
  const courses = useCourses()
  const courseMemberships = useCourseMemberships()
  const students = useStudents()
  const teacher = useTeacher()
  const schools = useSchools()
  const sharedStudents = useAsync(fetchSharedOrTransferredStudents, [
    'fetchSharedOrTransferredStudents',
  ])
  return (
    <WithResources
      resources={{teacher, students, courses, courseMemberships, schools, sharedStudents}}
      render={({teacher, students, courses, courseMemberships, schools, sharedStudents}) => {
        return (
          <AuthRedirect
            teacher={teacher}
            students={students}
            courses={courses}
            courseMemberships={courseMemberships}
            schools={schools}
            sharedStudents={sharedStudents}
          />
        )
      }}
    />
  )
}
