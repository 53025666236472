import * as React from 'react'
import {SafeTrans, useSafeTranslation} from '@freckle/educator-materials/ts/helpers/translate'
import {footerMain, footerLoginMain, footerWrap, footerText, footerTextTwo} from "../footer/footer.module.scss"
import {Link} from '../link'

export function UnauthenticatedFooter(): React.ReactElement {
  const {t} = useSafeTranslation()
  const childrenPrivacyNoticeLink = "https://renaissance.widen.net/view/pdf/9hoolemzvy/Renaissance---Childrens-Privacy-Notice.pdf?t.download=true&u=zceria"

  return (
    <div className={`${footerMain} ${footerLoginMain}`}>
      <div className={footerWrap}>
        <p className={footerText}>
          <SafeTrans
            i18nKey="FOOTER_FRECKLE_TEXT"
          />
        </p>
        <p className={footerText}>
          &copy; {' '}
          <SafeTrans
            i18nKey="FOOTER_RIGHT_RESERVED_TEXT"
          />
        </p>
        <Link
          href={childrenPrivacyNoticeLink}
          target="_blank"
          reactRouter={false}
        >
          {t('FOOTER_CHILDRENS_PRIVACY_NOTICE_TEXT')}
        </Link>
      </div>
      <p className={footerTextTwo}>
        <SafeTrans
          i18nKey="FOOTER_CONTACT_INFORMATION_TEXT"
        /></p>
    </div>
  )
}
