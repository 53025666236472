import {ajaxJsonCall} from '@freckle/ajax'
import {ParserT, record, Parser, number, fields, array} from '@freckle/parser'

import CApiHelper from '@freckle/educator-entities/ts/common/helpers/common-api-helper'

export type CourseMembershipAttrs = {
  id: number
  courseId: number
  studentId: number
}

const parseCourseMembershipAttrs: ParserT<CourseMembershipAttrs> = record({
  id: number(),
  courseId: fields(number(), 'course-id', 'courseId'),
  studentId: fields(number(), 'student-id', 'studentId'),
})

export const parseCourseMembership = Parser.mkRun<CourseMembershipAttrs>(parseCourseMembershipAttrs)

export const parseCourseMemberships = Parser.mkRun<Array<CourseMembershipAttrs>>(
  array(parseCourseMembershipAttrs)
)

export async function fetchCourseMemberships(): Promise<Array<CourseMembershipAttrs>> {
  const url = CApiHelper.fancyPaths.v2.course_memberships._0()
  const response = await ajaxJsonCall({
    url,
    method: 'GET',
    timeout: 0,
  })
  return parseCourseMemberships(response)
}
