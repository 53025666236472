import find from 'lodash/find'
import {ParserT, record, string, stringEnum, nullableDefined} from '@freckle/parser'
import {fromJust} from '@freckle/maybe'
import {components} from '@freckle/educator-entities/ts/curricula-api/generated-client'

export type WordStudyQuestionType = components['schemas']['WordStudyType']

export const WordStudyQuestionTypes = {
  Input: 'input' as WordStudyQuestionType,
  MultipleChoice: 'multiple_choice' as WordStudyQuestionType,
  Sorting: 'sorting' as WordStudyQuestionType,
  all: function(): Array<WordStudyQuestionType> {
    return [
      WordStudyQuestionTypes.Input,
      WordStudyQuestionTypes.MultipleChoice,
      WordStudyQuestionTypes.Sorting,
    ]
  },
  parse: function(s: string): WordStudyQuestionType {
    return fromJust(
      find(WordStudyQuestionTypes.all(), questionType => s === questionType),
      `Invalid WordStudyQuestionType ${s}`
    )
  },
}

type WordType = 'diagram' | 'text' | 'sound' | 'image-text'

export const WordTypes = {
  Diagram: 'diagram' as WordType,
  Text: 'text' as WordType,
  Sound: 'sound' as WordType,
  ImageAndText: 'image-text' as WordType,
  all: function(): Array<WordType> {
    return [WordTypes.Diagram, WordTypes.Text, WordTypes.Sound, WordTypes.ImageAndText]
  },
  parse: function(s: string): WordType {
    return fromJust(find(WordTypes.all(), wordType => s === wordType), `Invalid WordType ${s}`)
  },
}

export type Word = {
  imageUrl: string | null
  name: string
  type: WordType
  wordAudioUrl: string | null
}

export const parseWord: ParserT<Word> = record({
  imageUrl: nullableDefined(string()),
  name: string(),
  type: stringEnum('WordType', WordTypes.parse),
  wordAudioUrl: nullableDefined(string()),
})
