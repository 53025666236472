import * as React from 'react'
import {SyntheticEvent} from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavbarBrand from 'react-bootstrap/NavbarBrand'
import {Link} from '@freckle/educator-materials/ts/components/link'
import {Button} from '@freckle/educator-materials/ts/components/button'
import {Image} from '@freckle/educator-materials/ts/components/img'
import {Text} from '@freckle/educator-materials/ts/components/typography'
import {PremiumLicensesAttrs} from '@freckle/educator-entities/ts/common/models/premium-licenses'
import {
  isAnyPremium,
  isNotPremium,
  hasAllPremiumComponents,
} from '@freckle/educator-entities/ts/common/logic/premium-access'
import {PATHS} from '@freckle/educator-entities/ts/common/helpers/paths'
import {useSafeTranslation} from '@freckle/educator-materials/ts/helpers/translate'
import {TeacherSupportButton} from '@freckle/educator-materials/ts/components/support-button/teacher'
import NavItem from 'react-bootstrap/NavItem'
import {getSupportLink} from '@freckle/classroom/ts/common/helpers/support'
import {Resources} from '@freckle/classroom/ts/common/components/navbar/resources'
import {TeacherAttrs} from '@freckle/classroom/ts/users/models/teacher'
import {logout} from '@freckle/classroom/ts/common/helpers/logout'
import {QuoteRequestModal} from '@freckle/classroom/ts/common/components/quote-request-modal'
import {UserOptions} from '@freckle/classroom/ts/common/components/navbar/user-options'
import {EarnRewards} from '@freckle/classroom/ts/common/components/navbar/earn-rewards'
import {
  rightNav,
  navbarLogoLink,
  navbarLogoImg,
  requestQuoteStyle,
  premiumStarIcon,
} from './navbar.module.scss'

function onClickLogoutBtn(e: SyntheticEvent<EventTarget, Event>) {
  e.preventDefault()
  logout()
}

type Props = {
  teacher: TeacherAttrs
  premiumLicenses: PremiumLicensesAttrs
  showQuoteRequestModal: boolean
  hideModal: () => void
  onAccessAllFeatures: () => void
}

export function NavbarComponent(props: Props): React.ReactElement {
  const {teacher, premiumLicenses, showQuoteRequestModal, hideModal, onAccessAllFeatures} = props

  const {t} = useSafeTranslation()

  const premiumStatus = isAnyPremium(premiumLicenses) ? 'paid' : 'free'

  return (
    <div className="mainTeacherTopNavBar">
      <Navbar className={`main-navbar hidden-print`}>
        <NavbarBrand>
          <Link href="/" reactRouter addClass={navbarLogoLink}>
            <Image
              src={`${PATHS.imageAssetsUrl}/company-logos/renaissance-freckle-logo.svg`}
              alt={t('NAVBAR_LOGO_ALT_TITLE_FRECKLE')}
              title={t('NAVBAR_LOGO_ALT_TITLE_FRECKLE')}
              className={navbarLogoImg}
            />
          </Link>
        </NavbarBrand>

        <Nav className={rightNav}>
          {!hasAllPremiumComponents(premiumLicenses) && <EarnRewards />}
          {isNotPremium(premiumLicenses) && (
            <NavItem>
              <Button
                style="link"
                addClass={requestQuoteStyle}
                onClick={onAccessAllFeatures}
                dataTest="request-quote-premium-button"
              >
                <Image
                  className={premiumStarIcon}
                  src={`${PATHS.imageAssetsUrl}/icons/ic_premium-stars-teal.svg`}
                />
                <Text style="subtitle-2" htmlElement="span">
                  {t('NAV_ITEM_GET_FRECKLE_PREMIUM')}
                </Text>
              </Button>
            </NavItem>
          )}

          <Resources {...teacher} />

          <TeacherSupportButton
            supportLink={getSupportLink(premiumStatus, teacher.countryCode)}
            countryCode={teacher.countryCode}
          />

          <UserOptions
            teacher={teacher}
            onLogout={onClickLogoutBtn}
            premiumLicenses={premiumLicenses}
          />
        </Nav>
      </Navbar>

      <QuoteRequestModal
        analyticsEventKey="quoteRequestCompletedViaNavbarGetAccessToAllFeatures"
        show={showQuoteRequestModal}
        onHide={hideModal}
      />
    </div>
  )
}
