import {Parser, record, string, array} from '@freckle/parser'

export type QuestionWorkedExamples = {
  questionId: string
  workedExampleIds: string[]
}

export const parseQuestionWorkedExamples = Parser.mkRun<QuestionWorkedExamples>(
  record({
    questionId: string(),
    workedExampleIds: array(string()),
  })
)
