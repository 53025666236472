import find from 'lodash/find'
import {fromJust} from '@freckle/maybe'

export type CompletionStatusT = 'unstarted' | 'started' | 'completed'

export const CompletionStatuses = {
  unstarted: 'unstarted' as CompletionStatusT,
  started: 'started' as CompletionStatusT,
  completed: 'completed' as CompletionStatusT,

  all(): Array<CompletionStatusT> {
    return [CompletionStatuses.unstarted, CompletionStatuses.started, CompletionStatuses.completed]
  },

  parse(text: string): CompletionStatusT | undefined {
    return find(CompletionStatuses.all(), value => value === text)
  },

  parseThrows(text: string): CompletionStatusT {
    return fromJust(CompletionStatuses.parse(text), `Invalid status ${text}`)
  },
}

export function isComplete(status: CompletionStatusT): boolean {
  return status === CompletionStatuses.completed
}
