// File generated by generate-openapi-enums.js, do not change directly

import {exhaustive} from '@freckle/exhaustive'
import {fromJust} from '@freckle/maybe'
import {components} from '../../generated-client'

export type ContentArea = components['schemas']['ContentArea']

const Math: ContentArea = 'math'
const Ela: ContentArea = 'ela'

// Get all ContentArea values
function all(): ContentArea[] {
  return [Math, Ela]
}

// Parse ContentArea from a raw string, returns null if no match
function parse(raw: string): ContentArea | null {
  return all().find(e => e === raw) ?? null
}

// Parse ContentArea from a raw string, returns default if no match
function parseWithDefault(raw: string, def: ContentArea): ContentArea {
  return parse(raw) ?? def
}

// Parse ContentArea from a raw string, throws if no match
function unsafeParse(raw: string): ContentArea {
  return fromJust(parse(raw), `Invalid ContentArea: ${raw}`)
}

type Cases<Result> = {
  onMath: () => Result
  onEla: () => Result
}

// Make a function that will dispatch cases for ContentArea
function mkMatch<Result>({
  onMath,
  onEla,
}: Cases<Result>): (v: ContentArea) => Result {
  return (v: ContentArea) => {
    switch (v) {
      case 'math': return onMath()
      case 'ela': return onEla()
      default:
        return exhaustive(v)
    }
  }
}

function match<Result>(v: ContentArea, cases: Cases<Result>): Result {
  return mkMatch<Result>(cases)(v)
}

export const ContentAreas = {
  // Constants
  Math,
  Ela,

  // Enumeration
  all,

  // Parsing
  parse,
  unsafeParse,
  parseWithDefault,

  // Case dispatch
  mkMatch,
  match,
}
