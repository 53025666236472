import {NonEmptyArray, headOnNonEmpty, mkNonEmptySingleton} from '@freckle/non-empty'
import {
  ParserT,
  Parser,
  nonEmptyArray,
  nullable,
  record,
  string,
  number,
  typeOf,
  firstOf,
  pure,
} from '@freckle/parser'
import {validationErrorsParser} from '@freckle/educator-entities/ts/common/models/validation-errors'

export class ApiError extends Error {
  // N.B.: error.message set by super()
  details:
    | {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [x: string]: any
      }
    | undefined
    | null
  status: number | undefined | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(err: any) {
    const parsedError = flattenApiErrors(responseApiErrors(err))

    super(parsedError.message)
    this.name = parsedError.name
    this.details = parsedError.details
    if (typeof err?.status === 'number') {
      this.status = err.status
    }
  }
}

export type ApiErrorT = {
  name: string
  message: string
  details:
    | {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [x: string]: any
      }
    | undefined
    | null
}

// ApiError might be created from multiple returned errors which need to be flattened
// into a single error to be thrown
export const flattenApiErrors = (apiErrors: NonEmptyArray<ApiErrorT>): ApiErrorT => {
  const firstError = headOnNonEmpty(apiErrors)
  if (apiErrors.length === 1) {
    return firstError
  } else {
    return {
      // Since there may be multiple types of errors, flatten to a generic name for grouping
      name: 'MultipleApiErrors',
      message: `${apiErrors.length} errors returned`,
      details: {
        allErrors: apiErrors, // Preserve the original error object
      },
    }
  }
}

const apiErrorParser: ParserT<ApiErrorT> = record({
  name: string(),
  message: string(),
  details: nullable(typeOf('object')),
})

const fallbackErrorParser: ParserT<NonEmptyArray<ApiErrorT>> = Parser.map<
  {responseJSON: {errors: NonEmptyArray<ApiErrorT>}},
  NonEmptyArray<ApiErrorT>
>(
  record({
    responseJSON: record({
      errors: nonEmptyArray(apiErrorParser),
    }),
  }),
  'projectErrors',
  (result: {
    responseJSON: {
      errors: NonEmptyArray<ApiErrorT>
    }
  }) => result.responseJSON.errors
)

const xhrErrorParser: ParserT<NonEmptyArray<ApiErrorT>> = Parser.map<
  {status: number; readyState: number},
  NonEmptyArray<ApiErrorT>
>(
  record({
    status: number(),
    readyState: number(),
  }),
  'projectStatusError',
  ({status, readyState}) =>
    mkNonEmptySingleton({
      name: 'ApiError',
      message: `Server responded with ${status}`,
      details: {
        status,
        readyState,
      },
    })
)

export const mkApiErrorsParser = (
  fallbackMessage: string = 'Unknown error'
): ParserT<NonEmptyArray<ApiErrorT>> =>
  firstOf(
    validationErrorsParser,
    fallbackErrorParser,
    xhrErrorParser,
    pure(
      mkNonEmptySingleton({
        name: 'ApiError',
        message: fallbackMessage,
        details: null,
      })
    )
  )

export const responseApiErrors = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  response: any,
  fallbackMessage?: string
): NonEmptyArray<ApiErrorT> => {
  const apiErrorsParser = mkApiErrorsParser(fallbackMessage)
  return Parser.run(response, apiErrorsParser)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const firstResponseApiError = (response: any, fallbackMessage?: string): ApiErrorT =>
  headOnNonEmpty(responseApiErrors(response, fallbackMessage))
