import * as React from 'react'
import {addMaybeClassName} from '@freckle/educator-entities/ts/common/helpers/classnames'
import {innerHTMLText} from '@freckle/educator-entities/ts/common/models/html-text'

import {HeadingProps} from '@freckle/educator-materials/ts/components/typography/heading-props'
import {h3} from './typography.module.scss'

type Props = HeadingProps

export function H3(props: Props): React.ReactElement {
  const {children, id, addClass, dataTest} = props
  const className = addMaybeClassName(h3, addClass)
  const dataTestObj = dataTest !== null && dataTest !== undefined ? {'data-test': dataTest} : {}
  const tagToUse = props.as ?? 'h3'
  const node = React.createElement(
    tagToUse,
    {
      className,
      ...dataTestObj,
      id,
      ...(props.innerHTMLText !== undefined ? innerHTMLText(props.innerHTMLText) : {}),
    },
    children
  )
  return node
}
