import {saferStringify} from '@freckle/parser'
import isPlainObject from 'lodash/isPlainObject'

export class WithResourcesError extends Error {
  status: number | undefined | null
  readyState: number | undefined | null
  modelKey: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(error: any, modelKey: string) {
    super(`Non-error throw while fetching ${modelKey} ${saferStringify(error)}`)
    this.name = 'WithResourcesError'

    //Stash the JQuery.ajax status so that bugsnag reporting can ignore it if need be
    const errorIsPlainObject = isPlainObject(error)
    this.status = errorIsPlainObject ? parseInt(error.status, 10) : null
    this.readyState = errorIsPlainObject ? error.readyState : null
    this.modelKey = modelKey
  }
  bugsnagGroupingHash(): string {
    return `${window.location.hash} ${this.modelKey}`
  }
}
