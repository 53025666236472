import {ParserT, field, record, boolean, number} from '@freckle/parser'
import {PointT} from '@freckle/educator-entities/ts/math/common/logic/equation'

// Common Attributes to Graph Questions
export type CommonMathGraphQuestionAttrs = {
  graphShowCoordinates: boolean
  graphGridLowerBound: PointT
  graphGridUpperBound: PointT
  graphGridStepX: number
  graphGridStepY: number
}

export const parsePoint: ParserT<PointT> = record({
  x: number(),
  y: number(),
})

export const parseCommonMathGraphQuestionAttrs: ParserT<CommonMathGraphQuestionAttrs> = record({
  graphShowCoordinates: field(boolean(), 'graph-show-coordinates'),
  graphGridLowerBound: field(parsePoint, 'graph-grid-lower-bound'),
  graphGridUpperBound: field(parsePoint, 'graph-grid-upper-bound'),
  graphGridStepX: field(number(), 'graph-grid-step-x'),
  graphGridStepY: field(number(), 'graph-grid-step-y'),
})
