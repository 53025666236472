import {ParserT, Parser, record, merge, array, number} from '@freckle/parser'

import {
  Word,
  parseWord,
} from '@freckle/educator-entities/ts/ela/word-study/logic/word-study-question-types'
import {
  WordStudyQuestionCommonAttrs,
  parseWordStudyQuestionCommonAttrs,
} from './../models/word-study-question'
export type WordStudyMultipleChoiceQuestionAttrs = WordStudyQuestionCommonAttrs &
  WordStudyMultipleChoiceQuestionExtraAttrs

type WordStudyMultipleChoiceQuestionCorrectAnswer = {
  index: number
}

type WordStudyMultipleChoiceQuestionExtraAttrs = {
  answers: Array<Word>
  correctAnswers: WordStudyMultipleChoiceQuestionCorrectAnswer
}

const parseWordStudyMultipleChoiceQuestionExtraAttrs: ParserT<WordStudyMultipleChoiceQuestionExtraAttrs> =
  record({
    answers: array(parseWord),
    correctAnswers: record({index: number()}),
  })

const parseWordStudyMultipleChoiceQuestionAttrs: ParserT<WordStudyMultipleChoiceQuestionAttrs> =
  merge(parseWordStudyMultipleChoiceQuestionExtraAttrs, parseWordStudyQuestionCommonAttrs)

export const parseWordStudyMultipleChoiceQuestion =
  Parser.mkRun<WordStudyMultipleChoiceQuestionAttrs>(parseWordStudyMultipleChoiceQuestionAttrs)
