import filter from 'lodash/filter'
import map from 'lodash/map'
import {ajaxJsonCall} from '@freckle/ajax'
import {PATHS} from '@freckle/educator-entities/ts/common/helpers/paths'
import {ParserT, Parser, array, boolean, record, string} from '@freckle/parser'

import {ContentArea} from '@freckle/educator-entities/ts/curricula-api/generated-client/enums/content-area'
import CApiHelper from '@freckle/educator-entities/ts/common/helpers/common-api-helper'

export type RlStandardSetT = {
  id: string
  name: string
  isLive: boolean
  description: string
  domainLabel: string
  standardLabel: string
  // Plural versions of the above labels, from MathSystemConfiguration where we simply add an 's'
  domainsLabel: string
  standardsLabel: string
  // The backend doesn't provide skills labels at all, but probably should. These are faked for now:
  skillLabel: string
  skillsLabel: string
}

export const parseAttrs: ParserT<RlStandardSetT> = Parser.map<
  {
    id: string
    name: string
    isLive: boolean
    description: string
    domainLabel: string
    standardLabel: string
  },
  RlStandardSetT
>(
  record({
    id: string(),
    name: string(),
    isLive: boolean(),
    description: string(),
    domainLabel: string(),
    standardLabel: string(),
  }),
  'addLabels',
  attrs => ({
    ...attrs,
    domainsLabel: `${attrs.domainLabel}s`,
    standardsLabel: `${attrs.standardLabel}s`,
    skillLabel: 'skill',
    skillsLabel: 'skills',
  })
)

export function fetchRlStandardSets(contentArea: ContentArea): Promise<Array<RlStandardSetT>> {
  const url = `${PATHS.textAssetsUrl}/${contentArea}/standard-sets.json`

  return ajaxJsonCall({
    url,
    method: 'GET',
  }).then(response => {
    return Parser.run(response, array(parseAttrs))
  })
}

export function fetchRlStandardSetForSchool(schoolId: number): Promise<RlStandardSetT> {
  const url = CApiHelper.fancyPaths.v3.v3_schools.v3_school.school_math.standard_set(schoolId)

  return ajaxJsonCall({
    url,
    method: 'GET',
  }).then(response => {
    return Parser.run(response, parseAttrs)
  })
}

// NB: StandardSet Dark Test users include all Freckle email addresses, and
// are shown StandardSets that lack defined Administrative Areas
export function filterStandardSetsForDarkTest(
  standardSets: Array<RlStandardSetT>,
  email: string,
  selectedStandardSetId?: string | null
): Array<RlStandardSetT> {
  const hasSelectedStandardSetId =
    selectedStandardSetId !== undefined && selectedStandardSetId !== null
  return isStaffEmail(email)
    ? appendIdToStandardSetDescription(standardSets)
    : filter(
        standardSets,
        s => s.isLive || (hasSelectedStandardSetId && selectedStandardSetId === s.id)
      )
}

const STAFF_EMAIL_DOMAINS = ['freckle.com', 'renaissance.com', 'renlearn.co.uk']

function isStaffEmail(email: string): boolean {
  return STAFF_EMAIL_DOMAINS.some(domain => email.endsWith(`@${domain}`))
}

// In the dark test and console, there are standard sets that have the same
// name/description label despite being distinct systems. The function below
// appends the ID to the description label
export function appendIdToStandardSetDescription(
  standardSets: Array<RlStandardSetT>
): Array<RlStandardSetT> {
  return map(standardSets, standardSet => ({
    ...standardSet,
    description: !standardSet.isLive
      ? `${standardSet.description} - ${standardSet.id} (in testing)`
      : `${standardSet.description} - ${standardSet.id}`,
  }))
}
