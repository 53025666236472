import * as React from 'react'
import {Navigate} from 'react-router-dom'

import {AuthRedirectWrapper} from './auth-redirect'

export function DefaultPage(): React.ReactElement {
  return (
    <AuthRedirectWrapper>
      <Navigate to="/login" replace />
    </AuthRedirectWrapper>
  )
}
