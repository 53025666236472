import * as React from 'react'
import {useSafeTranslation} from '@freckle/educator-materials/ts/helpers/translate'
import {mmap} from '@freckle/maybe'
import NavDropdown from 'react-bootstrap/NavDropdown'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFolder} from '@fortawesome/free-solid-svg-icons'
import {AmericanEnglishExclusiveAddOn} from '@freckle/classroom/ts/common/components/american-english-exclusive-add-on'
import {LangT} from '@freckle/educator-entities/ts/common/helpers/languages'
import {getCertificatesUrl} from '../../../helpers/certificates'
import {resources, icon, titleStyle} from './resources.module.scss'
import {ExternalLinkItem} from './external-link-item'
import {mkHrefAction} from './action'
import {EducatorAcademyLink} from './educator-academy-link'

type Props = {
  countryCode: string | undefined | null
  language: LangT
  firstName: string
  lastName: string
  email: string
  id: number
  schoolId: number | null | undefined
}

export function Resources(props: Props): React.ReactElement {
  const {countryCode, language, schoolId: mSchoolId, ...delegated} = props

  const {t} = useSafeTranslation()
  return (
    <NavDropdown
      id="navbar-resources"
      className={resources}
      title={
        <>
          <FontAwesomeIcon icon={faFolder} className={icon} />
          <span className={titleStyle}>&nbsp;{t('NAVBAR_RESOURCES_TITLE')}&nbsp;</span>
        </>
      }
      data-test="navbar-resources"
    >
      {mmap(
        () => (
          <EducatorAcademyLink {...delegated} />
        ),
        mSchoolId
      )}

      <ExternalLinkItem
        action={mkHrefAction(
          'https://classroom-assets.freckle.com/marketing-assets/parent-pack-freckle.pdf'
        )}
        dataTest="family-letter"
        text={t('NAVBAR_RESOURCES_FAMILY_LETTER')}
      />
      <ExternalLinkItem
        action={mkHrefAction(
          'https://classroom-assets.freckle.com/marketing-assets/FreckleHandout.pdf'
        )}
        dataTest="freckle-overview"
        text={t('NAVBAR_RESOURCES_FRECKLE_OVERVIEW')}
      />
      <ExternalLinkItem
        action={mkHrefAction(getCertificatesUrl(countryCode))}
        dataTest="freckle-certificates"
        text={t('NAVBAR_RESOURCES_CERTIFICATES')}
      />
      <AmericanEnglishExclusiveAddOn userLanguage={language}>
        <ExternalLinkItem
          action={mkHrefAction(
            'https://renaissance.widen.net/s/jqmxl9fgcr/735902-freckle-flyer-usage-recommendations'
          )}
          dataTest="freckle-usage-recommendations"
          text={t('NAVBAR_RESOURCES_USAGE_RECOMMENDATIONS')}
        />
      </AmericanEnglishExclusiveAddOn>
      <ExternalLinkItem
        action={mkHrefAction(
          'https://resources.lalilo.com/Implementation_Guide_Individualized_Skills_Practice_Literacy.pdf'
        )}
        dataTest="freckle-lalilo-implementation-guide"
        text={t('FRECKLE_AND_LALILO')}
      />
    </NavDropdown>
  )
}
