import every from 'lodash/every'
import filter from 'lodash/filter'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import range from 'lodash/range'
import sortBy from 'lodash/sortBy'
import {NonEmptyArray} from '@freckle/non-empty'
import {mkNonEmpty} from '@freckle/non-empty'
import {exhaustive} from '@freckle/exhaustive'
import {ParserT, record, number, firstOf, literal} from '@freckle/parser'

export type PointT = {
  x: number
  y: number
}

export const parsePoint: ParserT<PointT> = record({
  x: number(),
  y: number(),
})

const DEFAULT_MINIMUM_POINTS = 8
const EPSILON = 1e-4

const nearlyEqual = function (x: number, y: number): boolean {
  return Math.abs(x - y) < EPSILON
}

type RangeT = {
  start: number
  stop: number
  stepBy: number
}

// Generate points for function in given range
export const generateSamplePointsForFunction = function (
  rangeArg: RangeT,
  fn: (x: number) => number
): Array<PointT> {
  const point = (x: number, y: number) => ({x, y})
  const {start, stop, stepBy} = rangeArg
  const points = map(range(start, stop + stepBy, stepBy), x => point(x, fn(x)))
  const wholePoints = filter(points, p => nearlyEqual(Math.round(p.y), p.y))
  return map(wholePoints, p => point(p.x, Math.round(p.y)))
}

// Sort points in order from most to least likely that a student
// would use it as an answer:
//  1. Sort points by closest to the horizontal center of the graph
//  2. Sort points by zero, positive, and negative x
//
// This is obviously a heuristic, but it seems to generate pleasing
// results
export const studentSort = function (range: RangeT, points: Array<PointT>): Array<PointT> {
  const {start, stop} = range

  // Sort by closest to center-x
  const centerX = (start + stop) / 2
  const sortedNearCenter = sortBy(points, point => Math.abs(point.x - centerX))

  // Sort by zero, positive, then negative x
  const [ZERO, POSITIVE, NEGATIVE] = [0, 1, 2] // Order of preference for x
  return sortBy(sortedNearCenter, point =>
    point.x === 0 ? ZERO : point.x > 0 ? POSITIVE : NEGATIVE
  )
}

// Linear Function

// y = m * x + b

export type LinearEquationCoefficientsT = {
  coefficientX1: number
  coefficientX0: number
}

export const getLinearFunction = function ({
  coefficientX1,
  coefficientX0,
}: LinearEquationCoefficientsT): (x: number) => number {
  return (x: number) => coefficientX1 * x + coefficientX0
}

export const validatePointsForLinearEquation = function (
  points: Array<PointT>,
  linearEquationCoefficients: LinearEquationCoefficientsT
): boolean {
  if (points.length === 0) {
    return false
  }
  const fn = getLinearFunction(linearEquationCoefficients)
  return every(points, point => nearlyEqual(fn(point.x), point.y))
}

export const generateSamplePointsForLinearEquation = function (
  range: RangeT,
  linearEquationCoefficients: LinearEquationCoefficientsT
): NonEmptyArray<PointT> | undefined | null {
  const fn = getLinearFunction(linearEquationCoefficients)
  const points = studentSort(range, generateSamplePointsForFunction(range, fn))
  return mkNonEmpty(points.slice(0, DEFAULT_MINIMUM_POINTS))
}

// Quadratic Function

// Standard Form:
// y = a * x^2 + b * x + c
export type QuadraticEquationCoefficientsT = {
  coefficientX2: number
  coefficientX1: number
  coefficientX0: number
}

export const getQuadraticFunction = function ({
  coefficientX2,
  coefficientX1,
  coefficientX0,
}: QuadraticEquationCoefficientsT): (x: number) => number {
  return (x: number) => coefficientX2 * Math.pow(x, 2) + coefficientX1 * x + coefficientX0
}

// Get the derivative of the quadratic function to validate the vertex point
export const getDerivativeOfQuadractiqEquation = function ({
  coefficientX2,
  coefficientX1,
}: QuadraticEquationCoefficientsT): LinearEquationCoefficientsT {
  return {
    coefficientX1: 2 * coefficientX2,
    coefficientX0: coefficientX1,
  }
}

// Get vertex given f(x) = ax² + bx + c
//   vx = -b / 2a
//   vertex  = (vx, f(vx))
const getVertexForQuadraticEquation = function (
  quadraticEquationCoefficients: QuadraticEquationCoefficientsT
): PointT {
  const fn = getQuadraticFunction(quadraticEquationCoefficients)
  const {coefficientX2, coefficientX1} = quadraticEquationCoefficients
  const vx = Math.round((-coefficientX1 / 2) * coefficientX2)
  return {x: vx, y: fn(vx)}
}

export const generateSamplePointsForQuadraticEquation = function (
  range: RangeT,
  quadraticEquationCoefficients: QuadraticEquationCoefficientsT
): {
  vertex: PointT
  points: Array<PointT>
} {
  const fn = getQuadraticFunction(quadraticEquationCoefficients)
  const vertex = getVertexForQuadraticEquation(quadraticEquationCoefficients)
  const points = studentSort(range, generateSamplePointsForFunction(range, fn))
  const notVertex = (point: PointT) => !isEqual(point, vertex)
  return {vertex, points: filter(points, notVertex).slice(0, DEFAULT_MINIMUM_POINTS - 1)}
}

// A vertex is valid if the derivative of the equation on this point is equal to 0
// and if the vertex coordinates validate the equation
// Equation: a * x^2 + b * x + c
// Derivative: 2a * x + b
// Is vertex if: 2a * Vx + b = 0
export const isVertexCorrect = function (
  vertex: PointT,
  quadraticEquationCoefficients: QuadraticEquationCoefficientsT
): boolean {
  const derivativeCoefficients = getDerivativeOfQuadractiqEquation(quadraticEquationCoefficients)
  const derivateFunc = getLinearFunction(derivativeCoefficients)
  const isAVertex = nearlyEqual(derivateFunc(vertex.x), 0)
  /* Check if the vertex validates the equation*/
  const fn = getQuadraticFunction(quadraticEquationCoefficients)
  const isPointCorrect = nearlyEqual(fn(vertex.x), vertex.y)
  return isAVertex && isPointCorrect
}

export const validateVertexAndPointForQuadraticEquation = function (
  vertex: PointT,
  point: PointT,
  quadraticEquationCoefficients: QuadraticEquationCoefficientsT
): boolean {
  /* Check if vertex is correct */
  const isVertexCorrectRes = isVertexCorrect(vertex, quadraticEquationCoefficients)
  /* Check if 2nd point is correct */
  const fn = getQuadraticFunction(quadraticEquationCoefficients)
  const isPointCorrect = nearlyEqual(fn(point.x), point.y)
  return isVertexCorrectRes && isPointCorrect
}

// Exponential Function

// y = a * b ^ x + c

export type ExponentialEquationCoefficientsT = {
  coefficientA: number
  coefficientB: number
  coefficientC: number
}

export const getExponentialFunction = function ({
  coefficientA,
  coefficientB,
  coefficientC,
}: ExponentialEquationCoefficientsT): (x: number) => number {
  return (x: number) => coefficientA * Math.pow(coefficientB, x) + coefficientC
}

export const validatePointsForExponentialEquation = function (
  points: Array<PointT>,
  exponentialEquationCoefficients: ExponentialEquationCoefficientsT
): boolean {
  if (points.length === 0) {
    return false
  }
  const fn = getExponentialFunction(exponentialEquationCoefficients)
  return every(points, point => nearlyEqual(fn(point.x), point.y))
}

export const generateSamplePointsForExponentialEquation = function (
  range: RangeT,
  exponentialEquationCoefficients: ExponentialEquationCoefficientsT
): NonEmptyArray<PointT> | undefined | null {
  const fn = getExponentialFunction(exponentialEquationCoefficients)
  const points = studentSort(range, generateSamplePointsForFunction(range, fn))
  return mkNonEmpty(points.slice(0, DEFAULT_MINIMUM_POINTS))
}

// Linear Inequality Function

export type InequalityT = 'lt' | 'le' | 'gt' | 'ge'

export const parseInequality: ParserT<InequalityT> = firstOf(
  literal('lt'),
  literal('le'),
  literal('gt'),
  literal('ge')
)

export const validateLinearInequationEquation = function (
  points: Array<PointT>,
  inequality: InequalityT,
  linearInequalityEquationCoefficients: LinearEquationCoefficientsT,
  correctInequality: InequalityT
): boolean {
  if (points.length === 0) {
    return false
  }
  const fn = getLinearFunction(linearInequalityEquationCoefficients)
  return (
    every(points, point => nearlyEqual(fn(point.x), point.y)) && correctInequality === inequality
  )
}

// Assumes we're in not using dangerously-set-inner-html
export function formatInequality(inequality: InequalityT): string {
  switch (inequality) {
    case 'lt':
      return '<'
    case 'gt':
      return '>'
    case 'le':
      return '≤'
    case 'ge':
      return '≥'
    default:
      return exhaustive(inequality, 'InequalityT')
  }
}

// Scatter Points

const sortPoints = function (points: Array<PointT>): Array<PointT> {
  const sortfunc = (point: PointT) => [point.x, point.y]
  return sortBy(points, sortfunc)
}

export const validateScatterPoints = function (
  points: Array<PointT>,
  correctPoints: Array<PointT>
): boolean {
  if (points.length === 0) {
    return false
  }
  return isEqual(sortPoints(points), sortPoints(correctPoints))
}
