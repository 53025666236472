import map from 'lodash/map'
import {Moment} from 'moment-timezone'
import {AnalyticsTool} from '@freckle/educator-entities/ts/common/helpers/analytics'
import {GoogleAnalytics} from '@freckle/educator-entities/ts/common/helpers/analytics'
import {IdentityProviderT} from '@freckle/educator-entities/ts/roster/identity-provider'
import {HttpError} from '@freckle/educator-entities/ts/common/exceptions/http-error'
import {logErrorAsUnhandled} from '@freckle/classroom/ts/common/helpers/exception-handlers/bugsnag-client'

// List of analytics tools that will be used
const ANALYTICS_TOOLS: Array<AnalyticsTool> = [GoogleAnalytics]

/* Events generated by user on custom actions */

type Event = {
  eventName: string
  incrementName: string
}

type EventKeyPropertiesT = {
  assignmentSharedOnRemind: Event
  assignmentSharedOnGoogle: Event
  assignmentLinkCopied: Event
  classAdded: Event
  classAddedFiveOrMoreStudents: Event
  classArchived: Event
  constructedResponseDownloaded: Event
  elaArticleShared: Event
  elaArticleReadingAssignmentCreated: Event
  elaArticleWritingAssignmentCreated: Event
  elaAssessmentCreated: Event
  elaWordStudyAssignmentCreated: Event
  enableSubjectAndDomainControl: Event
  downloadedInquirySheet: Event
  generatedAssignedPrintable: Event
  generatedAdaptivePrintable: Event
  mathAdaptiveAssignmentCreated: Event
  mathAssessmentCreated: Event
  mathFactPracticeAssignmentCreated: Event
  mathIblAssignmentCreated: Event
  mathIblShared: Event
  mathTargetedAssignmentCreated: Event
  onboardingCompleted: Event
  getStartedToRoster: Event
  getStartedToExplore: Event
  payWallEncountered: Event
  quoteRequestOpened: Event
  quoteRequestOpenedViaSubjectAndDomainControl: Event
  quoteRequestOpenedViaNavbarGetAccessToAllFeatures: Event
  quoteRequestOpenedViaYourAccountGetAccessToAllFeatures: Event
  quoteRequestCompleted: Event
  quoteRequestCompletedViaEnableSubjectAndDomainControl: Event
  quoteRequestCompletedViaNavbarGetAccessToAllFeatures: Event
  referralProgramOpened: Event
  referredTeacherSignup: Event
  signup: Event
  signupLoaded: Event
  signupExperimentRoleSelectionOn: Event
  scienceAssignmentCreated: Event
  scienceAttachmentDownloaded: Event
  slideshowFinished: Event
  socialStudyAssignmentCreated: Event
  socialStudyAttachmentDownloaded: Event
  studentArchived: Event
  studentCreated: Event
  studentPresenceEventHover: Event
  studentShared: Event
  studentTransferred: Event
  teacherSchoolUpdated: Event
  classAddedFromGoogleClassroom: Event
  acceptedRosteringReminderEmail: Event
  rejectedRosteringReminderEmail: Event
  visitedScienceHomepage: Event
  scienceTestButtonClicked: Event
  scienceTestEmailSubmitted: Event
  parentNavigatedToDashboardVariantOne: Event
  parentNavigatedToDashboardVariantTwo: Event
}

const EventKeyProperties: EventKeyPropertiesT = {
  assignmentSharedOnRemind: {
    eventName: 'Assignment Shared On Remind',
    incrementName: 'num_assignments_shared_on_remind',
  },
  assignmentSharedOnGoogle: {
    eventName: 'Assignment Shared On Google',
    incrementName: 'num_assignments_shared_on_google',
  },
  assignmentLinkCopied: {
    eventName: 'Assignment Link Copied',
    incrementName: 'num_assignment_links_copied',
  },
  classAdded: {
    eventName: 'Class Added',
    incrementName: 'num_classes_added',
  },
  classAddedFiveOrMoreStudents: {
    eventName: 'Class Added (5+ students)',
    incrementName: 'num_large_classes_added',
  },
  classArchived: {
    eventName: 'Class Archived',
    incrementName: 'num_classes_archived',
  },
  constructedResponseDownloaded: {
    eventName: 'Constructed Response Downloaded',
    incrementName: 'num_constructed_response_downloaded',
  },
  elaArticleShared: {
    eventName: 'ELA Article Shared',
    incrementName: 'num_ela_article_shared',
  },
  elaArticleReadingAssignmentCreated: {
    eventName: 'Ela Article Reading Assignment Created',
    incrementName: 'num_ela_article_reading_assignments_created',
  },
  elaArticleWritingAssignmentCreated: {
    eventName: 'Ela Article Writing Assignment Created',
    incrementName: 'num_ela_article_writing_assignments_created',
  },
  elaAssessmentCreated: {
    eventName: 'Ela Assessment Created',
    incrementName: 'num_ela_assessments_created',
  },
  elaWordStudyAssignmentCreated: {
    eventName: 'Ela Word Study Assignment Created',
    incrementName: 'num_ela_word_study_assignments_created',
  },
  enableSubjectAndDomainControl: {
    eventName: 'Activity Feed - Enable Subject and Domain Control',
    incrementName: 'num_activity_feed_enable_subject_and_domain_control',
  },
  downloadedInquirySheet: {
    eventName: 'Downloaded Inquiry Sheet',
    incrementName: 'num_lesson_inquiry_sheets_downloaded',
  },
  generatedAssignedPrintable: {
    eventName: 'Generated Assigned Printable',
    incrementName: 'num_assigned_printables_generated',
  },
  generatedAdaptivePrintable: {
    eventName: 'Generated Adaptive Printable',
    incrementName: 'num_adaptive_printables_generated',
  },
  mathAdaptiveAssignmentCreated: {
    eventName: 'Math Adaptive Assignment Added',
    incrementName: 'num_math_adaptive_assignment_added',
  },
  mathAssessmentCreated: {
    eventName: 'Math Assessment Added',
    incrementName: 'num_math_assessment_added',
  },
  mathFactPracticeAssignmentCreated: {
    eventName: 'Math Fact Practice Assignment Added',
    incrementName: 'num_math_fact_practice_assignment_added',
  },
  mathIblAssignmentCreated: {
    eventName: 'Math IBL Assignment Added',
    incrementName: 'num_math_ibl_assignment_added',
  },
  mathIblShared: {
    eventName: 'Math Ibl Shared',
    incrementName: 'num_math_ibl_shared',
  },
  mathTargetedAssignmentCreated: {
    eventName: 'Math Targeted Assignment Added',
    incrementName: 'num_math_targeted_assignment_added',
  },
  onboardingCompleted: {
    eventName: 'Onboarding Completed',
    incrementName: 'num_onboarding_completed',
  },
  getStartedToRoster: {
    eventName: 'Get Started - To Roster',
    incrementName: 'num_getstarted_to_roster',
  },
  getStartedToExplore: {
    eventName: 'Get Started - To Explore',
    incrementName: 'num_getstarted_to_explore',
  },
  payWallEncountered: {
    eventName: 'Pay Wall Encountered',
    incrementName: 'num_pay_wall_encountered',
  },
  quoteRequestOpened: {
    eventName: 'Quote Request Opened',
    incrementName: 'num_quote_request_opened',
  },
  quoteRequestOpenedViaSubjectAndDomainControl: {
    eventName: 'Quote Request Opened Via Subject And Domain Control',
    incrementName: 'num_quote_request_opened_via_subject_domain_control',
  },
  quoteRequestOpenedViaNavbarGetAccessToAllFeatures: {
    eventName: 'Quote Request Opened Via Navbar Get Access To All Features',
    incrementName: 'num_quote_request_opened_via_navbar_get_access_to_all_features',
  },
  quoteRequestOpenedViaYourAccountGetAccessToAllFeatures: {
    eventName: 'Quote Request Opened Via Your Account Get Access To All Features',
    incrementName: 'num_quote_request_opened_via_you_acct_get_access_to_all_features',
  },
  quoteRequestCompleted: {
    eventName: 'Quote Request Completed',
    incrementName: 'num_quote_request_completed',
  },
  quoteRequestCompletedViaEnableSubjectAndDomainControl: {
    eventName: 'Quote Request Completed Via Enable Subject And Domain Control',
    incrementName: 'num_quote_request_completed_via_enable_subject_domain_control',
  },
  quoteRequestCompletedViaNavbarGetAccessToAllFeatures: {
    eventName: 'Quote Request Completed Via Navbar Get Access To All Features',
    incrementName: 'num_quote_request_completed_via_navbar_get_access_to_all_features',
  },
  referralProgramOpened: {
    eventName: 'Referral Program Opened',
    incrementName: 'num_referral_progarm_opened',
  },
  referredTeacherSignup: {
    eventName: 'Referred Teacher Signup',
    incrementName: 'num_referred_teacher_signups',
  },
  signup: {
    eventName: 'Signup',
    incrementName: 'num_signup',
  },
  signupLoaded: {
    eventName: 'Signup Loaded',
    incrementName: 'num_signup_loaded',
  },
  signupExperimentRoleSelectionOn: {
    eventName: 'Signup Experiment Role Selection On',
    incrementName: 'num_signup_experiment_role_selection_on',
  },
  scienceAssignmentCreated: {
    eventName: 'Science Article Assignment',
    incrementName: 'num_science_article_assignment_created',
  },
  scienceAttachmentDownloaded: {
    eventName: 'Science Attachment Downloaded',
    incrementName: 'num_science_attachment_downloaded',
  },
  slideshowFinished: {
    eventName: 'Finished Slideshow',
    incrementName: 'num_lesson_slideshow_finished',
  },
  socialStudyAssignmentCreated: {
    eventName: 'Social Studies Article Assignment',
    incrementName: 'num_social_studies_article_assignment_created',
  },
  socialStudyAttachmentDownloaded: {
    eventName: 'Social Studies Attachment Downloaded',
    incrementName: 'num_social_studies_attachment_downloaded',
  },
  studentArchived: {
    eventName: 'Student Archived',
    incrementName: 'num_students_archived',
  },
  studentCreated: {
    eventName: 'Student Created',
    incrementName: 'num_students_created',
  },
  studentPresenceEventHover: {
    eventName: 'Student Presence Event Hover',
    incrementName: 'num_student_presence_event_hovers',
  },
  studentShared: {
    eventName: 'Student Shared',
    incrementName: 'num_students_shared',
  },
  studentTransferred: {
    eventName: 'Student Transferred',
    incrementName: 'num_students_transferred',
  },
  teacherSchoolUpdated: {
    eventName: "Teacher's School Updated",
    incrementName: 'num_teacher_school_updates',
  },
  classAddedFromGoogleClassroom: {
    eventName: 'Google Classroom Import',
    incrementName: 'num_google_classroom_import',
  },
  acceptedRosteringReminderEmail: {
    eventName: 'Click on Send Rostering Reminder',
    incrementName: 'accepted_rostering_reminder_email',
  },
  rejectedRosteringReminderEmail: {
    eventName: 'Refused to Send Rostering Reminder',
    incrementName: 'rejected_rostering_reminder_email',
  },
  visitedScienceHomepage: {
    eventName: 'Visited Science Homepage',
    incrementName: 'visited_science_homepage',
  },
  scienceTestButtonClicked: {
    eventName: 'Science Test - Button Clicked',
    incrementName: 'science_test_button_clicked',
  },
  scienceTestEmailSubmitted: {
    eventName: 'Science Test - Email Submitted',
    incrementName: 'science_test_email_submitted',
  },
  parentNavigatedToDashboardVariantOne: {
    eventName: 'Family Quickstart Experiment (Variant One) - Parent navigated to dashboard',
    incrementName: 'parent_navigated_to_dashboard_1',
  },
  parentNavigatedToDashboardVariantTwo: {
    eventName: 'Family Quickstart Experiment (Variant Two) - Parent navigated to dashboard',
    incrementName: 'parent_navigated_to_dashboard_2',
  },
}

export type EventsKey = keyof EventKeyPropertiesT

type EventProperties = {
  key: EventsKey
  eventName: string
  incrementName: string
}

function eventPropertiesFor(key: EventsKey): EventProperties {
  const {incrementName, eventName} = EventKeyProperties[key]
  return {
    key,
    eventName,
    incrementName,
  }
}

/* Raw setting of a teacher property */

type SchoolAndDistrictPropertiesToSendT = {
  school_id: number
  name: string
  school_state: string
  school_is_managed_by_clever: boolean
  school_is_managed_by_classlink: boolean
  school_active_idp: IdentityProviderT | null | undefined
  district_name: string | null | undefined
  district_id: number | null | undefined
}

type TeacherPropertiesToSendT = {
  email: string
  first_name: string
  last_name: string
  name: string
  created_at: number
  number_students: number
  default_class_code: string
  teacher_is_managed_by_clever: boolean
  teacher_is_managed_by_classlink: boolean
  teacher_is_managed_by_renaissance: boolean
  teacher_environment: string | null | undefined
  teacher_role: string | null | undefined
  utm_source: string | null | undefined
  utm_medium: string | null | undefined
  utm_campaign: string | null | undefined
}

type InitialPropertiesT = {
  number_students: number
  diagnostics_taken: number
  student_sessions: number
  team_mode: boolean
}

type PropertiesToSendT =
  | {number_students: number}
  | {team_mode: boolean}
  | {student_sessions: number}
  | {email: string}
  | InitialPropertiesT
  | TeacherPropertiesToSendT
  | SchoolAndDistrictPropertiesToSendT

// Private Method to adapt to each Analytics

const dateToUnixSeconds = (d: Moment) => d.unix()

export const trackEvent = function (
  eventKey: EventsKey,
  additionalProperties: Object = {}
): Promise<Array<void>> {
  const eventProperties = eventPropertiesFor(eventKey)
  const deferreds = map(ANALYTICS_TOOLS, analyticsTool => {
    return analyticsTool.trackEvent(eventProperties.eventName, {
      [eventProperties.eventName]: new Date(),
      ...additionalProperties,
    })
  })
  return Promise.all(deferreds)
}

const setProperties = function (
  userId: number,
  properties: PropertiesToSendT
): Promise<Array<void>> {
  const deferreds = map(ANALYTICS_TOOLS, analyticsTool => {
    return analyticsTool.setProperties(userId, properties)
  })
  return Promise.all(deferreds)
}

export const setupAnalytics = function (userId: number, userEmail: string): Promise<Array<void>> {
  const deferreds = map(ANALYTICS_TOOLS, analyticsTool => {
    return analyticsTool.setup(userId, userEmail)
  })
  return Promise.all(deferreds)
}

export const logOutUser = function (): Promise<Array<void>> {
  const deferreds = map(ANALYTICS_TOOLS, analyticsTool => {
    return analyticsTool.logout()
  })
  return Promise.all(deferreds)
}

type TeacherPropertiesT = {
  email: string
  first_name: string
  last_name: string
  created_at: Moment
  number_students: number
  default_class_code: string
  teacher_is_managed_by_clever: boolean
  teacher_is_managed_by_classlink: boolean
  teacher_is_managed_by_renaissance: boolean
  teacher_environment: string | null | undefined
  teacher_role: string | null | undefined
  utm_source: string | null | undefined
  utm_medium: string | null | undefined
  utm_campaign: string | null | undefined
}

export const setTeacherProperties = function (
  userId: number,
  teacherProperties: TeacherPropertiesT
) {
  const teacherPropertiesToSend: TeacherPropertiesToSendT = {
    email: teacherProperties.email,
    first_name: teacherProperties.first_name,
    last_name: teacherProperties.last_name,
    name: `${teacherProperties.first_name} ${teacherProperties.last_name}`,
    created_at: dateToUnixSeconds(teacherProperties.created_at),
    number_students: teacherProperties.number_students,
    default_class_code: teacherProperties.default_class_code,
    teacher_is_managed_by_clever: teacherProperties.teacher_is_managed_by_clever,
    teacher_is_managed_by_classlink: teacherProperties.teacher_is_managed_by_classlink,
    teacher_is_managed_by_renaissance: teacherProperties.teacher_is_managed_by_renaissance,
    teacher_environment: teacherProperties.teacher_environment,
    teacher_role: teacherProperties.teacher_role,
    utm_source: teacherProperties.utm_source,
    utm_medium: teacherProperties.utm_medium,
    utm_campaign: teacherProperties.utm_campaign,
  }
  setProperties(userId, teacherPropertiesToSend)
}

type SchoolProperties = {
  school_name: string
  school_id: number
  school_state: string
  school_is_managed_by_clever: boolean
  school_is_managed_by_classlink: boolean
  school_active_idp: IdentityProviderT | null | undefined
}

type DistrictProperties = {
  district_name: string
  district_id: number
}

export const setSchoolAndDistrictProperties = function (
  userId: number,
  schoolProperties: SchoolProperties | null | undefined,
  districtProperties: DistrictProperties | null | undefined
) {
  if (schoolProperties) {
    // A school is represented by the Company Model
    const schoolAndDistrictProperties: SchoolAndDistrictPropertiesToSendT = {
      school_id: schoolProperties.school_id,
      name: schoolProperties.school_name,
      school_state: schoolProperties.school_state,
      school_is_managed_by_clever: schoolProperties.school_is_managed_by_clever,
      school_is_managed_by_classlink: schoolProperties.school_is_managed_by_classlink,
      school_active_idp: schoolProperties.school_active_idp,
      district_name: districtProperties && districtProperties.district_name,
      district_id: districtProperties && districtProperties.district_id,
    }
    setProperties(userId, schoolAndDistrictProperties)
  }
}

export function trackClassroomRouteChanges(locationPath: string): Promise<void> {
  return GoogleAnalytics.trackRouteChange(locationPath).catch(err =>
    logErrorAsUnhandled(new HttpError(err))
  )
}
