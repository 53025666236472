import find from 'lodash/find'
import {fromJust} from '@freckle/maybe'

export type GrammarQuestionAnswerT =
  | 'drag_and_place'
  | 'fix_the_sentence'
  | 'multiple_choice'
  | 'select_the_structure'

const dragAndPlace: GrammarQuestionAnswerT = 'drag_and_place'
const fixTheSentence: GrammarQuestionAnswerT = 'fix_the_sentence'
const multipleChoice: GrammarQuestionAnswerT = 'multiple_choice'
const selectTheStructure: GrammarQuestionAnswerT = 'select_the_structure'

export const GrammarQuestionAnswer = {
  dragAndPlace,
  fixTheSentence,
  multipleChoice,
  selectTheStructure,

  all(): Array<GrammarQuestionAnswerT> {
    return [dragAndPlace, fixTheSentence, multipleChoice, selectTheStructure]
  },

  parse(text: string): GrammarQuestionAnswerT | undefined {
    return find(GrammarQuestionAnswer.all(), value => value === text)
  },

  parseThrows(text: string): GrammarQuestionAnswerT {
    return fromJust(GrammarQuestionAnswer.parse(text), `Invalid GrammarQuestionAnswer ${text}`)
  },
}
