import {useState, useEffect} from 'react'

import {useWindowSize} from '@freckle/educator-materials/ts/hooks/use-window-size'
import {
  ResponsiveBreakpointKey,
  getResponsiveBreakpoint,
} from '@freckle/educator-materials/ts/helpers/responsive-helper'

export function useResponsiveInfo(): ResponsiveBreakpointKey {
  const {width} = useWindowSize()
  const responsiveBreakpoint = getResponsiveBreakpoint(width)
  const [breakpoint, setBreakpoint] = useState<ResponsiveBreakpointKey>(responsiveBreakpoint)
  useEffect(() => setBreakpoint(responsiveBreakpoint), [responsiveBreakpoint])

  return breakpoint
}
