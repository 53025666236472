import {NonEmptyArray} from '@freckle/non-empty'
import {ParserT, string, literal, record, nonEmptyArray, firstOf} from '@freckle/parser'

export type GrammarQuestionNotation = NonEmptyArray<GrammarQuestionNotationPiece>

export type GrammarQuestionNotationOptionList = {
  tag: 'option_list'
  contents: NonEmptyArray<string>
}

type SelectTheStructureContent =
  | {
      tag: 'correct'
      contents: string
    }
  | {
      tag: 'incorrect'
      contents: string
    }
  | {tag: 'space'}
  | {tag: 'line_break'}

export type GrammarQuestionNotationPiece =
  | {tag: 'literal'; contents: string}
  | {tag: 'oxford_comma'}
  | GrammarQuestionNotationOptionList
  | SelectTheStructureContent

export const grammarQuestionNotationParser: ParserT<GrammarQuestionNotation> = nonEmptyArray(
  firstOf(
    record({tag: literal('literal'), contents: string()}),
    record({tag: literal('oxford_comma')}),
    record({tag: literal('option_list'), contents: nonEmptyArray(string())}),
    record({tag: literal('space')}),
    record({tag: literal('line_break')}),
    record({tag: literal('correct'), contents: string()}),
    record({tag: literal('incorrect'), contents: string()})
  )
)
