import * as React from 'react'
import {Navigate} from 'react-router-dom'
import {
  Resource,
  WithResourcesInternal,
  WithResourcesArrayInternal,
  WithResourcesPropsWithOptionalErrorT,
  WithResourcesArrayPropsWithOptionalErrorT,
} from '@freckle/educator-entities/ts/common/components/with-resources'
import {Routes} from '@freckle/classroom/ts/common/routers/routes'

export function WithResources<T extends Resource>({
  resources,
  render,
  error = (error: Error) => {
    console.error(error)
    return <Navigate to={Routes.dashboard()} replace />
  },
}: WithResourcesPropsWithOptionalErrorT<T>): React.ReactElement {
  return WithResourcesInternal({resources, render, error})
}

export function WithResourcesArray<T extends Resource>({
  resources,
  render,
  error = (error: Error) => {
    console.error(error)
    return <Navigate to={Routes.dashboard()} replace />
  },
}: WithResourcesArrayPropsWithOptionalErrorT<T>): React.ReactElement {
  return WithResourcesArrayInternal({resources, render, error})
}
