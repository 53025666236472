import every from 'lodash/every'
import map from 'lodash/map'
import filter from 'lodash/filter'
import moment, {Moment} from 'moment-timezone'
import {exhaustive} from '@freckle/exhaustive'
import {SubjectT} from '@freckle/educator-entities/ts/common/helpers/subjects'

import {PremiumLicensesAttrs} from '@freckle/educator-entities/ts/common/models/premium-licenses'

const checkMaybeDateIsAfterNow = (mDate?: Moment | null): boolean => {
  return mDate !== null && mDate !== undefined && mDate.isAfter(moment())
}

// COMMON

export function isAnyPremium(premiumLicenses: PremiumLicensesAttrs): boolean {
  return (
    isMathPremium(premiumLicenses) ||
    isElaPremium(premiumLicenses) ||
    isSocialStudiesPremium(premiumLicenses) ||
    isSciencePremium(premiumLicenses)
  )
}

export function isNotPremium(premiumLicenses: PremiumLicensesAttrs): boolean {
  return !isAnyPremium(premiumLicenses)
}

export function numberOfPremiumSubjects(premiumLicenses: PremiumLicensesAttrs): number {
  const premiumFnArr = [isMathPremium, isElaPremium, isSocialStudiesPremium, isSciencePremium]
  return filter(premiumFnArr, f => f(premiumLicenses)).length
}

export function hasAllPremiumComponents(premiumLicenses: PremiumLicensesAttrs): boolean {
  const premiumFnArr = [isMathPremium, isElaPremium, isSocialStudiesPremium, isSciencePremium]
  return every(map(premiumFnArr, f => f(premiumLicenses)))
}

export function hasPremiumSubject(
  premiumLicenses: PremiumLicensesAttrs,
  subject: SubjectT
): boolean {
  switch (subject) {
    case 'math':
      return isMathPremium(premiumLicenses)
    case 'ela':
      return isElaPremium(premiumLicenses)
    case 'science':
      return isSciencePremium(premiumLicenses)
    case 'social_studies':
      return isSocialStudiesPremium(premiumLicenses)
    default:
      return exhaustive(subject, 'SubjectT')
  }
}

export function isMathPremium(premiumLicenses: PremiumLicensesAttrs): boolean {
  return checkMaybeDateIsAfterNow(premiumLicenses.mathPremiumUntil)
}

export function isElaPremium(premiumLicenses: PremiumLicensesAttrs): boolean {
  return checkMaybeDateIsAfterNow(premiumLicenses.elaPremiumUntil)
}

export function isSocialStudiesPremium(premiumLicenses: PremiumLicensesAttrs): boolean {
  return checkMaybeDateIsAfterNow(premiumLicenses.socialStudiesPremiumUntil)
}

export function isSciencePremium(premiumLicenses: PremiumLicensesAttrs): boolean {
  return checkMaybeDateIsAfterNow(premiumLicenses.sciencePremiumUntil)
}
