import {TranslateT} from '@freckle/educator-entities/ts/common/helpers/translate'
import {RlDomainT} from '@freckle/educator-entities/ts/common/models/rl-domain'
import {RlStandardWithDomainT} from '@freckle/educator-entities/ts/common/models/rl-standard'
import {RlSkillT} from '@freckle/educator-entities/ts/common/models/rl-skill'
import {
  HTMLText,
  PlainText,
  toHTMLTextDangerous,
} from '@freckle/educator-entities/ts/common/models/html-text'

function getSkillsPracticeMode(t: TranslateT): string {
  return t('common|ELA_SKILLS_PRACTICE_MODE')
}

export function getAdaptiveSkillsPracticeMode(t: TranslateT): string {
  return getSkillsPracticeMode(t)
}

export function getAdaptiveSkillsTitle(
  rlDomain: RlDomainT | undefined | null,
  t: TranslateT,
  teacherDefinedTitle?: HTMLText | PlainText | null,
  rlStandardWithDomain?: RlStandardWithDomainT | null
): HTMLText | PlainText {
  if (teacherDefinedTitle !== null && teacherDefinedTitle !== undefined) {
    return teacherDefinedTitle
  }

  if (
    rlStandardWithDomain !== null &&
    rlStandardWithDomain !== undefined &&
    rlDomain !== null &&
    rlDomain !== undefined
  ) {
    return toHTMLTextDangerous(
      t('common|ELA_ADAPTIVE_SKILLS_TITLE_WITH_STANDARD', {
        domainName: rlDomain.name,
        standardName: rlStandardWithDomain.shortName,
      })
    )
  }

  if (rlDomain !== null && rlDomain !== undefined) {
    return toHTMLTextDangerous(
      t('common|ELA_ADAPTIVE_SKILLS_TITLE_WITH_DOMAIN', {
        systemDomainDescription: rlDomain.name,
      })
    )
  }

  return toHTMLTextDangerous(t('At your level'))
}

export function getTargetedSkillsPracticeMode(t: TranslateT): string {
  return getSkillsPracticeMode(t)
}

export function getFocusSkillsPracticeMode(t: TranslateT): string {
  return getSkillsPracticeMode(t)
}

export function getTargetedSkillsTitle(
  standard: RlStandardWithDomainT,
  t: TranslateT,
  teacherDefinedTitle?: HTMLText | PlainText | null
): HTMLText | PlainText {
  if (teacherDefinedTitle !== null && teacherDefinedTitle !== undefined) {
    return teacherDefinedTitle
  }
  return toHTMLTextDangerous(
    t('common|TARGETED_SKILLS_PRACTICE_TITLE_WITH_STANDARD', {
      standardShortName: standard.shortName,
      standardName: standard.name,
    })
  )
}

export function getFocusSkillsTitle(
  standard: RlStandardWithDomainT,
  t: TranslateT,
  teacherDefinedTitle?: HTMLText | PlainText | null
): HTMLText | PlainText {
  if (teacherDefinedTitle !== null && teacherDefinedTitle !== undefined) {
    return teacherDefinedTitle
  }
  return toHTMLTextDangerous(
    t('common|TARGETED_SKILLS_PRACTICE_TITLE_WITH_STANDARD', {
      standardShortName: standard.shortName,
      standardName: standard.name,
    })
  )
}

export function getArticlePracticeMode(
  assignmentType: 'reading' | 'writing',
  t: TranslateT
): string {
  return t('common|ELA_ARTICLE_PRACTICE_MODE', {assignmentType})
}

export function getArticleTitle(
  articleTitle: string,
  teacherDefinedTitle?: HTMLText | PlainText | null
): HTMLText | PlainText {
  if (teacherDefinedTitle !== null && teacherDefinedTitle !== undefined) {
    return teacherDefinedTitle
  }
  return toHTMLTextDangerous(articleTitle)
}

export function getAssessmentPracticeMode(t: TranslateT): string {
  return t('common|ASSESSMENT_PRACTICE_MODE')
}

export function getAssessmentTitle(
  letter: string,
  t: TranslateT,
  teacherDefinedTitle?: HTMLText | PlainText | null
): HTMLText | PlainText {
  if (teacherDefinedTitle !== null && teacherDefinedTitle !== undefined) {
    return teacherDefinedTitle
  }
  return toHTMLTextDangerous(t('common|ELA_ASSESSMENT_TITLE', {letter}))
}

export function getDecodablePracticeMode(t: TranslateT): string {
  return t('common|DECODABLE_PRACTICE_MODE')
}

export function getDecodableTitle(
  decodableTitle: string,
  teacherDefinedTitle?: HTMLText | PlainText | null
): HTMLText | PlainText {
  if (teacherDefinedTitle !== null && teacherDefinedTitle !== undefined) {
    return teacherDefinedTitle
  }
  return toHTMLTextDangerous(decodableTitle)
}

export function getWordStudyPracticeMode(t: TranslateT): string {
  return t('common|WORD_STUDY_PRACTICE_MODE')
}

export function getWordStudyTitle(
  standard: RlStandardWithDomainT | undefined | null,
  skill: RlSkillT | undefined | null,
  t: TranslateT,
  teacherDefinedTitle?: HTMLText | PlainText | null
): HTMLText | PlainText {
  if (teacherDefinedTitle !== null && teacherDefinedTitle !== undefined) {
    return teacherDefinedTitle
  }
  if (skill === null || skill === undefined || standard === null || standard === undefined) {
    return toHTMLTextDangerous(t('WORD_STUDY_TITLE'))
  } else {
    return toHTMLTextDangerous(`${skill.shortName} - ${standard.name}`)
  }
}

export function getAdaptiveGrammarTitle(
  rlDomain: RlDomainT | undefined | null,
  t: TranslateT,
  teacherDefinedTitle?: HTMLText | PlainText | null
): HTMLText | PlainText {
  if (teacherDefinedTitle !== null && teacherDefinedTitle !== undefined) {
    return teacherDefinedTitle
  }

  if (rlDomain === null || rlDomain === undefined) {
    return toHTMLTextDangerous(t('ADAPTIVE_GRAMMAR_TITLE'))
  }

  return toHTMLTextDangerous(
    t('ADAPTIVE_GRAMMAR_TITLE_WITH_DOMAIN', {
      systemDomainDescription: rlDomain.name,
    })
  )
}
