import * as React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import MenuItem from 'react-bootstrap/DropdownItem'
import {faArrowUpRightFromSquare} from '@fortawesome/free-solid-svg-icons'
import {icon} from './resources.module.scss'
import {Action} from './action'

export const ExternalLinkItem = ({
  dataTest,
  text,
  action,
  disabled = false,
}: {
  dataTest: string
  text: string
  action: Action
  disabled?: boolean
}) => {
  const handleAction = action.tag === 'href' ? {href: action.href} : {onClick: action.onClick}

  return (
    <MenuItem
      target="_blank"
      data-test={`navbar-resources-${dataTest}`}
      disabled={disabled}
      {...handleAction}
    >
      <FontAwesomeIcon icon={faArrowUpRightFromSquare} className={icon} />
      &nbsp;{text}
    </MenuItem>
  )
}
