import {lazy} from 'react'

export const ElaAssignArticleComponentContainer = lazy(
  () =>
    import(
      /* webpackChunkName: "AssignArticleComponentContainer" */ './../../../../../ela/articles/components/assign-article'
    )
)

export const ElaAssignAssessmentHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "" */ './../../../../../ela/assessment/components/assign-assessment'
    )
)

export const ElaAssignWordStudyHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "ElaAssignWordStudyHandler" */ './../../../../../ela/word-study/components/assign-word-study/'
    )
)

export const ElaAssignSkillPracticeHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "ElaAssignSkillPracticeHandler" */ './../../../../../ela/adaptive-skills/components/assign-skill-practice/'
    )
)

export const ElaAssignmentsHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "ElaAssignmentsHandler" */ './../../../../../ela/common/components/assignments/'
    )
)

export const AssignDecodablePage = lazy(
  () =>
    import(
      /* webpackChunkName: "AssignDecodablePage" */ './../../../../../ela/decodables/components/assign-decodable'
    )
)

export const ElaArticleDescriptionHandler = lazy(
  () =>
    import(
      /* webpackChunkName: "ElaArticleDescriptionHandler" */ './../../../../../ela/articles/components/article-page'
    )
)

export const ArticlesComponentContainer = lazy(
  () =>
    import(
      /* webpackChunkName: "ArticlesComponentContainer" */ './../../../../../ela/articles/components/articles-page'
    )
)
