import filter from 'lodash/filter'

import {CourseAttrs, isCourseArchived, isCourseHidden} from './../models/course'
import {isIdpManaged} from '../identity-provider'

type AllCoursesTagT = 'all-courses'
type ActiveAndHiddenCoursesTagT = 'active-and-hidden'
type ActiveAndVisibleCoursesTagT = 'active-and-visible'
type ArchievedOnlyCoursesTagT = 'archived-only'
type HiddenOnlyCoursesTagT = 'hidden-only'

export type CoursesTagT =
  | AllCoursesTagT
  | ActiveAndHiddenCoursesTagT
  | ActiveAndVisibleCoursesTagT
  | ArchievedOnlyCoursesTagT
  | HiddenOnlyCoursesTagT

// All courses including archived and hidden courses
export type AllCoursesT<T> = {
  tag: 'all-courses'
  courses: Array<T>
}
export function makeAllCourses<T>(courses: Array<T>): AllCoursesT<T> {
  return {tag: 'all-courses', courses}
}

// Active courses, including hidden courses
export type ActiveHiddenCoursesT<T> = {
  tag: 'active-and-hidden'
  courses: Array<T>
}
export function makeActiveHiddenCourses<T>(courses: Array<T>): ActiveHiddenCoursesT<T> {
  return {tag: 'active-and-hidden', courses}
}

// Active courses, not including hidden courses
export type ActiveVisibleCoursesT<T> = {
  tag: 'active-and-visible'
  courses: Array<T>
}
function makeActiveVisibleCourses<T>(courses: Array<T>): ActiveVisibleCoursesT<T> {
  return {tag: 'active-and-visible', courses}
}

// Only archived courses, regardless of hidden status
type ArchivedOnlyCoursesT<T> = {
  tag: 'archived-only'
  courses: Array<T>
}

function makeArchivedOnlyCourses<T>(courses: Array<T>): ArchivedOnlyCoursesT<T> {
  return {tag: 'archived-only', courses}
}

// Only hidden courses, not including archived courses
export type HiddenOnlyCoursesT<T> = {
  tag: 'hidden-only'
  courses: Array<T>
}
function makeHiddenOnlyCourses<T>(courses: Array<T>): HiddenOnlyCoursesT<T> {
  return {tag: 'hidden-only', courses}
}

// Utility functions

export function getActiveCourses(courses: Array<CourseAttrs>): ActiveHiddenCoursesT<CourseAttrs> {
  const filteredCourses = filter(courses, c => !isCourseArchived(c))
  return makeActiveHiddenCourses<CourseAttrs>(filteredCourses)
}

export function getArchivedCourses(courses: Array<CourseAttrs>): ArchivedOnlyCoursesT<CourseAttrs> {
  const filteredCourses = filter(courses, c => isCourseArchived(c))
  return makeArchivedOnlyCourses<CourseAttrs>(filteredCourses)
}

export function getActiveAndVisibleCourses(
  courses: Array<CourseAttrs>
): ActiveVisibleCoursesT<CourseAttrs> {
  const filteredCourses = filter(courses, c => !isCourseArchived(c) && !isCourseHidden(c))
  return makeActiveVisibleCourses<CourseAttrs>(filteredCourses)
}

export function getActiveAndHiddenCourses(
  courses: Array<CourseAttrs>
): HiddenOnlyCoursesT<CourseAttrs> {
  const filteredCourses = filter(courses, c => !isCourseArchived(c) && isCourseHidden(c))
  return makeHiddenOnlyCourses<CourseAttrs>(filteredCourses)
}

export function getModifiableCourses(courses: Array<CourseAttrs>): Array<CourseAttrs> {
  return filter(getActiveAndVisibleCourses(courses).courses, course => !isIdpManaged(course))
}
