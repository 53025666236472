import {maybe} from '@freckle/maybe'

const US_CERTIFICATES_URL = 'https://certificates.freckle.com/'
const UK_CERTIFICATES_URL = 'https://certificates.freckle.com/en-gb/'

// Determine which certificates site to send the user to based on their country code.
export function getCertificatesUrl(countryCode: string | null | undefined): string {
  return maybe(
    () => US_CERTIFICATES_URL,
    code => {
      switch (code) {
        case 'USA':
          return US_CERTIFICATES_URL
        case 'GBR':
          return UK_CERTIFICATES_URL
        case 'IRL':
          return UK_CERTIFICATES_URL
        default:
          return US_CERTIFICATES_URL
      }
    },
    countryCode
  )
}
