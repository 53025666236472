/* Preferred usage
 *
 * import {MathAssessmentTopicT} from './assessment-topic'
 * import * as MathAssessmentTopic from './assessment-topic''
 *
 * const topic = Parser.run(response, MathAssessmentTopic.parse)
 * const key = MathAssessmentTopic.toKey(topic)
 * const formatted = MathAssessmentTopic.format(topic, mathStandardSet, rlDomains, t)
 */

import flatMap from 'lodash/flatMap'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import {maybe} from '@freckle/maybe'
import {ParserT, array, number, record} from '@freckle/parser'
import {TranslateT} from '@freckle/educator-entities/ts/common/helpers/translate'
import {RlDomainT} from '@freckle/educator-entities/ts/common/models/rl-domain'
import {filterDomainsByGrade} from '@freckle/educator-entities/ts/common/models/rl-domain'

import {RlStandardSetT} from '@freckle/educator-entities/ts/common/models/rl-standard-set'
import * as RlDomainStandard from './rl-domain-standard'
import {RlDomainStandardT} from './rl-domain-standard'

export type MathAssessmentTopicT = {
  schoolGrade: number
  domainStandards: Array<RlDomainStandardT>
}

export const parse: ParserT<MathAssessmentTopicT> = record({
  schoolGrade: number(),
  domainStandards: array(RlDomainStandard.parse),
})

export function toKey(topic: MathAssessmentTopicT): string {
  const {schoolGrade, domainStandards} = topic
  const keys = sortBy(
    map(domainStandards, domainStandard => RlDomainStandard.toKey(domainStandard))
  )
  return `${schoolGrade}-${keys.join('-')}`
}

export function format(
  topic: MathAssessmentTopicT,
  mathStandardSet: RlStandardSetT,
  rlDomains: Array<RlDomainT>,
  t: TranslateT
): string {
  const {schoolGrade, domainStandards} = topic

  const {domainsLabel, standardsLabel} = mathStandardSet

  if (domainStandards.length > 1) {
    const allDomainsForGrade = filterDomainsByGrade(rlDomains, schoolGrade)
    const standards = flatMap(domainStandards, ds => ds.standards)

    return domainStandards.length === allDomainsForGrade.length && standards.length === 0
      ? t('common|ASSESSMENT_TOPIC_FORMAT_ALL_DOMAINS_FOR_GRADE', {
          domainsLabel,
          schoolGrade,
        })
      : t('common|ASSESSMENT_TOPIC_FORMAT_MULTIPLE_DOMAINS_AND_STANDARDS', {
          domainsLabel,
          standardsLabel,
        })
  } else {
    return maybe(
      () => t('common|ASSESSMENT_TOPIC_FORMAT_NO_DOMAINS', {domainsLabel}),
      ({domain, standards}) => {
        if (standards.length > 1) {
          return t('common|ASSESSMENT_TOPIC_FORMAT_MULTIPLE_STANDARDS', {
            domainName: domain.name,
            mdash,
            standardsLabel,
          })
        } else {
          return maybe(
            () =>
              //The convention being used by the backend is: if the domain is specified but
              //the standards array is empty, it means the entire domain has been selected.
              t('common|ASSESSMENT_TOPIC_FORMAT_ALL_STANDARDS', {
                domainName: domain.name,
                mdash,
                standardsLabel,
              }),
            ({name: standardName}) =>
              t('common|ASSESSMENT_TOPIC_FORMAT_DOMAIN_AND_STANDARD', {
                domainName: domain.name,
                mdash,
                standardName,
              }),
            standards[0]
          )
        }
      },
      domainStandards[0]
    )
  }
}

export function formatDomains(
  domains: Array<RlDomainT>,
  mathStandardSet: RlStandardSetT,
  t: TranslateT
): string {
  const {domainsLabel} = mathStandardSet

  if (domains.length > 1) {
    return t('common|ASSESSMENT_TOPIC_FORMAT_MULTIPLE_DOMAINS', {domainsLabel})
  } else {
    return maybe(
      () => t('common|ASSESSMENT_TOPIC_FORMAT_NO_DOMAINS', {domainsLabel}),
      domain => `${domain.name}`,
      domains[0]
    )
  }
}

const mdash = '\u2014'
