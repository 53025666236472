import {
  ParserT,
  field,
  record,
  firstOf,
  literal,
  onSelf,
  merge,
  obfuscated,
  array,
} from '@freckle/parser'
import {
  validateScatterPoints,
  PointT,
} from '@freckle/educator-entities/ts/math/common/logic/equation'
import {ScatterPointsGraphAnswerT} from '@freckle/educator-entities/ts/math/common/logic/answer-types'
import {
  CommonMathGraphQuestionAttrs,
  parseCommonMathGraphQuestionAttrs,
  parsePoint,
} from '@freckle/educator-entities/ts/math/common/models/questions/common-parser'
import {MathQuestionMetadataAttrs} from '@freckle/educator-entities/ts/math/common/models/questions'

type MathQuestionGraphScatterPointsExtraAttrs = {
  graphScatterPointsPoints: Array<PointT>
}

const rawGraphScatterPointsEquationCoefficients: ParserT<Array<PointT>> = array(parsePoint)

const parseGraphScatterPointsEquationCoefficients: ParserT<MathQuestionGraphScatterPointsExtraAttrs> =
  firstOf(
    record({
      graphScatterPointsPoints: field(
        rawGraphScatterPointsEquationCoefficients,
        'graph-scatter-points-points'
      ),
    }),
    record({
      graphScatterPointsPoints: field(
        obfuscated(rawGraphScatterPointsEquationCoefficients),
        'obfuscated-graph-scatter-points-points'
      ),
    })
  )

export type MathQuestionGraphScatterPointsMetadataAttrs = CommonMathGraphQuestionAttrs &
  MathQuestionGraphScatterPointsExtraAttrs
const parseMathQuestionGraphScatterPointsAttrs: ParserT<MathQuestionGraphScatterPointsMetadataAttrs> =
  merge(parseCommonMathGraphQuestionAttrs, parseGraphScatterPointsEquationCoefficients)

export const parseMathQuestionGraphScatterPointsMetadata: ParserT<MathQuestionMetadataAttrs> =
  record({
    answerType: field(literal('graph_scatter_points'), 'answer-type'),
    content: onSelf(parseMathQuestionGraphScatterPointsAttrs),
  })

// Return :: Double (either 0 or 100)
export function getAnswerCorrectness(
  metadata: MathQuestionGraphScatterPointsMetadataAttrs,
  answer: ScatterPointsGraphAnswerT
): number {
  const {graphScatterPointsPoints} = metadata

  const isCorrect = validateScatterPoints(answer.points, graphScatterPointsPoints)
  return isCorrect ? 100 : 0
}
