import {EnvironmentT} from '@freckle/educator-entities/ts/config/environment'

export type SplitIOTreatmentsMapT = Map<string, string>

export function getSplitIOBrowserSDKAPIKey(env?: EnvironmentT | null): string {
  const defaultKey = 'lnqpuohqcfl7srcm173s23sj3htbl3fign6g'
  switch (env) {
    case 'localhost':
    case 'staging':
    case 'test':
      return defaultKey
    case 'production':
      return 'ukfgq0emo384oaoe46ju5u82vrekn0q9fdtl'
    default: {
      console.warn(`Environment not recognized. Using localhost SplitIO Api Key`)
      return defaultKey
    }
  }
}

type ImpressionT = {
  feature: string // This is the split name
  keyName: string // This is the user. In our case it is typically the id of a teacher or admin - Careful this is a string
  treatment: string
  time: number
  label: string
  changeNumber: number
  bucketingKey: string | undefined | null
}

export function getValidatedImpression(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  impressionData?: any | null
): ImpressionT | undefined | null {
  if (impressionData === null || impressionData === undefined) {
    console.error('SplitIO Impression Listener', 'impressionData is null/undefined')
    return null
  }

  const {impression} = impressionData
  if (impression === null || impression === undefined || typeof impression !== 'object') {
    console.error('SplitIO Impression Listener', 'impression is null/undefined/not an object')
    return null
  }

  const {keyName, feature, label, treatment} = impression

  if (
    keyName === null ||
    keyName === undefined ||
    feature === null ||
    feature === undefined ||
    label === null ||
    label === undefined ||
    treatment === null ||
    treatment === undefined
  ) {
    console.error('SplitIO Impression Listener', 'keyName/feature/label is null/undefined')
    return null
  }

  return impressionData.impression
}
