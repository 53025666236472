import * as React from 'react'
import {useMatches} from 'react-router-dom'
import {useSafeTranslation} from '@freckle/educator-materials/ts/helpers/translate'
import {Link} from '@freckle/educator-materials/ts/components/link'
import {Text} from '@freckle/educator-materials/ts/components/typography'

import {linkWrapperStyle, activeStyle, primaryLinkStyle} from './sidebar-link.module.scss'
import {
  getDataTestFromTabId,
  getSidebarLinkTranslateKeyFromTabId,
  getTabIdForRoute,
  SidebarIdT,
} from './../logic'

export type Props = {
  tabId: SidebarIdT
  href: string
}

export function SidebarLink(props: Props): React.ReactElement {
  const {t} = useSafeTranslation()
  const {tabId, href} = props

  const routeMatch = useMatches()
  // TODO: https://app.asana.com/0/149473556304568/1206945111987245/f
  const currentTabId = getTabIdForRoute(routeMatch[0]!.pathname)
  const isActive = currentTabId === tabId

  return (
    <li className={`${linkWrapperStyle} ${isActive ? activeStyle : ''}`}>
      <Link
        addClass={primaryLinkStyle}
        href={href}
        dataTest={getDataTestFromTabId(tabId)}
        reactRouter
      >
        <Text style="subtitle-2" htmlElement="span">
          {t(getSidebarLinkTranslateKeyFromTabId(tabId))}
        </Text>
      </Link>
    </li>
  )
}
