import '@babel/polyfill'
import 'url-polyfill'
import 'whatwg-fetch'
import * as React from 'react'
import {createRoot} from 'react-dom/client'
import {RouterProvider} from 'react-router-dom'
import {QueryClientProvider, QueryClient} from '@tanstack/react-query'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import {fromJust} from '@freckle/maybe'
import CApiHelper from '@freckle/educator-entities/ts/common/helpers/common-api-helper'
import {ajaxJsonCall} from '@freckle/ajax'
import {BreakpointProvider} from '@freckle/educator-materials/ts/context/breakpoint-provider'
import '@freckle/educator-entities/ts/common/helpers/event-polyfill'
import {setupI18n} from '@freckle/educator-entities/ts/common/helpers/translate/i18n/i18n-helper'
import {DelayedSpinner} from '@freckle/educator-entities/ts/common/components/spinner-wrapper/delayed-spinner'
import {displayNotification, addSpinner} from '@freckle/classroom/ts/common/helpers/helpers'
import {checkInternetConnectivity, setupAjax} from '@freckle/classroom/ts/common/helpers/api-helper'
import NotificationManager from '@freckle/classroom/ts/common/components/notifications-wrapper/notification-manager'

// Important: Keep CSS import first to correctly order global CSS and CSS Modules
import './../css/freckle.scss'
import {initializeGlobalEventListeners} from './common/routers/init-global-event-listeners'
import {ErrorBoundary} from './error-boundary'
import {routerV2 as router} from './common/routers/router-v2'
import {feedbackOverlay} from './main.module.scss'
import {AnonymousSplitIOWrapper} from './common/routers/router-v2/split-io-wrapper/anonymous-split-io-wrapper'

setupI18n('classroom')

//
// Bundle version update notifier
//
// Asks user to refresh page when the JS bundle is detected to be outdated
//

const bundleHtmlElement = document.querySelector('script[src^="/js/bundle"]')
if (bundleHtmlElement !== null) {
  const bundleLocation = bundleHtmlElement.getAttribute('src')
  if (bundleLocation !== null) {
    const intervalId = setInterval(() => {
      ajaxJsonCall({
        url: bundleLocation,
        method: 'HEAD',
        cache: false,
      }).catch(response => {
        if (response.status === 404) {
          NotificationManager.createDashboardUpdateNotification()
          window.clearInterval(intervalId)
        }
      })
    }, 60000)
  }
}

//
// Reachability
//
// Ensures the user's connection is healthy during Freckle usage
//

const normalRetryPeriod = 30000
const failedRetryPeriod = 5000

const successCallback = () => {
  // remove fogginess and undisplay overlay when the connection is fine
  // classList is not supported by IE < 10
  const retryLayerNode = document.getElementById('retry-layer')
  if (retryLayerNode) {
    retryLayerNode.classList.add('hidden')
  }
  const freckleappNode = document.getElementById('freckleapp')
  if (freckleappNode) {
    freckleappNode.classList.remove('blurred')
  }
}

const failureCallback = () => {
  const msg = `Experiencing connection issues, retrying in ${failedRetryPeriod / 1000}s`
  displayNotification('error', msg, failedRetryPeriod / 1000)
  // classList is not supported by IE < 10
  const retryLayerNode = document.getElementById('retry-layer')
  if (retryLayerNode) {
    retryLayerNode.classList.remove('hidden')
  }
  const freckleappNode = document.getElementById('freckleapp')
  if (freckleappNode) {
    freckleappNode.classList.add('blurred')
  }
}

const pingUrl = CApiHelper.fancyPaths.v2.teacher_ping()
checkInternetConnectivity(
  pingUrl,
  normalRetryPeriod,
  failedRetryPeriod,
  successCallback,
  failureCallback
)

// initialize and render the views
setupAjax()
initializeGlobalEventListeners()
addSpinner()

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 100, // Debounce to prevent infinite refetching
    },
  },
})

const container = fromJust(document.getElementById('freckleapp'), 'Container not found')

const root = createRoot(container)

root.render(
  // @ts-ignore: Cannot use `children` after React 18
  <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <AnonymousSplitIOWrapper>
          <BreakpointProvider>
            {/* React Suspense is used for i18n helper and React.lazy usage in Router */}
            <React.Suspense fallback={<DelayedSpinner />}>
              <RouterProvider router={router} />
              <div id="feedback-overlay" className={feedbackOverlay} />
            </React.Suspense>
          </BreakpointProvider>
        </AnonymousSplitIOWrapper>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
  </ErrorBoundary>
)
