import includes from 'lodash/includes'
import {groupAllWith, headOnNonEmpty} from '@freckle/non-empty'
import find from 'lodash/find'
import some from 'lodash/some'
import map from 'lodash/map'
import filter from 'lodash/filter'
import partial from 'lodash/partial'
import reduce from 'lodash/reduce'
import {exhaustive} from '@freckle/exhaustive'
import {fromMaybe, maybe, mthen} from '@freckle/maybe'
import {GenericAssignmentSessionAttrsT} from '@freckle/educator-entities/ts/common/models/generic-assignment-session'
import CApiHelper from '@freckle/educator-entities/ts/common/helpers/common-api-helper'
import {Products} from '@freckle/educator-entities/ts/common/helpers/products'
import {ProductT} from '@freckle/educator-entities/ts/common/helpers/products'
import {MathAssignmentTypeT} from '@freckle/educator-entities/ts/math/common/models/math-assignment'
import {ElaAssignmentType} from '@freckle/educator-entities/ts/ela/assignments/ela-assignment'
import {MathAssignmentTypes} from '@freckle/educator-entities/ts/math/common/models/math-assignment'
import {ElaAssignmentTypes} from '@freckle/educator-entities/ts/ela/assignments/ela-assignment'
import {SubjectT} from '@freckle/educator-entities/ts/common/helpers/subjects'
import {CourseToSubjectMapT} from '@freckle/educator-entities/ts/roster/models/course-subject'
import {CourseAttrs} from '@freckle/educator-entities/ts/roster/models/course'
import {ajaxJsonCall} from '@freckle/ajax'
import {CourseMembershipAttrs} from '@freckle/educator-entities/ts/roster/models/course-membership'
import {markClaimedFreeResourcesStale} from '@freckle/classroom/ts/common/models/claimed-free-resources'
import {
  AssignmentContextArg,
  createAssignmentContextData,
  findAssignmentStartsAt,
} from '@freckle/educator-entities/ts/common/helpers/assignment-context'
import {getActiveCourses} from '@freckle/educator-entities/ts/roster/helpers/tagged-courses'
import {StudentAttrs} from '@freckle/educator-entities/ts/users/models/student'
import {isIdpManaged} from '@freckle/educator-entities/ts/roster/identity-provider'
import {forCourse} from '@freckle/classroom/ts/users/collections/students'

import {TeacherAttrs} from '@freckle/classroom/ts/users/models/teacher'
import {SelectedCourseToStudentIdsMapT} from '@freckle/classroom/ts/common/logic/student-course-picker'

export type MultiCourseAssignResponse = Array<{assignmentId: number; studentIds: Array<number>}>
export type MultiCourseAssignmentPostT = Map<number, {studentIds: Array<number>; postData: Object}>

export const mkMultiCourseAssignmentPost = (
  selectedStudents: SelectedCourseToStudentIdsMapT,
  postData: Object
): MultiCourseAssignmentPostT => {
  const entries: [number, number[]][] = Array.from(selectedStudents.entries())

  return new Map(map(entries, ([courseId, studentIds]) => [courseId, {studentIds, postData}]))
}

export async function getMultiCourseAssignmentPromise(
  assignmentType: MathAssignmentTypeT | ElaAssignmentType,
  standardSetIds: StandardSetIds,
  postDataPerCourse: MultiCourseAssignmentPostT
): Promise<MultiCourseAssignResponse> {
  const requestFn = getAssignmentReqFn(assignmentType, standardSetIds)

  const requests = map(
    Array.from(postDataPerCourse.entries()),
    ([courseId, {studentIds, postData}]) =>
      requestFn(studentIds, postData, {
        courseId,
        assignmentStartsAt: findAssignmentStartsAt(postData as {
          startsAt: number | undefined | null
        }),
      })
  )

  const responses = reduce(
    await Promise.all(requests),
    (acc, req) => {
      return [...acc, ...req]
    },
    [] as MultiCourseAssignResponse
  )
  markClaimedFreeResourcesStale()
  return responses
}

function getAssignmentReqFn(
  assignmentType: MathAssignmentTypeT | ElaAssignmentType,
  standardSetIds: StandardSetIds
): (
  selectedStudentIds: Array<number>,
  postData: Object,
  context: AssignmentContextArg
) => Promise<MultiCourseAssignResponse> {
  const elaAssignmentType = ElaAssignmentTypes.parse(assignmentType)
  const mathAssignmentType = MathAssignmentTypes.parse(assignmentType)
  if (elaAssignmentType !== null && elaAssignmentType !== undefined) {
    return partial(createElaAssignment, elaAssignmentType, standardSetIds)
  } else if (mathAssignmentType !== null && mathAssignmentType !== undefined) {
    return partial(createMathAssignment, mathAssignmentType, standardSetIds)
  } else {
    throw new Error(
      `Unable to create assignment due to unrecognized assignment type ${assignmentType}`
    )
  }
}

function createMathAssignment(
  mathAssignmentType: MathAssignmentTypeT,
  standardSetIds: StandardSetIds,
  selectedStudentIds: Array<number>,
  postData: Object,
  context: AssignmentContextArg
): Promise<MultiCourseAssignResponse> {
  switch (mathAssignmentType) {
    case 'MathAdaptive':
      return createAssignmentV3(
        Products.MathAdaptive,
        standardSetIds,
        selectedStudentIds,
        postData,
        context
      )
    case 'MathStandard':
      return createAssignmentV3(
        Products.MathTargeted,
        standardSetIds,
        selectedStudentIds,
        postData,
        context
      )
    case 'MathAssessment':
    case 'MathIbl':
    case 'MathNumberFacts':
    case 'MathDepthOfKnowledgePractice':
      return createMathAssignmentV2(mathAssignmentType, selectedStudentIds, postData, context)

    case 'MathFactPractice':
      return createAssignmentV3(
        Products.MathFactPractice,
        standardSetIds,
        selectedStudentIds,
        postData,
        context
      )

    case 'MathTargetedDepthOfKnowledgePractice':
      return createAssignmentV3(
        Products.MathTargetedDepthOfKnowledgePractice,
        standardSetIds,
        selectedStudentIds,
        postData,
        context
      )

    default:
      return exhaustive(mathAssignmentType, 'MathAssignmentTypeT')
  }
}

function createElaAssignment(
  elaAssignmentType: ElaAssignmentType,
  standardSetIds: StandardSetIds,
  selectedStudentIds: Array<number>,
  postData: Object,
  context: AssignmentContextArg
): Promise<MultiCourseAssignResponse> {
  switch (elaAssignmentType) {
    case 'ela_assessment':
    case 'ela_article':
    case 'ela_adaptive_skills':
    case 'ela_targeted_skills':
    case 'ela_decodable':
      return createElaAssignmentV2(elaAssignmentType, selectedStudentIds, postData, context)
    case 'ela_word_study':
      return createAssignmentV3(
        Products.ElaWordStudy,
        standardSetIds,
        selectedStudentIds,
        postData,
        context
      )

    default:
      return exhaustive(elaAssignmentType, 'ElaAssignmentType')
  }
}

function createMathAssignmentV2(
  type: MathAssignmentTypeT,
  studentIds: Array<number>,
  data: Object,
  context: AssignmentContextArg
): Promise<MultiCourseAssignResponse> {
  // Endoint returns [{id: <assignmentId>}]
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.math_assignments._(),
    method: 'POST',
    data: JSON.stringify({
      type,
      students: studentIds,
      metadata: data,
      context: createAssignmentContextData(context),
    }),
  }).then((res: unknown) =>
    // @ts-ignore https://app.asana.com/0/0/1204907599764039/f
    map(res, ({id}) => ({
      assignmentId: id,
      studentIds,
    }))
  )
}

function createElaAssignmentV2(
  type: ElaAssignmentType,
  studentIds: Array<number>,
  data: Object,
  context: AssignmentContextArg
): Promise<MultiCourseAssignResponse> {
  let tag
  if (type === ElaAssignmentTypes.ElaAssessment) {
    tag = 'PostElaAssessment'
  } else if (type === ElaAssignmentTypes.ElaWordStudy) {
    tag = 'PostElaWordStudy'
  } else if (type === ElaAssignmentTypes.ElaDecodable) {
    tag = 'PostElaDecodables'
  } else {
    tag = 'PostArticle'
  }

  // Endpoint returns {id: <assignmentId>}
  // N.B. This endpoint is a bit more complex since it returns something
  // different based on whether the logged in user is a teacher or a student.
  // Since this is the classroom app and I expect the only logged in user to be
  // a teacher, I am ignoring that complexity.
  return (
    ajaxJsonCall({
      url: CApiHelper.fancyPaths.v2.ela_assignments._(),
      method: 'POST',
      data: JSON.stringify({
        type,
        students: studentIds,
        metadata: {
          contents: data,
          tag,
        },
        context: createAssignmentContextData(context),
      }),
    })
      // @ts-ignore https://app.asana.com/0/0/1204907599764039/f
      .then(({id}) => [{assignmentId: id, studentIds}])
  )
}

export type StandardSetIds = {
  math: string
  ela?: string | null
}

export const mkStandardSetIds = (teacher: TeacherAttrs): StandardSetIds => ({
  math: teacher.mathStandardSet.id,
  ela: teacher.elaStandardSetId,
})

const standardSetQueryParams = ({math, ela}: StandardSetIds): string =>
  [`math-standard-set-id=${math}`, maybe(() => '', id => `&ela-standard-set-id=${id}`, ela)].join(
    ''
  )

function createAssignmentV3(
  product: ProductT,
  standardSetIds: StandardSetIds,
  studentIds: Array<number>,
  payload: Object,
  context: AssignmentContextArg
): Promise<MultiCourseAssignResponse> {
  const paths = Products.getPaths(product)
  const baseUrl = CApiHelper.fancyPaths.v3.subjects.subject.products.product.subject_product_sessions._(
    paths.subjectPath,
    paths.productPath
  )
  const startsAt = maybe(() => null, startsAt => startsAt.valueOf(), context.assignmentStartsAt)

  // Endpoint returns Array<GenericAssignmentSessionAttrsT>
  return ajaxJsonCall({
    url: `${baseUrl}?${standardSetQueryParams(standardSetIds)}`,
    method: 'POST',
    data: JSON.stringify({
      studentIds,
      metadata: payload,
      context: createAssignmentContextData(context),
      startsAt,
    }),
  }).then(genericSessions => {
    const castGenericSessions = genericSessions as GenericAssignmentSessionAttrsT[]
    const groupedSessions = groupAllWith(({assignmentId}) => assignmentId, castGenericSessions)

    return map(groupedSessions, genericSessions => {
      const studentIds = map(genericSessions, ({studentId}) => studentId)

      return {assignmentId: headOnNonEmpty(genericSessions).assignmentId, studentIds}
    })
  })
}

export function getInitialCourseIdWithResources(
  courses: Array<CourseAttrs>,
  disabledCourseIds: Array<number>,
  initialCourseId: number
): number | undefined {
  const {courses: activeCourses} = getActiveCourses(courses)
  const courseIds = map(activeCourses, c => c.id)
  const nonDisabledCourseIds = filter(
    courseIds,
    courseId => !some(disabledCourseIds, disabledId => disabledId === courseId)
  )

  const initialCourseIdDisabled = some(
    disabledCourseIds,
    disabledId => disabledId === initialCourseId
  )

  // If the initial course id is disabled, get the first non-disabled course id
  return initialCourseIdDisabled ? nonDisabledCourseIds[0] : initialCourseId
}

// Generic until backbone is removed
type GetInitialStudentSelectionMapArgs<TStudents> = {
  courses: Array<CourseAttrs>
  students: TStudents
  courseMemberships: Array<CourseMembershipAttrs>
  initialCourseId: number
  initialStudentIds?: Array<number> | null | undefined
  currentSubject: SubjectT
  courseSubjectMap: CourseToSubjectMapT
  disabledCourseIds: Array<number>
}

export const getInitialStudentSelectionMap = ({
  courses,
  students,
  courseMemberships,
  initialCourseId,
  initialStudentIds,
  currentSubject,
  courseSubjectMap,
  disabledCourseIds,
}: GetInitialStudentSelectionMapArgs<Array<StudentAttrs>>): SelectedCourseToStudentIdsMapT =>
  fromMaybe(
    () => new Map(),
    mthen(getInitialCourseIdWithResources(courses, disabledCourseIds, initialCourseId), courseId =>
      mthen(find(courses, c => c.id === courseId), course => {
        const courseStudents = map(forCourse(students, course.id, courseMemberships), x => x.id)
        const mayEmptyStudentIds = fromMaybe(() => courseStudents, initialStudentIds)
        const nonEmptyInitial =
          mayEmptyStudentIds.length === 0 ? courseStudents : mayEmptyStudentIds

        return mthen(courseSubjectMap.get(courseId), allowedSubjects =>
          includes(allowedSubjects, currentSubject) || isIdpManaged(course)
            ? new Map([[courseId, nonEmptyInitial]])
            : null
        )
      })
    )
  )
