import {UseQueryOptions, UseQueryResult} from '@tanstack/react-query'
import {useAsync} from '@freckle/educator-entities/ts/common/hooks/use-async'
import {
  PremiumLicensesAttrs,
  fetchPremiumLicenses,
} from '@freckle/educator-entities/ts/common/models/premium-licenses'

export function usePremiumLicenses(
  options?: Partial<UseQueryOptions<PremiumLicensesAttrs>>
): UseQueryResult<PremiumLicensesAttrs> {
  const defaultOptions = {staleTime: 60 * 1000}
  return useAsync(fetchPremiumLicenses, ['fetchPremiumLicenses'], {
    ...defaultOptions,
    ...options,
  })
}
