import mapKeys from 'lodash/mapKeys'
import {maybe} from '@freckle/maybe'

import {AnalyticsTool} from './index'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const gtag: (a: any, b: any, c?: any) => void

const GA_TRACKING_ID = 'G-GG4E30GFR5'

// Data for a custom dimension needs to consistently be sent to the right index
const CustomDimensions = {
  userId: 'dimension1',
  rosteredGradeLevel: 'dimension2',
  mathStandardSetId: 'dimension3',
  domainName: 'dimension4',
  standardName: 'dimension5',
  numIncorrectAttempts: 'dimension6',
  questionStemId: 'dimension7',
}

type CustomDimensionsT = {
  [key in keyof typeof CustomDimensions]: string
}

type ParsedDimensionsT = {
  [key in (typeof CustomDimensions)[keyof typeof CustomDimensions]]: string
}

const parseDimensions = (dimensions: Partial<CustomDimensionsT>): Partial<ParsedDimensionsT> =>
  mapKeys(dimensions, (_val, key) => CustomDimensions[key as keyof typeof CustomDimensions])

export const withDimensions = <T>(
  baseObj: T,
  customDimensions?: Partial<CustomDimensionsT>
): T & Partial<CustomDimensionsT> => ({
  ...maybe(() => ({}), parseDimensions, customDimensions),
  ...baseObj,
})

const GoogleAnalytics: AnalyticsTool = {
  setup: (userId: number): Promise<void> => {
    const strUserId = userId.toString()
    // set user id for GA
    // set user id custom dimension for GA
    gtag('config', GA_TRACKING_ID, {user_id: strUserId, dimension1: strUserId})
    // Send the userId to Google Tag Manager
    if (
      window.dataLayer !== undefined &&
      window.dataLayer !== null &&
      Array.isArray(window.dataLayer)
    ) {
      window.dataLayer.push({userId: strUserId})
    }
    return Promise.resolve(undefined)
  },

  trackEvent: (eventName: string, customDimensions?: CustomDimensionsT): Promise<void> => {
    const params = withDimensions(
      {hitType: 'event', eventCategory: 'Product', eventAction: eventName},
      customDimensions
    )

    gtag('event', eventName, params)

    return Promise.resolve(undefined)
  },

  setProperties: (_userId: number, customDimensions?: CustomDimensionsT): Promise<void> => {
    gtag('config', GA_TRACKING_ID, withDimensions({}, customDimensions))
    return Promise.resolve(undefined)
  },

  trackRouteChange: (fragment: string): Promise<void> => {
    try {
      const cleansedFragment = cleanseFragment(fragment)
      gtag('event', 'page_view', {page_location: cleansedFragment})
      return Promise.resolve(undefined)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  logout: (): Promise<void> => {
    return Promise.resolve(undefined)
  },
}

export default GoogleAnalytics

function cleanseFragment(gaFragment: string): string {
  let cleansedFragment = gaFragment

  // Removing course id, assessment id or student id to gather information in GA
  const regex = /\/(courses|students|assessments|assessment|lessons|usage|reports|schools)\/\d+\/?/
  if (regex.test(cleansedFragment)) {
    cleansedFragment = cleansedFragment.replace(regex, '/$1/id/')
  }

  // Remove standard id and standard code in standard and sub standard Page
  // eg /standards/foo
  // eg /standards/foo/standard/K.CC.1
  const regexStandardSubPage = /\/(standards|standard)\/[a-zA-Z0-9.]+/
  if (regexStandardSubPage.test(cleansedFragment)) {
    cleansedFragment = cleansedFragment.replace(regexStandardSubPage, '/$1/standardId')
  }

  // Remove articleUuid
  const regexArticleUuid = /\/(articles|social-studies-units|science-units)\/[a-zA-Z0-9-]+/
  if (regexArticleUuid.test(cleansedFragment)) {
    cleansedFragment = cleansedFragment.replace(regexArticleUuid, '/$1/articleUuid')
  }

  //Remove hashes
  const hashlessFragment = cleansedFragment.replace(/#/g, '')

  return hashlessFragment
}
