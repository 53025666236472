import find from 'lodash/find'
import {components} from '@freckle/educator-entities/ts/curricula-api/generated-client'

export type GenreT = components['schemas']['ElaArticle']['genre']

const Genres = {
  SocialStudies: 'Social Studies' as GenreT,
  Science: 'Science' as GenreT,
  Technology: 'Technology' as GenreT,
  Biographies: 'Biographies' as GenreT,
  PersonalInterest: 'Personal Interest' as GenreT,
  Food: 'Food' as GenreT,
  Careers: 'Careers' as GenreT,

  RealisticFiction: 'Realistic Fiction' as GenreT,
  HistoricalFiction: 'Historical Fiction' as GenreT,
  Mystery: 'Mystery' as GenreT,
  ScienceFiction: 'Science Fiction' as GenreT,
  Fantasy: 'Fantasy' as GenreT,
  Fables: 'Fables' as GenreT,

  all(): Array<GenreT> {
    return [...Genres.nonFictionGenres(), ...Genres.fictionGenres()]
  },

  nonFictionGenres(): Array<GenreT> {
    return [
      Genres.SocialStudies,
      Genres.Science,
      Genres.Technology,
      Genres.Biographies,
      Genres.PersonalInterest,
      Genres.Food,
      Genres.Careers,
    ]
  },

  fictionGenres(): Array<GenreT> {
    return [
      Genres.RealisticFiction,
      Genres.HistoricalFiction,
      Genres.Mystery,
      Genres.ScienceFiction,
      Genres.Fantasy,
      Genres.Fables,
    ]
  },

  parse(text: string): GenreT | undefined | null {
    const trimmed = text.trim()
    return find(Genres.all(), value => value === trimmed)
  },

  // Fiction genres might change soon, and we only really use this
  // for filtering, so just warn for unrecognized genres and let
  // them pass through
  parseLenient(text: string): GenreT {
    const mGenre = Genres.parse(text)
    if (mGenre !== null && mGenre !== undefined) {
      return mGenre
    }
    console.warn(`Unexpected value for GenreT: ${text}`)
    return text as GenreT
  },
}

export default Genres
