import {Moment} from 'moment-timezone'
import StorageHelper from '@freckle/educator-entities/ts/common/helpers/storage-helper'
import {RlStandardSetT} from '@freckle/educator-entities/ts/common/models/rl-standard-set'
import {
  parseTeacherAttrs,
  BaseTeacherAttrs,
  updateBaseResponseProperties,
} from '@freckle/educator-entities/ts/users/models/teacher'
import {parseAttrs as parseRlStandardSet} from '@freckle/educator-entities/ts/common/models/rl-standard-set'
import {
  RenaissanceKeepaliveT,
  parseKeepaliveAttrs,
} from '@freckle/educator-entities/ts/common/helpers/renaissance-keepalive'
import {
  ParserT,
  Parser,
  merge,
  record,
  array,
  number,
  boolean,
  field,
  date,
  nullable,
} from '@freckle/parser'

export type StudentLoginEventT = {
  studentId: number
  occurredAt: Moment
}

type TeacherMetadataAttrs = {
  schoolMathStandardSet: RlStandardSetT | null | undefined
  shareEnabled: boolean
  shouldSeeGettingStarted: boolean
  studentLatestLoginEvents: Array<StudentLoginEventT> | null | undefined
  mathStandardSet: RlStandardSetT
  elaStandardSet: RlStandardSetT | null | undefined
  renaissanceKeepalive: RenaissanceKeepaliveT | null | undefined
  hasStarReadingPlacements: boolean
  hasStarMathPlacements: boolean
}

export type TeacherAttrs = BaseTeacherAttrs & TeacherMetadataAttrs

const parseStudentLoginEventAttrs: ParserT<StudentLoginEventT> = record({
  studentId: number(),
  occurredAt: date(),
})

const parseTeacherMetadataAttrs: ParserT<TeacherMetadataAttrs> = record({
  schoolMathStandardSet: nullable(parseRlStandardSet),
  shareEnabled: boolean(),
  shouldSeeGettingStarted: boolean(),
  studentLatestLoginEvents: nullable(array(parseStudentLoginEventAttrs)),
  mathStandardSet: field(parseRlStandardSet, 'standardSet'),
  elaStandardSet: nullable(parseRlStandardSet),
  renaissanceKeepalive: nullable(parseKeepaliveAttrs),
  hasStarReadingPlacements: boolean(),
  hasStarMathPlacements: boolean(),
})

export function parse(response: unknown): TeacherAttrs {
  const parseFullAttrs = merge(parseTeacherAttrs, parseTeacherMetadataAttrs)
  const parseFullResponse = Parser.run(response, parseFullAttrs)
  // Hide Teacher's Name and email when in demoMode
  const demoMode = StorageHelper.getLocalStoragePropertyDefault('demoMode', false)
  const updatedResponse = updateBaseResponseProperties(parseFullResponse, demoMode)
  return updatedResponse
}

export const isMathOnly = ({elaStandardSetId}: TeacherAttrs): boolean =>
  elaStandardSetId === null || elaStandardSetId === undefined
