import {ParserT, Parser, string, record} from '@freckle/parser'

type FreckleConfigT = {
  BUGSNAG_API_KEY: string
  BUGSNAG_APP_VERSION: string
  COMMIT_UNIX_TIMESTAMP: string
  COMMIT_SHA: string
  LOCIZE_API_KEY: string
}

// Global defined in index.html for each app
declare let FRECKLE_CONFIG: FreckleConfigT

const parseConfigAttrs: ParserT<FreckleConfigT> = record({
  BUGSNAG_API_KEY: string(),
  BUGSNAG_APP_VERSION: string(),
  COMMIT_UNIX_TIMESTAMP: string(),
  COMMIT_SHA: string(),
  LOCIZE_API_KEY: string(),
})

// fail if the config is not what we expect
export const CONFIG = Parser.run(FRECKLE_CONFIG, parseConfigAttrs)
