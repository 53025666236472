import * as React from 'react'
import {
  ElaAdaptiveSkillsReportHandler,
  ElaArticleClassActivityHandler,
  ElaArticleReadingAssignmentSessionReportHandler,
  ElaAssessmentAssignmentsReportHandler,
  ElaAssessmentReportHandler,
  ElaAssessmentSessionReportHandler,
  ElaAssignmentSessionHistoryReportHandler,
  ElaCorrelationPage,
  ElaGroupsReportHandler,
  ElaLevelsReportHandler,
  ElaReportCardViewHandler,
  ElaReportCardsHandler,
  ElaReportsOverviewHandler,
  ElaSkillsProgressReportHandler,
  ElaStandardStudentReportHandler,
  ElaTargetedSkillsReportHandler,
  ElaFocusSkillsReportHandler,
  ElaWordStudyMatrixReportHandler,
  ElaWritingAssignmentReportHandler,
  PerformanceByStandardReportHandler,
  PrintElaReportCardsHandler,
  WordStudySessionReportHandler,
} from './lazy'
import {LoggedRoute} from '../../logged-route'

export const routerV2ElaReports = [
  {
    path: '/courses/:courseId/ela/report-cards',
    element: <LoggedRoute component={ElaReportCardsHandler} />,
  },
  {
    path: '/courses/:courseId/ela/report-cards/:studentId',
    element: <LoggedRoute component={ElaReportCardsHandler} />,
  },
  {
    path: '/courses/:courseId/ela/report-card',
    element: <LoggedRoute component={ElaReportCardViewHandler} />,
  },
  {
    path: '/courses/:courseId/ela/print-ela-report-cards',
    element: <LoggedRoute component={PrintElaReportCardsHandler} />,
  },
  {
    path: '/courses/:courseId/ela/reports',
    element: <LoggedRoute component={ElaReportsOverviewHandler} />,
  },
  {
    path: '/ela/reports/assessment/:assignmentId',
    element: <LoggedRoute component={ElaAssessmentReportHandler} />,
  },
  {
    path: '/courses/:courseId/ela/reports/assessment-sessions/:assignmentSessionId',
    element: <LoggedRoute component={ElaAssessmentSessionReportHandler} />,
  },
  {
    path: '/courses/:courseId/ela/reports/performance-by-standard',
    element: <LoggedRoute component={PerformanceByStandardReportHandler} />,
  },
  {
    path: '/courses/:courseId/ela/groups-report',
    element: <LoggedRoute component={ElaGroupsReportHandler} />,
  },
  {
    path: '/courses/:courseId/session-history/ela-article',
    element: <LoggedRoute component={ElaAssignmentSessionHistoryReportHandler} />,
  },
  {
    path: '/ela/standards/students/:studentId/report',
    element: <LoggedRoute component={ElaStandardStudentReportHandler} />,
  },
  {
    path: '/courses/:courseId/ela/reports/levels',
    element: <LoggedRoute component={ElaLevelsReportHandler} />,
  },
  {
    path: '/courses/:courseId/ela/reports/assessments',
    element: <LoggedRoute component={ElaAssessmentAssignmentsReportHandler} />,
  },
  {
    path: '/courses/:courseId/ela/word-study-matrix',
    element: <LoggedRoute component={ElaWordStudyMatrixReportHandler} />,
  },
  {
    path: '/courses/:courseId/ela-skills-progress-report',
    element: <LoggedRoute component={ElaSkillsProgressReportHandler} />,
  },
  {
    path: '/ela/reports/writing/:assignmentId',
    element: <LoggedRoute component={ElaWritingAssignmentReportHandler} />,
  },
  {
    path: '/courses/:courseId/ela/reports/reading/:assignmentId/students/:studentId',
    element: <LoggedRoute component={ElaArticleReadingAssignmentSessionReportHandler} />,
  },
  {
    path: '/courses/:courseId/ela/reports/adaptive-skills/:assignmentId/students/:studentId',
    element: <LoggedRoute component={ElaAdaptiveSkillsReportHandler} />,
  },
  {
    path: '/courses/:courseId/ela/reports/targeted-skills/:assignmentId/students/:studentId',
    element: <LoggedRoute component={ElaTargetedSkillsReportHandler} />,
  },
  {
    path: '/courses/:courseId/ela/reports/focus-skills/:assignmentId/students/:studentId',
    element: <LoggedRoute component={ElaFocusSkillsReportHandler} />,
  },
  {
    path: '/courses/:courseId/ela/reports/word-study/:assignmentSessionId/students/:studentId',
    element: <LoggedRoute component={WordStudySessionReportHandler} />,
  },
  {
    path: '/courses/:courseId/session-history/ela-article/:articleUuid',
    element: <LoggedRoute component={ElaArticleClassActivityHandler} />,
  },
  {
    path: '/ela/correlation',
    element: <LoggedRoute component={ElaCorrelationPage} />,
  },
]
