import * as React from 'react'
import {SplitFactoryProvider} from '@splitsoftware/splitio-react'
import {getEnvironment} from '@freckle/educator-entities/ts/config/environment'
import {getSplitIOBrowserSDKAPIKey} from '@freckle/educator-entities/ts/common/helpers/split-io-helper'

type Props = {
  children: React.ReactNode
  configKey: string
  trafficType: string
  logImpression: (data: {} | null | undefined) => void
}
export function SplitWrapper(props: Props): React.ReactElement {
  const {children, configKey: key, trafficType, logImpression} = props
  const environment = getEnvironment(window.location.hostname)
  const apiKey = getSplitIOBrowserSDKAPIKey(environment)
  const sdkConfig: SplitIO.IBrowserSettings = {
    core: {
      authorizationKey: apiKey,
      key,
      trafficType,
    },
    debug: false,
    impressionListener: {logImpression},
  }

  return <SplitFactoryProvider config={sdkConfig}>{children}</SplitFactoryProvider>
}
