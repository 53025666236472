import * as React from 'react'
import {addMaybeClassName} from '@freckle/educator-entities/ts/common/helpers/classnames'

import {BreadcrumbsProps, Breadcrumbs} from '@freckle/educator-materials/ts/components/breadcrumbs'
import {H1} from '@freckle/educator-materials/ts/components/typography'

import * as style from './page-header.module.scss'

type Props = {
  title?: PageTitleT
  breadcrumbsConfig?: BreadcrumbsProps | null
  breadcrumbsClass?: string
  addContainerClass?: string
  dataTest?: string
}

export type PageTitleT = React.ReactNode

export function PageHeader(props: Props): React.ReactElement {
  const {title, breadcrumbsConfig, breadcrumbsClass, addContainerClass, dataTest} = props

  const breadcrumbs =
    breadcrumbsConfig !== null && breadcrumbsConfig !== undefined ? (
      <Breadcrumbs breadcrumbsClass={breadcrumbsClass} {...breadcrumbsConfig} />
    ) : null

  const headerTitle =
    title !== null && title !== undefined ? (
      <H1 addClass={style.heading} dataTest={dataTest}>
        {title}
      </H1>
    ) : null

  if (breadcrumbs === null && headerTitle === null) {
    return <></>
  }

  const containerClassName = addMaybeClassName(style.header, addContainerClass)

  return (
    <div className={containerClassName}>
      {headerTitle}
      {breadcrumbs}
    </div>
  )
}
