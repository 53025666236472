import * as React from 'react'
import {useSafeTranslation} from '@freckle/educator-materials/ts/helpers/translate'
import {PATHS} from '@freckle/educator-entities/ts/common/helpers/paths'
import {exhaustive} from '@freckle/exhaustive'

import {ExternalLinkWithConfirmation} from '@freckle/classroom/ts/common/components/external-link-with-confirmation'
import {PageTypeT} from '@freckle/classroom/ts/common/components/page/page-wrapper'
import {freckleLogo} from './freckle-logo.module.scss'

type Props = {
  pageType: PageTypeT
}
export function FreckleLogo({pageType}: Props): React.ReactElement {
  const {t} = useSafeTranslation()
  const logoForDarkBgSrc = 'renaissance-freckle-logo-white.svg'
  const logoForLightBgSrc = 'renaissance-freckle-logo.svg'

  const logoSrc = (() => {
    switch (pageType) {
      case 'login':
      case 'signup':
      case 'onboarding':
      case 'resetByEmail':
      case 'changePassword':
        return logoForLightBgSrc
      case 'resetByEmailSuccess':
        return logoForDarkBgSrc
      default:
        return exhaustive(pageType, 'logoSrc')
    }
  })()

  return (
    <ExternalLinkWithConfirmation href="https://www.freckle.com">
      <img
        src={`${PATHS.imageAssetsUrl}/company-logos/${logoSrc}`}
        alt={t('FRECKLE_LOGO_IMAGE_LINK_ALT_TEXT')}
        title={t('FRECKLE_LOGO_IMAGE_LINK_TITLE_TEXT')}
        className={freckleLogo}
      />
    </ExternalLinkWithConfirmation>
  )
}
