import * as React from 'react'
import {Navigate, useLocation} from 'react-router-dom'
import StorageHelper from '@freckle/educator-entities/ts/common/helpers/storage-helper'

const REDIRECT_KEY = 'teacherLoginRedirectDest'

const isInvalidRedirect = (dest: string) => dest.startsWith('/login') || dest.startsWith('/signup')

type RedirectSetProps = {
  to: string
}

export const RedirectSet = ({to}: RedirectSetProps): React.ReactElement => {
  const location = useLocation()
  const dest = `${location.pathname}${location.search}`

  if (!isInvalidRedirect(dest)) {
    StorageHelper.setSecureCookie(REDIRECT_KEY, encodeURIComponent(dest))
  }
  return <Navigate to={to} replace />
}

export const RedirectConsume = ({
  defaultRoute,
}: {
  defaultRoute: () => string
}): React.ReactElement => {
  const nextUrl = StorageHelper.getCookie(REDIRECT_KEY)
  StorageHelper.clearSecureCookie(REDIRECT_KEY)
  if (nextUrl !== null && nextUrl !== undefined) {
    return <Navigate to={decodeURIComponent(nextUrl)} replace />
  } else {
    return <Navigate to={defaultRoute()} replace />
  }
}
