import * as React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import {exhaustive} from '@freckle/exhaustive'
import {UnauthenticatedFooter} from '@freckle/educator-materials/ts/components/footer/unauthenticated-footer'
import {Flex} from '@freckle/educator-materials/ts/components/flex'
import {login, signup, resetByEmail, onboarding, changePassword} from './page-wrapper.module.scss'

export type PageTypeT =
  | 'login'
  | 'signup'
  | 'resetByEmail'
  | 'resetByEmailSuccess'
  | 'onboarding'
  | 'changePassword'

type Props = {
  pageType: PageTypeT
  children?: React.ReactElement
}

const pageTypeStyle = (pageType: PageTypeT) => {
  switch (pageType) {
    case 'login':
      return login
    case 'signup':
      return signup
    case 'resetByEmail':
      return resetByEmail
    case 'resetByEmailSuccess':
      return resetByEmail
    case 'onboarding':
      return onboarding
    case 'changePassword':
      return changePassword
    default:
      return exhaustive(pageType)
  }
}
export function PageWrapper(props: Props): React.ReactElement {
  const footer = (props.pageType === 'signup' || props.pageType === 'login') && (
    <UnauthenticatedFooter />
  )

  return (
    <Container fluid>
      <Row>
        <Col xs={12}>
          <div className={pageTypeStyle(props.pageType)}>
            <Flex flexDirection="column" alignItems="center">
              {props.children}
            </Flex>
            {footer}
          </div>
        </Col>
      </Row>
    </Container>
  )
}
