import * as React from 'react'
import {isEqual} from 'lodash'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSpinner} from '@fortawesome/free-solid-svg-icons'
import {useSafeEffectExtraDeps} from '@freckle/react-hooks'
import {exhaustive} from '@freckle/exhaustive'
import {SizeT} from '@freckle/educator-materials/ts/helpers/size'
import {
  spinner,
  wrapper,
  invertedSpinner,
  overlayWrapperStyle,
  spinnerContainerStyle,
} from './spinner.module.scss'

const SPINNER_DELAY = 300

type Timeout = ReturnType<typeof setTimeout>

type SpinnerProps = {
  delay?: boolean
  size?: SizeT
  invertedColor?: boolean
}

function Spinner(props: SpinnerProps) {
  const {delay, size = 'md', invertedColor} = props
  const [showSpinner, setShowSpinner] = React.useState(delay !== true)
  const [timer, setTimer] = React.useState<null | Timeout>(null)

  useSafeEffectExtraDeps(
    ({timer}: {timer: Timeout | null}) => {
      if (delay === true && timer === null) {
        setTimer(setTimeout(() => setShowSpinner(true), SPINNER_DELAY))
      }

      return () => {
        if (timer !== null) {
          clearTimeout(timer)
          setTimer(null)
        }
      }
    },
    [delay],
    {
      timer: {
        value: timer,
        comparator: isEqual,
      },
    }
  )

  return showSpinner ? (
    <SpinnerIcon size={getSizeStyle(size)} invertedColor={invertedColor === true} />
  ) : null
}

function getSizeStyle(size: SizeT): string {
  switch (size) {
    case 'lg':
      return '5x'

    case 'md':
      return '3x'

    case 'sm':
      return 'md'

    default:
      return exhaustive(size, 'SizeT')
  }
}

function CenteredSpinner(props: SpinnerProps): React.ReactElement {
  return (
    <div className={wrapper}>
      <Spinner {...props} />
    </div>
  )
}

function SpinnerIcon({
  size,
  invertedColor,
}: {
  size: string
  invertedColor: boolean
}): React.ReactElement {
  const spinnerClass = invertedColor ? invertedSpinner : spinner
  return (
    <FontAwesomeIcon
      icon={faSpinner}
      spin
      size={size as 'lg' | 'sm'}
      className={spinnerClass}
      data-test="spinner"
    />
  )
}

function SpinnerOverlay(props: SpinnerProps): React.ReactElement {
  return (
    <div className={overlayWrapperStyle} data-test="spinner-overlay">
      <div className={spinnerContainerStyle} data-test="spinner-box">
        <Spinner {...props} />
      </div>
    </div>
  )
}

export {Spinner, CenteredSpinner, SpinnerOverlay}
