import isNil from 'lodash/isNil'
import {
  UseQueryResult,
  useQuery,
  UseQueryOptions,
  QueryFunction,
  QueryKey,
  useQueries,
  MutationFunction,
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'

export function useAsync<T>(
  queryFn: QueryFunction<T>,
  queryKey: QueryKey,
  otherQueryOptions: Partial<UseQueryOptions<T>> = {}
): UseQueryResult<T> {
  return useQuery({queryKey, queryFn, ...otherQueryOptions})
}

// Make an `async` request once dependencies are neither `undefined` nor `null`
//
// N.B. it's important that parameters get resolved as defined, somehow.
// Otherwise this will never be executed and will remain in a loading state.
//
// This is useful for two types of resolution:
//  1. resolving dependent requests
//  2. resolving parameters which are later validated as being defined
//
// Example of 1:
//
//    const teacher = useTeacher()
//    const someTeacherData = useAsyncOnceDefined(
//      ({teacher}) => fetchSomeData(teacher.id),
//      ['fetchSomeData', teacher.data?.id],
//      {teacher},
//    )
//
// Example of 2:
//
//    const mTeacherId = useQueryParam('teacherId')
//    const someTeacherData = useAsyncOnceDefined(
//      ({teacherId}) => fetchSomeData(teacherId),
//      ['fetchSomeData', mTeacherId],
//      {teacherId: { data: mTeacherId }},
//    )
//    if (mTeacherId === undefined || mTeacherId === null) {
//      logError(new Error('Required teacher ID not given'))
//      return <Redirect to={Routes.dashboard()} replace />
//    }
//
export function useAsyncOnceDefined<T extends Record<string, unknown>, R>(
  queryFn: (a: T) => Promise<R>,
  queryKey: QueryKey,
  extraDeps: {[P in keyof T]: {data: T[P] | null | undefined}},
  otherQueryOptions: Partial<UseQueryOptions<R>> = {}
): UseQueryResult<R> {
  const allDefined = Object.values(extraDeps)
    .map(d => d.data)
    .every(v => !isNil(v))
  // Having `enabled: allDefined` ensures we don't call the query function
  // with `| null | undefined`
  const deps: T = Object.fromEntries(
    Object.entries(extraDeps).map(([key, value]) => [key, value.data])
  ) as unknown as T
  const asyncQueryFn: QueryFunction<R> = () => queryFn(deps)

  return useAsync(asyncQueryFn, queryKey, {
    ...otherQueryOptions,
    enabled: (otherQueryOptions.enabled === undefined || otherQueryOptions.enabled) && allDefined,
  })
}

export function useAsyncMap<T, I>(
  iterable: I[],
  queryFn: (arg: I) => Promise<T>,
  queryKey: QueryKey,
  otherQueryOptions: Partial<UseQueryOptions<T>> = {}
): UseQueryResult<T>[] {
  return useQueries({
    queries: iterable.map(i => ({
      queryKey: [i, ...queryKey],
      queryFn: () => queryFn(i),
      ...otherQueryOptions,
    })),
  })
}

export function useAsyncMutation<T, I>(
  mutationFn: MutationFunction<T, I>,
  key: {invalidate?: QueryKey; update?: [QueryKey, I]} = {}
): UseMutationResult<T, Error, I, unknown> {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn,
    onSuccess: key.invalidate
      ? () => queryClient.invalidateQueries({queryKey: key.invalidate})
      : key.update
      ? () => queryClient.setQueryData(key.update?.[0]!, key.update?.[1]!)
      : undefined,
  })
}
