const ADMIN_SUPPORT_EMAIL = 'mailto:customersuccess@freckle.com'
const UK_ADMIN_SUPPORT_EMAIL = 'mailto:support@renlearn.co.uk'

export function getFAQLink(countryCode: string | null | undefined): string {
  switch (countryCode) {
    case 'GBR':
    case 'IRL':
      return 'https://help.renlearn.co.uk/freckle'
    default:
      return 'https://help2.renaissance.com/freckle'
  }
}

export function getAdminSupportEmail(countryCode: string | null | undefined): string {
  switch (countryCode) {
    case 'GBR':
    case 'IRL':
      return UK_ADMIN_SUPPORT_EMAIL
    default:
      return ADMIN_SUPPORT_EMAIL
  }
}
