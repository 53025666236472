import reduce from 'lodash/reduce'
import map from 'lodash/map'
import compact from 'lodash/compact'

export function addMaybeClassName(className: string, addClass?: string | null): string {
  return addClass !== null && addClass !== undefined ? `${className} ${addClass}` : className
}

export function addMaybeClassNames(
  className: string,
  addClasses: Array<string | undefined | null>
): string {
  return reduce(addClasses, addMaybeClassName, className)
}

export function addIfClassNames(
  pairs: Array<[boolean | undefined | null, string | undefined | null]>
): string {
  const maybeClassNames = map(pairs, ([shouldAdd, className]) =>
    shouldAdd === true ? className : null
  )
  return compact(maybeClassNames).join(' ')
}

/** Convert an array of class into a className */
export function addClassNames(names: Array<string | null>): string {
  return addIfClassNames(names.map(n => [n === null ? false : true, n]))
}
