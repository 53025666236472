import flatten from 'lodash/flatten'
import {exhaustive} from '@freckle/exhaustive'
import {maybe} from '@freckle/maybe'
import {Moment} from 'moment-timezone'
import {ajaxJsonCall} from '@freckle/ajax'
import {
  ParserT,
  Parser,
  firstOf,
  date,
  number,
  literal,
  nullable,
  record,
  stringEnum,
  field,
} from '@freckle/parser'
import {mmap} from '@freckle/maybe'
import {CompletionStatuses} from '@freckle/educator-entities/ts/common/helpers/completion-status'
import {
  CompletionStatusT,
  isComplete as isCompleteHelper,
} from '@freckle/educator-entities/ts/common/helpers/completion-status'
import {fromParams} from '@freckle/educator-entities/ts/common/helpers/generic-assignment-sessions-params'
import {ParamsT} from '@freckle/educator-entities/ts/common/helpers/generic-assignment-sessions-params'
import {parseSubject} from '@freckle/educator-entities/ts/common/helpers/subjects'
import {SubjectT} from '@freckle/educator-entities/ts/common/helpers/subjects'
import {IsSelfAssignedT} from '@freckle/educator-entities/ts/common/helpers/self-assigned'
import {TranslateT} from '@freckle/educator-entities/ts/common/helpers/translate'
import {Products} from '@freckle/educator-entities/ts/common/helpers/products'
import {
  MathAdaptiveT,
  MathTargetedT,
  MathFocusSkillsPracticeT,
  MathAssessmentsT,
  MathFactPracticeT,
  MathIblsT,
  MathNumberFactsT,
  MathNumberBasicsT,
  MathDepthOfKnowledgePracticeT,
  MathTargetedDepthOfKnowledgePracticeT,
  ElaDecodablesT,
  ElaTargetedSkillsPracticeT,
  ElaFocusSkillsPracticeT,
  ElaAdaptiveSkillsPracticeT,
  ElaWordStudyT,
  ElaSightWordsT,
  ElaArticlesReadingT,
  ElaArticlesWritingT,
  ElaAssessmentsT,
  ScienceArticlesReadingT,
  ScienceArticlesWritingT,
  SocialStudiesArticlesReadingT,
  SocialStudiesArticlesWritingT,
  ProductT,
} from '@freckle/educator-entities/ts/common/helpers/products'
import {
  ArticleMetadataT,
  ResumableArticleMetadataT,
} from '@freckle/educator-entities/ts/common/models/generic-assignment-session/articles'

import CApiHelper from '@freckle/educator-entities/ts/common/helpers/common-api-helper'
import {ElaArticleInfoAttrs} from '@freckle/educator-entities/ts/ela/articles/models/ela-article-info'
import {fetchElaArticleInfoCollection} from '@freckle/educator-entities/ts/ela/articles/models/ela-article-info'
import {fetchDecodableInfos} from '@freckle/educator-entities/ts/ela/decodables/models/decodable-info'
import {fetchElaAssessments} from '@freckle/educator-entities/ts/ela/assessments/models/ela-assessment'
import {getElaAssessmentLetter} from '@freckle/educator-entities/ts/ela/assessments/models/ela-assessment'
import {
  ElaAssessmentMetadataT,
  ElaDecodablesMetadataT,
  ElaAdaptiveSkillsPracticeMetadataT,
  ElaTargetedSkillsPracticeMetadataT,
  ElaFocusSkillsPracticeMetadataT,
  ElaWordStudyMetadataT,
  ElaSightWordsMetadataT,
} from '@freckle/educator-entities/ts/common/models/generic-assignment-session/ela'
import {
  parseElaArticlesReadingMetadata,
  parseElaArticlesWritingMetadata,
  parseElaAssessmentsMetadata,
  parseElaDecodablesMetadata,
  parseElaAdaptiveSkillsPracticeMetadata,
  parseElaTargetedSkillsPracticeMetadata,
  parseElaFocusSkillsPracticeMetadata,
  parseWordStudyMetadata,
  parseElaSightWordsMetadata,
} from '@freckle/educator-entities/ts/common/models/generic-assignment-session/ela'
import {
  getAdaptiveSkillsTitle,
  getDecodableTitle,
  getTargetedSkillsTitle,
  getFocusSkillsTitle,
  getArticleTitle,
  getWordStudyTitle,
  getAssessmentTitle,
} from '@freckle/educator-entities/ts/ela/common/logic/assignments'
import {
  HTMLText,
  PlainText,
  plainTextParser,
} from '@freckle/educator-entities/ts/common/models/html-text'

import {
  MathAdaptiveMetadataT,
  MathTargetedMetadataT,
  MathFocusSkillsPracticeMetadataT,
  MathAssessmentMetadataT,
  MathIblMetadataT,
  MathFactPracticeMetadataT,
  MathNumberBasicsMetadataT,
  MathDepthOfKnowledgeMetadataT,
  MathTargetedDepthOfKnowledgeMetadataT,
} from './math'
import {
  parseMathAdaptiveMetadata,
  parseMathTargetedMetadata,
  parseMathAssessmentsMetadata,
  parseMathIblsMetadata,
  parseMathNumberFactsMetadata,
  parseMathNumberBasicsMetadata,
  parseMathFactPracticeMetadata,
  parseMathDepthOfKnowledgeMetadata,
  parseMathTargetedDepthOfKnowledgeMetadata,
} from './math'
import {parseScienceReadingMetadata, parseScienceWritingMetadata} from './science'
import {
  parseSocialStudiesReadingMetadata,
  parseSocialStudiesWritingMetadata,
} from './social-studies'

export type GenericAssignmentSessionMetadataT =
  | {
      tag: MathAdaptiveT
      contents: MathAdaptiveMetadataT
    }
  | {
      tag: MathTargetedT
      contents: MathTargetedMetadataT
    }
  | {
      tag: MathFocusSkillsPracticeT
      contents: MathFocusSkillsPracticeMetadataT
    }
  | {
      tag: MathAssessmentsT
      contents: MathAssessmentMetadataT
    }
  | {
      tag: MathFactPracticeT
      contents: MathFactPracticeMetadataT
    }
  | {
      tag: MathIblsT
      contents: MathIblMetadataT
    }
  | {
      tag: MathNumberFactsT
    }
  | {
      tag: MathNumberBasicsT
      contents: MathNumberBasicsMetadataT
    }
  | {
      tag: MathDepthOfKnowledgePracticeT
      contents: MathDepthOfKnowledgeMetadataT
    }
  | {
      tag: MathTargetedDepthOfKnowledgePracticeT
      contents: MathTargetedDepthOfKnowledgeMetadataT
    }
  | {
      tag: ElaDecodablesT
      contents: ElaDecodablesMetadataT
    }
  | {
      tag: ElaTargetedSkillsPracticeT
      contents: ElaTargetedSkillsPracticeMetadataT
    }
  | {
      tag: ElaFocusSkillsPracticeT
      contents: ElaFocusSkillsPracticeMetadataT
    }
  | {
      tag: ElaAdaptiveSkillsPracticeT
      contents: ElaAdaptiveSkillsPracticeMetadataT
    }
  | {
      tag: ElaWordStudyT
      contents: ElaWordStudyMetadataT
    }
  | {
      tag: ElaSightWordsT
      contents: ElaSightWordsMetadataT
    }
  | {
      tag: ElaArticlesReadingT
      contents: ResumableArticleMetadataT
    }
  | {
      tag: ElaArticlesWritingT
      contents: ArticleMetadataT
    }
  | {
      tag: ElaAssessmentsT
      contents: ElaAssessmentMetadataT
    }
  | {
      tag: ScienceArticlesReadingT
      contents: ResumableArticleMetadataT
    }
  | {
      tag: ScienceArticlesWritingT
      contents: ArticleMetadataT
    }
  | {
      tag: SocialStudiesArticlesReadingT
      contents: ResumableArticleMetadataT
    }
  | {
      tag: SocialStudiesArticlesWritingT
      contents: ArticleMetadataT
    }

const parseStatus: ParserT<CompletionStatusT> = stringEnum(
  'CompletionStatusT',
  CompletionStatuses.parse
)

const parseMetadata = firstOf<GenericAssignmentSessionMetadataT>(
  // Science
  parseScienceReadingMetadata,
  parseScienceWritingMetadata,

  // Social Studies
  parseSocialStudiesReadingMetadata,
  parseSocialStudiesWritingMetadata,

  // ELA
  parseElaArticlesReadingMetadata,
  parseElaArticlesWritingMetadata,
  parseElaAssessmentsMetadata,
  parseElaDecodablesMetadata,
  parseElaAdaptiveSkillsPracticeMetadata,
  parseElaTargetedSkillsPracticeMetadata,
  parseElaFocusSkillsPracticeMetadata,
  parseWordStudyMetadata,
  parseElaSightWordsMetadata,

  // Math
  parseMathAdaptiveMetadata,
  parseMathTargetedMetadata,
  parseMathAssessmentsMetadata,
  parseMathIblsMetadata,
  parseMathFactPracticeMetadata,
  parseMathNumberFactsMetadata,
  parseMathNumberBasicsMetadata,
  parseMathDepthOfKnowledgeMetadata,
  parseMathTargetedDepthOfKnowledgeMetadata
)

const parseTeacherAssigned: ParserT<IsSelfAssignedT> = firstOf(
  literal('self_assigned'),
  literal('teacher_assigned')
)

export type GenericAssignmentSessionAttrsT = {
  sessionId: number
  assignmentId: number
  isSelfAssigned: IsSelfAssignedT
  studentId: number
  teacherId: number | undefined | null
  createdAt: Moment
  completedAt: Moment | undefined | null
  startsAt: Moment
  durationSeconds: number | undefined | null
  numQuestionsAnswered: number | undefined | null
  accuracy: number | undefined | null
  status: CompletionStatusT
  subject: SubjectT
  metadata: GenericAssignmentSessionMetadataT
  title: HTMLText | PlainText | undefined | null
}

export const getPracticeMode = (t: TranslateT, session: GenericAssignmentSessionAttrsT): string =>
  Products.getPracticeMode(t, session.metadata.tag)

export const parseAttrs: ParserT<GenericAssignmentSessionAttrsT> = record({
  //Using sessionId instead of id to prevent backbone from deduping sessions with like id's.
  sessionId: field(number(), 'id'),
  assignmentId: number(),
  isSelfAssigned: parseTeacherAssigned,
  studentId: number(),
  teacherId: nullable(number()),
  createdAt: date(),
  completedAt: nullable(date()),
  startsAt: date(),
  durationSeconds: nullable(number()),
  numQuestionsAnswered: nullable(number()),
  accuracy: nullable(number()),
  status: parseStatus,
  subject: parseSubject,
  metadata: parseMetadata,
  title: nullable(plainTextParser),
})

export const parse = Parser.mkRun<GenericAssignmentSessionAttrsT>(parseAttrs)

export const isSelfAssigned = (session: GenericAssignmentSessionAttrsT): boolean =>
  session.isSelfAssigned === 'self_assigned'

export function isTargetedOrTeacherAssigned(session: GenericAssignmentSessionAttrsT): boolean {
  const {metadata, isSelfAssigned} = session
  return metadata.tag === 'math_targeted' || isSelfAssigned !== 'self_assigned'
}

export function isComplete(session: GenericAssignmentSessionAttrsT): boolean {
  return isCompleteHelper(session.status)
}

export function needsGrading(session: GenericAssignmentSessionAttrsT): boolean {
  const {tag} = session.metadata
  if (
    tag === 'ela_articles_writing' ||
    tag === 'social_studies_articles_writing' ||
    tag === 'science_articles_writing'
  ) {
    const status = session.status
    const accuracy = session.accuracy
    return status === 'completed' && (accuracy === null || accuracy === undefined)
  }
  return false
}

type SessionContextT =
  | {
      type: 'school'
      schoolId: number
    }
  | {
      type: 'classroom'
      courseId: number
    }

function getBaseSessionsUrl(context: SessionContextT, product: ProductT): string {
  const paths = Products.getPaths(product)
  switch (context.type) {
    case 'school':
      return CApiHelper.fancyPaths.v3.v3_schools.v3_school.school_subjects.school_subject.school_products.school_product.sessions(
        context.schoolId,
        paths.subjectPath,
        paths.productPath
      )
    case 'classroom':
      return CApiHelper.fancyPaths.v3.v3_courses.v3_course.course_subjects.course_subject.course_products.course_product.course_subject_product_sessions._(
        context.courseId,
        paths.subjectPath,
        paths.productPath
      )
    default:
      return exhaustive(context, 'SessionContextT')
  }
}

function getSessionsUrl(context: SessionContextT, product: ProductT, params: ParamsT): string {
  const baseUrl = getBaseSessionsUrl(context, product)
  return `${baseUrl}${fromParams(params)}`
}

// Fetch a single session for given product
export function fetchGenericAssignmentSessionForProduct(
  product: ProductT,
  sessionId: number
): Promise<GenericAssignmentSessionAttrsT> {
  const paths = Products.getPaths(product)
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v3.subjects.subject.products.product.subject_product_sessions.subject_product_session._(
      paths.subjectPath,
      paths.productPath,
      sessionId
    ),
    method: 'GET',
  }).then(parse)
}

// Fetch sessions for a set of products in classroom
export const fetchClassroomGenericAssignmentSessions = async (
  courseId: number,
  products: Array<ProductT>,
  params: ParamsT
): Promise<Array<GenericAssignmentSessionAttrsT>> =>
  await Promise.all(
    products.map(async product => {
      const sessions: Array<unknown> = await ajaxJsonCall({
        url: getSessionsUrl({type: 'classroom', courseId}, product, params),
        method: 'GET',
      })
      return sessions.map(parse)
    })
  ).then(resolvedSessions => flatten(resolvedSessions))

// Fetch sessions for a single product in all courses
export const fetchClassroomGenericAssignmentSessionsForCourses = async (
  courses: Array<number>,
  product: ProductT,
  params: ParamsT
): Promise<Array<GenericAssignmentSessionAttrsT>> =>
  await Promise.all(
    courses.map(async courseId => {
      const sessions: Array<unknown> = await ajaxJsonCall({
        url: getSessionsUrl({type: 'classroom', courseId}, product, params),
        method: 'GET',
      })
      return sessions.map(parse)
    })
  ).then(resolvedSessions => flatten(resolvedSessions))

// Fetch sessions for a set of products in school
export const fetchSchoolGenericAssignmentSessions = async (
  schoolId: number,
  products: Array<ProductT>,
  params: ParamsT
): Promise<Array<GenericAssignmentSessionAttrsT>> =>
  await Promise.all(
    products.map(async product => {
      const sessions: Array<unknown> = await ajaxJsonCall({
        url: getSessionsUrl({type: 'school', schoolId}, product, params),
        method: 'GET',
      })
      return sessions.map(parse)
    })
  ).then(resolvedSessions => flatten(resolvedSessions))

export const filterGenericAssignmentSessionsForProduct = (
  sessions: Array<GenericAssignmentSessionAttrsT>,
  product: ProductT
): Array<GenericAssignmentSessionAttrsT> =>
  sessions.filter(session => session.metadata.tag === product)

export const filterGenericAssignmentSessionsForProducts = (
  sessions: Array<GenericAssignmentSessionAttrsT>,
  products: ProductT[]
): Array<GenericAssignmentSessionAttrsT> =>
  sessions.filter(session => products.includes(session.metadata.tag))

// Get title for ELA products.
//
// This function will trivially return null for non-ELA products. We often
// displaying either Math products OR ELA products. Since we know that
// beforehand, there is no need to force clients to fetch the data needed to
// generate Math titles when there shouldn't be any math sessions.
//
// This function is also "best effort". For example, if a decodable session is
// associated with a decodable that is not in the collection, we simply return
// null. The client of the function can decide to error.
//
// The function is a port of the logic for `mkAssignmentsTableDataModel`.
export const getElaAssignmentTitles = async (
  t: TranslateT,
  sessions: Array<GenericAssignmentSessionAttrsT>
): Promise<Array<GenericAssignmentSessionAttrsT>> =>
  Promise.all(
    sessions.map(async session => {
      const mTitle = await maybe(
        () => getAssignmentTitleForElaProduct(t, session),
        title => Promise.resolve(title),
        session.title
      )
      return {...session, title: mTitle}
    })
  )

export async function getAssignmentTitleForElaProduct(
  t: TranslateT,
  session: GenericAssignmentSessionAttrsT
): Promise<HTMLText | PlainText | undefined | null> {
  switch (session.metadata.tag) {
    case 'math_adaptive':
    case 'math_targeted':
    case 'focus_skills_practice':
    case 'math_assessments':
    case 'math_fact_practice':
    case 'math_ibls':
    case 'math_number_facts':
    case 'math_number_basics':
    case 'math_depth_of_knowledge_practice':
    case 'math_targeted_depth_of_knowledge_practice':
      return null
    case 'ela_decodables': {
      const targetDecodableUuid = session.metadata.contents.decodableUuid
      // TODO: fetch with react query
      const decodableInfoCollection = await fetchDecodableInfos()

      const maybeDecodable = decodableInfoCollection.find(
        decodableInfo => decodableInfo.uuid === targetDecodableUuid
      )

      return mmap(
        targetDecodable => getDecodableTitle(targetDecodable.title, session.title),
        maybeDecodable
      )
    }
    case 'ela_targeted_skills_practice': {
      const standard = session.metadata.contents.standard
      return getTargetedSkillsTitle(standard, t, session.title)
    }
    case 'ela_focus_skills_practice': {
      const standard = session.metadata.contents.standard
      return getFocusSkillsTitle(standard, t, session.title)
    }
    case 'ela_adaptive_skills_practice': {
      const {domain, rlStandard} = session.metadata.contents
      return getAdaptiveSkillsTitle(domain, t, session.title, rlStandard)
    }
    case 'ela_word_study': {
      const {rlStandard, rlSkill} = session.metadata.contents
      return getWordStudyTitle(rlStandard, rlSkill, t, session.title)
    }
    case 'ela_sight_words':
      return null
    case 'ela_articles_reading': {
      const articleUuid = session.metadata.contents.articleUuid
      const elaArticleInfoCollection = await fetchElaArticleInfoCollection()

      return getTitleForArticle(articleUuid, elaArticleInfoCollection, session.title)
    }

    case 'ela_articles_writing': {
      const uuid = session.metadata.contents.articleUuid

      const articleInfoCollection = await fetchElaArticleInfoCollection()

      return getTitleForArticle(uuid, articleInfoCollection, session.title)
    }
    case 'ela_assessments': {
      const assessmentContentUuid = session.metadata.contents.assessmentContentUuid
      // TODO: fetch with react query
      const elaAssessments = await fetchElaAssessments()

      const mLetter = getElaAssessmentLetter(elaAssessments, assessmentContentUuid)

      return mmap(letter => getAssessmentTitle(letter, t, session.title), mLetter)
    }
    case 'science_articles_reading':
    case 'science_articles_writing':
    case 'social_studies_articles_reading':
    case 'social_studies_articles_writing':
      return null
    default:
      return exhaustive(session.metadata, 'ProductT')
  }
}

function getTitleForArticle(
  articleUuid: string,
  elaArticleInfoCollection: Array<ElaArticleInfoAttrs>,
  assignmentTitle?: HTMLText | PlainText | null
): HTMLText | PlainText | undefined | null {
  const mArticleInfo = elaArticleInfoCollection.find(
    articleInfo => articleInfo.uuid === articleUuid
  )

  return mmap(articleInfo => getArticleTitle(articleInfo.title, assignmentTitle), mArticleInfo)
}
