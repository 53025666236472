import * as React from 'react'
import {useSafeTranslation} from '@freckle/educator-materials/ts/helpers/translate'
import {useLocation} from 'react-router-dom'
import {BugsnagEventT} from '@freckle/educator-entities/ts/common/helpers/exception-handlers/bugsnag-helper'
import {PATHS} from '@freckle/educator-entities/ts/common/helpers/paths'
import {useSafeEffect} from '@freckle/react-hooks'
import {H2, Text} from '@freckle/educator-materials/ts/components/typography'
import {Flex} from '@freckle/educator-materials/ts/components/flex'
import {Image} from '@freckle/educator-materials/ts/components/img'
import {Link} from '@freckle/educator-materials/ts/components/link'
import {logError} from '@freckle/classroom/ts/common/helpers/exception-handlers/bugsnag-client'
import {Routes} from '@freckle/classroom/ts/common/routers/routes'
import {imageStyle, wrapperStyle} from './not-found.module.scss'

export function NotFound(): React.ReactElement {
  const {t} = useSafeTranslation()
  const {pathname} = useLocation()

  useSafeEffect(() => {
    logError(new Error(`Page Not Found - Invalid url ${pathname}`), (event: BugsnagEventT) => {
      event.severity = 'info'
      event.unhandled = false
    })
  }, [pathname])

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      className={wrapperStyle}
    >
      <Image
        src={`${PATHS.imageAssetsUrl}/avatars/jt-confused.svg`}
        className={imageStyle}
        alt=""
        title=""
      />
      <H2>{t('PAGE_NOT_FOUND_TITLE')}</H2>
      <Text style="body-3">{t('PAGE_NOT_FOUND_ERROR_CODE')}</Text>
      <Link
        style="btn-primary"
        href={Routes.dashboard()}
        reactRouter
        dataTest="page-not-found-link"
      >
        {t('PAGE_NOT_FOUND_LINK')}
      </Link>
    </Flex>
  )
}
