import * as React from 'react'
import {useSafeTranslation, SafeTrans} from '@freckle/educator-materials/ts/helpers/translate'
import {Alert} from '@freckle/educator-materials/ts/components/alert'
import {isSocialStudiesPremium} from '@freckle/educator-entities/ts/common/logic/premium-access'
import {WithResources} from '@freckle/classroom/ts/common/components/with-resources'

import {QuoteRequestModalLink} from '@freckle/classroom/ts/common/components/quote-request-modal-link'
import {PageWrapper} from '@freckle/classroom/ts/common/components/page-wrapper'
import {usePremiumLicenses} from '@freckle/classroom/ts/hooks/use-premium-licenses'

type Props = {
  component: React.ComponentType<{}>
}

export function SocialStudiesTakedown({component: Component}: Props): React.ReactElement {
  const {t} = useSafeTranslation()

  const premiumLicenses = usePremiumLicenses()
  return (
    <WithResources
      resources={{premiumLicenses}}
      render={({premiumLicenses}) => {
        const isPremium = isSocialStudiesPremium(premiumLicenses)
        if (isPremium) {
          return <Component />
        } else {
          const quoteRequestModalLink = (
            <QuoteRequestModalLink
              linkText={t('SOCIAL_STUDIES_TAKEDOWN_REQUEST_QUOTE_LINK_TEXT')}
            />
          )
          return (
            <PageWrapper pageHeaderText={t('SOCIAL_STUDIES_TAKEDOWN_PAGE_HEADER_TEXT')}>
              <Alert type="premium">
                <SafeTrans
                  i18nKey="SOCIAL_STUDIES_TAKEDOWN_PREMIUM_ALERT"
                  components={{quoteRequestModalLink}}
                />
              </Alert>
            </PageWrapper>
          )
        }
      }}
    />
  )
}
