import {getValidatedImpression} from '@freckle/educator-entities/ts/common/helpers/split-io-helper'
import {v4 as uuidV4} from 'uuid'
import StorageHelper from '@freckle/educator-entities/ts/common/helpers/storage-helper'
import {saveSplitImpression} from '@freckle/classroom/ts/common/helpers/api-helper'
import {updatePendoAuthedSplitIOTreatments} from '@freckle/classroom/ts/common/routers/app-router'

const SPLIT_IO_ANONYMOUS_KEY = 'splitIoAnonymousId'

export function logImpression(impressionData: Object | null | undefined) {
  const impression = getValidatedImpression(impressionData)

  if (impression !== null && impression !== undefined) {
    const {keyName, feature: split, treatment, label} = impression
    const teacherId = parseInt(keyName, 10)
    saveSplitImpression(teacherId, split, treatment, label)
    updatePendoAuthedSplitIOTreatments(split, treatment)
  }
}

function createSplitIOAnonymousId(): string {
  const uuid = uuidV4()
  StorageHelper.setSecureCookie(SPLIT_IO_ANONYMOUS_KEY, uuid.toString())
  return uuid.toString()
}

function getSplitIOAnonymousId(): string | null | undefined {
  return StorageHelper.getCookie(SPLIT_IO_ANONYMOUS_KEY)
}

export function getOrCreateSplitIOAnonymousId(): string {
  const mAnonymousCookieId = getSplitIOAnonymousId()

  return mAnonymousCookieId === null ||
    mAnonymousCookieId === undefined ||
    mAnonymousCookieId.length === 0
    ? createSplitIOAnonymousId()
    : mAnonymousCookieId
}
