import * as React from 'react'
import delay from 'lodash/delay'
import {SafeTrans} from '@freckle/educator-materials/ts/helpers/translate'
import {H1} from '@freckle/educator-materials/ts/components/typography'
import {Spinner} from '@freckle/educator-entities/ts/common/components/spinner-wrapper/spinner'
import {PATHS} from '@freckle/educator-entities/ts/common/helpers/paths'
import {AvatarLogo} from '@freckle/classroom/ts/common/components/page/avatar-logo'

export function GoogleLogin(): React.ReactElement {
  React.useEffect(() => {
    delay(() => {
      document.location.href = `${PATHS.unversionedAPIUrl}/auth/page/google/forward`
    }, 2000)
  }, [])

  return (
    <>
      <AvatarLogo pageType="login" />
      <SafeTrans i18nKey="GOOGLE_EMAIL_RESET_LOADING_SCREEN_BODY" components={{title: <H1 />}} />
      <Spinner inline />
    </>
  )
}
