import * as React from 'react'
import Modal from 'react-bootstrap/Modal'
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalFooter from 'react-bootstrap/ModalFooter'
import ModalTitle from 'react-bootstrap/ModalTitle'
import {asHTMLAttributeValue} from '@freckle/maybe'
import {addMaybeClassNames} from '@freckle/educator-entities/ts/common/helpers/classnames'

import {Flex, FlexDirectionT, AlignItemsT} from '@freckle/educator-materials/ts/components/flex'
import {
  modalStyle,
  modalHeaderStyle,
  modalTitleStyle,
  modalBodyStyle,
  footerStyle,
} from './basic-modal.module.scss'

type Props = {
  /** When true The modal will show itself */
  show: boolean
  /** Trigger when clicking outside of the modal or closing the modal */
  onHide: () => void
  /** Trigger when modal finishes closing */
  onExited?: () => void
  /** Title of the modal in the header */
  title: string
  /** The content of the modal */
  children: React.ReactNode
  /** Adds a data-test attributes to the modal container */
  dataTest?: string
  /** Adds a data-test attributes to the modal title */
  dataTestTitle?: string
  /** Callback fired before the Modal transitions in */
  onEnter?: () => void
  /** Component you can put at the bottom of the modal */
  footer?: React.ReactNode
  /** Hides the close button if true */
  isDismissable?: boolean
  /**
   * Include a backdrop component. Specify 'static' for a backdrop that doesn't trigger an "onHide" when clicked.
   * 'static' = Clicking outside doesn't close the modal
   * false = Clicking outside doesn't close the modal
   * true = Clicking outside close the modal
   */
  backdrop?: 'static' | boolean
  /** Determine with direction the modal body flex direction */
  flexDirection?: FlexDirectionT
  /** Determine modal body flex alignment */
  alignItems?: AlignItemsT
  addBodyStyle?: string
}

export function BasicModal(props: Props): React.ReactElement {
  const {
    show,
    onHide,
    onExited,
    title,
    dataTest,
    dataTestTitle,
    children,
    onEnter,
    footer,
    isDismissable,
    backdrop = 'static',
    flexDirection = 'row',
    alignItems,
    addBodyStyle,
  } = props
  const wrappedFooter =
    footer !== null && footer !== undefined ? (
      <ModalFooter className={footerStyle}>{footer}</ModalFooter>
    ) : null
  const dataTestObj = {'data-test': asHTMLAttributeValue(dataTest)}
  const className = addMaybeClassNames('modal-body', [modalBodyStyle, addBodyStyle])
  return (
    <Modal
      show={show}
      backdrop={backdrop}
      onHide={onHide}
      onExited={onExited}
      dialogClassName={modalStyle}
      onEnter={onEnter}
      size="lg"
      {...dataTestObj}
    >
      <ModalHeader
        closeButton={isDismissable === false ? false : true}
        bsPrefix={`modal-header ${modalHeaderStyle}`}
        onHide={onHide}
      >
        <ModalTitle bsPrefix={`modal-title ${modalTitleStyle}`} data-test={dataTestTitle}>
          {title}
        </ModalTitle>
      </ModalHeader>
      <ModalBody bsPrefix={className}>
        <Flex justifyContent="space-between" flexDirection={flexDirection} alignItems={alignItems}>
          {children}
        </Flex>
      </ModalBody>
      {wrappedFooter}
    </Modal>
  )
}
