import find from 'lodash/find'
import {Moment} from 'moment-timezone'
import {Languages, LangT} from '@freckle/educator-entities/ts/common/helpers/languages'
import {
  ParserT,
  Parser,
  string,
  record,
  array,
  number,
  boolean,
  date,
  stringEnum,
  nullable,
  nullableDefined,
} from '@freckle/parser'
import {ajaxJsonCall} from '@freckle/ajax'
import {fromJust} from '@freckle/maybe'
import {Environments, Environment} from '@freckle/educator-entities/ts/common/helpers/environment'
import {Leaderboards, Leaderboard} from '@freckle/educator-entities/ts/common/helpers/leaderboard'
import {TeacherRoles, TeacherRole} from '@freckle/educator-entities/ts/common/helpers/teacher-role'
import {Subjects, SubjectT} from '@freckle/educator-entities/ts/common/helpers/subjects'
import StorageHelper from '@freckle/educator-entities/ts/common/helpers/storage-helper'
import CApiHelper from '@freckle/educator-entities/ts/common/helpers/common-api-helper'
import {
  ElaLevelingPrograms,
  ElaLevelingProgramT,
} from '@freckle/educator-entities/ts/ela/common/logic/ela-leveling-program'
import {
  IdentityProviderT,
  parser as identityProviderParser,
} from '@freckle/educator-entities/ts/roster/identity-provider'

export type StudentNameSortFieldT = 'first_name' | 'last_name'

export const StudentNameSortField = {
  FirstName: 'first_name' as StudentNameSortFieldT,
  LastName: 'last_name' as StudentNameSortFieldT,

  all(): Array<StudentNameSortFieldT> {
    return [StudentNameSortField.FirstName, StudentNameSortField.LastName]
  },
  parse(s: string): StudentNameSortFieldT | undefined | null {
    return find(StudentNameSortField.all(), sortField => sortField === s)
  },
  parseThrows(s: string): StudentNameSortFieldT {
    return fromJust(
      StudentNameSortField.parse(s),
      `Unexpected value for StudentNameSortFieldT: ${s}`
    )
  },
  forDisplay(s: StudentNameSortFieldT): string {
    return s === StudentNameSortField.FirstName ? 'First Name' : 'Last Name'
  },
}

//Only add properties than can be shared across all apps.
export type BaseTeacherAttrs = {
  id: number
  email: string
  emailVerified: boolean
  firstName: string
  lastName: string
  language: LangT
  countryCode: string | undefined | null
  createdAt: Moment
  updatedAt: Moment
  deletedAt: Moment | undefined | null
  schoolId: number | undefined | null
  leaderboard: Leaderboard
  focusMode: boolean
  googleOnly: boolean
  canChangeEmail: boolean
  environment: Environment | undefined | null
  role: TeacherRole | undefined | null
  cleverTeacherId: string | undefined | null
  classLinkTeacherId: string | undefined | null
  renaissanceRPIdentifier: string | undefined | null
  gurId: string | null
  mathStandardSetId: string // Teacher preference standard set id
  elaStandardSetId: string | undefined | null
  onboardingGrades: Array<number> | undefined | null
  courseSubjects: Array<SubjectT> | undefined | null
  studentNameSortField: StudentNameSortFieldT
  elaLevelingProgram: ElaLevelingProgramT | undefined | null
  sharedRosteringEnabled: boolean | undefined | null
  identityProvider: IdentityProviderT | undefined | null
  idpManaged: boolean
}

export const parseTeacherAttrs: ParserT<BaseTeacherAttrs> = Parser.map<
  BaseTeacherAttrs,
  BaseTeacherAttrs
>(
  record({
    id: number(),
    email: string(),
    emailVerified: boolean(),
    firstName: string(),
    lastName: string(),
    language: Languages.parseT(),
    countryCode: nullable(string()),
    createdAt: date(),
    updatedAt: date(),
    deletedAt: nullable(date()),
    schoolId: nullable(number()),
    leaderboard: stringEnum('Leaderboard', Leaderboards.parse),
    focusMode: boolean(),
    googleOnly: boolean(),
    canChangeEmail: boolean(),
    environment: nullable(stringEnum('Environment', Environments.parse)),
    role: nullable(stringEnum('TeacherRole', TeacherRoles.parse)),
    cleverTeacherId: nullable(string()),
    classLinkTeacherId: nullable(string()),
    gurId: nullableDefined(string()),
    renaissanceRPIdentifier: nullable(string()),
    mathStandardSetId: string(),
    elaStandardSetId: nullable(string()),
    onboardingGrades: nullable(array(number())),
    courseSubjects: nullable(array(stringEnum('SubjectT', Subjects.parse))),
    studentNameSortField: stringEnum('StudentNameSortFieldT', StudentNameSortField.parse),
    elaLevelingProgram: nullable(stringEnum('ElaLevelingProgramT', ElaLevelingPrograms.parse)),
    sharedRosteringEnabled: nullable(boolean()),
    identityProvider: nullable(identityProviderParser),
    idpManaged: boolean(),
  }),
  'checkDemoMode',
  parsedResponse => {
    // Hide Teacher's Name and email when in demoMode
    const demoMode = StorageHelper.getLocalStoragePropertyDefault('demoMode', false)
    const updatedResponse = updateBaseResponseProperties(parsedResponse, demoMode)
    return updatedResponse
  }
)

export const parse = Parser.mkRun<BaseTeacherAttrs>(parseTeacherAttrs)

const parseTeachers = Parser.mkRun<Array<BaseTeacherAttrs>>(array(parseTeacherAttrs))

// Because Teacher has different metadata depending on the app
// The following functions expect something in the Shape of the common attributes

export function updateBaseResponseProperties<
  ExtraAttrs,
  AllAttrs extends Record<string, unknown> & BaseTeacherAttrs & ExtraAttrs
>(teacherAttrs: AllAttrs, isDemoMode: boolean): AllAttrs {
  const updatedResponse = {
    email: isDemoMode ? 'demo@freckle.com' : teacherAttrs.email,
    firstName: isDemoMode ? 'Demo' : teacherAttrs.firstName,
    lastName: isDemoMode ? 'Account' : teacherAttrs.lastName,
  }
  return {...teacherAttrs, ...updatedResponse}
}

export function formattedName(teacher: Inexact<{firstName: string; lastName: string}>): string {
  return `${teacher.firstName} ${teacher.lastName}`
}

export async function fetchTeachersAsASchoolAdmin(): Promise<Array<BaseTeacherAttrs>> {
  const url = CApiHelper.fancyPaths.v2.school_admins.school_admins_me.teachers()
  const response = await ajaxJsonCall({url, method: 'GET'})
  return parseTeachers(response)
}
