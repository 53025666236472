import {ParserT, Parser, string} from '@freckle/parser'

export type RlStandardIdT = string

export const parse: ParserT<RlStandardIdT> = Parser.map(
  string(),
  'RlStandardIdT.fromString',
  fromString
)

export function fromString(rlStandardId: string): RlStandardIdT {
  return rlStandardId
}

export function toString(rlStandardId: RlStandardIdT): string {
  return rlStandardId
}

export function isEqual(a: RlStandardIdT, b: RlStandardIdT): boolean {
  return a === b
}
