import includes from 'lodash/includes'
import map from 'lodash/map'
import filter from 'lodash/filter'
import uniq from 'lodash/uniq'
import maxBy from 'lodash/maxBy'
import find from 'lodash/find'
import {fromJust} from '@freckle/maybe'
import {CourseAttrs} from '@freckle/educator-entities/ts/roster/models/course'
import {CourseMembershipAttrs} from '@freckle/educator-entities/ts/roster/models/course-membership'
import {getActiveAndVisibleCourses} from '@freckle/educator-entities/ts/roster/helpers/tagged-courses'
import {StudentAttrs} from '@freckle/educator-entities/ts/users/models/student'
import {getStudentsforCourse} from '@freckle/educator-entities/ts/users/logic/students'
import {TranslateT} from '@freckle/educator-entities/ts/common/helpers/translate'

import {TeacherAttrs} from '@freckle/classroom/ts/users/models/teacher'
import {
  maxCoursesForFreeEdition,
  maxCoursesForPremium,
} from '@freckle/classroom/ts/common/helpers/helpers'

export function getBiggestCourse(
  courses: Array<CourseAttrs>,
  students: Array<StudentAttrs>,
  courseMemberships: Array<CourseMembershipAttrs>
): CourseAttrs {
  const activeAndVisibleCourses = getActiveAndVisibleCourses(courses).courses
  if (activeAndVisibleCourses.length === 0) {
    throw 'No default course found!'
  } else {
    return fromJust(
      maxBy(
        activeAndVisibleCourses,
        course => getStudentsforCourse(students, course.id, courseMemberships).length
      ),
      'No default course found!'
    )
  }
}

export function getActiveAndVisibleCoursesExcludingDemo(
  teacher: TeacherAttrs,
  courses: Array<CourseAttrs>,
  students: Array<StudentAttrs>,
  courseMemberships: Array<CourseMembershipAttrs>
): Array<CourseAttrs> {
  const teacherStudentIds = map(
    filter(students, s => s.isTeacher),
    s => s.id
  )
  const courseIdsWithoutTeacherStudents = uniq(
    map(
      filter(courseMemberships, m => !includes(teacherStudentIds, m.studentId)),
      m => m.courseId
    )
  )

  const activeAndVisibleCourses = getActiveAndVisibleCourses(courses).courses

  return filter(
    activeAndVisibleCourses,
    course =>
      course.teacherId === teacher.id && includes(courseIdsWithoutTeacherStudents, course.id)
  )
}

export function getAnyActiveAndVisibleCourseWithStudent(
  student: StudentAttrs,
  courses: Array<CourseAttrs>,
  courseMemberships: Array<CourseMembershipAttrs>
): CourseAttrs | null | undefined {
  const studentId = student.id
  const activeAndVisibleCourses = getActiveAndVisibleCourses(courses).courses
  const activeAndVisibleCourseIds = map(activeAndVisibleCourses, course => course.id)

  // Get any course membership for the student
  const courseMembershipForStudent = find(
    courseMemberships,
    membership =>
      membership.studentId === studentId && activeAndVisibleCourseIds.includes(membership.courseId)
  )

  return courseMembershipForStudent !== undefined
    ? find(activeAndVisibleCourses, course => course.id === courseMembershipForStudent.courseId)
    : null
}

export function prettifyCourseNameError(rawError: string, t: TranslateT): string {
  return (
    {
      'unique-violation': t('COURSES_UNIQUE_NAME_ERROR'),
      unauthorized: t('COURSES_UNAUTHORIZED_ERROR'),
      'empty-course-name': t('COURSES_EMPTY_COURSE_NAME_ERROR'),
      'max-courses': t('COURSES_MAX_COURSES_ERROR', {maxCourses: maxCoursesForPremium}),
      'no-premium': t('COURSES_MAX_COURSES_FREE_EDITION_ERROR', {
        maxCourses: maxCoursesForFreeEdition,
      }),
    }[rawError] ?? t('COMMON_ERROR_MESSAGE')
  )
}
