import * as React from 'react'
import {educatorAcademyUser} from '@freckle/classroom/ts/common/helpers/api-helper'
import {displayNotification} from '@freckle/classroom/ts/common/helpers/helpers'
import {useSafeTranslation} from '@freckle/educator-materials/ts/helpers/translate'
import {ExternalLinkItem} from './external-link-item'
import {mkOnClickAction} from './action'

type Props = {
  firstName: string
  lastName: string
  email: string
  id: number // TeacherId
}

const mkNorthPassUrl = ({
  firstName,
  lastName,
  email,
}: {
  firstName: string
  lastName: string
  email: string
}): string =>
  `https://renaissance.northpass.com/app?first_name=${firstName}&last_name=${lastName}&email=${email}`

type MkHandlerProps = Props & {setDisabled: (state: boolean) => void; msg: string}

const mkHandler = (
  props: MkHandlerProps
): ((e: React.SyntheticEvent<EventTarget, Event>) => void) => {
  const {setDisabled, firstName, lastName, email, id, msg} = props

  const handler = (e: React.SyntheticEvent<EventTarget, Event>) => {
    e.preventDefault()

    setDisabled(true)

    const openNorthpass = () => {
      window.open(mkNorthPassUrl({firstName, lastName, email}), '_blank', 'noopener noreferrer')
    }

    const notifyUser = () => displayNotification('error', msg, 2)

    const enableLink = () => setDisabled(false)

    educatorAcademyUser(id).then(openNorthpass).catch(notifyUser).finally(enableLink)
  }

  return handler
}

export const EducatorAcademyLink = (props: Props) => {
  const {firstName, lastName, email, id} = props

  const {t} = useSafeTranslation()

  const [disabled, setDisabled] = React.useState(false)

  const handleClick = mkHandler({
    firstName,
    lastName,
    email,
    id,
    setDisabled,
    msg: t('NAVBAR_RESOURCES_EDUCATOR_ACADEMY_ERROR'),
  })

  return (
    <ExternalLinkItem
      action={mkOnClickAction(handleClick)}
      disabled={disabled}
      dataTest="educator-academy"
      text={t('NAVBAR_RESOURCES_EDUCATOR_ACADEMY')}
    />
  )
}
