import '@formatjs/intl-getcanonicallocales/polyfill'
import '@formatjs/intl-pluralrules/polyfill'
import '@formatjs/intl-numberformat/polyfill'
import '@formatjs/intl-pluralrules/locale-data/en'
import '@formatjs/intl-numberformat/locale-data/en'
import '@formatjs/intl-pluralrules/locale-data/es'
import '@formatjs/intl-numberformat/locale-data/es'
import i18n from 'i18next'
import ICU from 'i18next-icu'
import {initReactI18next} from 'react-i18next'
import LocizeBackend from 'i18next-locize-backend'
import HttpBackend from 'i18next-http-backend'
import {exhaustive} from '@freckle/exhaustive'
import {PATHS} from '@freckle/educator-entities/ts/common/helpers/paths'
import {CONFIG} from '@freckle/educator-entities/ts/common/config'

import {getEnvironment} from '@freckle/educator-entities/ts/config/environment'

const DEFAULT_LANG = 'en'
// All apps use the same project ID
// but separate namespaces
const LOCIZE_PROJECT_ID = '6eeceef9-51a3-46e1-8ea3-cd0c9af5987c'

type TranslatedAppT = 'student' | 'classroom' | 'school' | 'console'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getCommonConfig(app: TranslatedAppT): any {
  return {
    lng: DEFAULT_LANG,
    fallbackLng: 'en',
    allowMultiLoading: true,

    // keySeparator
    // The symbol for nesting translations is "." and we have some translations that have
    // a "." because it is regular English sentences. As we have a flat JSON file, let disable nesting.
    // https://www.i18next.com/overview/configuration-options#misc
    keySeparator: false,

    // nsSeparator
    // The symbol for namespace in translations files is "." and we have some translations that have
    // a "." because it is regular English sentences. Let's change the key for the namespace selector.
    // https://www.i18next.com/overview/configuration-options#misc
    nsSeparator: '|',
    ns: [getAppNamespace(app), 'common'],
    defaultNS: getAppNamespace(app),
    fallbackNS: 'common',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    react: {
      // transKeepBasicHtmlNodesFor
      // For <Trans />, certain simple elements don't need to be converted to <1></1>
      // https://react.i18next.com/latest/trans-component#trans-props
      transKeepBasicHtmlNodesFor: ['br', 'i', 'p', 'strong', 'sup', 'b', 'code'],
    },

    saveMissing: false,
    saveMissingTo: getAppNamespace(app),
  }
}

export function setupI18n(app: TranslatedAppT) {
  const hostname = window.location.hostname
  const env = getEnvironment(hostname)

  switch (env) {
    case 'localhost':
      i18n
        .use(ICU)
        .use(LocizeBackend)
        .use(initReactI18next)
        .init({
          ...getCommonConfig(app),
          debug: true,
          backend: {
            referenceLng: 'en',
            projectId: LOCIZE_PROJECT_ID,
            apiKey: CONFIG.LOCIZE_API_KEY,
            allowedAddOrUpdateHosts: getLocalizeBackendAllowedHosts(app),
            onSaved: (lng: string, ns: string) => {
              console.log(
                `[i18n][locize-backend] Keys for language`,
                lng,
                'in namespace',
                ns,
                'have been updated'
              )
            },
          },
        })
      break

    case 'production':
    case 'staging':
    case 'test':
    case 'demo':
    case null:
    case undefined:
      i18n
        .use(ICU)
        .use(initReactI18next)
        .use(HttpBackend)
        .init({
          ...getCommonConfig(app),
          backend: {
            loadPath: getHttpBackendLoadPath(),
          },
        })
      break
    default:
      exhaustive(env)
  }
}

function getLocalizeBackendAllowedHosts(app: TranslatedAppT): Array<string> {
  switch (app) {
    case 'student':
      return ['student.localhost.com', 'localhost.com', 'localhost']
    case 'classroom':
      return ['classroom.localhost.com', 'localhost.com', 'localhost']
    case 'school':
      return ['school.localhost.com', 'localhost.com', 'localhost']
    case 'console':
      return ['console.localhost.com', 'localhost.com', 'localhost']
    default:
      return exhaustive(app, `TranslatedAppT`)
  }
}

function getHttpBackendLoadPath(): string {
  return `${PATHS.translationsUrl}/latest/{{lng}}/{{ns}}`
}

function getAppNamespace(app: TranslatedAppT): string {
  switch (app) {
    case 'student':
      return 'student'
    case 'classroom':
      return 'classroom'
    case 'school':
      return 'school'
    case 'console':
      return 'console'
    default:
      return exhaustive(app, `TranslatedAppT`)
  }
}
