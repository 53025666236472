import sortBy from 'lodash/sortBy'
import reduce from 'lodash/reduce'
import {ajaxJsonCall} from '@freckle/ajax'
import {NonEmptyArray} from '@freckle/non-empty'
import {urlWithQueryParams} from '@freckle/query-params'
import {
  ParserT,
  Parser,
  string,
  record,
  array,
  number,
  nullable,
  boolean,
  nonEmptyArray,
  nullableDefault,
} from '@freckle/parser'
import {exhaustive} from '@freckle/exhaustive'
import {ContentArea} from '@freckle/educator-entities/ts/curricula-api/generated-client/enums/content-area'
import {RlStandardIdT} from '@freckle/educator-entities/ts/common/types/rl-standard-id'
import * as RlStandardId from '@freckle/educator-entities/ts/common/types/rl-standard-id'
import {RlSkillUspIdT} from '@freckle/educator-entities/ts/common/types/rl-skill-usp-id'
import * as RlSkillUspId from '@freckle/educator-entities/ts/common/types/rl-skill-usp-id'
import {RlSubSkillUspIdT} from '@freckle/educator-entities/ts/common/types/rl-subskill-usp-id'
import * as RlSubSkillUspId from '@freckle/educator-entities/ts/common/types/rl-subskill-usp-id'
import CApiHelper from '@freckle/educator-entities/ts/common/helpers/common-api-helper'
import {RlStandardWithDomainT, RlStandardT} from './../models/rl-standard'

export type RlSubSkillT = {
  uspId: RlSubSkillUspIdT
  parentUspId: string
  standardSetId: string
  name: string
  shortName: string
  progressionOrder: number
}

const parseRlSubSkillAttrs: ParserT<RlSubSkillT> = record({
  uspId: RlSubSkillUspId.parse,
  parentUspId: string(),
  standardSetId: string(),
  name: string(),
  shortName: string(),
  progressionOrder: number(),
})

export type DehydratedSkill = {
  uspId: RlSkillUspIdT
}

export const parseDehydratedSkill: ParserT<DehydratedSkill> = record({
  uspId: RlSkillUspId.parse,
})

export type RlSkillT = {
  uspId: RlSkillUspIdT
  name: string // "Long form" version of the skill name, useful as a description
  shortName: string // Potentially shortened version of the name, useful for constrained space
  progressionOrder: number
  grade: number
  standardSetId: string
  standardId: RlStandardIdT
  isFocus: boolean
  subSkills?: Array<RlSubSkillT> | null
  hasElaGrammarQuestions: boolean
  hasElaPathwayContent: boolean
  hasElaWordStudyQuestions: boolean
}

export type RlSkillWithNonEmptySubSkills = RlSkillT & {
  subSkills: NonEmptyArray<RlSubSkillT>
}

const commonParserAtts = {
  uspId: RlSkillUspId.parse,
  name: string(),
  shortName: string(),
  progressionOrder: number(),
  grade: number(),
  standardSetId: string(),
  standardId: RlStandardId.parse,
  isFocus: boolean(),
  hasElaGrammarQuestions: nullableDefault(boolean(), false),
  hasElaPathwayContent: nullableDefault(boolean(), false),
  hasElaWordStudyQuestions: nullableDefault(boolean(), false),
}

export const parseRlSkillAttrs: ParserT<RlSkillT> = record({
  ...commonParserAtts,
  subSkills: nullable(array(parseRlSubSkillAttrs)),
})

const parseRlSkillWithNonEmptySubSkillsAttrs: ParserT<RlSkillWithNonEmptySubSkills> = record({
  ...commonParserAtts,
  subSkills: nonEmptyArray(parseRlSubSkillAttrs),
})

export const parseRlSkill = Parser.mkRun<RlSkillT>(parseRlSkillAttrs)
export const parseRlSkills = Parser.mkRun<RlSkillT[]>(array(parseRlSkillAttrs))
export const parseRlSkillsWithNonEmptySubSkills = Parser.mkRun<RlSkillWithNonEmptySubSkills[]>(
  array(parseRlSkillWithNonEmptySubSkillsAttrs)
)

export type FetchSkillsStandardFilter =
  | {tag: 'all-standards'}
  | {tag: 'specific-standards'; standardIds: NonEmptyArray<RlStandardIdT>}

const standardIdQueryParam = (
  filter: FetchSkillsStandardFilter
): {standardId: RlStandardIdT[] | null} => {
  switch (filter.tag) {
    case 'all-standards':
      return {standardId: null}
    case 'specific-standards':
      return {standardId: filter.standardIds}
    default:
      return exhaustive(filter)
  }
}

export async function fetchRlSkills(
  contentArea: ContentArea,
  standardSetId: string,
  standards: FetchSkillsStandardFilter
): Promise<Array<RlSkillT>> {
  const queryParams = {
    standardSetId,
    ...standardIdQueryParam(standards),
  }

  const url = urlWithQueryParams(
    CApiHelper.fancyPaths.v3.content_area.content_area_skills._(contentArea),
    queryParams
  )
  const response = await ajaxJsonCall({url, method: 'GET'})
  const parsedSkills = parseRlSkills(response)
  return sortBy(parsedSkills, s => s.progressionOrder)
}

export async function fetchSkillSetSkills(
  contentArea: ContentArea,
  standardSetId: string,
  skillSetIds: Array<number>
): Promise<Array<RlSkillT>> {
  const queryParams = {
    standardSetId,
    skillSetIds,
  }

  const url = urlWithQueryParams(
    CApiHelper.fancyPaths.v3.content_area.content_area_skills._(contentArea),
    queryParams
  )
  const response = await ajaxJsonCall({url, method: 'GET'})
  const parsedSkills = parseRlSkills(response)
  return sortBy(parsedSkills, s => s.progressionOrder)
}

type RlStandardWithAssociatedSkills = {
  skills: RlSkillT[]
} & RlStandardT

// Helper Functions

export const addRlSkillsToStandard = (
  standard: RlStandardWithDomainT,
  skills: RlSkillT[]
): RlStandardWithAssociatedSkills => {
  const skillsByFrStandard = indexRlSkillsByStandard(skills)
  const {id: standardId} = standard
  return {...standard, skills: skillsByFrStandard.get(standardId) ?? []}
}

const indexRlSkillsByStandard = (skills: RlSkillT[]): Map<RlStandardIdT, RlSkillT[]> => {
  const indexByFrStandard = (acc: Map<string, RlSkillT[]>, skill: RlSkillT) => {
    const {standardId} = skill
    const curVal = acc.get(standardId) ?? []
    const newVal = curVal.concat(skill)
    acc.set(standardId, newVal)
    return acc
  }

  return reduce(skills, indexByFrStandard, new Map())
}
