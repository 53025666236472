import isNil from 'lodash/isNil'
import flatten from 'lodash/flatten'
import isEmpty from 'lodash/isEmpty'
import CApiHelper from '@freckle/educator-entities/ts/common/helpers/common-api-helper'
import {ajaxJsonCall} from '@freckle/ajax'
import {Products} from '@freckle/educator-entities/ts/common/helpers/products'
import {
  AssignmentContextArg,
  createAssignmentContextData,
} from '@freckle/educator-entities/ts/common/helpers/assignment-context'
import {
  ElaPassageT,
  fetchElaPassage,
  parseElaPassageShell,
} from '@freckle/educator-entities/ts/ela/common/models/ela-passage'
import {RlSkillUspIdT} from '@freckle/educator-entities/ts/common/types/rl-skill-usp-id'
import {RlStandardIdT} from '@freckle/educator-entities/ts/common/types/rl-standard-id'
import {RlDomainIdT} from '@freckle/educator-entities/ts/common/types/rl-domain-id'
import {ContentAreas} from '@freckle/educator-entities/ts/curricula-api/generated-client/enums/content-area'
import {
  GenericAssignmentSessionAttrsT,
  parse as parseGenericAssignmentSession,
} from '@freckle/educator-entities/ts/common/models/generic-assignment-session'
import {SkillsForQuestionsT} from '@freckle/classroom/ts/ela/adaptive-skills/components/assign-skill-practice/assign-skill-form-container'

export async function createElaAdaptiveSkillsAssignment(
  rlDomainId: RlDomainIdT,
  elaStandardSetId: string,
  startsAt: number | null | undefined,
  studentIds: Array<number>,
  contextArg: AssignmentContextArg
): Promise<GenericAssignmentSessionAttrsT> {
  const PATHS = Products.getPaths(Products.ElaAdaptiveSkillsPractice)
  const baseUrl = CApiHelper.fancyPaths.v3.subjects.subject.products.product.subject_product_sessions._(
    PATHS.subjectPath,
    PATHS.productPath
  )
  const responseArray: unknown[] = await ajaxJsonCall({
    url: `${baseUrl}?ela-standard-set-id=${elaStandardSetId}`,
    method: 'POST',
    data: JSON.stringify({
      studentIds,
      metadata: {
        rlDomainId,
        // TODO Use unified query-param ID per https://app.asana.com/0/1203641938689893/1203683681935217/f
        standardSetId: elaStandardSetId,
      },
      context: createAssignmentContextData(contextArg),
      startsAt,
    }),
  })

  const sessionResp = responseArray[0]

  return parseGenericAssignmentSession(sessionResp)
}

export type CreateElaTargetedParams = {
  contentIds?: Array<string>
  questionIds?: Array<string>
}

const createElaTargetedParamsToMetadata = ({contentIds, questionIds}: CreateElaTargetedParams) => ({
  ...(!isNil(contentIds) && !isEmpty(contentIds) ? {contentIds} : {}),
  ...(!isNil(questionIds) && !isEmpty(questionIds) ? {questionIds} : {}),
})

export async function createElaTargetedSkillsPracticeAssignment(
  rlSkillId: string | null,
  rlStandardId: RlStandardIdT | null,
  elaStandardSetId: string,
  skillsForQuestions: SkillsForQuestionsT,
  startsAt: number | null | undefined,
  params: CreateElaTargetedParams,
  studentIds: Array<number>,
  contextArg: AssignmentContextArg
): Promise<GenericAssignmentSessionAttrsT> {
  const PATHS = Products.getPaths(Products.ElaTargetedSkillsPractice)
  const baseUrl = CApiHelper.fancyPaths.v3.subjects.subject.products.product.subject_product_sessions._(
    PATHS.subjectPath,
    PATHS.productPath
  )
  const test = createElaTargetedParamsToMetadata(params)
  const responseArray: unknown[] = await ajaxJsonCall({
    url: `${baseUrl}?ela-standard-set-id=${elaStandardSetId}`,
    method: 'POST',
    data: JSON.stringify({
      studentIds,
      startsAt,
      context: createAssignmentContextData(contextArg),
      metadata: {
        rlSkillId,
        rlStandardId: rlSkillId === null ? rlStandardId : null,
        // TODO Use unified query-param ID per https://app.asana.com/0/1203641938689893/1203683681935217/f
        standardSetId: elaStandardSetId,
        skillsForQuestions:
          skillsForQuestions !== undefined ? Object.fromEntries(skillsForQuestions) : {},
        ...test,
      },
    }),
  })

  const sessionResp = responseArray[0]

  return parseGenericAssignmentSession(sessionResp)
}

type ResponseT = {questions: Array<{uuid: string}>}[]

export const fetchTargetedSkillsPreviewPassages = async (
  targetSkillId: RlSkillUspIdT | null,
  targetStandardId: RlStandardIdT | null,
  excludeContent: Array<string>,
  elaStandardSetId: string,
  numQuestions: number
): Promise<{passages: Array<ElaPassageT>; skillsForQuestions: SkillsForQuestionsT}> => {
  const response: ResponseT = await ajaxJsonCall({
    url: CApiHelper.fancyPaths.v3.content_area.content_area_questions.arbitrary(ContentAreas.Ela),
    method: 'POST',
    data: JSON.stringify({
      targetSkillId,
      targetStandardId,
      standardSetId: elaStandardSetId,
      excludeContent,
      numQuestions,
    }),
  })
  const elaPassageShells = flatten(response.map(r => r.questions)).map(parseElaPassageShell)
  const elaPassageIds = elaPassageShells.map(({uuid}) => uuid)

  const passages = await Promise.all(elaPassageIds.map(fetchElaPassage))
  const skillsForQuestions = new Map<string, RlSkillUspIdT>()
  elaPassageShells.forEach(p => {
    if (!isNil(p.skillId)) skillsForQuestions.set(p.uuid, p.skillId)
  })

  return {passages, skillsForQuestions}
}
