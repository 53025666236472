import isPlainObject from 'lodash/isPlainObject'
import isFinite from 'lodash/isFinite'
import isError from 'lodash/isError'
import isNil from 'lodash/isNil'
import endsWith from 'lodash/endsWith'
import isArray from 'lodash/isArray'
import some from 'lodash/some'
import includes from 'lodash/includes'
import moment from 'moment-timezone'
import Bugsnag, {Client} from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import {fromMaybe} from '@freckle/maybe'
import {CONFIG} from '@freckle/educator-entities/ts/common/config'
import {HandledError} from '@freckle/educator-entities/ts/common/exceptions/handled-error'
import {getCustomGroupingHash} from '@freckle/educator-entities/ts/common/helpers/exception-handlers/bugsnag-helper/custom-grouping-hash'
import EXCLUDED_FUNCTION_NAMES from '@freckle/educator-entities/ts/common/helpers/exception-handlers/bugsnag-helper/not-in-project-functions.json'
import {getEnvironment} from '@freckle/educator-entities/ts/config/environment'

const EXCLUDED_FILE_MINIFIED_NAME_CONTAINS = [
  'static-vendor.js',
  'node_modules',
  '[native code]',
  'chunk.vendor',
  'api-fetch.js',
  'react-dom.production',
]

const EXCLUDED_ERRORS = [
  "'console' is undefined",
  'The node before which the new node is to be inserted is not a child of this node',
]

export type BugsnagEventT = {
  apiKey?: string
  app: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
  }
  device: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
  }
  request: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
  }
  context?: string
  breadcrumbs: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
  }[]
  groupingHash?: string
  severity: 'info' | 'warning' | 'error'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  originalError: any
  unhandled: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addMetadata: (section: string, key: string | any, value?: any) => void
  getMetadata: (section: string, key?: string) => void
  clearMetadata: (section: string, key?: string) => void
  errors: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
  }[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getUser: () => any
  setUser: (id?: string, email?: string, name?: string) => void
}

export type ErrorContext = {
  user: UserData | undefined | null
}

type UserData = {
  id: number
}

const BugsnagHelper = {
  init: (getErrorContext: () => ErrorContext): Client => {
    return Bugsnag.start({
      apiKey: CONFIG.BUGSNAG_API_KEY,
      appVersion: CONFIG.BUGSNAG_APP_VERSION,

      autoDetectErrors: true,
      enabledErrorTypes: {
        unhandledExceptions: true,
        unhandledRejections: true,
      },

      enabledBreadcrumbTypes: [
        'navigation',
        'request',
        'process',
        'log',
        'user',
        'state',
        'error',
        'manual',
      ],

      plugins: [new BugsnagPluginReact()],

      onError: (event: BugsnagEventT) => {
        const customGroupHash = getCustomGroupingHash(event)
        if (customGroupHash !== null) {
          event.groupingHash = customGroupHash
        }
        event.severity = 'error'

        if (event.context === '/') {
          event.context = `/${window.location.hash}`
        }

        event.app.releaseStage = fromMaybe(
          () => 'unknown',
          getEnvironment(window.location.hostname)
        )

        // TODO: https://app.asana.com/0/149473556304568/1206945111987245/f
        if (isArray(event.errors[0]!.stacktrace)) {
          // TODO: https://app.asana.com/0/149473556304568/1206945111987245/f
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          event.errors[0]!.stacktrace.forEach((frame: any) => {
            const inProject = !isNotInProject(frame)
            // If you set one stackframe.inProject you should set them all
            frame.inProject = inProject
          })
        }

        event.app.github = `https://github.com/freckle/megarepo/commit/${CONFIG.BUGSNAG_APP_VERSION}`
        event.app.timestamp = moment.unix(Number(CONFIG.COMMIT_UNIX_TIMESTAMP)).format()

        const {user: user} = getErrorContext()
        if (user?.id !== undefined) {
          event.setUser(user.id.toString())
        }
      },
    })
  },

  logError: (err: Error, onError?: (event: BugsnagEventT) => void) => {
    const formattedError = formatError(err)

    const notifyErrorToBugsnag = () => {
      console.error(formattedError)
      onError ? Bugsnag.notify(formattedError, onError) : Bugsnag.notify(formattedError)
    }

    filterAndNotify(err, notifyErrorToBugsnag)
  },

  logErrorAsUnhandled(error: Error) {
    try {
      const notifyReportToBugsnag = () => {
        // Anytime we notify bugsnag with a non-Error object, bugsnag instantiates an Error object
        // with our non-Error value.  This instantiation creates a stackTrace deep in bugsnag
        // code.  They then remove stackFrames from the trace so as to remove bugsnag as the
        // source of the error.  This process does not happen when we notify with a report instead
        // of an Error object.

        // Make sure the error is an Error object, otherwise we'll lose the stackTrace.  Ideally we should _always_ pass in
        // the original Error object so as to not lose stack traces.
        const errorInstance = isError(error) ? error : new Error(JSON.stringify(error))

        console.error(errorInstance)

        // `notify` expects an error object as the first argument.
        // Optionally, you can send diagnostic data or other customizations by passing an `onError` callback as the second argument
        // The callback receives an Event object as a parameter which can be used to add or amend the data sent to your Bugsnag dashboard.
        // You can also return false from the callback to prevent the event being sent at all
        Bugsnag.notify(errorInstance, (event: BugsnagEventT) => {
          // TODO: https://app.asana.com/0/149473556304568/1206945111987245/f
          event.errors[0]!.errorMessage = errorInstance.message
          event.originalError = error
          event.severity = 'error'
          event.unhandled = true
        })
      }

      filterAndNotify(error, notifyReportToBugsnag)
    } catch (e) {
      BugsnagHelper.logError(isError(e) ? e : new Error(JSON.stringify(e)))
    }
  },
}

export default BugsnagHelper

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function filterAndNotify(error: any, notify: () => void) {
  const formattedError = formatError(error)

  // Error may be an XHR or ApiError
  const isReadyStateZero = error?.readyState === 0 || error?.details?.readyState === 0

  // readyState: 0 means the client never even sent the request. This can
  // happen when a promise is cancelled or we've already started
  // navigating away.
  if (isReadyStateZero) {
    return
  }

  // Some errors may be generated by bad actors such
  // as bots without proper JS engines and we'd like
  // to filter these out.
  const errorString = JSON.stringify(error)
  if (EXCLUDED_ERRORS.some(error => errorString.includes(error))) {
    return
  }

  // Temporarily stop reporting Handled errors
  // since we are exceeding the free quota in
  // bugsnag which results in sampling. Doing this
  // will allow capturing of more unhandled errors.
  if (error instanceof HandledError) {
    return
  }

  isPlainObject(error) || isError(error)
    ? shouldReportStatus(error.status)
      ? notify()
      : console.error(formattedError)
    : notify()
}

type FormattedError = Error | string
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function formatError(error: any): FormattedError {
  if (isError(error)) {
    return error
  } else {
    return JSON.stringify(error)
  }
}

function shouldReportStatus(status: number): boolean {
  //If we don't recognize this as a finite status, let's not block reporting
  if (!isFinite(status)) {
    return true
  }

  //we dont want to track following response codes:
  //400 will usually mean validation errors
  //401 & 403 means user is not authorized to access the content
  //Status codes >= 500 are tracked by backend
  const excludedStatuses = [400, 401, 403, 500, 503]

  return !excludedStatuses.includes(status)
}

export function isNotInProject(frame: {method?: string | null; file?: string | null}): boolean {
  const frameMethodName = fromMaybe(() => '', frame.method)
  const frameFile = fromMaybe(() => '', frame.file)

  const isAnExcludedMethod = some(EXCLUDED_FUNCTION_NAMES, name => endsWith(frameMethodName, name))

  const isAnExcludedFile = some(EXCLUDED_FILE_MINIFIED_NAME_CONTAINS, name =>
    includes(frameFile, name)
  )

  return isAnExcludedMethod || isAnExcludedFile
}

type AjaxRequestErrorBreadcrumbT = {
  reqType: string | undefined | null
  reqUrl: string | undefined | null
  reqStatus: {
    status: string
    textStatus: string
  }
  //Duration of time since .ajax() was called
  totalDurationMs: number
  //Duration of time the request took on the server
  serverDurationMs: number
  errorThrown: string
}

export function leaveAjaxErrorBreadcrumb({
  reqType,
  reqUrl,
  totalDurationMs,
  serverDurationMs,
  reqStatus,
  errorThrown,
}: AjaxRequestErrorBreadcrumbT) {
  if (!isNil(reqType) && !isNil(reqUrl)) {
    const request = `${reqType} ${reqUrl}`
    const {status, textStatus} = reqStatus
    const metaData = {request, totalDurationMs, serverDurationMs, status, textStatus, errorThrown}
    Bugsnag.leaveBreadcrumb('XMLHttpRequest failed', metaData, 'request')
  }
}
