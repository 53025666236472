import map from 'lodash/map'
import {Moment} from 'moment-timezone'
import {ajaxJsonCall} from '@freckle/ajax'
import CApiHelper from '@freckle/educator-entities/ts/common/helpers/common-api-helper'

import {ParserT, Parser, record, number, date} from '@freckle/parser'

export type SharedOrTransferredStudentT = {
  studentId: number
  sourceTeacherId: number
  targetTeacherId: number
  createdAt: Moment
}

const sharedOrTransferredStudent: ParserT<SharedOrTransferredStudentT> = record({
  studentId: number(),
  sourceTeacherId: number(),
  targetTeacherId: number(),
  createdAt: date(),
})

export const parseSharedOrTransferredStudent = Parser.mkRun<SharedOrTransferredStudentT>(
  sharedOrTransferredStudent
)

export async function fetchSharedOrTransferredStudents(): Promise<
  Array<SharedOrTransferredStudentT>
> {
  const url = CApiHelper.fancyPaths.v2.student_shares()
  const response: unknown[] = await ajaxJsonCall({url, method: 'GET'})
  return map(response, parseSharedOrTransferredStudent)
}
