import * as React from 'react'
import {useSafeTranslation} from '@freckle/educator-materials/ts/helpers/translate'
import {Container} from '@freckle/educator-materials/ts/components/container'
import {exhaustive} from '@freckle/exhaustive'
import {H1} from '@freckle/educator-materials/ts/components/typography'

import {PageWrapper} from '@freckle/classroom/ts/common/components/page/page-wrapper'
import {FreckleLogo} from '@freckle/classroom/ts/common/components/page/freckle-logo'
import {EmailForm} from '@freckle/classroom/ts/common/components/page/reset-by-email/email-form'
import {EmailSentMessage} from '@freckle/classroom/ts/common/components/page/reset-by-email/email-sent-msg'
import {GoogleLogin} from './google-login'

import {resetByEmailContainer, titleMsgStyle} from './reset-by-email.module.scss'

type EmailResetStepT = 'email-form' | 'email-sent' | 'google-login'

export function ResetByEmailPage(): React.ReactElement {
  const {t} = useSafeTranslation()
  const [emailResetStep, setEmailResetStep] = React.useState<EmailResetStepT>('email-form')

  const onEmailSent = () => {
    setEmailResetStep('email-sent')
  }

  const onGoogleLogin = () => {
    setEmailResetStep('google-login')
  }

  let body
  switch (emailResetStep) {
    case 'email-sent':
      body = <EmailSentMessage />
      break
    case 'google-login':
      body = <GoogleLogin />
      break
    case 'email-form':
      body = <EmailForm onEmailSent={onEmailSent} onGoogleLogin={onGoogleLogin} />
      break
    default:
      return exhaustive(emailResetStep, 'ResetByEmailPage')
  }

  return (
    <PageWrapper pageType="resetByEmail">
      <>
        <FreckleLogo pageType="resetByEmail" />
        <H1 addClass={titleMsgStyle} dataTest="title-msg">
          {t('RESET_PASSWORD_TITLE_TEXT')}
        </H1>
        <Container addClass={resetByEmailContainer}>{body}</Container>
      </>
    </PageWrapper>
  )
}
