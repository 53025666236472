import * as React from 'react'
import {SafeTrans} from '@freckle/educator-materials/ts/helpers/translate'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons'
import {Link} from '@freckle/educator-materials/ts/components/link'
import {Routes} from '@freckle/classroom/ts/common/routers/routes'

import {loginLinkStyle, successIconStyle, emailSuccessBodyStyle} from './email-sent-msg.module.scss'

export function EmailSentMessage(): React.ReactElement {
  const loginLink = (
    <Link
      style="btn-primary"
      size="lg"
      addClass={loginLinkStyle}
      dataTest="return-login-button"
      href={Routes.login()}
      reactRouter
    />
  )

  return (
    <>
      <FontAwesomeIcon icon={faCheckCircle} className={successIconStyle} size="3x" />
      <span className={emailSuccessBodyStyle}>
        <SafeTrans i18nKey="RESET_PASSWORD_BY_EMAIL_SUCCESS_TEXT" components={{loginLink}} />
      </span>
    </>
  )
}
