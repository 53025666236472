import find from 'lodash/find'
import {WithResourcesError} from '@freckle/educator-entities/ts/common/components/with-resources/with-resources-error'

type CustomErrorGroupT = {
  hash: string
  errorMessages: Array<string>
}

function referenceErrorMessages(variable: string): Array<string> {
  return [
    `'${variable}' is not defined`,
    `${variable} is not defined`,
    `'${variable}' is undefined`,
    `${variable} is undefined`,
    `Can't find variable: ${variable}`,
    `ReferenceError: Can't find variable: ${variable}`,
  ]
}

const customGroups: Array<CustomErrorGroupT> = [
  {
    hash: 'Jquery is undefined',
    errorMessages: referenceErrorMessages('$').concat([
      "Cannot read property 'ajaxSetup' of undefined",
      "Cannot read property 'ajax' of undefined",
    ]),
  },
  {
    hash: 'MathJax is undefined',
    errorMessages: referenceErrorMessages('MathJax'),
  },
  {
    hash: 'Out of memory',
    errorMessages: [
      'Error: Out of memory',
      'Error: Out of memory ',
      'Error: out of memory',
      'Error: out of memory ',
      'Error Out of memory',
      'Error Out of memory ',
      'Error out of memory',
      'Error out of memory ',
      'Out of memory',
      'Out of memory ',
      'out of memory',
      'out of memory ',
    ],
  },
  {
    hash: 'Access is denied.',
    errorMessages: [
      'Error: Access is denied.',
      'Error: Access is denied.\r\n',
      'Error Access is denied.',
      'Error Access is denied.\r\n',
      'Access is denied.',
      'Access is denied.\r\n',
    ],
  },
  {
    hash: 'Unexpected syntax',
    errorMessages: [
      "Unexpected token ']'",
      "Unexpected token ')'",
      "Unexpected token ';'",
      "Unexpected token '<'",
      'Unexpected token ILLEGAL',
      'Unexpected end of input',
      'Unexpected identifier',
      'Unexpected number',
      'Unexpected token else',
    ],
  },
  {
    hash: 'Undefined window.webkit.messageHandlers',
    errorMessages: [
      "undefined is not an object (evaluating 'window.webkit.messageHandlers')",
      "TypeError undefined is not an object (evaluating 'window.webkit.messageHandlers')",
    ],
  },
]

// TODO: can change from any to BugsnagEventT after the type has been converted from Flow to TS
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getCustomGroupingHash(event: any): string | null {
  if (event.originalError instanceof WithResourcesError) {
    return event.originalError.bugsnagGroupingHash()
  }

  const group = find(customGroups, group =>
    // event.errors[0] array contains at least one Error that represents the thrown object with subsequent
    // elements representing errors that caused the preceding error
    group.errorMessages.includes(event.errors[0].errorMessage)
  )
  return group !== undefined ? group.hash : null
}
