import {PATHS} from '@freckle/educator-entities/ts/common/helpers/paths'
import {RlStandardIdT} from '@freckle/educator-entities/ts/common/types/rl-standard-id'
import {
  ContentArea,
  ContentAreas,
} from '@freckle/educator-entities/ts/curricula-api/generated-client/enums/content-area'
import {ajaxJsonCall} from '@freckle/ajax'
import {
  MathQuestionAttrs,
  parseMathQuestion,
} from '@freckle/educator-entities/ts/math/common/models/questions'
import {
  TaggedElaQuestionAttrs,
  ElaQuestionParseInput,
  parseElaQuestion,
} from '@freckle/educator-entities/ts/ela/common/models/questions'
import {
  QuestionWorkedExamples,
  parseQuestionWorkedExamples,
} from '@freckle/classroom/ts/common/models/question-worked-examples'
import {
  QuestionAlignment,
  parseQuestionAlignment,
} from '@freckle/classroom/ts/common/models/question-alignment'

export type QuestionResponseT = MathQuestionResponseT | ElaQuestionResponseT | null

export type MathQuestionResponseT = {
  tag: 'math'
  questions: MathQuestionAttrs[]
  alignments: QuestionAlignment[]
  workedExamples?: QuestionWorkedExamples[]
}

export type ElaQuestionResponseT = {
  tag: 'ela'
  questions: TaggedElaQuestionAttrs[]
  alignments: QuestionAlignment[]
}

export async function fetchSampleQuestion(
  lang: string,
  contentArea: ContentArea,
  standardSetId: string,
  standard: RlStandardIdT
): Promise<QuestionResponseT> {
  const res: {
    questions: {tag: 'math' | 'ela'; value: unknown}[]
    workedExamples: unknown[]
    alignment: unknown[]
  } = await ajaxJsonCall({
    url: `${PATHS.textAssetsUrl}/questions?language=${lang}&content-area=${contentArea}&standard-set=${standardSetId}&standard=${standard}&sample&expand=alignment,workedExamples`,
    method: 'GET',
  })

  if (res.questions.length === 0) return null

  if (contentArea === ContentAreas.Math)
    return {
      tag: 'math',
      questions: res.questions.map(q => parseMathQuestion(q.value)),
      alignments: res.alignment.map(a => parseQuestionAlignment(a)),
      workedExamples: res.workedExamples.map(w => parseQuestionWorkedExamples(w)),
    } as MathQuestionResponseT

  return {
    tag: 'ela',
    questions: res.questions.map(q => parseElaQuestion(q.value as ElaQuestionParseInput)),
    alignments: res.alignment.map(a => parseQuestionAlignment(a)),
  } as ElaQuestionResponseT
}

export async function fetchQuestionAlignment(
  lang: string,
  contentArea: ContentArea,
  standardSetId: string,
  questions: string[]
): Promise<QuestionResponseT> {
  const res: {
    questions: {tag: 'math' | 'ela'; value: unknown}[]
    alignment: unknown[]
  } = await ajaxJsonCall({
    url: `${
      PATHS.textAssetsUrl
    }/questions?language=${lang}&content-area=${contentArea}&standard-set=${standardSetId}&questions=${questions.join(
      ','
    )}&expand=alignment`,
    method: 'GET',
  })

  if (contentArea === ContentAreas.Math)
    return {
      tag: 'math',
      questions: res.questions.map(q => parseMathQuestion(q.value)),
      alignments: res.alignment.map(a => parseQuestionAlignment(a)),
    }

  return {
    tag: 'ela',
    questions: res.questions.map(q => parseElaQuestion(q.value as ElaQuestionParseInput)),
    alignments: res.alignment.map(a => parseQuestionAlignment(a)),
  }
}
