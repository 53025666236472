import {exhaustive} from '@freckle/exhaustive'

type PremiumStatus = 'paid' | 'free'

const FREE_TEACHER_SUPPORT_UK = 'https://frontrowed.wufoo.com/forms/freckle-support-request/'
const PAID_TEACHER_SUPPORT_UK = 'https://frontrowed.wufoo.com/forms/k15d3l6c06us99d/'
const TEACHER_SUPPORT_US = 'https://www.renaissance.com/request-support/'

export function getSupportLink(
  premium: PremiumStatus,
  countryCode: string | null | undefined
): string {
  switch (premium) {
    case 'free':
      return getTeacherSupportLink(true, countryCode)
    case 'paid':
      return getTeacherSupportLink(false, countryCode)
    default:
      return exhaustive(premium, 'PremiumStatus')
  }
}

function getTeacherSupportLink(
  isFreeTeacher: boolean,
  countryCode: string | null | undefined
): string {
  switch (countryCode) {
    case 'GBR':
    case 'IRL':
      //field25 is the country field ID in wufoo form for "Freckle Support Request" form for free teacher support (for UK only)
      //field23 is the country field ID in wufoo form for "Freckle Premium Support Request" form for premium support (for UK only)
      return isFreeTeacher
        ? `${FREE_TEACHER_SUPPORT_UK}def/field25=${countryCode}`
        : `${PAID_TEACHER_SUPPORT_UK}def/field23=${countryCode}`
    default:
      return TEACHER_SUPPORT_US
  }
}
