import * as React from 'react'

import {AuthRedirectWrapper} from './auth-redirect'

type Props = {
  component: React.ComponentType<{}>
}

export function AuthRedirectRoute(props: Props): React.ReactElement {
  const {component: Component} = props

  return (
    <AuthRedirectWrapper>
      <Component />
    </AuthRedirectWrapper>
  )
}
