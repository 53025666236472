import * as React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {IconProp} from '@fortawesome/fontawesome-svg-core'
import {asHTMLAttributeValue} from '@freckle/maybe'
import {exhaustive} from '@freckle/exhaustive'
import {addIfClassNames} from '@freckle/educator-entities/ts/common/helpers/classnames'

import * as styleCSS from './button.module.scss'

type IconType = IconProp

export type ButtonStyleT =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'premium'
  | 'danger'
  | 'warning'
  | 'success'
  | 'incorrect'
  | 'link'

export type ButtonSizeT = 'sm' | 'md' | 'lg' | 'xlg'
export type ButtonTypeT = 'button' | 'reset' | 'submit'

export type Props = {
  /** `sm` or `md` or `lg or `xlg` */
  size?: ButtonSizeT
  /** Action of the button */
  onClick?: (e: React.SyntheticEvent<EventTarget, Event>) => void
  /** Content of the button */
  children?: React.ReactNode
  /** Accessibility information for symbolic buttons **/
  ariaLabel?: string
  ariaHaspopup?: boolean
  ariaExpanded?: boolean
  ariaControls?: string
  ariaChecked?: boolean
  role?: string
  /** Additional CSS Class to add to the button */
  addClass?: string | null
  /** Define the style of the Button */
  style?: ButtonStyleT
  fullWidth?: boolean
  noWrap?: boolean
  disabled?: boolean
  tabIndex?: number | string
  /** Defines HTML button type attribute in `button`, `reset`, `submit` */
  type?: ButtonTypeT
  dataTest?: string
  dataPendo?: string
  autoFocus?: boolean
  title?: string
  id?: string | number
  // If a button submits a form that is not its parent
  // refer to its id here
  form?: string
  leftIcon?: IconType
  rightIcon?: IconType
  onMouseEnter?: (e: React.SyntheticEvent<EventTarget, Event>) => void
  onMouseLeave?: (e: React.SyntheticEvent<EventTarget, Event>) => void
  onMouseOver?: (e: React.SyntheticEvent<EventTarget, Event>) => void
  onMouseOut?: (e: React.SyntheticEvent<EventTarget, Event>) => void
  selected?: boolean
  rounded?: boolean
}

/**
 * This is the standard Button.
 *
 * It contains the most used attribute of the regular HTML `<button>` tag.
 *
 */

export const Button = React.forwardRef<HTMLButtonElement, Props>(
  ({style = 'primary', size = 'md', disabled = false, type = 'button', ...restProps}, ref) => {
    const {
      onClick,
      children,
      id,
      dataTest,
      dataPendo,
      tabIndex,
      autoFocus,
      title,
      ariaLabel,
      ariaHaspopup,
      ariaExpanded,
      ariaControls,
      ariaChecked,
      role,
      leftIcon,
      rightIcon,
      onMouseEnter,
      onMouseLeave,
      onMouseOver,
      onMouseOut,
      form,
    } = restProps

    const props = {
      style,
      size,
      disabled,
      type,
      ...restProps,
    }

    const className = getClassName(props)

    const handleClick = disabled ? preventClick : onClick

    const idObj = {id: asHTMLAttributeValue(id) as string}
    const titleObj = {title: asHTMLAttributeValue(title)}
    const dataTestObj = {'data-test': asHTMLAttributeValue(dataTest)}
    const dataPendoObj = {'data-pendo': asHTMLAttributeValue(dataPendo)}
    const disabledObj = {disabled: disabled ? true : undefined}
    const autoFocusObj = {autoFocus: autoFocus === true ? true : undefined}
    const tabIndexObj = {tabIndex: asHTMLAttributeValue(tabIndex) as number}
    const onMouseEnterObj = {onMouseEnter: asHTMLAttributeValue(onMouseEnter)}
    const onMouseLeaveObj = {onMouseLeave: asHTMLAttributeValue(onMouseLeave)}
    const onMouseOverObj = {onMouseOver: asHTMLAttributeValue(onMouseOver)}
    const onMouseOutObj = {onMouseOut: asHTMLAttributeValue(onMouseOut)}
    const formObj = {form: asHTMLAttributeValue(form)}
    const ariaControlsObj = {'aria-controls': asHTMLAttributeValue(ariaControls)}
    const roleObj = {role: asHTMLAttributeValue(role)}
    const ariaCheckedObj = {'aria-checked': asHTMLAttributeValue(ariaChecked)}

    const additionalProps = {
      ...disabledObj,
      ...idObj,
      ...titleObj,
      ...autoFocusObj,
      ...dataTestObj,
      ...dataPendoObj,
      ...tabIndexObj,
      ...onMouseEnterObj,
      ...onMouseLeaveObj,
      ...onMouseOverObj,
      ...onMouseOutObj,
      ...formObj,
      ...ariaControlsObj,
      ...ariaCheckedObj,
      ...roleObj,
    }

    const leftIconNode =
      leftIcon !== undefined ? (
        <span className={styleCSS.leftIconContainer}>
          <FontAwesomeIcon icon={leftIcon} aria-hidden="true" />
        </span>
      ) : null

    const rightIconNode =
      rightIcon !== undefined ? (
        <span className={styleCSS.rightIconContainer}>
          <FontAwesomeIcon icon={rightIcon} aria-hidden="true" />
        </span>
      ) : null

    return (
      <button
        ref={ref}
        onClick={handleClick}
        type={type}
        className={className}
        aria-label={ariaLabel}
        aria-haspopup={ariaHaspopup}
        aria-expanded={ariaExpanded}
        {...additionalProps}
      >
        {leftIconNode}
        {children}
        {rightIconNode}
      </button>
    )
  }
)
Button.displayName = 'Button'

function preventClick(e: React.SyntheticEvent<EventTarget, Event>) {
  e.preventDefault()
}

function getClassName(props: Props): string {
  const {addClass, fullWidth, noWrap, style, size, selected, rounded} = props

  const baseClass = style !== 'link' ? styleCSS.button : styleCSS.link

  return addIfClassNames([
    [true, `${baseClass} ${styleCSS[style as string]}`],
    [style !== 'link', styleCSS[size as string]],
    [fullWidth, styleCSS.fullWidth],
    [noWrap, styleCSS.noWrap],
    [selected, getSelectedStyle(style)],
    [rounded, styleCSS.rounded],
    [addClass !== null && addClass !== undefined, addClass],
  ])
}

function getSelectedStyle(buttonStyle?: ButtonStyleT | null): string {
  switch (buttonStyle) {
    case 'primary':
    case 'secondary':
    case 'tertiary':
    case 'danger':
    case 'warning':
    case 'success':
    case 'premium':
      return styleCSS.selectedStyle
    case 'incorrect':
    case 'link':
    case null:
    case undefined:
      return ''

    default:
      return exhaustive(buttonStyle, 'ButtonStyleT')
  }
}
