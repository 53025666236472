import * as React from 'react'

type Props = {
  src: string
  alt?: string
  title?: string
  dataTest?: string
  /** CSS Class to add to the button */
  className?: string
  height?: string
  width?: string
  onLoad?: () => void
  onError?: () => void
  imgRef?: React.LegacyRef<HTMLImageElement>
  draggable?: boolean
  tabIndex?: number
}

function Image(props: Props): React.ReactElement {
  const {src, alt = '', title = '', dataTest, imgRef, tabIndex, ...rest} = props
  const dataTestObj = dataTest !== undefined ? {'data-test': dataTest} : {}

  const additionalProps = {
    ...dataTestObj,
    ...(tabIndex !== undefined ? {tabIndex} : tabIndex),
  }

  return <img src={src} alt={alt} title={title} ref={imgRef} {...additionalProps} {...rest} />
}

export {Image}
