import {ajaxJsonCall} from '@freckle/ajax'
import {Moment} from 'moment-timezone'
import {ParserT, Parser, record, nullable, date, mapStatic} from '@freckle/parser'

import CApiHelper from '@freckle/educator-entities/ts/common/helpers/common-api-helper'

export type PremiumCutoff = Moment

const makePremiumCutoff = (m: Moment): PremiumCutoff => m

export type PremiumLicensesAttrs = {
  mathPremiumUntil: Moment | undefined | null
  elaPremiumUntil: Moment | undefined | null
  socialStudiesPremiumUntil: Moment | undefined | null
  sciencePremiumUntil: Moment | undefined | null
  premiumCutoff: PremiumCutoff
}

export const parsePremiumLicensesAttrs: ParserT<PremiumLicensesAttrs> = record({
  mathPremiumUntil: nullable(date()),
  elaPremiumUntil: nullable(date()),
  socialStudiesPremiumUntil: nullable(date()),
  sciencePremiumUntil: nullable(date()),
  premiumCutoff: mapStatic(date(), makePremiumCutoff),
})

export const parsePremiumLicenses = Parser.mkRun<PremiumLicensesAttrs>(parsePremiumLicensesAttrs)

export async function fetchPremiumLicenses(): Promise<PremiumLicensesAttrs> {
  const url = CApiHelper.fancyPaths.v2.premium_licenses()
  const response = await ajaxJsonCall({url, method: 'GET'})
  return parsePremiumLicenses(response)
}
