import {ajaxJsonCall} from '@freckle/ajax'
import {Moment} from 'moment-timezone'
import uniq from 'lodash/uniq'
import flatten from 'lodash/flatten'
import {mapMaybes} from '@freckle/maybe'
import {fromJust} from '@freckle/maybe'
import {exhaustive} from '@freckle/exhaustive'
import {NonEmptyArray} from '@freckle/non-empty'
import {PATHS} from '@freckle/educator-entities/ts/common/helpers/paths'
import {
  ParserT,
  Parser,
  firstOf,
  field,
  number,
  string,
  literal,
  array,
  nonEmptyArray,
  boolean,
  nullable,
  nullableDefined,
  record,
  stringEnum,
  date,
} from '@freckle/parser'
import {ReadAloudSupportT} from '@freckle/educator-entities/ts/common/models/read-aloud-support'
import {
  RlSkillUspIdT,
  parse as parseUspId,
} from '@freckle/educator-entities/ts/common/types/rl-skill-usp-id'
import sortFreckleTextLevels from '@freckle/educator-entities/ts/ela/articles/helpers/sort-freckle-text-levels'
import {GenreT} from '@freckle/educator-entities/ts/ela/articles/helpers/genre'
import Genres from '@freckle/educator-entities/ts/ela/articles/helpers/genre'
import {HTMLText, htmlTextParser} from '@freckle/educator-entities/ts/common/models/html-text'

import {StudentFreckleTextLevelT} from '@freckle/educator-entities/ts/common/types/student-freckle-text-level'

export type ELAReadingQuestion = {
  questionUuid: string
  questionText: string
  questionOptions: Array<string>
  questionAnswersIndexes: Array<number>
  standardProxyRlSkill: RlSkillUspIdT
}

const parseElaReadingQuestion: ParserT<ELAReadingQuestion> = record({
  questionUuid: field(string(), 'uuid'),
  questionText: field(string(), 'question'),
  questionOptions: field(array(string()), 'options'),
  questionAnswersIndexes: field(array(number()), 'answersIndexes'),
  standardProxyRlSkill: parseUspId,
})

export type ELAWritingQuestion = {
  questionUuid: string
  questionText: HTMLText
  standardProxyRlSkills: Array<RlSkillUspIdT>
}

const parseElaWritingQuestion: ParserT<ELAWritingQuestion> = record({
  questionUuid: field(string(), 'uuid'),
  questionText: field(htmlTextParser, 'question'),
  standardProxyRlSkills: array(parseUspId),
})

export type Paragraph =
  | {
      tag: 'text'
      text: string
    } // Used for tagged vocab
  | {
      tag: 'text-vocab'
      text: string
      html: string
    }
  | {
      tag: 'title'
      title: string
    }
  | {
      tag: 'picture'
      picture: string
      caption: string | undefined | null
    }
  | {
      tag: 'picture_url'
      pictureUrl: string
      caption: string | undefined | null
    }
  | {
      tag: 'audio'
      audio: string
    }
  | {
      tag: 'audio_url'
      audioUrl: string
    }

export const parseElaParagraph: ParserT<Paragraph> = firstOf(
  record({
    tag: field(literal('text'), 'type'),
    text: field(string(), 'content'),
  }),
  record({
    tag: field(literal('title'), 'type'),
    title: field(string(), 'content'),
  }),
  record({
    tag: field(literal('picture'), 'type'),
    picture: field(string(), 'content'),
    caption: nullable(string()),
  }),
  record({
    tag: field(literal('picture_url'), 'type'),
    pictureUrl: field(string(), 'content'),
    caption: nullable(string()),
  }),
  record({
    tag: field(literal('audio'), 'type'),
    audio: field(string(), 'content'),
  }),
  record({
    tag: field(literal('audio_url'), 'type'),
    audioUrl: field(string(), 'content'),
  })
)

export type ArticleLevel = {
  uuid: string
  freckleTextLevel: string
  isDeprecated: boolean
  paragraphs: Array<Paragraph>
  writing: Array<ELAWritingQuestion> | undefined | null
  reading: Array<ELAReadingQuestion>
  audioFilename: string | undefined | null
  vocab: Array<string> | undefined | null
  context: NonEmptyArray<string> | null
}

const parseArticleLevel: ParserT<ArticleLevel> = record({
  uuid: string(),
  freckleTextLevel: string(),
  isDeprecated: boolean(),
  paragraphs: Parser.map(array(parseElaParagraph), 'filterDeprecatedPicture', paragraphs =>
    paragraphs.filter(({tag}) => tag !== 'picture')
  ),
  writing: nullable(array(parseElaWritingQuestion)),
  reading: array(parseElaReadingQuestion),
  audioFilename: nullable(string()),
  vocab: nullable(array(string())),
  context: nullableDefined(nonEmptyArray(string())),
})

type ReadingAnchorSkill =
  | 'Explicit Information'
  | 'Summarizing & Main Ideas'
  | 'Analyzing Connections'
  | 'Word Meaning & Choice'
  | 'Text Structure & Development'
  | 'Author’s Intent'
  | 'Presentation of Content'
  | 'Claims & Evidence'
  | 'Multiple Texts'

const ExplicitInformation: ReadingAnchorSkill = 'Explicit Information'
const SummarizingMainIdeas: ReadingAnchorSkill = 'Summarizing & Main Ideas'
const AnalyzingConnections: ReadingAnchorSkill = 'Analyzing Connections'
const WordMeaningChoice: ReadingAnchorSkill = 'Word Meaning & Choice'
const TextStructureDevelopment: ReadingAnchorSkill = 'Text Structure & Development'
const AuthorsIntent: ReadingAnchorSkill = 'Author’s Intent'
const PresentationOfContent: ReadingAnchorSkill = 'Presentation of Content'
const ClaimsEvidence: ReadingAnchorSkill = 'Claims & Evidence'
const MultipleTexts: ReadingAnchorSkill = 'Multiple Texts'

type WritingAnchorSkill =
  | 'Argument'
  | 'Informative'
  | 'Narrative'
  | 'Writing Conventions'
  | 'Revise & Edit'
  | 'Technology Usage'
  | 'Research Projects'
  | 'Multiple Sources'
  | 'Drawing Evidence'
  | 'Write Routinely'

const Argument: WritingAnchorSkill = 'Argument'
const Informative: WritingAnchorSkill = 'Informative'
const Narrative: WritingAnchorSkill = 'Narrative'
const WritingConventions: WritingAnchorSkill = 'Writing Conventions'
const ReviseEdit: WritingAnchorSkill = 'Revise & Edit'
const TechnologyUsage: WritingAnchorSkill = 'Technology Usage'
const ResearchProjects: WritingAnchorSkill = 'Research Projects'
const MultipleSources: WritingAnchorSkill = 'Multiple Sources'
const DrawingEvidence: WritingAnchorSkill = 'Drawing Evidence'
const WriteRoutinely: WritingAnchorSkill = 'Write Routinely'

type LanguageAnchorSkill =
  | 'Grammar Conventions'
  | 'Punctuation & Spelling'
  | 'English Knowledge'
  | 'Vocabulary'
  | 'Figurative Language'

const GrammarConventions: LanguageAnchorSkill = 'Grammar Conventions'
const PunctuationAndSpelling: LanguageAnchorSkill = 'Punctuation & Spelling'
const EnglishKnowledge: LanguageAnchorSkill = 'English Knowledge'
const Vocabulary: LanguageAnchorSkill = 'Vocabulary'
const FigurativeLanguage: LanguageAnchorSkill = 'Figurative Language'

type SpeechAndListeningAnchorSkill =
  | 'Collaborative Discussion'
  | 'Understand Text in Multiple Media Formats'
  | 'Listen Effectively'
  | 'Oral Reports'
  | 'Use Multimedia Components'
  | 'Speech in Various Contexts'

const CollaborativeDiscussion: SpeechAndListeningAnchorSkill = 'Collaborative Discussion'
const UnderstandTextInMultipleMediaFormats: SpeechAndListeningAnchorSkill =
  'Understand Text in Multiple Media Formats'
const ListenEffectively: SpeechAndListeningAnchorSkill = 'Listen Effectively'
const OralReports: SpeechAndListeningAnchorSkill = 'Oral Reports'
const UseMultimediaComponents: SpeechAndListeningAnchorSkill = 'Use Multimedia Components'
const SpeechInVariousContexts: SpeechAndListeningAnchorSkill = 'Speech in Various Contexts'

type AnyAnchorSkill =
  | ReadingAnchorSkill
  | WritingAnchorSkill
  | LanguageAnchorSkill
  | SpeechAndListeningAnchorSkill

export type ArticleAttrs = {
  articleUuid: string
  title: string
  diagramUrl: string
  genre: GenreT
  author: string | null
  datePublished: Moment | null
  isSelfAssignable: boolean
  isOnlySocialStudies: boolean
  isOnlyScience: boolean
  levels: Array<ArticleLevel>
}

const parseArticleAttrs: ParserT<ArticleAttrs> = record({
  articleUuid: field(string(), 'uuid'),
  title: string(),
  diagramUrl: string(),
  genre: stringEnum('GenreT', Genres.parseLenient),
  author: nullableDefined(string()),
  datePublished: nullableDefined(date()),
  isSelfAssignable: boolean(),
  isOnlySocialStudies: boolean(),
  isOnlyScience: boolean(),
  levels: array(parseArticleLevel),
})

export const parseArticle = Parser.mkRun<ArticleAttrs>(parseArticleAttrs)

export function getArticleLevel(article: ArticleAttrs, uuid: string): ArticleLevel {
  const articleLevels = article.levels
  return fromJust(
    articleLevels.find(level => level.uuid === uuid),
    `Could not find reading level for article level id ${uuid}`
  )
}

export const getLevelsSortedByFreckleTextLevel = (article: ArticleAttrs): Array<ArticleLevel> =>
  article.levels.sort(sortFreckleTextLevels)

export function getAllVocabWords(article: ArticleAttrs): Array<string> {
  const filteredLevels = article.levels.filter(
    level => level.vocab !== null && level.vocab !== undefined && level.vocab.length > 0
  )
  return uniq(flatten(mapMaybes(filteredLevels, level => level.vocab)))
}

function getReadingStandardAnchor(standardAnchorIndex: number): ReadingAnchorSkill | undefined {
  switch (standardAnchorIndex) {
    case 1:
      return ExplicitInformation
    case 2:
      return SummarizingMainIdeas
    case 3:
      return AnalyzingConnections
    case 4:
      return WordMeaningChoice
    case 5:
      return TextStructureDevelopment
    case 6:
      return AuthorsIntent
    case 7:
      return PresentationOfContent
    case 8:
      return ClaimsEvidence
    case 9:
      return MultipleTexts
    default:
      console.error(`getReadingStandardAnchor: Invalid standard Index: ${standardAnchorIndex}`)
  }
}

function getWritingStandardAnchor(standardAnchorIndex: number): WritingAnchorSkill | undefined {
  switch (standardAnchorIndex) {
    case 1:
      return Argument
    case 2:
      return Informative
    case 3:
      return Narrative
    case 4:
      return WritingConventions
    case 5:
      return ReviseEdit
    case 6:
      return TechnologyUsage
    case 7:
      return ResearchProjects
    case 8:
      return MultipleSources
    case 9:
      return DrawingEvidence
    case 10:
      return WriteRoutinely
    default:
      console.error(`getWritingStandardAnchor: Invalid standard Index: ${standardAnchorIndex}`)
  }
}

function getLanguageStandardAnchor(standardAnchorIndex: number): LanguageAnchorSkill | undefined {
  switch (standardAnchorIndex) {
    case 1:
      return GrammarConventions
    case 2:
      return PunctuationAndSpelling
    case 3:
      return EnglishKnowledge
    case 4:
      return Vocabulary
    case 5:
      return FigurativeLanguage
    default:
      console.error(`getLanguageStandardAnchor: Invalid standard Index: ${standardAnchorIndex}`)
  }
}

function getSpeechAndListeningStandardAnchor(
  standardAnchorIndex: number
): SpeechAndListeningAnchorSkill | undefined {
  switch (standardAnchorIndex) {
    case 1:
      return CollaborativeDiscussion
    case 2:
      return UnderstandTextInMultipleMediaFormats
    case 3:
      return ListenEffectively
    case 4:
      return OralReports
    case 5:
      return UseMultimediaComponents
    case 6:
      return SpeechInVariousContexts
    default:
      console.error(
        `getSpeechAndListeningStandardAnchor: Invalid standard Index: ${standardAnchorIndex}`
      )
  }
}

export function getAnchorSkillNameFromStandard(standard: string): AnyAnchorSkill | undefined {
  /// split always returns at least one value
  const abbreviation = standard.split('.')[0]
  switch (abbreviation) {
    case 'RL':
    case 'RI':
      return getReadingAnchorSkillNameFromStandard(standard)
    case 'W':
      return getWritingAnchorSkillNameFromStandard(standard)
    case 'L':
      return getLanguageAnchorSkillNameFromStandard(standard)
    case 'SL':
      return getSpeechAndListeningAnchorSkillNameFromStandard(standard)
    default:
      console.error(`getAnchorSkillNameFromStandard: Invalid abbreviation: ${abbreviation}`)
  }
}

function getReadingAnchorSkillNameFromStandard(standard: string): ReadingAnchorSkill | undefined {
  const index = getAnchorSkillIndex(standard)
  return getReadingStandardAnchor(index)
}

export function getWritingAnchorSkillNameFromStandard(
  standard: string
): WritingAnchorSkill | undefined {
  const index = getAnchorSkillIndex(standard)
  return getWritingStandardAnchor(index)
}

function getLanguageAnchorSkillNameFromStandard(standard: string): LanguageAnchorSkill | undefined {
  const index = getAnchorSkillIndex(standard)
  return getLanguageStandardAnchor(index)
}

function getSpeechAndListeningAnchorSkillNameFromStandard(
  standard: string
): SpeechAndListeningAnchorSkill | undefined {
  const index = getAnchorSkillIndex(standard)
  return getSpeechAndListeningStandardAnchor(index)
}

// RI.X.Y
function getAnchorSkillIndex(standard: string): number {
  const reg = /\w+\.\w+\.(\d+)/g
  const res = fromJust(reg.exec(standard), `Not a valid standard ${standard}`)
  // TODO: https://app.asana.com/0/149473556304568/1206945111987245/f
  return parseInt(res[1]!, 10)
}

export function showTTSAudio(
  readAloudSupport: ReadAloudSupportT,
  studentFreckleTextLevel?: StudentFreckleTextLevelT | null
): boolean {
  switch (readAloudSupport) {
    case 'always':
      return true
    case 'never':
      return false
    case 'default':
      return (
        studentFreckleTextLevel !== null &&
        studentFreckleTextLevel !== undefined &&
        TTS_TO_LEVELS_C2_AND_BELOW.includes(studentFreckleTextLevel)
      )
    default:
      return exhaustive(readAloudSupport)
  }
}

// Expose TTS to a subset of article levels
export const TTS_TO_LEVELS_C2_AND_BELOW = ['ER', '1A', '1B', '1C', '2A', '2B', '2C']

export const FRECKLE_TEXT_LEVELS_FOR_EARLY_READER_FORMAT = ['ER', '1A', '1B', '1C']

export async function fetchElaArticle(uuid: string): Promise<ArticleAttrs> {
  const url = `${PATHS.textAssetsUrl}/articles/${uuid}.json`
  const response = await ajaxJsonCall({url, method: 'GET'})
  return parseArticle(response)
}
