import * as React from 'react'
import {TeacherAttrs} from '@freckle/classroom/ts/users/models/teacher'
import {SplitWrapper} from '@freckle/educator-entities/ts/common/components/experiment'

import {WithResources} from '@freckle/classroom/ts/common/components/with-resources'
import {useTeacher} from '@freckle/classroom/ts/hooks/use-teacher'
import {logImpression} from '@freckle/classroom/ts/common/helpers/split-io-factory'

type WrapperProps = React.PropsWithChildren<{}>

type Props = WrapperProps & {
  teacher: TeacherAttrs
}

export function AuthedSplitIOWrapper(props: WrapperProps): React.ReactElement {
  const teacher = useTeacher()
  return (
    <WithResources
      resources={{teacher}}
      render={({teacher}) => <AuthedSplitIOWrapperBase teacher={teacher} {...props} />}
      noSpinner
    />
  )
}

function AuthedSplitIOWrapperBase(props: Props): React.ReactElement {
  const {children, teacher} = props
  const trafficType = 'teacher'
  return (
    <SplitWrapper
      configKey={teacher.id.toString()}
      trafficType={trafficType}
      logImpression={logImpression}
    >
      {children}
    </SplitWrapper>
  )
}
