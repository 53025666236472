import find from 'lodash/find'
import {fromJust} from '@freckle/maybe'
import {PATHS} from '@freckle/educator-entities/ts/common/helpers/paths'
import createClient from 'openapi-fetch'
import {components, paths} from '@freckle/educator-entities/ts/curricula-api/generated-client'
import {HttpError} from '@freckle/educator-entities/ts/common/exceptions/http-error'
import sortFreckleTextLevels from '@freckle/educator-entities/ts/ela/articles/helpers/sort-freckle-text-levels'

export type ElaArticleInfoAttrs = components['schemas']['ElaArticleBrief']

function getFreckleTextLevelForArticleInfo(info: ElaArticleInfoAttrs, uuid: string): string {
  const level = getArticleLevelForArticleInfo(info, uuid)
  return level.freckleTextLevel
}

function getArticleLevelForArticleInfo(
  info: ElaArticleInfoAttrs,
  uuid: string
): components['schemas']['ElaArticleLevelBrief'] {
  const articleLevels = info.levels
  return fromJust(
    find(articleLevels, level => level.id === uuid),
    `Could not find reading level for article level id ${uuid}`
  )
}

export function getFreckleTextLevelForArticleInfoCollection(
  elaArticleInfoCollection: Array<ElaArticleInfoAttrs>,
  articleUuid: string,
  elaArticleLevelId: string
): string {
  const articleInfo = fromJust(
    find(elaArticleInfoCollection, elaArticleInfo => elaArticleInfo.uuid === articleUuid),
    `Could not find article info with uuid ${articleUuid}`
  )
  return getFreckleTextLevelForArticleInfo(articleInfo, elaArticleLevelId)
}

export function getLevelsSortedByFreckleTextLevel(
  elaArticleInfo: ElaArticleInfoAttrs
): Array<components['schemas']['ElaArticleLevelBrief']> {
  return elaArticleInfo.levels.sort(sortFreckleTextLevels)
}

const _client = createClient<paths>({baseUrl: PATHS.textAssetsUrl})

export async function fetchElaArticleInfoCollection(): Promise<
  components['schemas']['ElaArticleBrief'][]
> {
  return _client
    .GET('/ela-article-info.json')
    .then(({response, data}) =>
      response.ok && data !== undefined ? data : Promise.reject(new HttpError(response))
    )
}
