import {ajaxJsonCall} from '@freckle/ajax'
import {TeacherRole} from '@freckle/educator-entities/ts/common/helpers/teacher-role'
import CApiHelper from '@freckle/educator-entities/ts/common/helpers/common-api-helper'
import {LangT} from '@freckle/educator-entities/ts/common/helpers/languages'
import {ElaLevelingProgramT} from '@freckle/educator-entities/ts/ela/common/logic/ela-leveling-program'
import {StudentNameSortFieldT} from '@freckle/educator-entities/ts/users/models/teacher'
import {Leaderboard} from '@freckle/educator-entities/ts/common/helpers/leaderboard'
import {TeacherAttrs, parse} from '@freckle/classroom/ts/users/models/teacher'

/* Get */

export function fetchCurrentTeacher(): Promise<TeacherAttrs> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.teachers.me._(),
    method: 'GET',
  }).then((response: unknown) => parse(response))
}

/* Patch */

export type PatchParamT = {
  role?: TeacherRole | null | undefined
  onboardingGrades?: Array<number>
  focusMode?: boolean
  elaLevelingProgram?: ElaLevelingProgramT | null | undefined
  firstName?: string
  lastName?: string
  email?: string
  mathStandardSetId?: string
  elaStandardSetId?: string | null | undefined
  studentNameSortField?: StudentNameSortFieldT
  leaderboard?: Leaderboard
  language?: LangT
  countryCode?: string
  schoolId?: number | null | undefined
}

export function updateTeacher(teacherId: number, patchParams: PatchParamT): Promise<TeacherAttrs> {
  return ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.teachers._1(teacherId),
    method: 'PATCH',
    data: JSON.stringify(patchParams),
  }).then(parse)
}

export const deleteTeacher = (teacherId: number): Promise<void> =>
  ajaxJsonCall({
    url: CApiHelper.fancyPaths.v2.teachers._1(teacherId),
    method: 'DELETE',
  })
