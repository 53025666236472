import * as React from 'react'
import {asHTMLAttributeValue} from '@freckle/maybe'
import {exhaustive} from '@freckle/exhaustive'
import {addMaybeClassNames} from '@freckle/educator-entities/ts/common/helpers/classnames'
import {BoardSizeT} from '@freckle/educator-materials/ts/helpers/size'

import * as style from './container.module.scss'

type CornerStyleT = 'rounded' | 'square'
type ShadowStyleT = 'none' | 'regular'

type Props = {
  children: React.ReactNode
  addClass?: string | null
  size?: BoardSizeT
  dataTest?: string | null
  fullHeight?: boolean
  onClick?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  corners?: CornerStyleT
  shadowStyle?: ShadowStyleT
}

function Container(props: Props): React.ReactElement {
  const {
    children,
    addClass,
    size = 'lg',
    dataTest,
    onClick,
    onMouseEnter,
    onMouseLeave,
    corners = 'rounded',
    fullHeight = false,
    shadowStyle = 'none',
  } = props

  const addDataTest = {'data-test': asHTMLAttributeValue(dataTest)}
  const addOnClick =
    onClick !== undefined ? {onClick, role: 'button'} : {onClick: undefined, role: undefined}
  const addOnMouseEnter = {onMouseEnter: asHTMLAttributeValue(onMouseEnter)}
  const addOnMouseLeave = {onMouseLeave: asHTMLAttributeValue(onMouseLeave)}

  const contentContainerClassName = addMaybeClassNames(style.container, [
    style[corners],
    getSizeStyle(size),
    addClass,
    fullHeight ? style.fullHeightContainer : null,
    shadowStyle !== 'none' ? style.shadowStyle : null,
  ])

  const optionalProps = {
    ...addDataTest,
    ...addOnClick,
    ...addOnMouseEnter,
    ...addOnMouseLeave,
  }

  const contentContainer = (
    <div className={contentContainerClassName} {...optionalProps}>
      {children}
    </div>
  )

  return contentContainer
}

function getSizeStyle(size: BoardSizeT | string): string {
  switch (size) {
    case 'lg':
      return style.lg

    case 'md':
      return style.md

    case 'sm':
      return style.sm

    case 'xs':
      return style.xs

    case 'empty':
      return style.noPadding

    default:
      return exhaustive(size as never, 'BoardSizeT')
  }
}

export {Container}
