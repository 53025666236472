import {Moment} from 'moment-timezone'
import {
  QueryParamsObjT,
  ParamFiltersT,
  createAPIQueryParams,
  toQueryParamObj,
} from '@freckle/query-params'
import {catMaybes, mmap} from '@freckle/maybe'

/**
 * Parameters used to filter a set of generic assignment sessions in the API
 */
export type ParamsT = {
  mathStandardSetId: string
  elaStandardSetId: string | undefined | null
  isRetry?: boolean | null | undefined
  isSelfAssigned?: boolean | null | undefined
  isComplete?: boolean | null | undefined
  isTeacherAssignedOrRetry?: boolean | null | undefined
  timeSpan?:
    | {
        from: Moment
        to: Moment
      }
    | null
    | undefined
  completedAt?: ParamFiltersT<Moment> | null | undefined
  assignmentIds?: Array<number> | null | undefined
  accuracy?: ParamFiltersT<number> | null | undefined
  decodablesUuid?: string | null | undefined
  studentId?: number
}

/**
 * Turn a ParamsT into a query string to be tacked onto a generic assignment sessions URL
 */
export function fromParams(params: ParamsT): string {
  const pairs = catMaybes([
    `math-standard-set-id=${params.mathStandardSetId}`,
    mmap(elaStandardSetId => `ela-standard-set-id=${elaStandardSetId}`, params.elaStandardSetId),
    mmap(isRetry => (isRetry ? 'is-assignment-retry' : 'is-not-assignment-retry'), params.isRetry),
    mmap(
      isSelfAssigned => (isSelfAssigned ? 'self-assigned' : 'teacher-assigned'),
      params.isSelfAssigned
    ),
    mmap(isComplete => (isComplete ? 'complete' : 'incomplete'), params.isComplete),
    mmap(
      isTeacherAssignedOrRetry => (isTeacherAssignedOrRetry ? 'is-teacher-assigned-or-retry' : ''),
      params.isTeacherAssignedOrRetry
    ),
    mmap(({from, to}) => `from=${from.valueOf()}&to=${to.valueOf()}`, params.timeSpan),
    mmap(x => createAPIQueryParams(toQueryParamObj('completed-at', x)), params.completedAt),
    mmap(assignmentIds => {
      const queryParams: QueryParamsObjT = {assignmentIds}
      return createAPIQueryParams(queryParams)
    }, params.assignmentIds),
    mmap(x => createAPIQueryParams(toQueryParamObj('accuracy', x)), params.accuracy),
    mmap(decodablesUuid => `metadata.decodables-uuid=${decodablesUuid}`, params.decodablesUuid),
    mmap(studentId => `student-id=${studentId}`, params.studentId),
  ])

  return pairs.length === 0 ? '' : `?${pairs.join('&')}`
}
