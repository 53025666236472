import * as React from 'react'
import {SyntheticEvent} from 'react'
import {Link as RouterLink} from 'react-router-dom'
import {useSafeTranslation} from '@freckle/educator-materials/ts/helpers/translate'
import NavDropdown from 'react-bootstrap/NavDropdown'
import MenuItem from 'react-bootstrap/DropdownItem'
import {PremiumLicensesAttrs} from '@freckle/educator-entities/ts/common/models/premium-licenses'
import {RenaissanceIcon} from '@freckle/educator-materials/ts/components/renaissance-icon'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faUserCircle, faSignOutAlt} from '@fortawesome/free-solid-svg-icons'
import {Text} from '@freckle/educator-materials/ts/components/typography'
import {numberOfPremiumSubjects} from '@freckle/educator-entities/ts/common/logic/premium-access'
import {Subjects} from '@freckle/educator-entities/ts/common/helpers/subjects'
import {Badge} from '@freckle/educator-materials/ts/components/badge'
import {addMaybeClassName} from '@freckle/educator-entities/ts/common/helpers/classnames'
import {isManagedByRenaissance} from '@freckle/educator-entities/ts/roster/identity-provider'
import {TeacherAttrs} from '@freckle/classroom/ts/users/models/teacher'
import {Routes} from '@freckle/classroom/ts/common/routers/routes'
import {
  userOptions,
  renaissanceHomeWrapper,
  userIcon,
  logoutIcon,
  premiumMessagingWrapper,
  collapsedDivider,
  premiumMessagingTitle,
  compactPremiumMessagingTitle,
  premiumBadgeStyle,
  teacherNameStyle,
} from './user-options.module.scss'

type Props = {
  teacher: TeacherAttrs
  premiumLicenses: PremiumLicensesAttrs
  onLogout: (e: SyntheticEvent<EventTarget, Event>) => void
}

// Please see Resources component. Once we have a third example, we can abstract
// away the commonalities.
export function UserOptions(props: Props): React.ReactElement {
  const {t} = useSafeTranslation()
  const {teacher, onLogout, premiumLicenses} = props

  const numPremiumSubjects = numberOfPremiumSubjects(premiumLicenses)
  const numTotalSubjects = Subjects.all().length
  const {countryCode} = teacher
  const showPremiumSubjectCount = countryCode !== 'GBR' && countryCode !== 'IRL'

  const titleNode = (
    <>
      {numPremiumSubjects > 0 && (
        <>
          <Badge style="premium" dataTest="top-nav-premium-badge" addClass={premiumBadgeStyle}>
            {t('USER_OPTIONS_PREMIUM_BADGE')}
          </Badge>{' '}
        </>
      )}
      <FontAwesomeIcon icon={faUserCircle} className={userIcon} />
      <span className={teacherNameStyle}>&nbsp;{teacher.firstName}&nbsp;</span>
    </>
  )

  return (
    <NavDropdown
      id="navbar-user-options"
      className={userOptions}
      title={titleNode}
      data-test="navbar-user-options"
    >
      {numPremiumSubjects > 0 && (
        <>
          <MenuItem
            as={RouterLink}
            to={Routes.settings()}
            className={premiumMessagingWrapper}
            data-test="navbar-user-options-premium-messaging"
          >
            <div>
              <Text
                style="subtitle-2"
                addClass={addMaybeClassName(
                  premiumMessagingTitle,
                  showPremiumSubjectCount ? null : compactPremiumMessagingTitle
                )}
              >
                {t('USER_OPTIONS_PREMIUM_MESSAGING_TITLE')}
              </Text>
              {showPremiumSubjectCount && (
                <Text style="caption-2">
                  {t('USER_OPTIONS_PREMIUM_SUBJECT_COUNTS', {
                    numPremiumSubjects,
                    numTotalSubjects,
                  })}
                </Text>
              )}
            </div>
          </MenuItem>
          <MenuItem divider className={collapsedDivider} />
        </>
      )}
      <MenuItem
        as={RouterLink}
        to={Routes.settings()}
        data-test="navbar-user-options-dropdown-settings"
      >
        {t('USER_OPTIONS_SETTINGS')}
      </MenuItem>
      <MenuItem divider className={collapsedDivider} />
      {isManagedByRenaissance(teacher) && (
        <>
          <MenuItem
            href={Routes.ssoLogoutToRenaissance()}
            data-test="navbar-user-options-sso-logout-renaissance-home"
          >
            <span className={renaissanceHomeWrapper}>
              <RenaissanceIcon />
              {t('USER_OPTIONS_RENAISSANCE_HOME')}
            </span>
          </MenuItem>
          <MenuItem divider className={collapsedDivider} />
        </>
      )}
      <MenuItem onClick={onLogout} data-test="navbar-user-options-dropdown-logout">
        <FontAwesomeIcon icon={faSignOutAlt} className={logoutIcon} />
        {t('USER_OPTIONS_LOGOUT')}
      </MenuItem>
    </NavDropdown>
  )
}
