import {NonEmptyArray} from '@freckle/non-empty'
import {
  ParserT,
  Parser,
  string,
  literal,
  boolean,
  record,
  nonEmptyArray,
  stringEnum,
  array,
  firstOf,
} from '@freckle/parser'
import {
  grammarQuestionNotationParser,
  GrammarQuestionNotation,
  GrammarQuestionNotationPiece,
} from '@freckle/educator-entities/ts/ela/skills/models/grammar-question-notation'
import {RlSkillUspIdT} from '@freckle/educator-entities/ts/common/types/rl-skill-usp-id'
import {GrammarQuestionAnswerT, GrammarQuestionAnswer} from './answer'

// Re-export
export type {GrammarQuestionNotation, GrammarQuestionNotationPiece}

export type GrammarQuestionT = {
  uuid: string
  answerType: GrammarQuestionAnswerT
  content: GrammarQuestionContentT
}

export type GrammarQuestionContentT = NonEmptyArray<GrammarQuestionContentType>

type GrammarQuestionContentType =
  | GrammarQuestionContentTypeWithNotation
  | {tag: 'directive'; contents: string}
  | {tag: 'audio'; contents: string}
  | {tag: 'text'; contents: string}

type GrammarQuestionContentTypeWithNotation =
  | {tag: 'sentence'; contents: GrammarQuestionNotation}
  | {tag: 'correct'; contents: GrammarQuestionNotation}
  | {tag: 'incorrect'; contents: GrammarQuestionNotation}
  | {tag: 'structures'; contents: GrammarQuestionNotation}

const parseAttrs: ParserT<GrammarQuestionT> = record({
  uuid: string(),
  answerType: stringEnum<GrammarQuestionAnswerT>(
    'GrammarQuestionAnswerT',
    GrammarQuestionAnswer.parse
  ),
  content: nonEmptyArray(
    firstOf(
      record({tag: literal('directive'), contents: string()}),
      record({tag: literal('audio'), contents: string()}),
      record({tag: literal('text'), contents: string()}),
      record({tag: literal('sentence'), contents: grammarQuestionNotationParser}),
      record({tag: literal('correct'), contents: grammarQuestionNotationParser}),
      record({tag: literal('incorrect'), contents: grammarQuestionNotationParser}),
      record({tag: literal('structures'), contents: grammarQuestionNotationParser})
    )
  ),
})

export const parseGrammarQuestion = Parser.mkRun<GrammarQuestionT>(parseAttrs)

export type GrammarQuestionShellT = {
  answerType: GrammarQuestionAnswerT
  id: string
  skillId: RlSkillUspIdT
  isDeprecated: boolean
}

const parseGrammarQuestionShellAttrs: ParserT<GrammarQuestionShellT> = record({
  id: string(),
  skillId: string(),
  answerType: stringEnum<GrammarQuestionAnswerT>(
    'GrammarQuestionAnswerT',
    GrammarQuestionAnswer.parse
  ),
  isDeprecated: boolean(),
})

export const parseGrammarQuestionShells = Parser.mkRun<Array<GrammarQuestionShellT>>(
  array(parseGrammarQuestionShellAttrs)
)
