import {create} from 'zustand'
import {WrappedState} from '@freckle/educator-entities/ts/common/components/with-resources/with-resources-helpers'
import {CourseAttrs} from '@freckle/educator-entities/ts/roster/models/course'
import {firstResponseApiError} from '@freckle/educator-entities/ts/common/models/api-error'
import {fetchDefaultCourseForTeacher} from '@freckle/classroom/ts/common/models/default-course'

export type CurrentCourseState = WrappedState<CourseAttrs>

const initialCurrentCourseState: CurrentCourseState = {
  status: 'loading',
  data: undefined,
  isFetching: true,
  error: null,
}

type Actions = {
  changeCurrentCourse: (course: CourseAttrs) => void
  setRequestStart: () => void
  setRequestError: (message: string) => void
}

const useStore = create<CurrentCourseState & Actions>()(set => ({
  ...initialCurrentCourseState,
  changeCurrentCourse: (course: CourseAttrs) =>
    set(_ => ({status: 'success', data: course, isFetching: false, error: null})),
  setRequestStart: () =>
    set(state =>
      state.status === 'success'
        ? {status: 'success', data: state.data, isFetching: false, error: null}
        : {status: 'loading', data: undefined, isFetching: true, error: null}
    ),
  setRequestError: (message: string) =>
    set(_ => ({status: 'error', data: undefined, isFetching: false, error: message})),
}))

export const useCurrentCourseActions = (): {
  changeCurrentCourse: (course: CourseAttrs) => void
  loadDefaultCourse: (teacherId: number) => Promise<void>
  currentCourse: CurrentCourseState
} => {
  const {changeCurrentCourse, setRequestStart, setRequestError, ...currentCourse} = useStore()

  const loadDefaultCourse = async (teacherId: number) => {
    if (currentCourse.status !== 'loading') return

    setRequestStart()
    try {
      const defaultCourse = await fetchDefaultCourseForTeacher(teacherId)
      changeCurrentCourse(defaultCourse)
    } catch (error) {
      const apiError = firstResponseApiError(error)
      setRequestError(apiError.message)
    }
  }

  return {
    changeCurrentCourse,
    loadDefaultCourse,
    currentCourse,
  }
}
